import React, { useState, useRef, useEffect } from "react";
import useDynamicHeight from "../../../hooks/useDynamicHeight";
import EndGameOptions from "../../end-game-options/end-game-options";
import Confetti from "react-confetti";
import styles from "./magic-lamps.module.css";

import wallper from "./images/wallper.png";
import serviceMan from "./images/serviceMan.png";
import lampWithLight from "./images/lampWithLight.png";
import blackLamp from "./images/blackLamp.png";
import smallBoat from "./images/smallBoat.png";
import boat from "./images/boat.png";
import smallFlippers from "./images/smallFlippers.png";
import flippers from "./images/flippers.png";
import smallVarnish from "./images/smallVarnish.png";
import varnish from "./images/varnish.png";
import smallSwallow from "./images/smallSwallow.png";
import swallow from "./images/swallow.png";
import smallShovel from "./images/smallShovel.png";
import shovel from "./images/shovel.png";
import smallFlower from "./images/smallFlower.png";
import flower from "./images/flower.png";
import smallOnion from "./images/smallOnion.png";
import onion from "./images/onion.png";
import smallRays from "./images/smallRays.png";
import rays from "./images/rays.png";
import smallZoom from "./images/smallZoom.png";
import zoom from "./images/zoom.png";
import smallBench from "./images/smallBench.png";
import bench from "./images/bench.png";
import smallMoon from "./images/smallMoon.png";
import moon from "./images/moon.png";
import smallPaws from "./images/smallPaws.png";
import paws from "./images/paws.png";
import smallLamp from "./images/smallLamp.png";
import lamp from "./images/lamp.png";

import startInstructionSound from "./sounds/startInstruction.mp3";
import secondInstructionSound from "./sounds/secondInstruction.mp3";
import backgroundSound from "./sounds/backgroundSound.mp3";
import lightOnSound from "./sounds/lightOn.mp3";
import praiseSound from "./sounds/praise.mp3";
import finalSound from "./sounds/finalSound.mp3";

const lampData = [
  {
    id: 1,
    blackLampSrc: blackLamp,
    lampWithLightSrc: lampWithLight,
    smallImageSrc: smallBoat,
    bigImageSrc: boat,
    lampPosition: { top: "30%", left: "14%" },
    stickerPosition: { top: "35%", left: "15.5%" },
  },
  {
    id: 2,
    blackLampSrc: blackLamp,
    lampWithLightSrc: lampWithLight,
    smallImageSrc: smallFlippers,
    bigImageSrc: flippers,
    lampPosition: { top: "24%", left: "1%" },
    stickerPosition: { top: "29%", left: "2.5%" },
  },
  {
    id: 3,
    blackLampSrc: blackLamp,
    lampWithLightSrc: lampWithLight,
    smallImageSrc: smallVarnish,
    bigImageSrc: varnish,
    lampPosition: { top: "22.9%", left: "25%" },
    stickerPosition: { top: "27.9%", left: "26.5%" },
  },
  {
    id: 4,
    blackLampSrc: blackLamp,
    lampWithLightSrc: lampWithLight,
    smallImageSrc: smallSwallow,
    bigImageSrc: swallow,
    lampPosition: { top: "0%", left: "3%" },
    stickerPosition: { top: "5%", left: "4.5%" },
  },
  {
    id: 5,
    blackLampSrc: blackLamp,
    lampWithLightSrc: lampWithLight,
    smallImageSrc: smallShovel,
    bigImageSrc: shovel,
    lampPosition: { top: "7.5%", left: "14%" },
    stickerPosition: { top: "12.5%", left: "15.5%" },
  },
  {
    id: 6,
    blackLampSrc: blackLamp,
    lampWithLightSrc: lampWithLight,
    smallImageSrc: smallFlower,
    bigImageSrc: flower,
    lampPosition: { top: "1.2%", left: "24.6%" },
    stickerPosition: { top: "6.2%", left: "26.1%" },
  },
  {
    id: 7,
    blackLampSrc: blackLamp,
    lampWithLightSrc: lampWithLight,
    smallImageSrc: smallOnion,
    bigImageSrc: onion,
    lampPosition: { top: "7.2%", left: "34.6%" },
    stickerPosition: { top: "12.2%", left: "36.1%" },
  },
  {
    id: 8,
    blackLampSrc: blackLamp,
    lampWithLightSrc: lampWithLight,
    smallImageSrc: smallRays,
    bigImageSrc: rays,
    lampPosition: { top: "7.2%", left: "58%" },
    stickerPosition: { top: "12.2%", left: "59.5%" },
  },
  {
    id: 9,
    blackLampSrc: blackLamp,
    lampWithLightSrc: lampWithLight,
    smallImageSrc: smallZoom,
    bigImageSrc: zoom,
    lampPosition: { top: "1.5%", left: "69%" },
    stickerPosition: { top: "6.5%", left: "70.5%" },
  },
  {
    id: 10,
    blackLampSrc: blackLamp,
    lampWithLightSrc: lampWithLight,
    smallImageSrc: smallBench,
    bigImageSrc: bench,
    lampPosition: { top: "6%", left: "80%" },
    stickerPosition: { top: "11%", left: "81.5%" },
  },
  {
    id: 11,
    blackLampSrc: blackLamp,
    lampWithLightSrc: lampWithLight,
    smallImageSrc: smallMoon,
    bigImageSrc: moon,
    lampPosition: { top: "1%", left: "91%" },
    stickerPosition: { top: "6%", left: "92.5%" },
  },
  {
    id: 12,
    blackLampSrc: blackLamp,
    lampWithLightSrc: lampWithLight,
    smallImageSrc: smallPaws,
    bigImageSrc: paws,
    lampPosition: { top: "22%", left: "66%" },
    stickerPosition: { top: "27%", left: "67.5%" },
  },
  {
    id: 13,
    blackLampSrc: blackLamp,
    lampWithLightSrc: lampWithLight,
    smallImageSrc: smallLamp,
    bigImageSrc: lamp,
    lampPosition: { top: "28%", left: "77.5%" },
    stickerPosition: { top: "33%", left: "79%" },
  },
];

const MagicLampsGame = () => {
  useDynamicHeight();
  const [isInteractionBlocked, setIsInteractionBlocked] = useState(false);
  const [isGameCompleted, setIsGameCompleted] = useState(false);
  const [buttonText, setButtonText] = useState("Начать игру");
  const [isButtonVisible, setIsButtonVisible] = useState(true);
  const [showConfetti, setShowConfetti] = useState(false);
  const [isAnimating, setIsAnimating] = useState(false);

  const [lampStates, setLampStates] = useState(
    lampData.map(() => ({
      lampOn: false,
      showBigImage: false,
      showSmallImage: false,
    }))
  );

  const audioRef = useRef(null);
  const startInstructionAudioRef = useRef(new Audio(startInstructionSound));
  const backgroundAudioRef = useRef(new Audio(backgroundSound));
  const lightOnSoundRef = useRef(new Audio(lightOnSound));
  const finalSoundRef = useRef(new Audio(finalSound));
  const praiseSoundRef = useRef(new Audio(praiseSound));

  const handleLampClick = (index) => {
    if (isAnimating || isInteractionBlocked) return;

    setIsAnimating(true);

    // Воспроизводим звук при включении лампы
    lightOnSoundRef.current.play();

    setLampStates((prevLampStates) => {
      const newLampStates = [...prevLampStates];
      newLampStates[index].lampOn = true;
      newLampStates[index].showBigImage = true;
      setTimeout(() => {
        newLampStates[index].showBigImage = false;
        newLampStates[index].showSmallImage = true;
        setLampStates([...newLampStates]);

        // Проверяем, зажглись ли все лампы
        const allLampsOn = newLampStates.every((lamp) => lamp.lampOn);
        if (allLampsOn) {
          document.querySelector(`.${styles.wallper}`).style.filter = "none";
          document.querySelector(`.${styles.serviceMan}`).style.filter = "none";

          // Воспроизводим звук финала
          finalSoundRef.current.play();

          // Запускаем конфетти и звук похвалы параллельно
          finalSoundRef.current.onended = () => {
            setShowConfetti(true);
            setTimeout(() => {
              setIsGameCompleted(true);
            }, 5000);
            praiseSoundRef.current.play();
          };
        }

        setIsAnimating(false);
      }, 3000); // Показываем большую картинку на 3 секунды
      return newLampStates;
    });
  };

  const handleNextTask = () => {
    setIsInteractionBlocked(true); // Блокируем взаимодействие

    if (startInstructionAudioRef.current) {
      startInstructionAudioRef.current.play();

      startInstructionAudioRef.current.onended = () => {
        const secondInstructionAudio = new Audio(secondInstructionSound);
        secondInstructionAudio.play();

        secondInstructionAudio.onended = () => {
          setIsInteractionBlocked(false); // Разблокируем взаимодействие после окончания второй инструкции
          if (backgroundAudioRef.current) {
            backgroundAudioRef.current.loop = true; // Включаем повтор для фоновой музыки
            backgroundAudioRef.current.play(); // Запускаем фоновую музыку
          }
        };
      };
    }

    setIsButtonVisible(false);
  };

  useEffect(() => {
    return () => {
      startInstructionAudioRef.current.pause();
      startInstructionAudioRef.current.currentTime = 0;
      backgroundAudioRef.current.pause();
      backgroundAudioRef.current.currentTime = 0;

      finalSoundRef.current.pause();
      finalSoundRef.current.currentTime = 0;

      praiseSoundRef.current.pause();
      praiseSoundRef.current.currentTime = 0;
    };
  }, []);

  return (
    <>
      {showConfetti && (
        <Confetti
          numberOfPieces={500}
          angle={180}
          spread={120}
          origin={{ x: 1, y: 0.5 }}
        />
      )}

      {isGameCompleted && <EndGameOptions />}
      <div className={`container ${styles.someOtherClass}`}>
        <div className={styles.backgroundContainer}>
          <div className={styles.dropContainer}>
            <img className={styles.wallper} src={wallper} alt="Room" />
            <img
              className={styles.serviceMan}
              src={serviceMan}
              alt="Service Man"
            />
            {lampData.map((lamp, index) => (
              <React.Fragment key={lamp.id}>
                <img
                  className={styles.lamp}
                  src={
                    lampStates[index].lampOn
                      ? lamp.lampWithLightSrc
                      : lamp.blackLampSrc
                  }
                  alt={`Lamp ${lamp.id}`}
                  onClick={() => handleLampClick(index)}
                  style={{
                    top: lamp.lampPosition.top,
                    left: lamp.lampPosition.left,
                    position: "absolute",
                  }}
                />
                {lampStates[index].showBigImage && (
                  <img
                    className={styles.bigImage}
                    src={lamp.bigImageSrc}
                    alt={`Big Image ${lamp.id}`}
                    style={{
                      top: "50%",
                      left: "50%",
                      width: "30%",
                      position: "absolute",
                      transition: "all 1s ease-in-out",
                      transform: "translate(-50%, -50%)",
                    }}
                  />
                )}
                {lampStates[index].showSmallImage && (
                  <img
                    className={styles.smallImage}
                    src={lamp.smallImageSrc}
                    alt={`Small Image ${lamp.id}`}
                    style={{
                      top: lamp.stickerPosition.top,
                      left: lamp.stickerPosition.left,
                      width: "6%",
                      position: "absolute",
                      transition: "all 1s ease-in-out",
                    }}
                  />
                )}
              </React.Fragment>
            ))}
          </div>
        </div>
      </div>
      <audio ref={audioRef} />
      {isButtonVisible && (
        <div className={styles.darkOverlay}>
          <button className={styles.button} onClick={handleNextTask}>
            {buttonText}
          </button>
        </div>
      )}
    </>
  );
};

export default MagicLampsGame;
