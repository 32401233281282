import { config } from "./api";

function handleResponse(response) {
  if (!response.ok) {
    return response
      .json()
      .then((errorData) => {
        // Предполагая, что сервер отправляет информативные сообщения об ошибке
        const error = (errorData && errorData.message) || response.statusText;
        return Promise.reject(new Error(error));
      })
      .catch(() => {
        // Обработка случаев, когда тело ответа не является JSON
        return Promise.reject(
          new Error(`HTTP error! status: ${response.status}`)
        );
      });
  }
  return response.json();
}

function getCookie(name) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(";").shift();
}

export function getUserSubscriptionInfo(userId) {
  const token = getCookie("token");
  return fetch(`${config.baseUrl}/subscriptions/${userId}`, {
    method: "GET",
    headers: {
      ...config.headers,
      Authorization: `Bearer ${token}`,
    },
    })
    .then(handleResponse)
    .catch((error) => {
      console.error("There was a problem with the fetch operation:", error);
      return Promise.reject(error); // Передаем ошибку дальше
    });
}
