import React, { useState, useRef, useEffect } from "react";
import useDynamicHeight from "../../../hooks/useDynamicHeight";
import EndGameOptions from "../../end-game-options/end-game-options";
import { gsap } from "gsap";
import styles from "./bumblebee.module.css";

import wallper from "./images/wallper.png";
import flowerOne from "./images/image 11.png";
import flowerTwo from "./images/image 7.png";
import flowerThree from "./images/image 8.png";
import ishUsh from "./images/ishUsh.svg";
import isha from "./images/isha.svg";
import oshSha from "./images/oshSha.svg";
import ushSha from "./images/ushSha.svg";
import shaSha from "./images/shaSha.svg";
import shaShu from "./images/shaShu.svg";
import sheSha from "./images/sheSha.svg";
import shoShi from "./images/shoShi.svg";
import sho from "./images/sho.svg";
import shuSha from "./images/shuSha.svg";
import shu from "./images/shu.svg";
import asha from "./images/asha.svg";

import startInstructionSound from "./sounds/startInstruction.mp3";
import backgroundSound from "./sounds/backgroundSound.mp3";
import beeSound from "./sounds/bee.mp3";
import praiseSound from "./sounds/praise.mp3";

const colors = [
  "#FF0000",
  "#FF7F00",
  "#FFFF00",
  "#00FF00",
  "#0000FF",
  "#4B0082",
  "#8B00FF",
];

const triggerFireworks = () => {
  const fireworksContainer = document.querySelector(
    `.${styles.fireworksContainer}`
  );
  if (fireworksContainer) {
    for (let i = 0; i < 10; i++) {
      setTimeout(() => createFirework(fireworksContainer), i * 500);
    }
  } else {
    console.error("Fireworks container not found");
  }
};

const createFirework = (container) => {
  const firework = document.createElement("div");
  firework.className = styles.firework;
  firework.style.left = `${Math.random() * 100}%`;
  firework.style.top = `${Math.random() * 100}%`;
  container.appendChild(firework);

  const particles = [];
  for (let i = 0; i < 30; i++) {
    const particle = document.createElement("div");
    particle.className = styles.particle;
    particle.style.background =
      colors[Math.floor(Math.random() * colors.length)];
    firework.appendChild(particle);
    particles.push(particle);
  }

  const tl = gsap.timeline({
    onComplete: () => {
      firework.remove();
    },
  });

  particles.forEach((particle) => {
    const angle = Math.random() * Math.PI * 2;
    const distance = Math.random() * 200 + 50;
    tl.fromTo(
      particle,
      { x: 0, y: 0, opacity: 1 },
      {
        x: Math.cos(angle) * distance,
        y: Math.sin(angle) * distance,
        opacity: 0,
        duration: 2,
        ease: "power2.out",
      },
      0
    );
  });
};

const flowerData = [
  {
    id: 1,
    flowerSrc: flowerOne,
    soundSrc: shaShu,
    flowerPosition: { top: "64%", left: "4%" },
    soundPosition: { top: "68%", left: "6%" },
  },
  {
    id: 2,
    flowerSrc: flowerTwo,
    soundSrc: shoShi,
    flowerPosition: { top: "36%", left: "2%" },
    soundPosition: { top: "40%", left: "4%" },
  },
  {
    id: 3,
    flowerSrc: flowerThree,
    soundSrc: sheSha,
    flowerPosition: { top: "26%", left: "20%" },
    soundPosition: { top: "30%", left: "22%" },
  },
  {
    id: 4,
    flowerSrc: flowerThree,
    soundSrc: shaSha,
    flowerPosition: { top: "56%", left: "20%" },
    soundPosition: { top: "60%", left: "22%" },
  },
  {
    id: 5,
    flowerSrc: flowerTwo,
    soundSrc: shu,
    flowerPosition: { top: "43%", left: "33%" },
    soundPosition: { top: "47%", left: "35%" },
  },
  {
    id: 6,
    flowerSrc: flowerOne,
    soundSrc: sho,
    flowerPosition: { top: "64%", left: "44%" },
    soundPosition: { top: "68%", left: "46%" },
  },
  {
    id: 7,
    flowerSrc: flowerThree,
    soundSrc: asha,
    flowerPosition: { top: "40%", left: "50%" },
    soundPosition: { top: "44%", left: "52%" },
  },
  {
    id: 8,
    flowerSrc: flowerTwo,
    soundSrc: isha,
    flowerPosition: { top: "68%", left: "60%" },
    soundPosition: { top: "72%", left: "62%" },
  },
  {
    id: 9,
    flowerSrc: flowerOne,
    soundSrc: ishUsh,
    flowerPosition: { top: "40%", left: "65%" },
    soundPosition: { top: "44%", left: "67%" },
  },
  {
    id: 10,
    flowerSrc: flowerThree,
    soundSrc: oshSha,
    flowerPosition: { top: "68%", left: "75%" },
    soundPosition: { top: "72%", left: "77%" },
  },
  {
    id: 11,
    flowerSrc: flowerTwo,
    soundSrc: ushSha,
    flowerPosition: { top: "45%", left: "85%" },
    soundPosition: { top: "49%", left: "87%" },
  },
  {
    id: 12,
    flowerSrc: flowerOne,
    soundSrc: shuSha,
    flowerPosition: { top: "20%", left: "80%" },
    soundPosition: { top: "24%", left: "82%" },
  },
];

const BumbleBeeGame = () => {
  useDynamicHeight();
  const [isInteractionBlocked, setIsInteractionBlocked] = useState(false);
  const [isGameCompleted, setIsGameCompleted] = useState(false);
  const [buttonText, setButtonText] = useState("Начать игру");
  const [isButtonVisible, setIsButtonVisible] = useState(true);
  const [sounds, setSounds] = useState(flowerData.map((item) => item.soundSrc));

  const audioRef = useRef(null);
  const startInstructionAudioRef = useRef(new Audio(startInstructionSound));
  const backgroundAudioRef = useRef(new Audio(backgroundSound));
  const beeSoundRef = useRef(new Audio(beeSound));
  const praiseSoundRef = useRef(new Audio(praiseSound));

  const handleSoundClick = (id) => {
    if (isInteractionBlocked) return;
    const soundElement = document.querySelector(`[data-id='${id}']`);
    if (soundElement) {
      beeSoundRef.current.play();

      soundElement.classList.add(styles.disappearing);
      setTimeout(() => {
        setSounds((prevSounds) => prevSounds.filter((sound) => sound !== id));

        if (
          document.querySelectorAll(`.${styles.disappearing}`).length ===
          sounds.length
        ) {
          triggerFireworks();
          setTimeout(() => {
            setIsGameCompleted(true);
          }, 5000);
          praiseSoundRef.current.play();
        }
      }, 1000);
    }
  };
  const handleNextTask = () => {
    setIsInteractionBlocked(true);

    if (startInstructionAudioRef.current) {
      startInstructionAudioRef.current.play();

      startInstructionAudioRef.current.onended = () => {
        setIsInteractionBlocked(false);
        if (backgroundAudioRef.current) {
          backgroundAudioRef.current.loop = true;
          backgroundAudioRef.current.play();
        }
      };
    }

    setIsButtonVisible(false);
  };

  useEffect(() => {
    return () => {
      startInstructionAudioRef.current.pause();
      startInstructionAudioRef.current.currentTime = 0;
      backgroundAudioRef.current.pause();
      backgroundAudioRef.current.currentTime = 0;

      praiseSoundRef.current.pause();
      praiseSoundRef.current.currentTime = 0;

      gsap.globalTimeline.clear();
    };
  }, []);

  return (
    <>
      {isGameCompleted && <EndGameOptions />}
      <div className={`container ${styles.someOtherClass}`}>
        <div
          className={styles.backgroundContainer}
          style={{
            pointerEvents: isInteractionBlocked ? "none" : "auto",
          }}
        >
          <div className={styles.dropContainer}>
            <img className={styles.wallper} src={wallper} alt="фон" />
            {flowerData.map((flower) => (
              <div key={flower.id}>
                <img
                  className={styles.flower}
                  src={flower.flowerSrc}
                  alt={`Flower ${flower.id}`}
                  style={{
                    top: flower.flowerPosition.top,
                    left: flower.flowerPosition.left,
                    position: "absolute",
                    width: "13%",
                  }}
                />
                {sounds.includes(flower.soundSrc) && (
                  <img
                    className={`${styles.sound} ${
                      sounds.includes(flower.soundSrc)
                        ? ""
                        : styles.disappearing
                    }`}
                    src={flower.soundSrc}
                    alt={`Sound ${flower.id}`}
                    data-id={flower.soundSrc}
                    style={{
                      top: flower.soundPosition.top,
                      left: flower.soundPosition.left,
                      position: "absolute",
                      width: "9%",
                    }}
                    onClick={() => handleSoundClick(flower.soundSrc)}
                  />
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className={styles.fireworksContainer}></div>
      <audio ref={audioRef} />
      {isButtonVisible && (
        <div className={styles.darkOverlay}>
          <button className={styles.button} onClick={handleNextTask}>
            {buttonText}
          </button>
        </div>
      )}
    </>
  );
};

export default BumbleBeeGame;
