import React from "react";
import styles from "./modal.module.css";
import Overlay from "../overlay/overlay";
import { useEffect } from "react";
import ReactDOM from "react-dom";

// export interface Imodal {
//   children: React.ReactNode;
//   closeModal: {
//     (): void;
//     (event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void;
//   };
// }

function Modal({ children, closeModal }) {
  const modalRoot = document.getElementById("react-modal");
  useEffect(() => {
    const closeModalOnEscape = (event) => {
      if (event.key === "Escape") {
        closeModal();
      }
    };

    document.addEventListener("keydown", closeModalOnEscape);

    return () => {
      document.removeEventListener("keydown", closeModalOnEscape);
    };
  });

  if (modalRoot === null) return null;

  return ReactDOM.createPortal(
    <Overlay closeModal={closeModal}>
      <div className={styles.modal}>
        <div className={`${styles.close}`}>
          {/* <button
            className={`${styles.close__button}`}
            onClick={closeModal}
            id="modalClose"
          ></button> */}
        </div>
        {children}
      </div>
    </Overlay>,
    modalRoot
  );
}

export default Modal;
