import { isSafari, isChrome } from "react-device-detect";
import { useState, useEffect, useRef } from "react";
import useDynamicHeight from "../../../hooks/useDynamicHeight";
import EndGameOptions from "../../end-game-options/end-game-options";
import { gsap } from "gsap";
import Confetti from "react-confetti";
import styles from "./fish-game.module.css";
import wallper from "./images/wallper.svg";
import homeMask from "./images/homeMask.svg";
import leftCoral from "./images/left-coral.svg";
import rightCoral from "./images/right-coral.svg";
import mainFish from "./images/mainFish.svg";
import lynx from "./images/lynx.svg";
import slingshot from "./images/slingshot.svg";
import robot from "./images/robot.svg";
import shell from "./images/shell.svg";
import rocket from "./images/rocket.svg";
import justFish from "./images/justFish.svg";
import rainbow from "./images/rainbow.svg";
import backgroundSound from "./sounds/background.mp3";
import startInstructionSound from "./sounds/instruction.mp3";
import actionSound from "./sounds/action.mp3";
import finalSound from "./sounds/praise.mp3";

function FishGame() {
  useDynamicHeight();
  const [isInteractionBlocked, setIsInteractionBlocked] = useState(false);
  const [isGameCompleted, setIsGameCompleted] = useState(false);
  const [buttonText, setButtonText] = useState("Начать игру");
  const [isButtonVisible, setIsButtonVisible] = useState(true);
  const [showConfetti, setShowConfetti] = useState(false);
  const audioRef = useRef(null);
  const startInstructionAudioRef = useRef(new Audio(startInstructionSound));
  const backgroundAudioRef = useRef(new Audio(backgroundSound));
  const mainFishRef = useRef(null);

  useEffect(() => {
    const fishElement = mainFishRef.current;
    if (fishElement) {
      gsap.to(fishElement, {
        x: "10vw",
        yoyo: true,
        repeat: -1,
        duration: 3,
        ease: "power1.inOut",
      });

      gsap.to(fishElement, {
        rotation: 10,
        yoyo: true,
        repeat: -1,
        duration: 1.5,
        ease: "power1.inOut",
      });
    } else {
    }
  }, []);

  const clickOnSubject = (e) => {
    // Если Safari, используем вторую функцию
    if (isSafari) {
      handleSafariClick(e);
    } else {
      // В других браузерах используем первую функцию
      handleChromeAndOtherBrowsersClick(e);
    }
  };

  const handleChromeAndOtherBrowsersClick = (e) => {
    const classesToCheck = [
      styles.lynx,
      styles.slingshot,
      styles.robot,
      styles.shell,
      styles.rocket,
      styles.justFish,
      styles.rainbow,
    ];
    const classFound = classesToCheck.some((className) =>
      e.target.className.includes(className)
    );
    if (classFound) {
      const actionAudio = new Audio(actionSound);
      actionAudio.play();
      e.target.classList.add(styles.growAndFade);
      const handleAnimationEnd = () => {
        e.target.style.display = "none";
        e.target.removeEventListener("animationend", handleAnimationEnd);

        // Проверяем, остались ли предметы
        const remainingItems = document.querySelectorAll(
          `.${styles.backgroundContainer} img:not([style*="display: none"])`
        );
        const remainingClasses = Array.from(remainingItems).some((item) =>
          classesToCheck.some((className) => item.className.includes(className))
        );

        // Если больше нет предметов, воспроизводим финальный звук
        if (!remainingClasses) {
          setShowConfetti(true);
          setTimeout(() => {
            setIsGameCompleted(true);
          }, 5000);
          const finalAudio = new Audio(finalSound);
          finalAudio.play();
        }
      };

      e.target.addEventListener("animationend", handleAnimationEnd);
    }
  };

  const handleSafariClick = (e) => {
    const classesToCheck = [
      styles.lynx,
      styles.slingshot,
      styles.robot,
      styles.shell,
      styles.rocket,
      styles.justFish,
      styles.rainbow,
    ];
    const classFound = classesToCheck.some((className) =>
      e.target.className.includes(className)
    );
    if (classFound) {
      const actionAudio = new Audio(actionSound);
      actionAudio.play();

      const element = e.target;
      let liftDistance = "-80px"; // Стандартное расстояние подъема

      // Проверяем, является ли элемент 'lynx', и увеличиваем расстояние подъема
      if (e.target.className.includes(styles.lynx)) {
        liftDistance = "-130px"; // Увеличенное расстояние подъема для lynx
      }

      // Создаём таймлайн для последовательной анимации
      const tl = gsap.timeline({
        onComplete: () => {
          element.style.display = "none";

          // Проверяем, остались ли предметы
          const remainingItems = document.querySelectorAll(
            `.${styles.backgroundContainer} img:not([style*="display: none"])`
          );
          const remainingClasses = Array.from(remainingItems).some((item) =>
            classesToCheck.some((className) =>
              item.className.includes(className)
            )
          );

          // Если больше нет предметов, воспроизводим финальный звук
          if (!remainingClasses) {
            setShowConfetti(true);
            setTimeout(() => {
              setIsGameCompleted(true);
            }, 5000);
            const finalAudio = new Audio(finalSound);
            finalAudio.play();
          }
        },
      });

      // Первая часть: поднимаем элемент вверх
      tl.to(element, {
        y: liftDistance, // Поднимаем элемент на определённое расстояние
        scale: 1.2, // Немного увеличиваем элемент
        duration: 1.5, // Длительность подъема 0.5 секунды
        ease: "power2.out", // Плавное ускорение и замедление
      });

      // Вторая часть: элемент начинает исчезать
      tl.to(element, {
        opacity: 0, // Плавно исчезает
        duration: 1, // Длительность исчезания 1 секунда
        ease: "power2.out", // Плавное ускорение и замедление
      });
    }
  };

  const handleNextTask = () => {
    setIsInteractionBlocked(true);

    if (startInstructionAudioRef.current) {
      startInstructionAudioRef.current.play();

      startInstructionAudioRef.current.onended = () => {
        setIsInteractionBlocked(false);
        if (backgroundAudioRef.current) {
          backgroundAudioRef.current.loop = true;
          backgroundAudioRef.current.play();
        }
      };
    }

    setIsButtonVisible(false);
  };

  useEffect(() => {
    const fishElement = mainFishRef.current;
    if (fishElement) {
      const swimAnimation = gsap.to(fishElement, {
        x: "10vw",
        yoyo: true,
        repeat: -1,
        duration: 3,
        ease: "power1.inOut",
      });

      const rotateAnimation = gsap.to(fishElement, {
        rotation: 10,
        yoyo: true,
        repeat: -1,
        duration: 1.5,
        ease: "power1.inOut",
      });

      // Функция очистки
      return () => {
        // Остановить и сбросить все аудио
        startInstructionAudioRef.current.pause();
        startInstructionAudioRef.current.currentTime = 0;
        backgroundAudioRef.current.pause();
        backgroundAudioRef.current.currentTime = 0;

        // Остановить все анимации GSAP
        swimAnimation.kill();
        rotateAnimation.kill();
        gsap.globalTimeline.clear();
      };
    }
  }, []);

  return (
    <>
      {isGameCompleted && <EndGameOptions />}
      <div className={`container ${styles.someOtherClass}`}>
        {showConfetti && (
          <Confetti
            numberOfPieces={1000}
            angle={180}
            spread={120}
            origin={{ x: 1, y: 0.5 }}
          />
        )}
        <div
          className={styles.backgroundContainer}
          onClick={clickOnSubject}
          style={{
            pointerEvents: isInteractionBlocked ? "none" : "auto",
          }}
        >
          <img className={styles.wallper} src={wallper} alt="обои" />
          <img className={styles.homeMask} src={homeMask} alt="маска дома" />
          <img
            ref={mainFishRef}
            className={styles.mainFish}
            src={mainFish}
            alt="главная рыба"
          />
          <img className={styles.leftCoral} src={leftCoral} alt="коралл" />
          <img className={styles.rightCoral} src={rightCoral} alt="коралл" />

          <img className={styles.lynx} src={lynx} alt="рысь" />

          <img className={styles.slingshot} src={slingshot} alt="рогатка" />

          <img className={styles.robot} src={robot} alt="робот" />

          <img className={styles.shell} src={shell} alt="ракушка" />

          <img className={styles.rocket} src={rocket} alt="ракета" />

          <img className={styles.justFish} src={justFish} alt="рыбка" />

          <img className={styles.rainbow} src={rainbow} alt="радуга" />
        </div>
        <audio ref={audioRef} src={backgroundSound} loop />
        {isButtonVisible && (
          <div className={styles.darkOverlay}>
            <button className={styles.button} onClick={handleNextTask}>
              {buttonText}
            </button>
          </div>
        )}
      </div>
    </>
  );
}

export default FishGame;
