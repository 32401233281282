import React, { useState, useRef, useEffect } from "react";
import useDynamicHeight from "../../../hooks/useDynamicHeight";
import EndGameOptions from "../../end-game-options/end-game-options";
import { gsap } from "gsap";
import Confetti from "react-confetti";
import styles from "./acorn-with-surprise.module.css";

import wallper from "./images/wallper.png";
import squirell from "./images/squirell.png";
import hand from "./images/hand.png";
import acorn from "./images/acorn.png";
import crashedAcorn from "./images/crashedAcorn.png";
import bug from "./images/bug.png";
import toad from "./images/toad.png";
import jasmine from "./images/jasmine.png";
import juggler from "./images/juggler.png";
import crane from "./images/crane.png";
import jelly from "./images/jelly.png";
import jetton from "./images/jetton.png";
import coal from "./images/coal.png";

import bigBug from "./images/bigBug.png";
import bigToad from "./images/bigToad.png";
import bigJasmine from "./images/bigJasmine.png";
import bigJuggler from "./images/bigJuggler.png";
import bigCrane from "./images/bigCrane.png";
import bigJelly from "./images/bigJelly.png";
import bigJetton from "./images/bigJetton.png";
import bigCoal from "./images/bigCoal.png";

import startInstructionSound from "./sounds/startInstruction.mp3";
import backgroundSound from "./sounds/backgroundSound.mp3";
import nutsSound from "./sounds/nuts.mp3";
import praiseSound from "./sounds/praise.mp3";

const bigImages = {
  bug: bigBug,
  toad: bigToad,
  jasmine: bigJasmine,
  juggler: bigJuggler,
  crane: bigCrane,
  jelly: bigJelly,
  jetton: bigJetton,
  coal: bigCoal,
};

const AcornWithSurpriseGame = () => {
  useDynamicHeight();
  const [isGameCompleted, setIsGameCompleted] = useState(false);
  const [isInteractionBlocked, setIsInteractionBlocked] = useState(false);
  const [buttonText, setButtonText] = useState("Начать игру");
  const [isButtonVisible, setIsButtonVisible] = useState(true);
  const [showConfetti, setShowConfetti] = useState(false);
  const squirrelContainerRef = useRef(null);
  const handRef = useRef(null);
  const acornRefs = useRef([]);
  const bigImageRef = useRef(null);
  const [acorns, setAcorns] = useState([
    {
      id: 1,
      image: acorn,
      crackedImage: crashedAcorn,
      additionalImage: bug,
      bigImage: bigBug,
      cracked: false,
      visible: true,
    },
    {
      id: 2,
      image: acorn,
      crackedImage: crashedAcorn,
      additionalImage: toad,
      bigImage: bigToad,
      cracked: false,
      visible: true,
    },
    {
      id: 3,
      image: acorn,
      crackedImage: crashedAcorn,
      additionalImage: jasmine,
      bigImage: bigJasmine,
      cracked: false,
      visible: true,
    },
    {
      id: 4,
      image: acorn,
      crackedImage: crashedAcorn,
      additionalImage: juggler,
      bigImage: bigJuggler,
      cracked: false,
      visible: true,
    },
    {
      id: 5,
      image: acorn,
      crackedImage: crashedAcorn,
      additionalImage: crane,
      bigImage: bigCrane,
      cracked: false,
      visible: false,
    },
    {
      id: 6,
      image: acorn,
      crackedImage: crashedAcorn,
      additionalImage: jelly,
      bigImage: bigJelly,
      cracked: false,
      visible: false,
    },
    {
      id: 7,
      image: acorn,
      crackedImage: crashedAcorn,
      additionalImage: jetton,
      bigImage: bigJetton,
      cracked: false,
      visible: false,
    },
    {
      id: 8,
      image: acorn,
      crackedImage: crashedAcorn,
      additionalImage: coal,
      bigImage: bigCoal,
      cracked: false,
      visible: false,
    },
  ]);

  const [visibleAcorns, setVisibleAcorns] = useState(acorns.slice(0, 4));

  const audioRef = useRef(null);
  const startInstructionAudioRef = useRef(new Audio(startInstructionSound));
  const backgroundAudioRef = useRef(new Audio(backgroundSound));
  const nutsSoundRef = useRef(new Audio(nutsSound));
  const praiseSoundRef = useRef(new Audio(praiseSound));

  useEffect(() => {
    visibleAcorns.forEach((acorn, index) => {
      if (acornRefs.current[index]) {
        gsap.set(acornRefs.current[index], {
          left: `${45 + 12 * index}%`,
        });
      }
    });
  }, [visibleAcorns]);

  const handleSquirrelClick = () => {
    const currentAcorn = visibleAcorns.find((acorn) => !acorn.cracked);
    if (!currentAcorn) return;

    const currentAcornIndex = visibleAcorns.indexOf(currentAcorn);

    gsap
      .timeline()
      .to(squirrelContainerRef.current, {
        rotation: 20,
        duration: 0.5,
        ease: "power2.inOut",
        transformOrigin: "50% 100%",
      })
      .to(
        handRef.current,
        {
          rotation: 60,
          duration: 0.5,
          ease: "power2.inOut",
        },
        "<"
      )
      .call(() => {
        nutsSoundRef.current.play();
        // Меняем состояние ореха на расколотый и обновляем изображение сразу после удара
        const updatedAcorns = acorns.map((acorn) => {
          if (acorn.id === currentAcorn.id) {
            return { ...acorn, cracked: true };
          }
          return acorn;
        });
        setAcorns(updatedAcorns);

        acornRefs.current[currentAcornIndex].src = currentAcorn.crackedImage;
        acornRefs.current[currentAcornIndex].nextSibling.style.display =
          "block"; // Показываем дополнительное изображение

        // Показать большое изображение сбоку экрана
        if (bigImageRef.current) {
          bigImageRef.current.src = currentAcorn.bigImage;
          gsap.set(bigImageRef.current, { display: "block", opacity: 1 });
          gsap.to(bigImageRef.current, {
            duration: 3,
            opacity: 1,
            x: -50,
            ease: "power2.inOut",
            onComplete: () => {
              gsap.to(bigImageRef.current, {
                duration: 1,
                opacity: 0,
                onComplete: () => {
                  gsap.set(bigImageRef.current, { display: "none", x: 0 });
                },
              });
            },
          });
        }
      })
      .to(handRef.current, {
        rotation: 0,
        duration: 0.5,
        ease: "power2.inOut",
      })
      .to(
        squirrelContainerRef.current,
        {
          rotation: 0,
          duration: 0.5,
          ease: "power2.inOut",
        },
        "<0.5"
      )
      .call(() => {
        setTimeout(() => {
          const newVisibleAcorns = visibleAcorns.filter(
            (acorn) => acorn.id !== currentAcorn.id
          );
          const nextAcorn = acorns.find(
            (acorn) => !acorn.cracked && !visibleAcorns.includes(acorn)
          );
          if (nextAcorn) {
            nextAcorn.visible = true;
            newVisibleAcorns.push(nextAcorn);
          }
          setVisibleAcorns(newVisibleAcorns);

          newVisibleAcorns.forEach((acorn, index) => {
            gsap.to(acornRefs.current[index], {
              duration: 0.5,
              ease: "power2.inOut",
            });
          });

          if (newVisibleAcorns.length === 0) {
            // Все орехи кончились, запускаем конфетти и звук похвалы
            setShowConfetti(true);
            setTimeout(() => {
              setIsGameCompleted(true);
            }, 5000);
            praiseSoundRef.current.play();
          }
        }, 3000); // Показать расколотый орех на 3 секунды перед его исчезновением
      })
      .to(
        acornRefs.current[currentAcornIndex],
        {
          autoAlpha: 0,
          duration: 1,
        },
        "+=3"
      );
  };

  const handleNextTask = () => {
    setIsInteractionBlocked(true); // Блокируем взаимодействие
    if (startInstructionAudioRef.current) {
      startInstructionAudioRef.current.play();

      startInstructionAudioRef.current.onended = () => {
        setIsInteractionBlocked(false);
        if (backgroundAudioRef.current) {
          backgroundAudioRef.current.loop = true; // Включаем повтор для фоновой музыки
          backgroundAudioRef.current.play(); // Запускаем фоновую музыку
        }
      };
    }

    setIsButtonVisible(false);
  };

  useEffect(() => {
    return () => {
      startInstructionAudioRef.current.pause();
      startInstructionAudioRef.current.currentTime = 0;
      backgroundAudioRef.current.pause();
      backgroundAudioRef.current.currentTime = 0;

      praiseSoundRef.current.pause();
      praiseSoundRef.current.currentTime = 0;

      // Остановить все анимации GSAP
      gsap.globalTimeline.clear();
    };
  }, []);

  return (
    <>
      {showConfetti && (
        <Confetti
          numberOfPieces={500}
          angle={180}
          spread={120}
          origin={{ x: 1, y: 0.5 }}
        />
      )}

      {isGameCompleted && <EndGameOptions />}
      <div className={`container ${styles.someOtherClass}`}>
        <div
          className={styles.backgroundContainer}
          style={{
            pointerEvents: isInteractionBlocked ? "none" : "auto",
          }}
        >
          <div className={styles.dropContainer}>
            <img className={styles.wallper} src={wallper} alt="фон" />
            {visibleAcorns.map((acorn, index) => (
              <div key={acorn.id} className={styles.acornWrapper}>
                <img
                  ref={(el) => (acornRefs.current[index] = el)}
                  src={acorn.cracked ? acorn.crackedImage : acorn.image}
                  alt={acorn.cracked ? "Разбитый желудь" : "Желудь"}
                  className={`${styles.acorn} ${
                    index === 0 ? styles.activeAcorn : styles.queuedAcorn
                  }`}
                  style={{
                    opacity: acorn.cracked ? 1 : 1, // Оставляем изображение расколотого ореха видимым
                    display: "block",
                  }}
                />
                <img
                  src={acorn.additionalImage}
                  alt="Сюрприз"
                  className={styles.additionalImage}
                  style={{ display: "none" }} // Дополнительное изображение скрыто по умолчанию
                />
              </div>
            ))}
            <div
              className={styles.squirellContainer}
              onClick={handleSquirrelClick}
              ref={squirrelContainerRef}
            >
              <img src={squirell} alt="белка" className={styles.squirell} />
              <img
                src={hand}
                ref={handRef}
                alt="рука"
                className={styles.hand}
              />
            </div>
          </div>
          <img
            ref={bigImageRef}
            alt="Большой сюрприз"
            className={styles.bigImage}
            style={{ display: "none" }}
          />
        </div>
      </div>
      <audio ref={audioRef} />
      {isButtonVisible && (
        <div className={styles.darkOverlay}>
          <button className={styles.button} onClick={handleNextTask}>
            {buttonText}
          </button>
        </div>
      )}
    </>
  );
};

export default AcornWithSurpriseGame;
