import styles from "./subscription.module.css";
import { DataGrid } from "@mui/x-data-grid";
import { NavLink } from "react-router-dom";
import { useMediaQuery } from "@mui/material";
import { useState, useEffect } from "react";
import { getUserSubscriptionInfo } from "../api/user-subscription";

function Subscription() {
  const [paymentInfo, setPaymentInfo] = useState(null);
  const isNarrowScreen = useMediaQuery("(max-width:660px)");
  const userId = localStorage.getItem("userId").replace(/"/g, "");

  useEffect(() => {
    getUserSubscriptionInfo(userId)
      .then((data) => {
        setPaymentInfo(data);
      })
      .catch((error) => {
        console.error("Ошибка при получении информации о подписке:", error);
        setPaymentInfo(null);
      });
  }, [userId]);

  // console.log(paymentInfo);

  function calculateSubscriptionEnd(endDateStr) {
    const endDate = new Date(endDateStr);

    // Добавляем один дополнительный день
    endDate.setDate(endDate.getDate() + 1);

    return endDate.toLocaleDateString("ru-RU", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    });
  }

  const subscriptionEnd = paymentInfo
    ? calculateSubscriptionEnd(paymentInfo.expiresAt)
    : null;

  const rows =
    paymentInfo?.payments
      .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
      .map((payment, index) => ({
        id: payment.id,
        date: new Date(payment.createdAt).toLocaleDateString("ru-RU", {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
        }),
        amount: `${(payment.amount / 100).toFixed(2)} ₽`,
        status: payment.status === "CONFIRMED" ? "Оплачено" : "Не оплачено",
      })) || [];

  const columns = [
    {
      field: "date",
      headerName: "Дата",
      width: isNarrowScreen ? 100 : 150,
    },
    {
      field: "amount",
      headerName: "Сумма",
      width: isNarrowScreen ? 100 : 150,
    },
    {
      field: "status",
      headerName: "Статус",
      width: isNarrowScreen ? 100 : 150,
    },
  ];

  // эмуляция данных с закончившейся подпиской
  // useEffect(() => {
  //   getUserSubscriptionInfo(userId)
  //     .then((data) => {
  //       const simulatedData = {
  //         ...data,
  //         expiresAt: "2024-09-01T14:50:59.605646+04:00",
  //       };
  //       setPaymentInfo(simulatedData);
  //     })
  //     .catch((error) => {
  //       console.error("Ошибка при получении информации о подписке:", error);
  //       setPaymentInfo(null);
  //     });
  // }, [userId]);

  return (
    <div className={styles.subscriptionContainer}>
      <div className={styles.subscriptionImgContainer}>
        <div className={styles.imgSubscription}></div>
        <h4 className={styles.headerText}>Текущие подписки</h4>
      </div>
      {paymentInfo && new Date(paymentInfo.expiresAt) > new Date() ? (
        <p className={styles.text}>
          Ваша подписка действует до: {subscriptionEnd}
        </p>
      ) : (
        <p className={styles.text}>К сожалению, у вас нет активной подписки</p>
      )}
      <NavLink to="/subscription/pricing" className={styles.link}>
        Выбрать тариф
      </NavLink>
      <div className={styles.subscriptionImgContainer}>
        <div className={styles.imgSubscriptionTwo}></div>
        <h4 className={styles.historyText}>История платежей</h4>
      </div>
      <div className={styles.tableContainer}>
        <DataGrid
          rows={rows}
          columns={columns}
          pagination={false}
          hideFooter
          disableColumnMenu
        />
      </div>
    </div>
  );
}

export default Subscription;
