import React, { useState, useEffect, useRef } from "react";
import useDynamicHeight from "../../../hooks/useDynamicHeight";
import EndGameOptions from "../../end-game-options/end-game-options";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { gsap } from "gsap";
import styles from "./animals-farm.module.css";
import DraggableItem from "../../DnD/drag";
import DropTarget from "../../DnD/drop";

import wallperImage from "./images/wallper.png";
import goat from "./images/goat.png";
import cow from "./images/cow.png";
import cat from "./images/cat.png";
import sheep from "./images/sheep.png";
import donkey from "./images/donkey.png";
import cock from "./images/cock.png";
import pig from "./images/pig.png";
import dog from "./images/dog.png";

import catShadow from "./images/catShadow.png";
import cockShadow from "./images/cockShadow.svg";
import cowShadow from "./images/cowShadow.svg";
import dogShadow from "./images/dogShadow.svg";
import donkeyShadow from "./images/donkeyShadow.svg";
import goatShadow from "./images/goatShadow.svg";
import pigShadow from "./images/pigShadow.svg";
import sheepShadow from "./images/sheepShadow.svg";

import backgroundSound from "./sounds/background.mp3";
import startInstructionSound from "./sounds/instruction.mp3";
import incorrectSound from "./sounds/no.mp3";
import catSound from "./sounds/cat.mp3";
import cowSound from "./sounds/cow.mp3";
import dogSound from "./sounds/dog.mp3";
import donkeySound from "./sounds/donkey.mp3";
import goatSound from "./sounds/goat.mp3";
import cockSound from "./sounds/koko.mp3";
import pigSound from "./sounds/pig.mp3";
import sheepSound from "./sounds/sheep.mp3";
import finalSound from "./sounds/praise.mp3";
import winSound from "./sounds/win.mp3";

const colors = [
  "#FF0000",
  "#FF7F00",
  "#FFFF00",
  "#00FF00",
  "#0000FF",
  "#4B0082",
  "#8B00FF",
];

const triggerFireworks = () => {
  const fireworksContainer = document.querySelector(
    `.${styles.fireworksContainer}`
  );
  if (fireworksContainer) {
    for (let i = 0; i < 10; i++) {
      setTimeout(() => createFirework(fireworksContainer), i * 500);
    }
  } else {
    console.error("Fireworks container not found");
  }
};

const createFirework = (container) => {
  const firework = document.createElement("div");
  firework.className = styles.firework;
  firework.style.left = `${Math.random() * 100}%`;
  firework.style.top = `${Math.random() * 100}%`;
  container.appendChild(firework);

  const particles = [];
  for (let i = 0; i < 30; i++) {
    const particle = document.createElement("div");
    particle.className = styles.particle;
    particle.style.background =
      colors[Math.floor(Math.random() * colors.length)];
    firework.appendChild(particle);
    particles.push(particle);
  }

  const tl = gsap.timeline({
    onComplete: () => {
      firework.remove();
    },
  });

  particles.forEach((particle) => {
    const angle = Math.random() * Math.PI * 2;
    const distance = Math.random() * 200 + 50;
    tl.fromTo(
      particle,
      { x: 0, y: 0, opacity: 1 },
      {
        x: Math.cos(angle) * distance,
        y: Math.sin(angle) * distance,
        opacity: 0,
        duration: 2,
        ease: "power2.out",
      },
      0
    );
  });
};

const images = [
  {
    id: "козлик",
    src: goat,
    alt: "козлик",
    className: styles.goat,
    dropStyle: {
      left: "44%",
      top: "34%",
      width: "8%",
    },
    shadowStyle: {
      left: "45",
      top: "36",
    },
    targetId: "козлик",
  },
  {
    id: "корова",
    src: cow,
    alt: "корова",
    className: styles.cow,
    dropStyle: {
      left: "80%",
      top: "41%",
      width: "11%",
    },
    shadowStyle: {
      left: "83",
      top: "41",
    },
    targetId: "корова",
  },
  {
    id: "кошка",
    src: cat,
    alt: "кошка",
    className: styles.cat,
    dropStyle: {
      left: "64%",
      top: "44%",
      width: "5%",
    },
    shadowStyle: {
      left: "62",
      top: "46",
    },
    targetId: "кошка",
  },
  {
    id: "овца",
    src: sheep,
    alt: "овца",
    className: styles.sheep,
    dropStyle: {
      left: "13%",
      top: "33%",
      width: "7%",
    },
    shadowStyle: {
      left: "13",
      top: "33",
    },
    targetId: "овца",
  },
  {
    id: "осел",
    src: donkey,
    alt: "осел",
    className: styles.donkey,
    dropStyle: {
      left: "69%",
      top: "39%",
      width: "6%",
    },
    shadowStyle: {
      left: "67",
      top: "39",
    },
    targetId: "осел",
  },
  {
    id: "петух",
    src: cock,
    alt: "петух",
    className: styles.cock,
    dropStyle: {
      left: "48%",
      top: "63%",
      width: "7%",
    },
    shadowStyle: {
      left: "48",
      top: "63",
    },
    targetId: "петух",
  },
  {
    id: "свинья",
    src: pig,
    alt: "свинья",
    className: styles.pig,
    dropStyle: {
      left: "52%",
      top: "30%",
      width: "10%",
    },
    shadowStyle: {
      left: "52",
      top: "30",
    },
    targetId: "свинья",
  },
  {
    id: "собака",
    src: dog,
    alt: "собака",
    className: styles.dog,
    dropStyle: {
      left: "31%",
      top: "31%",
      width: "7%",
    },
    shadowStyle: {
      left: "31",
      top: "31",
    },
    targetId: "собака",
  },
];

const AnimalsAtFarm = () => {
  useDynamicHeight();
  const [isInteractionBlocked, setIsInteractionBlocked] = useState(false);
  const [isGameCompleted, setIsGameCompleted] = useState(false);
  const [buttonText, setButtonText] = useState("Начать игру");
  const [isButtonVisible, setIsButtonVisible] = useState(true);
  const audioRef = useRef(null);
  const startInstructionAudioRef = useRef(new Audio(startInstructionSound));
  const backgroundAudioRef = useRef(new Audio(backgroundSound));
  const [itemPlaced, setItemPlaced] = useState(
    images.reduce(
      (acc, image) => ({
        ...acc,
        [image.alt]: false,
      }),
      {}
    )
  );

  const handleDrop = (itemId, targetId) => {
    if (itemId === targetId) {
      setItemPlaced((prev) => {
        const updatedItemPlaced = {
          ...prev,
          [itemId]: true,
        };

        let sound;
        switch (itemId) {
          case "козлик":
            sound = new Audio(goatSound);
            break;
          case "корова":
            sound = new Audio(cowSound);
            break;
          case "кошка":
            sound = new Audio(catSound);
            break;
          case "овца":
            sound = new Audio(sheepSound);
            break;
          case "осел":
            sound = new Audio(donkeySound);
            break;
          case "петух":
            sound = new Audio(cockSound);
            break;
          case "свинья":
            sound = new Audio(pigSound);
            break;
          case "собака":
            sound = new Audio(dogSound);
            break;
          default:
            break;
        }
        if (sound) {
          sound.play();
          sound.onended = () => {
            const allPlaced = Object.values(updatedItemPlaced).every(Boolean);
            if (allPlaced) {
              const winAudio = new Audio(winSound);
              winAudio.play();
              winAudio.onended = () => {
                triggerFireworks();
                setTimeout(() => {
                  setIsGameCompleted(true);
                }, 5000);
                const finalAudio = new Audio(finalSound);
                finalAudio.play();
              };
            }
          };
        }

        return updatedItemPlaced;
      });
    } else {
      const errorSound = new Audio(incorrectSound);
      errorSound.play();
    }
  };

  const handleNextTask = () => {
    setIsInteractionBlocked(true);

    if (startInstructionAudioRef.current) {
      startInstructionAudioRef.current.play();

      startInstructionAudioRef.current.onended = () => {
        setIsInteractionBlocked(false);
        if (backgroundAudioRef.current) {
          backgroundAudioRef.current.loop = true;
          backgroundAudioRef.current.play();
        }
      };
    }

    setIsButtonVisible(false);
  };

  useEffect(() => {
    // Функция очистки
    return () => {
      // Остановить и сбросить все аудио
      startInstructionAudioRef.current.pause();
      startInstructionAudioRef.current.currentTime = 0;
      backgroundAudioRef.current.pause();
      backgroundAudioRef.current.currentTime = 0;
    };
  }, []);

  return (
    <>
      <DndProvider backend={HTML5Backend}>
        {isGameCompleted && <EndGameOptions />}
        <div className={`container ${styles.someOtherClass}`}>
          <div
            className={styles.backgroundContainer}
            style={{
              pointerEvents: isInteractionBlocked ? "none" : "auto",
            }}
          >
            <div className={styles.dropContainer}>
              <img className={styles.wallperImage} src={wallperImage} alt="" />
              {!itemPlaced["козлик"] && (
                <img className={styles.goatShadow} src={goatShadow} alt="" />
              )}
              {!itemPlaced["корова"] && (
                <img className={styles.cowShadow} src={cowShadow} alt="" />
              )}
              {!itemPlaced["кошка"] && (
                <img className={styles.catShadow} src={catShadow} alt="" />
              )}
              {!itemPlaced["овца"] && (
                <img className={styles.sheepShadow} src={sheepShadow} alt="" />
              )}
              {!itemPlaced["осел"] && (
                <img
                  className={styles.donkeyShadow}
                  src={donkeyShadow}
                  alt=""
                />
              )}
              {!itemPlaced["петух"] && (
                <img className={styles.cockShadow} src={cockShadow} alt="" />
              )}
              {!itemPlaced["свинья"] && (
                <img className={styles.pigShadow} src={pigShadow} alt="" />
              )}
              {!itemPlaced["собака"] && (
                <img className={styles.dogShadow} src={dogShadow} alt="" />
              )}

              {images.map((image, index) => (
                <DropTarget
                  key={index}
                  id={image.id}
                  targetId={image.targetId}
                  onDrop={(itemId, targetId) => handleDrop(itemId, targetId)}
                  left={image.shadowStyle.left}
                  top={image.shadowStyle.top}
                />
              ))}

              {images.map(
                (image, index) =>
                  itemPlaced[image.id] && (
                    <img
                      key={index}
                      src={image.src}
                      alt={image.alt}
                      className={image.className}
                      style={{
                        position: "absolute",
                        ...image.dropStyle,
                      }}
                    />
                  )
              )}
            </div>

            <div className={styles.gallery}>
              {images.map((image, index) => {
                if (!itemPlaced[image.alt]) {
                  return (
                    <div key={index} className={styles.frameImage}>
                      <DraggableItem
                        id={image.id}
                        src={image.src}
                        alt={image.alt}
                        className={image.className}
                      />
                    </div>
                  );
                }
                return null;
              })}
            </div>
            <div className={styles.fireworksContainer}></div>
          </div>
          <audio ref={audioRef} src={backgroundSound} loop />
          {isButtonVisible && (
            <div className={styles.darkOverlay}>
              <button className={styles.button} onClick={handleNextTask}>
                {buttonText}
              </button>
            </div>
          )}
        </div>
      </DndProvider>
    </>
  );
};

export default AnimalsAtFarm;
