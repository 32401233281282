import React, { useState } from "react";
import GamesMenu from "../games-menu/games-menu";
import GamesList from "../games-list/games-list";
import styles from "./games.module.css";
import frogImg from "./images/frog.png";
import fishImg from "./images/fish.png";
import bigSmallerImg from "./images/biggerSmaller.png";
import dinoImg from "./images/dino.png";
import farmImg from "./images/farm.png";
import animalsForestImg from "./images/forestAnimals.png";
import animalsHotCountriesImg from "./images/hotCountriesAnimals.png";
import roomImg from "./images/room.png";
import guessActionImg from "./images/actions.png";
import findInParkImg from "./images/findInPark.png";
import artistMistakesImg from "./images/artistMistakes.png";
import seasonsImg from "./images/seasonsMistake.png";
import nonSpeechSoundsAnimals from "./images/animalsSounds.png";
import HomeAppliancesImg from "./images/homeSounds.png";
import CountrysideImg from "./images/countysideSounds.png";
import streetImg from "./images/street.png";
import transportImg from "./images/transport.png";
import natureImg from "./images/nature.png";
import emotionsImg from "./images/emotions.png";
import birdsImg from "./images/birds.png";
import musInstrumentsImg from "./images/musInstr.png";
import insects from "./images/insects.png";
import figureOneImg from "./images/figureOne.png";
import figureTwoImg from "./images/figureTwo.png";
import figureThreeImg from "./images/figureThree.png";
import figureFourImg from "./images/figureFour.png";
import figureFiveImg from "./images/figureFive.png";
import figureSixImg from "./images/figureSix.png";
import figureSevenImg from "./images/figureSeven.png";
import figureEightImg from "./images/figureEight.png";
import figureNineImg from "./images/figureNine.png";
import figureTenImg from "./images/figureTen.png";
import angryGoatImg from "./images/angryGoat.png";
import magicLampsImg from "./images/magicLamps.png";
import bumblebeeImg from "./images/bumblebee.png";
import snakeImg from "./images/snake.png";
import datingWithZhuzhaImg from "./images/datingWithZhuzha.png";
import acornImg from "./images/acorn.png";
import pickingFruitsImg from "./images/pickingFruits.png";
import whatOwlFoundImg from "./images/whatOwlFound.png";
import circleImg from "./images/circle.png";
import squareImg from "./images/square.png";
import triangleImg from "./images/triangle.png";
import rectangleImg from "./images/rectangle.png";
import rhombusImg from "./images/rhomb.png";
import ovalImg from "./images/oval.png";
import rabbitImg from "./images/rabbit.png";
import associationsImg from "./images/associations.png";

const Games = () => {
  const [activeCategory, setActiveCategory] = useState({
    category: "Звукопроизношение",
    subcategory: null,
  });
  const gamesData = [
    {
      id: 1001,
      title: "Зайчик и морковка",
      category: "Математика",
      subcategory: "Цифры, счет",
      description: "Учимся считать от 1 до 10",
      path: "/games/rabbit",
      imageUrl: rabbitImg,
      unique_id: 1001,
    },
    {
      id: 1002,
      title: 'Ассоциации "кто где живет"',
      category: "Память, внимание и логика",
      subcategory: "Логические задачи",
      description:
        "Найти связи между картинками по какому-либо свойству или признаку",
      path: "/games/associations",
      imageUrl: associationsImg,
      unique_id: 1002,
    },
    {
      id: 1,
      title: "Помоги лягушонку добраться до дома",
      category: "Звукопроизношение",
      subcategory: "Автоматизация звуков",
      description: "Автоматизация звука 'Л' в слогах",
      path: "/games/frogs",
      imageUrl: frogImg,
      unique_id: 1,
    },
    {
      id: 2,
      title: "Помоги рыбе клоуну найти игрушки",
      category: "Звукопроизношение",
      subcategory: "Автоматизация звуков",
      description: "Автоматизация звука 'Р' в начале слова",
      path: "/games/fish",
      imageUrl: fishImg,
      unique_id: 2,
    },
    {
      id: 3,
      title: "Кто больше, кто меньше",
      category: "Математика",
      subcategory: "Сравнение",
      description: "Сравнить два предмета и выбрать правильный ответ",
      path: "/games/smaller-bigger",
      imageUrl: bigSmallerImg,
      unique_id: 3,
    },
    {
      id: 4,
      title: "Автоматизация звука 'Р' Динозавр",
      category: "Звукопроизношение",
      subcategory: "Автоматизация звуков",
      description: " Автоматизация звука 'Р' в слогах",
      path: "/games/dinosaur",
      imageUrl: dinoImg,
      unique_id: 4,
    },
    {
      id: 5,
      title: "Домашние животные",
      category: "Развитие речи",
      subcategory: "Лексика",
      description:
        "Ребенку необходимо выбрать животное, назвать его и сказать где оно живёт",
      path: "/games/animals-at-farm",
      imageUrl: farmImg,
      unique_id: 5,
    },
    {
      id: 6,
      title: "Дикие животные",
      category: "Развитие речи",
      subcategory: "Лексика",
      description:
        "Ребенку необходимо выбрать животное, назвать его и сказать где оно живёт",
      path: "/games/animals-in-forest",
      imageUrl: animalsForestImg,
      unique_id: 6,
    },
    {
      id: 7,
      title: "Животные жарких стран",
      category: "Развитие речи",
      subcategory: "Лексика",
      description:
        "Ребенку необходимо выбрать животное, назвать его и сказать где оно живёт",
      path: "/games/animals-of-hot-countries",
      imageUrl: animalsHotCountriesImg,
      unique_id: 7,
    },
    {
      id: 8,
      title: "Найди в комнате",
      category: "Память, внимание и логика",
      subcategory: "Зрительное внимание",
      description: "Найти силуэт и подобрать к нему предмет",
      path: "/games/find-in-room",
      imageUrl: roomImg,
      unique_id: 8,
    },
    {
      id: 9,
      title: "Угадай действие",
      category: "Развитие речи",
      subcategory: "Связаная речь",
      description:
        "Послушать задание и выбрать правильную картинку. Можно попросить ребенка назвать что делают на картинках персонажи",
      path: "/games/guess-action",
      imageUrl: guessActionImg,
      unique_id: 9,
    },
    {
      id: 10,
      title: "Угадай действие",
      category: "Слуховое восприятие",
      subcategory: "Фонетический",
      description:
        "Послушать задание. Можно попросить ребенка назвать что делают на картинках персонажи",
      path: "/games/guess-action",
      imageUrl: guessActionImg,
      unique_id: 9,
    },
    {
      id: 11,
      title: "Найди в парке",
      category: "Развитие речи",
      subcategory: "Связаная речь",
      description:
        "Послушать инструкцию и выбрать правильный ответ. Можно попросить ребенка назвать что делают на картинках персонажи",
      path: "/games/find-in-the-park",
      imageUrl: findInParkImg,
      unique_id: 10,
    },
    {
      id: 12,
      title: "Найди в парке",
      category: "Слуховое восприятие",
      subcategory: "Фонетический",
      description:
        "Послушать инструкцию и выбрать правильный ответ. Можно попросить ребенка назвать что делают на картинках персонаж",
      path: "/games/find-in-the-park",
      imageUrl: findInParkImg,
      unique_id: 10,
    },
    {
      id: 13,
      title: "Ошибки художника",
      category: "Память, внимание и логика",
      subcategory: "Зрительное внимание",
      description: "Найти ошибку в картинке и сказать что неправильно",
      path: "/games/artist-mistakes",
      imageUrl: artistMistakesImg,
      unique_id: 11,
    },
    {
      id: 14,
      title: "Времена года",
      category: "Окружающий мир",
      subcategory: "Явления природы",
      description: "Найти ошибку в картинке и сказать что неправильно",
      path: "/games/seasons",
      imageUrl: seasonsImg,
      unique_id: 12,
    },
    {
      id: 15,
      title: "Времена года",
      category: "Память, внимание и логика",
      subcategory: "Логические задачи",
      description: "Найти ошибку в картинке и сказать что неправильно",
      path: "/games/seasons",
      imageUrl: seasonsImg,
      unique_id: 12,
    },
    {
      id: 16,
      title: "Времена года",
      category: "Память, внимание и логика",
      subcategory: "Зрительное внимание",
      description: "Найти ошибку в картинке и сказать что неправильно",
      path: "/games/seasons",
      imageUrl: seasonsImg,
      unique_id: 12,
    },
    {
      id: 17,
      title: "Неречевые звуки (животные)",
      category: "Слуховое восприятие",
      subcategory: "Неречевые звуки",
      description: "Послушать звук и выбрать правильную картинку",
      path: "/games/animals-sounds",
      imageUrl: nonSpeechSoundsAnimals,
      unique_id: 13,
    },
    {
      id: 18,
      title: "Бытовые звуки",
      category: "Слуховое восприятие",
      subcategory: "Неречевые звуки",
      description: "Послушать звук и выбрать правильную картинку",
      path: "/games/home-appliances",
      imageUrl: HomeAppliancesImg,
      unique_id: 14,
    },
    {
      id: 19,
      title: "За городом",
      category: "Слуховое восприятие",
      subcategory: "Неречевые звуки",
      description: "Послушать звук и выбрать правильную картинку",
      path: "/games/countryside-sounds",
      imageUrl: CountrysideImg,
      unique_id: 15,
    },
    {
      id: 20,
      title: "На улице",
      category: "Слуховое восприятие",
      subcategory: "Неречевые звуки",
      description: "Послушать звук и выбрать правильную картинку",
      path: "/games/street-sounds",
      imageUrl: streetImg,
      unique_id: 16,
    },
    {
      id: 21,
      title: "Транспорт",
      category: "Слуховое восприятие",
      subcategory: "Неречевые звуки",
      description: "Послушать звук и выбрать правильную картинку",
      path: "/games/transport-sounds",
      imageUrl: transportImg,
      unique_id: 17,
    },
    {
      id: 22,
      title: "Природа",
      category: "Слуховое восприятие",
      subcategory: "Неречевые звуки",
      description: "Послушать звук и выбрать правильную картинку",
      path: "/games/nature-sounds",
      imageUrl: natureImg,
      unique_id: 18,
    },
    {
      id: 23,
      title: "Звуки людей (эмоции)",
      category: "Слуховое восприятие",
      subcategory: "Неречевые звуки",
      description: "Послушать звук и выбрать правильную картинку",
      path: "/games/emotions-sounds",
      imageUrl: emotionsImg,
      unique_id: 19,
    },
    {
      id: 24,
      title: "Птицы",
      category: "Слуховое восприятие",
      subcategory: "Неречевые звуки",
      description: "Послушать звук и выбрать правильную картинку",
      path: "/games/birds-sounds",
      imageUrl: birdsImg,
      unique_id: 20,
    },
    {
      id: 25,
      title: "Музыкальные инструменты",
      category: "Слуховое восприятие",
      subcategory: "Неречевые звуки",
      description: "Послушать звук и выбрать правильную картинку",
      path: "/games/musical-instruments-sounds",
      imageUrl: musInstrumentsImg,
      unique_id: 21,
    },
    {
      id: 26,
      title: "Насекомые",
      category: "Слуховое восприятие",
      subcategory: "Неречевые звуки",
      description: "Послушать звук и выбрать правильную картинку",
      path: "/games/insects-sounds",
      imageUrl: insects,
      unique_id: 22,
    },
    {
      id: 27,
      title: "Знакомство с цифрой 1",
      category: "Математика",
      subcategory: "Цифры, счет",
      description: "Внимательно послушайте задания и выполните инструкции",
      path: "/games/math-figure-one",
      imageUrl: figureOneImg,
      unique_id: 23,
    },
    {
      id: 28,
      title: "Знакомство с цифрой 2",
      category: "Математика",
      subcategory: "Цифры, счет",
      description: "Внимательно послушайте задания и выполните инструкции",
      path: "/games/math-figure-two",
      imageUrl: figureTwoImg,
      unique_id: 24,
    },
    {
      id: 29,
      title: "Знакомство с цифрой 3",
      category: "Математика",
      subcategory: "Цифры, счет",
      description: "Внимательно послушайте задания и выполните инструкции",
      path: "/games/math-figure-three",
      imageUrl: figureThreeImg,
      unique_id: 25,
    },
    {
      id: 30,
      title: "Знакомство с цифрой 4",
      category: "Математика",
      subcategory: "Цифры, счет",
      description: "Внимательно послушайте задания и выполните инструкции",
      path: "/games/math-figure-four",
      imageUrl: figureFourImg,
      unique_id: 26,
    },
    {
      id: 31,
      title: "Знакомство с цифрой 5",
      category: "Математика",
      subcategory: "Цифры, счет",
      description: "Внимательно послушайте задания и выполните инструкции",
      path: "/games/math-figure-five",
      imageUrl: figureFiveImg,
      unique_id: 27,
    },
    {
      id: 32,
      title: "Знакомство с цифрой 6",
      category: "Математика",
      subcategory: "Цифры, счет",
      description: "Внимательно послушайте задания и выполните инструкции",
      path: "/games/math-figure-six",
      imageUrl: figureSixImg,
      unique_id: 28,
    },
    {
      id: 33,
      title: "Знакомство с цифрой 7",
      category: "Математика",
      subcategory: "Цифры, счет",
      description: "Внимательно послушайте задания и выполните инструкции",
      path: "/games/math-figure-seven",
      imageUrl: figureSevenImg,
      unique_id: 29,
    },
    {
      id: 34,
      title: "Знакомство с цифрой 8",
      category: "Математика",
      subcategory: "Цифры, счет",
      description: "Внимательно послушайте задания и выполните инструкции",
      path: "/games/math-figure-eight",
      imageUrl: figureEightImg,
      unique_id: 30,
    },
    {
      id: 35,
      title: "Знакомство с цифрой 9",
      category: "Математика",
      subcategory: "Цифры, счет",
      description: "Внимательно послушайте задания и выполните инструкции",
      path: "/games/math-figure-nine",
      imageUrl: figureNineImg,
      unique_id: 31,
    },
    {
      id: 36,
      title: "Знакомство с числом 10",
      category: "Математика",
      subcategory: "Цифры, счет",
      description: "Внимательно послушайте задания и выполните инструкции",
      path: "/games/math-figure-ten",
      imageUrl: figureTenImg,
      unique_id: 32,
    },
    {
      id: 37,
      title: "Злой козлик",
      category: "Звукопроизношение",
      subcategory: "Автоматизация звуков",
      description: "Автоматизация звука “Б”",
      path: "/games/angry-goat",
      imageUrl: angryGoatImg,
      unique_id: 33,
    },
    {
      id: 38,
      title: "Волшебные лампы",
      category: "Звукопроизношение",
      subcategory: "Автоматизация звуков",
      description: "Автоматизация звука “Л” в начале слова",
      path: "/games/magic-lamps",
      imageUrl: magicLampsImg,
      unique_id: 34,
    },
    {
      id: 39,
      title: "Помоги шмелю покушать",
      category: "Звукопроизношение",
      subcategory: "Автоматизация звуков",
      description: "Автоматизация звука “Ш” в слогах",
      path: "/games/help-bumblebee-eat",
      imageUrl: bumblebeeImg,
      unique_id: 36,
    },
    {
      id: 40,
      title: "Что у змеи в норе",
      category: "Звукопроизношение",
      subcategory: "Автоматизация звуков",
      description: "Автоматизация звука “Ш” в начале слова",
      path: "/games/what-in-snakes-hole",
      imageUrl: snakeImg,
      unique_id: 37,
    },
    {
      id: 41,
      title: "Свидание с подругой Жужжой",
      category: "Звукопроизношение",
      subcategory: "Автоматизация звуков",
      description: "Автоматизация звука “Ж” в слогах",
      path: "/games/dating-with-zhuzha",
      imageUrl: datingWithZhuzhaImg,
      unique_id: 38,
    },

    {
      id: 42,
      title: "Желудь с сюрпризом",
      category: "Звукопроизношение",
      subcategory: "Автоматизация звуков",
      description: "Автоматизация звука “Ж” в начале слова",
      path: "/games/acorn-with-surprise",
      imageUrl: acornImg,
      unique_id: 39,
    },
    {
      id: 43,
      title: "Собираем фрукты",
      category: "Звукопроизношение",
      subcategory: "Автоматизация звуков",
      description: "Автоматизация звука “С” в слогах",
      path: "/games/picking-fruits",
      imageUrl: pickingFruitsImg,
      unique_id: 40,
    },
    {
      id: 44,
      title: "Что сова нашла в лесу",
      category: "Звукопроизношение",
      subcategory: "Автоматизация звуков",
      description: "Автоматизация звука “С” в начале слова",
      path: "/games/what-owl-found-in-forest",
      imageUrl: whatOwlFoundImg,
      unique_id: 41,
    },
    {
      id: 45,
      title: "Изучаем круг",
      category: "Математика",
      subcategory: "Фигуры",
      description: "Знакомство с кругом",
      path: "/games/studying-circle",
      imageUrl: circleImg,
      unique_id: 42,
    },
    {
      id: 46,
      title: "Изучаем квадрат",
      category: "Математика",
      subcategory: "Фигуры",
      description: "Знакомство с квадратом",
      path: "/games/studying-square",
      imageUrl: squareImg,
      unique_id: 43,
    },
    {
      id: 47,
      title: "Изучаем треугольник",
      category: "Математика",
      subcategory: "Фигуры",
      description: "Знакомство с треугольником",
      path: "/games/studying-triangle",
      imageUrl: triangleImg,
      unique_id: 44,
    },
    {
      id: 48,
      title: "Изучаем прямоугольник",
      category: "Математика",
      subcategory: "Фигуры",
      description: "Знакомство с прямоугольником",
      path: "/games/studying-rectangle",
      imageUrl: rectangleImg,
      unique_id: 45,
    },
    {
      id: 49,
      title: "Изучаем ромб",
      category: "Математика",
      subcategory: "Фигуры",
      description: "Знакомство с ромбом",
      path: "/games/studying-rhomb",
      imageUrl: rhombusImg,
      unique_id: 46,
    },
    // {
    //   id: 50,
    //   title: "Изучаем овал",
    //   category: "Математика",
    //   subcategory: "Фигуры",
    //   description: "Знакомство с овалом, выполнение заданий с помощью мышки",
    //   path: "/games/studying-oval",
    //   imageUrl: ovalImg,
    //   unique_id: 47,
    // },
  ];

  return (
    <div>
      <div className={styles.mobileMessage}>
        Извините, эта страница доступна только на планшетах и компьютерах.
      </div>
      <div className={styles.test}>
        <GamesMenu
          activeCategory={activeCategory}
          setActiveCategory={setActiveCategory}
          gamesData={gamesData}
        />
        <GamesList gamesData={gamesData} activeCategory={activeCategory} />
      </div>
    </div>
  );
};

export default Games;
