import { useEffect, useState, useRef } from "react";
import { gsap } from "gsap";
import styles from "./frogs-game.module.css";
import useDynamicHeight from "../../../hooks/useDynamicHeight";
import EndGameOptions from "../../end-game-options/end-game-options";

import wallper from "./images/wallper.png";
import frog from "./images/frog.svg";
import tableLamp from "./images/table-lamp.svg";
import frogHouse from "./images/house.svg";
import frogLight from "./images/frog-lighting.svg";
import frogHouseLight from "./images/light-for-home.svg";
import lamp from "./images/lamp.svg";
import frogMother from "./images/mother.svg";
import al from "./images/al.svg";
import il from "./images/il.svg";
import la from "./images/la.svg";
import lo from "./images/lo.svg";
import lu from "./images/lu.svg";
import ly from "./images/ly.svg";
import le from "./images/le.svg";
import ol from "./images/ol.svg";
import ul from "./images/ul.svg";
import el from "./images/el.svg";
import backgroundSound from "./sounds/background.mp3";
import startInstructionSound from "./sounds/instruction.mp3";
import jumpSound from "./sounds/jump.mp3";
import actionSound from "./sounds/action.mp3";
import finalSound from "./sounds/praise.mp3";

const colors = [
  "#FF0000",
  "#FF7F00",
  "#FFFF00",
  "#00FF00",
  "#0000FF",
  "#4B0082",
  "#8B00FF",
];

const triggerFireworks = () => {
  const fireworksContainer = document.querySelector(
    `.${styles.fireworksContainer}`
  );
  if (fireworksContainer) {
    for (let i = 0; i < 10; i++) {
      setTimeout(() => createFirework(fireworksContainer), i * 500);
    }
  } else {
    console.error("Fireworks container not found");
  }
};

const createFirework = (container) => {
  const firework = document.createElement("div");
  firework.className = styles.firework;
  firework.style.left = `${Math.random() * 100}%`;
  firework.style.top = `${Math.random() * 100}%`;
  container.appendChild(firework);

  const particles = [];
  for (let i = 0; i < 30; i++) {
    const particle = document.createElement("div");
    particle.className = styles.particle;
    particle.style.background =
      colors[Math.floor(Math.random() * colors.length)];
    firework.appendChild(particle);
    particles.push(particle);
  }

  const tl = gsap.timeline({
    onComplete: () => {
      firework.remove();
    },
  });

  particles.forEach((particle) => {
    const angle = Math.random() * Math.PI * 2;
    const distance = Math.random() * 200 + 50;
    tl.fromTo(
      particle,
      { x: 0, y: 0, opacity: 1 },
      {
        x: Math.cos(angle) * distance,
        y: Math.sin(angle) * distance,
        opacity: 0,
        duration: 2,
        ease: "power2.out",
      },
      0
    );
  });
};

function FrogGame() {
  useDynamicHeight();
  const [isInteractionBlocked, setIsInteractionBlocked] = useState(true);
  const [buttonText, setButtonText] = useState("Начать игру");
  const [isButtonVisible, setIsButtonVisible] = useState(true);
  const [isLampVisible, setIsLampVisible] = useState(true);
  const [currentFrogImage, setCurrentFrogImage] = useState(frog);
  const [isHomeVisible, setIsHomeVisible] = useState(false);
  const [isMotherFrogVisible, setIsMotherFrogVisible] = useState(false);
  const [isfrogHouseLightVisible, setIsfrogHouseLightVisible] = useState(false);
  const [isLightVisible, setIsLightVisible] = useState(false);
  const [isJumping, setIsJumping] = useState(false);
  const [keyPressed, setKeyPressed] = useState(false);
  const [canJump, setCanJump] = useState(true);
  const [currentSounds, setCurrentSounds] = useState([
    "la",
    "lu",
    "lo",
    "ly",
    "le",
  ]);
  const [isGameCompleted, setIsGameCompleted] = useState(false);
  const [newSounds, setNewSounds] = useState(["al", "ul", "ol", "il", "el"]);

  const [currentJump, setCurrentJump] = useState(0);
  const [visitedImages, setVisitedImages] = useState({
    la: false,
    lu: false,
    lo: false,
    ly: false,
    le: false,
  });

  const [visitedNewImages, setVisitedNewImages] = useState({
    al: false,
    ul: false,
    ol: false,
    il: false,
    el: false,
  });

  const audioRef = useRef(null);
  const startInstructionAudioRef = useRef(new Audio(startInstructionSound));
  const backgroundAudioRef = useRef(new Audio(backgroundSound));

  const jump1 = [
    { x: 16.8, y: 79.9 },
    { x: 16.8, y: 73.5 },
    { x: 18.5, y: 67.2 },
    { x: 22.3, y: 65.2 },
    { x: 26.3, y: 70.2 },
    { x: 28.3, y: 75.2 },
    { x: 28.3, y: 83.2 },
  ];

  const jump2 = [
    { x: 28.3, y: 83.2 },
    { x: 30.5, y: 65 },
    { x: 32.5, y: 70 },
    { x: 35, y: 75 },
  ];

  const jump3 = [
    { x: 35, y: 75 },
    { x: 41, y: 67 },
    { x: 45, y: 60 },
    { x: 46, y: 60 },
    { x: 49, y: 68 },
    { x: 49, y: 77 },
  ];

  const jump4 = [
    { x: 49, y: 77 },
    { x: 52, y: 59 },
    { x: 54, y: 64 },
    { x: 57, y: 67 },
  ];

  const jump5 = [
    { x: 57, y: 67 },
    { x: 63, y: 59 },
    { x: 67, y: 55 },
    { x: 69, y: 60 },
    { x: 72, y: 63 },
    { x: 72, y: 74 },
  ];

  const jump6 = [
    { x: 72, y: 74 },
    { x: 72, y: 63 },
    { x: 75, y: 60 },
    { x: 77, y: 65 },
    { x: 81, y: 71 },
    // { x: 72, y: 72 },
  ];

  const jump7 = [
    { x: 16.8, y: 79.9 },
    { x: 16.8, y: 73 },
    { x: 19, y: 70 },
    { x: 21, y: 68 },
    { x: 23, y: 74 },
  ];

  const jump8 = [
    { x: 23, y: 74 },
    { x: 26, y: 65 },
    { x: 28, y: 55 },
    { x: 30, y: 50 },
    { x: 31, y: 55 },
    { x: 31, y: 60 },
    // { x: 72, y: 72 },
  ];

  const jumps = [jump1, jump2, jump3, jump4, jump5, jump6];
  const reverseJumps = jumps
    .slice()
    .reverse()
    .map((jump) => jump.slice().reverse())
    .concat([jump7, jump8]);

  useEffect(() => {
    let controlMethod = "keyboard"; // По умолчанию используем клавиатуру

    const handleKeyDown = (event) => {
      if (
        controlMethod === "keyboard" &&
        !keyPressed &&
        canJump &&
        !isInteractionBlocked
      ) {
        setKeyPressed(true);
        handleJump();
      }
    };

    const handleKeyUp = () => {
      if (controlMethod === "keyboard") {
        setKeyPressed(false);
        if (!isJumping) {
          setCanJump(true);
        }
      }
    };

    const handleTouchStart = () => {
      if (
        controlMethod === "mouse" &&
        canJump &&
        !keyPressed &&
        !isInteractionBlocked
      ) {
        handleJump();
      }
    };

    const handleMouseDown = () => {
      if (
        controlMethod === "mouse" &&
        canJump &&
        !keyPressed &&
        !isInteractionBlocked
      ) {
        handleJump();
      }
    };

    // Определение метода управления при первом взаимодействии
    const detectControlMethod = (event) => {
      if (event.type === "keydown" || event.type === "keyup") {
        controlMethod = "keyboard";
      } else if (event.type === "mousedown" || event.type === "touchstart") {
        controlMethod = "mouse";
      }
      // Удаляем обработчик после определения метода управления
      window.removeEventListener("keydown", detectControlMethod);
      window.removeEventListener("mousedown", detectControlMethod);
      window.removeEventListener("touchstart", detectControlMethod);
    };

    // Слушаем первое взаимодействие пользователя
    window.addEventListener("keydown", detectControlMethod);
    window.addEventListener("mousedown", detectControlMethod);
    window.addEventListener("touchstart", detectControlMethod);

    window.addEventListener("keydown", handleKeyDown);
    window.addEventListener("keyup", handleKeyUp);
    window.addEventListener("touchstart", handleTouchStart);
    window.addEventListener("mousedown", handleMouseDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
      window.removeEventListener("keyup", handleKeyUp);
      window.removeEventListener("touchstart", handleTouchStart);
      window.removeEventListener("mousedown", handleMouseDown);
    };
  }, [keyPressed, canJump, isJumping, isInteractionBlocked]);

  const handleJump = () => {
    if (currentJump >= jumps.length + reverseJumps.length) {
      return; // Ничего не делаем, если все прыжки завершены
    }
    setIsJumping(true);
    setCanJump(false);

    let currentJumpSteps;
    let isReverseJump = currentJump >= jumps.length;
    let reverseJumpIndex = currentJump - jumps.length;

    if (!isReverseJump) {
      // Прямые прыжки
      currentJumpSteps = jumps[currentJump];
    } else {
      // Обратные прыжки
      if (reverseJumpIndex < reverseJumps.length) {
        currentJumpSteps = reverseJumps[reverseJumpIndex];
      } else {
        console.log("Игра окончена");
        return;
      }
    }

    let stepIndex = 0;

    const executeJumpStep = () => {
      if (stepIndex === 0) {
        const jumpAudio = new Audio(jumpSound);
        jumpAudio.play();
      }

      const position = currentJumpSteps[stepIndex];
      const frogElement = document.querySelector("." + styles.frog);
      if (frogElement) {
        frogElement.style.left = `${position.x}%`;
        frogElement.style.top = `${position.y}%`;
      }

      if (stepIndex === currentJumpSteps.length - 1) {
        if (!isReverseJump) {
          const newVisitedImages = { ...visitedImages };
          const soundName = currentSounds[currentJump];
          newVisitedImages[soundName] = true;
          setVisitedImages(newVisitedImages);

          if (currentJump === jumps.length - 1) {
            setIsLampVisible(false);
            setCurrentFrogImage(frogLight);
            setIsHomeVisible(true);
            setIsMotherFrogVisible(true);
            setVisitedNewImages({
              al: true,
              ul: true,
              ol: true,
              il: true,
              el: true,
            });
            const actionAudio = new Audio(actionSound);
            actionAudio.play();
          }
        } else {
          // Обратные прыжки
          const reverseJumpIndex = currentJump - jumps.length;
          if (reverseJumpIndex < newSounds.length) {
            const soundName = newSounds[reverseJumpIndex];
            setVisitedNewImages((prev) => ({ ...prev, [soundName]: false }));
          }
          if (currentJump === jumps.length + reverseJumps.length - 1) {
            setIsfrogHouseLightVisible(true);
            setIsLightVisible(true);
            const actionAudio = new Audio(actionSound);
            actionAudio.play();

            actionAudio.onended = () => {
              const finalAudio = new Audio(finalSound);
              finalAudio.play();
              triggerFireworks();
              setTimeout(() => {
                setIsGameCompleted(true);
              }, 5000);
            };
          }
        }
      }

      stepIndex++;
      if (stepIndex < currentJumpSteps.length) {
        setTimeout(executeJumpStep, 50);
      } else {
        setCurrentJump(currentJump + 1);
        setIsJumping(false);
        if (!keyPressed) {
          setCanJump(true);
        }
      }
    };

    executeJumpStep();
  };

  const frogPosition = getFrogPosition();

  function getFrogPosition() {
    if (currentJump < jumps.length) {
      return jumps[currentJump][0];
    } else if (currentJump - jumps.length < reverseJumps.length) {
      return reverseJumps[currentJump - jumps.length][0];
    }
    // Возвращаем последнюю известную позицию, если прыжки завершены
    return reverseJumps[reverseJumps.length - 1][
      reverseJumps[reverseJumps.length - 1].length - 1
    ];
  }

  const handleNextTask = () => {
    setIsInteractionBlocked(true);
    setCanJump(false);

    if (startInstructionAudioRef.current) {
      startInstructionAudioRef.current.play();

      startInstructionAudioRef.current.onended = () => {
        setIsInteractionBlocked(false);
        setCanJump(true);
        if (backgroundAudioRef.current) {
          backgroundAudioRef.current.loop = true;
          backgroundAudioRef.current.play();
        }
      };
    }

    setIsButtonVisible(false);
  };

  useEffect(() => {
    return () => {
      startInstructionAudioRef.current.pause();
      startInstructionAudioRef.current.currentTime = 0;
      backgroundAudioRef.current.pause();
      backgroundAudioRef.current.currentTime = 0;

      gsap.globalTimeline.clear();

      const jumpAudioRef = new Audio(jumpSound);
      jumpAudioRef.pause();
      jumpAudioRef.currentTime = 0;

      const actionAudioRef = new Audio(actionSound);
      actionAudioRef.pause();
      actionAudioRef.currentTime = 0;

      const finalAudioRef = new Audio(finalSound);
      finalAudioRef.pause();
      finalAudioRef.currentTime = 0;
    };
  }, []);

  return (
    <>
      {isGameCompleted && <EndGameOptions />}
      <div className={`container ${styles.someOtherClass}`}>
        <div
          className={styles.backgroundContainer}
          style={{
            pointerEvents: isInteractionBlocked ? "none" : "auto",
          }}
        >
          <img className={styles.wallper} src={wallper} alt="" />

          <div className={styles.fireworksContainer}></div>

          <img
            className={styles.frog}
            src={currentFrogImage}
            style={{ left: `${frogPosition.x}%`, top: `${frogPosition.y}%` }}
            alt=""
          />
          {!visitedImages.la && <img className={styles.la} src={la} alt="" />}
          {!visitedImages.lu && <img className={styles.lu} src={lu} alt="" />}
          {!visitedImages.lo && <img className={styles.lo} src={lo} alt="" />}
          {!visitedImages.ly && <img className={styles.ly} src={ly} alt="" />}
          {!visitedImages.le && <img className={styles.le} src={le} alt="" />}
          {isLampVisible && (
            <img className={styles.tableLamp} src={tableLamp} alt="Lamp" />
          )}
          {visitedNewImages.al && <img className={styles.le} src={al} alt="" />}
          {visitedNewImages.ul && (
            <img className={styles.ly} src={ul} alt="Ul" />
          )}
          {visitedNewImages.ol && (
            <img className={styles.lo} src={ol} alt="El" />
          )}
          {visitedNewImages.il && (
            <img className={styles.lu} src={il} alt="El" />
          )}
          {visitedNewImages.el && (
            <img className={styles.la} src={el} alt="El" />
          )}
          {isHomeVisible && (
            <img
              className={styles.frogHouse}
              src={frogHouse}
              alt="дом лягушки"
            />
          )}
          {isMotherFrogVisible && (
            <img
              className={styles.frogMother}
              src={frogMother}
              alt="мама-лягушка"
            />
          )}

          {isfrogHouseLightVisible && (
            <img
              className={styles.frogHouseLight}
              src={frogHouseLight}
              alt="мама-лягушка"
            />
          )}
          {isLightVisible && (
            <img className={styles.lamp} src={lamp} alt="мама-лягушка" />
          )}
          <audio ref={audioRef} />
        </div>
        {isButtonVisible && (
          <div className={styles.darkOverlay}>
            <button className={styles.button} onClick={handleNextTask}>
              {buttonText}
            </button>
          </div>
        )}
      </div>
    </>
  );
}

export default FrogGame;
