import React, { useState, useEffect, useRef } from "react";
import EndGameOptions from "../../end-game-options/end-game-options";
import { gsap } from "gsap";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import DraggableItem from "./DraggableItem";
import DropTarget from "./DropTarget";
import styles from "./math-ten.module.css";

import figureTen from "./images/figureTen.png";
import fishOne from "./images/fishOne.png";
import fishTwo from "./images/fishTwo.png";
import fishThree from "./images/fishThree.png";
import fishFour from "./images/fishFour.png";
import fishFive from "./images/5.png";
import fishSix from "./images/fishSix.png";
import fishSeven from "./images/fishSeven.png";
import fishEight from "./images/fishEight.png";
import fishNine from "./images/fishNine.png";
import fishTen from "./images/fishTen.png";
import aquarium from "./images/aquarium.png";
import baloonOne from "./images/baloonOne.png";
import baloonTwo from "./images/baloonTwo.png";
import baloonSeven from "./images/baloonSeven.png";
import baloonTen from "./images/baloonTen.png";
import baloonTenSecond from "./images/baloonTenSecond.png";
import baloonTenThird from "./images/baloonTenThird.png";
import baloonFifteen from "./images/baloonFifteen.png";

import startInstructionSound from "./sounds/startInstruction.mp3";
import backgroundSound from "./sounds/backgroundSound.mp3";
import taskZeroSound from "./sounds/taskZero.mp3";
import taskTwoSound from "./sounds/taskTwo.mp3";
import taskThreeSound from "./sounds/taskThree.mp3";
import taskFourSound from "./sounds/taskFour.mp3";
import incorrectBaloonSound from "./sounds/no.mp3";
import fishSound from "./sounds/fish.mp3";
import baloonPopSound from "./sounds/baloon.mp3";
import finalSound from "./sounds/finalSound.mp3";
import praiseSound from "./sounds/praise.mp3";

const colors = [
  "#FF0000",
  "#FF7F00",
  "#FFFF00",
  "#00FF00",
  "#0000FF",
  "#4B0082",
  "#8B00FF",
];

const triggerFireworks = () => {
  const fireworksContainer = document.querySelector(
    `.${styles.fireworksContainer}`
  );
  if (fireworksContainer) {
    for (let i = 0; i < 10; i++) {
      setTimeout(() => createFirework(fireworksContainer), i * 500);
    }
  } else {
    console.error("Fireworks container not found");
  }
};

const createFirework = (container) => {
  const firework = document.createElement("div");
  firework.className = styles.firework;
  firework.style.left = `${Math.random() * 100}%`;
  firework.style.top = `${Math.random() * 100}%`;
  container.appendChild(firework);

  const particles = [];
  for (let i = 0; i < 30; i++) {
    const particle = document.createElement("div");
    particle.className = styles.particle;
    particle.style.background =
      colors[Math.floor(Math.random() * colors.length)];
    firework.appendChild(particle);
    particles.push(particle);
  }

  const tl = gsap.timeline({
    onComplete: () => {
      firework.remove();
    },
  });

  particles.forEach((particle) => {
    const angle = Math.random() * Math.PI * 2;
    const distance = Math.random() * 200 + 50;
    tl.fromTo(
      particle,
      { x: 0, y: 0, opacity: 1 },
      {
        x: Math.cos(angle) * distance,
        y: Math.sin(angle) * distance,
        opacity: 0,
        duration: 2,
        ease: "power2.out",
      },
      0
    );
  });
};

const MathFigureTenGame = () => {
  const [isGameCompleted, setIsGameCompleted] = useState(false);
  const [currentTaskIndex, setCurrentTaskIndex] = useState(0);
  const [imageOpacity, setImageOpacity] = useState(1);
  const [buttonText, setButtonText] = useState("Послушать");
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [isButtonVisible, setIsButtonVisible] = useState(true);
  const [isInteractionBlocked, setIsInteractionBlocked] = useState(true);
  const [fishesInAquarium, setFishesInAquarium] = useState({
    leftFish1: false,
    leftFish2: false,
    leftFish3: false,
    leftFish4: false,
    leftFish5: false,
    rightFish1: false,
    rightFish2: false,
    rightFish3: false,
    rightFish4: false,
    rightFish5: false,
  });

  const [visibleBalloons, setVisibleBalloons] = useState({
    baloonOne: true,
    baloonTwo: true,
    baloonSeven: true,
    baloonTen: true,
    baloonTenSecond: true,
    baloonTenThird: true,
    baloonFifteen: true,
  });

  const audioRef = useRef(null);
  const startInstructionAudioRef = useRef(new Audio(startInstructionSound));
  const backgroundAudioRef = useRef(new Audio(backgroundSound));
  const baloonPopSoundRef = useRef(new Audio(baloonPopSound));
  const finalSoundRef = useRef(new Audio(finalSound));
  const praiseSoundRef = useRef(new Audio(praiseSound));
  const incorrectBaloonSoundRef = useRef(new Audio(incorrectBaloonSound));

  const tasks = [
    {
      question: "Это число десять",
      images: [
        { src: figureTen, alt: "число десять", class: styles.figureTen },
      ],
      audioSrc: taskZeroSound,
    },
    {
      question: "А это десять рыбок",
      images: [
        {
          src: figureTen,
          alt: "число десять",
          class: styles.figureSevenWithPetals,
        },
        { src: fishOne, alt: "рыбка", class: styles.fishOne },
        { src: fishTwo, alt: "рыбка", class: styles.fishTwo },
        { src: fishThree, alt: "рыбка", class: styles.fishThree },
        { src: fishFour, alt: "рыбка", class: styles.fishFour },
        { src: fishFive, alt: "рыбка", class: styles.fishFive },
        { src: fishSix, alt: "рыбка", class: styles.fishSix },
        { src: fishSeven, alt: "рыбка", class: styles.fishSeven },
        { src: fishEight, alt: "рыбка", class: styles.fishEight },
        { src: fishNine, alt: "рыбка", class: styles.fishNine },
        { src: fishTen, alt: "рыбка", class: styles.fishTen },
      ],
      audioSrc: taskTwoSound,
    },
    {
      question: "Помести 10 рыбок в аквариум",
      images: [
        {
          src: aquarium,
          alt: "аквариум",
          class: styles.aquarium,
          id: "aquarium",
          targetId: "aquarium",
        },
        { src: fishOne, alt: "рыбка", class: styles.leftFish, id: "leftFish1" },
        { src: fishTwo, alt: "рыбка", class: styles.leftFish, id: "leftFish2" },
        {
          src: fishThree,
          alt: "рыбка",
          class: styles.leftFish,
          id: "leftFish3",
        },
        {
          src: fishFour,
          alt: "рыбка",
          class: styles.leftFish,
          id: "leftFish4",
        },
        {
          src: fishFive,
          alt: "рыбка",
          class: styles.leftFish,
          id: "leftFish5",
        },
        {
          src: fishSix,
          alt: "рыбка",
          class: styles.rightFish,
          id: "rightFish1",
        },
        {
          src: fishSeven,
          alt: "рыбка",
          class: styles.rightFish,
          id: "rightFish2",
        },
        {
          src: fishEight,
          alt: "рыбка",
          class: styles.rightFish,
          id: "rightFish3",
        },
        {
          src: fishNine,
          alt: "рыбка",
          class: styles.rightFish,
          id: "rightFish4",
        },
        {
          src: fishTen,
          alt: "рыбка",
          class: styles.rightFish,
          id: "rightFish5",
        },
      ],
      audioSrc: taskThreeSound,
    },
    {
      question: "Лопни шарики с числом десять!",
      images: [
        {
          src: baloonTen,
          alt: "Воздушный шарик",
          class: styles.baloon,
          isBalloon: true,
          id: "baloonTen",
        },
        {
          src: baloonOne,
          alt: "Воздушный шарик",
          class: styles.baloon,
          isBalloon: true,
          id: "baloonOne",
        },
        {
          src: baloonTwo,
          alt: "Воздушный шарик",
          class: styles.baloon,
          isBalloon: true,
          id: "baloonTwo",
        },
        {
          src: baloonTenSecond,
          alt: "Воздушный шарик",
          class: styles.baloon,
          isBalloon: true,
          id: "baloonTenSecond",
        },
        {
          src: baloonSeven,
          alt: "Воздушный шарик",
          class: styles.baloon,
          isBalloon: true,
          id: "baloonSeven",
        },

        {
          src: baloonFifteen,
          alt: "Воздушный шарик",
          class: styles.baloon,
          isBalloon: true,
          id: "baloonFifteen",
        },
        {
          src: baloonTenThird,
          alt: "Воздушный шарик",
          class: styles.baloon,
          isBalloon: true,
          id: "baloonTenThird",
        },
      ],
      audioSrc: taskFourSound,
    },
  ];

  const currentTask = tasks[currentTaskIndex];

  const playSound = () => {
    if (currentTask.audioSrc) {
      setIsInteractionBlocked(true);
      setButtonDisabled(true);

      const taskAudio = new Audio(currentTask.audioSrc);
      taskAudio.play().catch((error) => {
        console.error("Playback failed:", error);
        setButtonDisabled(false);
        setIsInteractionBlocked(false);
      });

      taskAudio.onended = () => {
        setButtonDisabled(false);
        setIsInteractionBlocked(false);

        if (currentTaskIndex === tasks.length - 1) {
          setButtonText("");
        } else {
          setButtonText("Далее");
        }
      };
    }
  };

  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.onended = () => {
        if (currentTaskIndex !== 2) {
          setButtonDisabled(false);
          setButtonText("Далее");
        }
      };
    }
  }, [currentTaskIndex]);

  const handleNextTask = () => {
    if (buttonText === "Послушать") {
      setIsInteractionBlocked(true); // Блокируем взаимодействие сразу после нажатия
      playSound(); // Воспроизводим звук для текущей задачи
    } else if (buttonText === "Далее" && !buttonDisabled) {
      setImageOpacity(0);
      setTimeout(() => {
        if (currentTaskIndex + 1 < tasks.length) {
          setCurrentTaskIndex(currentTaskIndex + 1);
          setButtonText("Послушать");
          setButtonDisabled(false);
          setIsInteractionBlocked(true); // Снова блокируем после перехода к следующему заданию
        }
        setImageOpacity(1);
      }, 500);
    }
  };

  useEffect(() => {
    if (currentTaskIndex === 2) {
      const allFishesInAquarium = Object.values(fishesInAquarium).every(
        (inAquarium) => inAquarium
      );
      setButtonDisabled(!allFishesInAquarium && buttonText === "Далее");
      if (allFishesInAquarium) {
        setButtonText("Далее");
      }
    }
  }, [fishesInAquarium, currentTaskIndex, buttonText]);

  const handleDrop = (itemId, targetId) => {
    if (targetId === "aquarium") {
      setFishesInAquarium((prev) => ({
        ...prev,
        [itemId]: true,
      }));
      const fishSoundAudio = new Audio(fishSound);
      fishSoundAudio.play();
    }
  };

  const handleBalloonClick = (balloonId) => {
    if (
      balloonId === "baloonTen" ||
      balloonId === "baloonTenSecond" ||
      balloonId === "baloonTenThird"
    ) {
      setVisibleBalloons((prevState) => ({
        ...prevState,
        [balloonId]: "popped",
      }));
      baloonPopSoundRef.current.play();

      setTimeout(() => {
        setVisibleBalloons((prevState) => {
          const newState = {
            ...prevState,
            [balloonId]: false,
          };

          const allRequiredBalloonsPopped = [
            "baloonTen",
            "baloonTenSecond",
            "baloonTenThird",
          ].every((id) => newState[id] === false);

          if (allRequiredBalloonsPopped) {
            triggerFireworks();
            finalSoundRef.current.play();

            finalSoundRef.current.onended = () => {
              triggerFireworks();
              setTimeout(() => {
                setIsGameCompleted(true);
              }, 5000);
              praiseSoundRef.current.play();
            };
          }

          return newState;
        });
      }, 500);
    } else {
      incorrectBaloonSoundRef.current.play(); // Звук для неправильного шара
    }
  };

  const handleStartGame = () => {
    setButtonDisabled(true);
    setIsInteractionBlocked(true);
    const instructionAudio = startInstructionAudioRef.current;
    instructionAudio.play();

    instructionAudio.onended = () => {
      if (audioRef.current) {
        audioRef.current.play();
      }
      if (backgroundAudioRef.current) {
        backgroundAudioRef.current.play();
      }
      setButtonDisabled(false);
    };

    setIsButtonVisible(false);
  };

  useEffect(() => {
    if (currentTaskIndex === 2) {
      document.body.style.backgroundColor = "#ffff";
    } else {
      document.body.style.backgroundColor = "rgba(252, 229, 170, 1)";
    }

    return () => {
      if (startInstructionAudioRef.current) {
        startInstructionAudioRef.current.pause();
        startInstructionAudioRef.current.currentTime = 0;
      }

      if (backgroundAudioRef.current) {
        backgroundAudioRef.current.pause();
        backgroundAudioRef.current.currentTime = 0;
      }

      document.body.style.backgroundColor = "";
    };
  }, [currentTaskIndex]);

  return (
    <DndProvider backend={HTML5Backend}>
      {isGameCompleted && <EndGameOptions />}
      <div className={styles.container}>
        <div
          className={styles.backgroundContainer}
          style={{
            pointerEvents: isInteractionBlocked ? "none" : "auto",
          }}
        >
          <h3 className={styles.header}>{currentTask.question}</h3>
          <div
            style={{
              opacity: imageOpacity,
              transition: "opacity 0.5s ease-in-out",
            }}
            className={styles.taskContainer}
          >
            {currentTaskIndex === 0 &&
              currentTask.images.map((img, index) => (
                <img
                  key={index}
                  src={img.src}
                  alt={img.alt}
                  className={img.class}
                />
              ))}
            {currentTaskIndex === 1 && (
              <div className={styles.figureAndFishContainer}>
                <img
                  src={figureTen}
                  alt="число десять"
                  className={styles.figureTenWithFish}
                />
                <div className={styles.fishContainer}>
                  {currentTask.images.slice(1).map((img, index) => (
                    <img
                      key={index}
                      src={img.src}
                      alt={img.alt}
                      className={img.class}
                    />
                  ))}
                </div>
              </div>
            )}
            {currentTaskIndex === 2 && (
              <div className={styles.aquariumContainer}>
                {currentTask.images
                  .filter(
                    (img) => !fishesInAquarium[img.id] && img.id !== "aquarium"
                  )
                  .map((img) => (
                    <DraggableItem
                      key={img.id}
                      id={img.id}
                      src={img.src}
                      alt={img.alt}
                      className={styles[`fishInitial-${img.id}`]}
                    />
                  ))}
                <DropTarget
                  targetId="aquarium"
                  onDrop={handleDrop}
                  className={styles.aquariumDropTarget}
                >
                  <img
                    src={aquarium}
                    alt="аквариум"
                    className={styles.aquarium}
                  />
                  <div className={styles.aquariumFishes}>
                    {Object.keys(fishesInAquarium)
                      .filter((key) => fishesInAquarium[key])
                      .map((key) => (
                        <img
                          key={key}
                          src={
                            currentTask.images.find((img) => img.id === key).src
                          }
                          alt="рыбка"
                          className={`${styles[`fishInAquarium-${key}`]} ${
                            styles.fishInAquarium
                          }`}
                        />
                      ))}
                  </div>
                </DropTarget>
              </div>
            )}

            {currentTaskIndex === 3 && (
              <div className={styles.balloonContainer}>
                {currentTask.images.map((img, index) => {
                  const isPopped = visibleBalloons[img.id] === "popped";
                  const isVisible = visibleBalloons[img.id] !== false;
                  return (
                    <img
                      key={index}
                      src={img.src}
                      alt={img.alt}
                      className={`${img.class} ${
                        img.isBalloon ? styles.balloon : ""
                      } ${isPopped ? styles.pop : ""}`}
                      onClick={() =>
                        img.isBalloon && handleBalloonClick(img.id)
                      }
                      style={{ display: isVisible ? "block" : "none" }}
                    />
                  );
                })}
              </div>
            )}
          </div>
        </div>
        {currentTaskIndex < tasks.length - 1 ? (
          <button
            className={styles.button}
            onClick={handleNextTask}
            disabled={buttonDisabled}
          >
            {buttonText}
          </button>
        ) : (
          buttonText === "Послушать" && (
            <button
              className={styles.button}
              onClick={() => {
                playSound();
                setButtonText("");
              }}
              disabled={buttonDisabled}
            >
              {buttonText}
            </button>
          )
        )}
        <div className={styles.fireworksContainer}></div>
      </div>
      <audio ref={audioRef} />
      <audio ref={backgroundAudioRef} src={backgroundSound} loop />
      {isButtonVisible && (
        <div className={styles.darkOverlay}>
          <button className={styles.buttonStartGame} onClick={handleStartGame}>
            Начать игру
          </button>
        </div>
      )}
    </DndProvider>
  );
};

export default MathFigureTenGame;
