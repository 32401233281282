import { useEffect, useState, useRef } from "react";
import EndGameOptions from "../../end-game-options/end-game-options";
import Confetti from "react-confetti";
import { gsap } from "gsap";
import styles from "./snakes-hole.module.css";
import wallper from "./images/wallper.png";
import labyrinth from "./images/labyrinth.svg";
import snake from "./images/snake.png";
import flag from "./images/flag.svg";
import ball from "./images/ball.png";
import tent from "./images/tent.png";
import tire from "./images/tire.png";
import cone from "./images/cone.png";
import box from "./images/box.png";
import helmet from "./images/helmet.png";
import hat from "./images/hat.png";
import chocolate from "./images/chocolate.png";
import shorts from "./images/shorts.png";
import trousers from "./images/trousers.png";
import courtains from "./images/courtains.png";
import furCoats from "./images/furCoats.png";
import clown from "./images/clown.png";
import six from "./images/6.png";

import startInstructionSound from "./sounds/startInstruction.mp3";
import backgroundSound from "./sounds/backgroundSound.mp3";
import itemSound from "./sounds/item.mp3";
import finalSound from "./sounds/finalSound.mp3";
import praiseSound from "./sounds/praise.mp3";

const itemsData = [
  { id: 1, src: furCoats, top: "46%", left: "4%" },
  { id: 2, src: shorts, top: "57%", left: "10%" },
  { id: 3, src: tire, top: "65%", left: "18%" },
  { id: 4, src: hat, top: "75%", left: "21%" },
  { id: 5, src: cone, top: "49%", left: "30%" },
  { id: 6, src: helmet, top: "69%", left: "32%" },
  { id: 7, src: courtains, top: "44%", left: "41%" },
  { id: 8, src: tent, top: "57%", left: "43%" },
  { id: 9, src: clown, top: "44%", left: "53%" },
  { id: 10, src: ball, top: "72%", left: "62%" },
  { id: 11, src: trousers, top: "49%", left: "68%" },
  { id: 12, src: chocolate, top: "76%", left: "74%" },
  { id: 13, src: box, top: "48%", left: "80%" },
  { id: 14, src: six, top: "51%", left: "91%" },
];

const pathCoordinates = [
  { top: "46%", left: "4%", disappearId: 1 },
  { top: "43%", left: "8%", disappearId: null },
  { top: "54%", left: "11%", disappearId: 2 },
  { top: "54%", left: "6%", disappearId: null },
  { top: "65%", left: "6%", disappearId: null },
  { top: "68%", left: "6%", disappearId: null },
  { top: "68%", left: "9%", disappearId: null },
  { top: "70%", left: "9%", disappearId: null },
  { top: "70%", left: "16%", disappearId: null },
  { top: "65%", left: "18%", disappearId: 3 },
  { top: "61%", left: "19%", disappearId: null },
  { top: "61%", left: "19%", disappearId: null },
  { top: "57%", left: "20%", disappearId: null },
  { top: "57%", left: "24%", disappearId: null },
  { top: "57%", left: "24%", disappearId: null },
  { top: "74%", left: "24%", disappearId: 4 },
  { top: "57%", left: "24%", disappearId: null },
  { top: "57%", left: "22%", disappearId: null },
  { top: "57%", left: "22%", disappearId: null },
  { top: "52%", left: "22%", disappearId: null },
  { top: "51%", left: "26%", disappearId: null },
  { top: "49%", left: "30%", disappearId: 5 },
  { top: "46%", left: "33%", disappearId: null },
  { top: "55%", left: "33%", disappearId: null },
  { top: "57%", left: "27%", disappearId: null },
  { top: "73%", left: "26%", disappearId: null },
  { top: "76%", left: "37%", disappearId: null },
  { top: "63%", left: "38%", disappearId: null },
  { top: "67%", left: "32%", disappearId: 6 },
  { top: "69%", left: "28%", disappearId: null },
  { top: "63%", left: "30%", disappearId: null },
  { top: "55%", left: "37%", disappearId: null },
  { top: "43%", left: "41%", disappearId: 7 },
  { top: "43%", left: "44%", disappearId: null },
  { top: "56%", left: "43%", disappearId: 8 },
  { top: "56%", left: "40%", disappearId: null },
  { top: "56%", left: "40%", disappearId: null },
  { top: "66%", left: "41%", disappearId: null },
  { top: "66%", left: "44%", disappearId: null },
  { top: "63%", left: "46%", disappearId: null },
  { top: "63%", left: "47%", disappearId: null },
  { top: "74%", left: "47%", disappearId: null },
  { top: "73%", left: "52%", disappearId: null },
  { top: "70%", left: "55%", disappearId: null },
  { top: "60%", left: "57%", disappearId: null },
  { top: "55%", left: "56%", disappearId: null },
  { top: "52%", left: "55%", disappearId: null },
  { top: "50%", left: "52%", disappearId: null },
  { top: "66%", left: "52%", disappearId: null },
  { top: "66%", left: "50%", disappearId: null }, // проверка угла примерно тут
  { top: "60%", left: "49%", disappearId: null },
  { top: "45%", left: "48%", disappearId: null },
  { top: "44%", left: "53%", disappearId: 9 },
  { top: "45%", left: "58%", disappearId: null },
  { top: "49%", left: "60%", disappearId: null },
  { top: "73%", left: "63%", disappearId: 10 },
  { top: "74%", left: "65%", disappearId: null },
  { top: "68%", left: "66%", disappearId: null },
  { top: "67%", left: "64%", disappearId: null },
  { top: "67%", left: "69%", disappearId: null },
  { top: "58%", left: "69%", disappearId: null },
  { top: "54%", left: "67%", disappearId: null },
  { top: "47%", left: "66%", disappearId: null },
  { top: "47%", left: "68%", disappearId: 11 },
  { top: "47%", left: "71%", disappearId: null },
  { top: "73%", left: "72%", disappearId: null },
  { top: "74%", left: "74%", disappearId: 12 },
  { top: "76%", left: "81%", disappearId: null },
  { top: "71%", left: "81%", disappearId: null },
  { top: "71%", left: "78%", disappearId: null },
  { top: "56%", left: "77%", disappearId: null },
  { top: "48%", left: "77%", disappearId: null },
  { top: "48%", left: "77%", disappearId: null },
  { top: "48%", left: "81%", disappearId: 13 },
  { top: "48%", left: "83%", disappearId: null },
  { top: "67%", left: "83%", disappearId: null },
  { top: "69%", left: "88%", disappearId: null },
  { top: "64%", left: "90%", disappearId: null },
  { top: "50%", left: "91%", disappearId: 14 },
  { top: "20%", left: "91%", disappearId: null },
];

function WhatIsInSnakesHoleGame() {
  const [isGameCompleted, setIsGameCompleted] = useState(false);
  const [isInteractionBlocked, setIsInteractionBlocked] = useState(false);
  const [buttonText, setButtonText] = useState("Начать игру");
  const [isButtonVisible, setIsButtonVisible] = useState(true);
  const [showConfetti, setShowConfetti] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [timeline, setTimeline] = useState(null);

  const audioRef = useRef(null);
  const startInstructionAudioRef = useRef(new Audio(startInstructionSound));
  const backgroundAudioRef = useRef(new Audio(backgroundSound));
  const itemSoundRef = useRef(new Audio(itemSound));
  const finalSoundRef = useRef(new Audio(finalSound));
  const praiseSoundRef = useRef(new Audio(praiseSound));

  useEffect(() => {
    const snakeElement = document.querySelector(`.${styles.snake}`);
    const tl = gsap.timeline({
      paused: true,
      onComplete: () => {
        finalSoundRef.current.play();
        finalSoundRef.current.onended = () => {
          setShowConfetti(true);
          setTimeout(() => {
            setIsGameCompleted(true);
          }, 5000);
          praiseSoundRef.current.play();
        };
      },
    });

    pathCoordinates.forEach((step, index) => {
      const isDisappearingPoint = step.disappearId !== null;
      const duration = isDisappearingPoint ? 2 : 0.5;

      tl.to(snakeElement, {
        top: step.top,
        left: step.left,
        duration: duration,
        ease: "power1.inOut",
        onComplete: () => {
          if (step.disappearId) {
            const itemElement = document.querySelector(
              `.item${step.disappearId}`
            );

            // Анимация полета и исчезновения с помощью GSAP
            gsap.to(itemElement, {
              y: -50, // Полет на 50px вверх
              opacity: 0, // Плавное исчезновение
              duration: 1, // Длительность анимации
              onComplete: () => {
                itemElement.style.display = "none";
              },
            });

            // Воспроизведение звука предмета
            itemSoundRef.current.play();

            tl.pause(); // Пауза для исчезновения предмета
          }
        },
      });
    });

    setTimeline(tl); // Сохраняем таймлайн в состояние
  }, []);

  const handleClick = () => {
    if (timeline && !timeline.isActive()) {
      timeline.play();
    }
  };

  const handleNextTask = () => {
    setIsInteractionBlocked(true); // Блокируем взаимодействие

    if (startInstructionAudioRef.current) {
      startInstructionAudioRef.current.play();

      startInstructionAudioRef.current.onended = () => {
        setIsInteractionBlocked(false); // Разблокируем взаимодействие после окончания инструкции
        if (backgroundAudioRef.current) {
          backgroundAudioRef.current.loop = true; // Включаем повтор для фоновой музыки
          backgroundAudioRef.current.play(); // Запускаем фоновую музыку
        }
      };
    }

    setIsButtonVisible(false);
  };

  useEffect(() => {
    return () => {
      startInstructionAudioRef.current.pause();
      startInstructionAudioRef.current.currentTime = 0;
      backgroundAudioRef.current.pause();
      backgroundAudioRef.current.currentTime = 0;

      praiseSoundRef.current.pause();
      praiseSoundRef.current.currentTime = 0;

      gsap.globalTimeline.clear();
    };
  }, []);

  return (
    <>
      {showConfetti && (
        <Confetti
          numberOfPieces={500}
          angle={180}
          spread={120}
          origin={{ x: 1, y: 0.5 }}
        />
      )}
      {isGameCompleted && <EndGameOptions />}
      <div className={styles.container}>
        <div
          className={styles.backgroundContainer}
          onClick={handleClick}
          style={{
            pointerEvents: isInteractionBlocked ? "none" : "auto",
          }}
        >
          <img className={styles.wallper} src={wallper} alt="обои" />
          <img className={styles.labyrinth} src={labyrinth} alt="лабиринт" />
          <img className={styles.flag} src={flag} alt="флаг" />
          <img className={styles.snake} src={snake} alt="змейка" />
          {itemsData.map((item) => (
            <img
              key={item.id}
              className={`${styles.item} item${item.id}`}
              src={item.src}
              alt={`Item ${item.id}`}
              style={{ position: "absolute", top: item.top, left: item.left }}
            />
          ))}
        </div>
      </div>
      <audio ref={audioRef} />
      {isButtonVisible && (
        <div className={styles.darkOverlay}>
          <button className={styles.button} onClick={handleNextTask}>
            {buttonText}
          </button>
        </div>
      )}
    </>
  );
}

export default WhatIsInSnakesHoleGame;
