import React, { useState, useEffect, useRef } from "react";
import Confetti from "react-confetti";
import useDynamicHeight from "../../../hooks/useDynamicHeight";
import EndGameOptions from "../../end-game-options/end-game-options";
import styles from "./rabbit-game.module.css";
import rabbitImage from "./images/rabbit.png";
import wallperImage from "./images/wallper.png";
import carrotImage from "./images/carrot.png";
import carrotFood from "./images/carrotFood.png";

import startInstructionSound from "./sounds/startInstruction.mp3";
import backgroundSound from "./sounds/backgroundSound.mp3";
import eatCarrotSound from "./sounds/eatCarrot.mp3";
import jumpSound from "./sounds/jump.mp3";
import praiseSound from "./sounds/praise.mp3";

const RabbitGame = () => {
  useDynamicHeight();
  const [isInteractionBlocked, setIsInteractionBlocked] = useState(false);
  const [isGameCompleted, setIsGameCompleted] = useState(false);
  const [buttonText, setButtonText] = useState("Начать игру");
  const [isButtonVisible, setIsButtonVisible] = useState(true);
  const [showConfetti, setShowConfetti] = useState(false);
  const [isCarrotFoodVisible, setIsCarrotFoodVisible] = useState(true);
  const [currentJumpIndex, setCurrentJumpIndex] = useState(0);
  const [keyPressed, setKeyPressed] = useState(false);
  const [canJump, setCanJump] = useState(false);
  const [counter, setCounter] = useState(0);
  const [isButtonClicked, setIsButtonClicked] = useState(false);

  const audioRef = useRef(null);
  const startInstructionAudioRef = useRef(new Audio(startInstructionSound));
  const backgroundAudioRef = useRef(new Audio(backgroundSound));
  const eatCarrotSoundRef = useRef(new Audio(eatCarrotSound));
  const jumpSoundRef = useRef(new Audio(jumpSound));
  const praiseSoundRef = useRef(new Audio(praiseSound));

  const jump1 = [
    { x: 37, y: 71 },
    { x: 37, y: 68 },
    { x: 38, y: 65 },
    { x: 40, y: 60 },
    { x: 43, y: 57 },
    { x: 47, y: 55 },
    { x: 49, y: 52 },
    { x: 53, y: 52 },
    { x: 56, y: 58 },
  ];

  const jump2 = [
    { x: 56, y: 58 },
    { x: 56, y: 55 },
    { x: 55, y: 52 },
    { x: 53, y: 50 },
    { x: 50, y: 48 },
    { x: 48, y: 48, size: 11 },
    { x: 43, y: 49 },
  ];

  const jump3 = [
    { x: 43, y: 49 },
    { x: 43, y: 40 },
    { x: 45, y: 38 },
    { x: 49, y: 35 },
    { x: 52, y: 32 },
    { x: 55, y: 35 },
    { x: 55, y: 38 },
    { x: 57, y: 42, size: 9 },
    { x: 57, y: 43 },
  ];

  const jump4 = [
    { x: 57, y: 43 },
    { x: 57, y: 38 },
    { x: 54, y: 35 },
    { x: 51, y: 39 },
    { x: 49, y: 41, size: 8.5 },
    { x: 47, y: 42 },
  ];

  const jump5 = [
    { x: 47, y: 42 },
    { x: 47, y: 34 },
    { x: 50, y: 35 },
    { x: 52, y: 36, size: 7.5 },
    { x: 53, y: 37 },
    { x: 53, y: 39 },
  ];

  const jump6 = [
    { x: 53, y: 39 },
    { x: 53, y: 34 },
    { x: 55, y: 33 },
    { x: 56, y: 34, size: 6 },
    { x: 56, y: 37 },
  ];

  const jump7 = [
    { x: 56, y: 37 },
    { x: 56, y: 34 },
    { x: 55, y: 31 },
    { x: 53, y: 31 },
    { x: 51, y: 32, size: 5 },
    { x: 50, y: 35 },
    { x: 49, y: 36 },
    { x: 49, y: 37 },
  ];

  const jump8 = [
    { x: 49, y: 37 },
    { x: 50, y: 34 },
    { x: 51, y: 32 },
    { x: 52, y: 32, size: 5 },
    { x: 53, y: 33 },
    { x: 53, y: 34.5 },
  ];
  const jump9 = [
    { x: 53, y: 34.5 },
    { x: 52, y: 32 },
    { x: 51, y: 31 },
    { x: 50.5, y: 31.5, size: 4 },
    { x: 50, y: 33.5 },
  ];

  const jump10 = [
    { x: 50, y: 33.5 },
    { x: 51, y: 32 },
    { x: 51, y: 31, size: 3 },
    { x: 51.5, y: 30.5 },
    { x: 51.5, y: 32.5 },
  ];

  const jumps = [
    jump1,
    jump2,
    jump3,
    jump4,
    jump5,
    jump6,
    jump7,
    jump8,
    jump9,
    jump10,
  ];

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (isInteractionBlocked || isButtonClicked || !canJump) return;
      if (!keyPressed && currentJumpIndex < jumps.length) {
        setKeyPressed(true);
        setCounter((prevCounter) => prevCounter + 1);
        performJump(jumps[currentJumpIndex]);
        setCurrentJumpIndex((prevIndex) => prevIndex + 1);
      }
    };

    const handleKeyUp = () => {
      setKeyPressed(false);
    };

    const handleTouchStart = (event) => {
      event.preventDefault();
      if (isInteractionBlocked || isButtonClicked || !canJump) return;
      setKeyPressed(true);
      setCounter((prevCounter) => prevCounter + 1);
      performJump(jumps[currentJumpIndex]);
      setCurrentJumpIndex((prevIndex) => prevIndex + 1);
    };

    const handleMouseDown = (event) => {
      event.preventDefault();
      if (isInteractionBlocked || isButtonClicked || !canJump) return;
      setKeyPressed(true);
      setCounter((prevCounter) => prevCounter + 1);
      performJump(jumps[currentJumpIndex]);
      setCurrentJumpIndex((prevIndex) => prevIndex + 1);
    };

    window.addEventListener("keydown", handleKeyDown);
    window.addEventListener("keyup", handleKeyUp);
    window.addEventListener("touchstart", handleTouchStart);
    window.addEventListener("mousedown", handleMouseDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
      window.removeEventListener("keyup", handleKeyUp);
      window.removeEventListener("touchstart", handleTouchStart);
      window.removeEventListener("mousedown", handleMouseDown);
    };
  }, [
    isInteractionBlocked,
    isButtonClicked,
    keyPressed,
    canJump,
    currentJumpIndex,
    jumps,
  ]);

  const performJump = (jumpSteps) => {
    jumpSoundRef.current.currentTime = 0;
    jumpSoundRef.current.play();
    let totalDelay = 0; // Начальная задержка
    const delayIncrement = 45; // Задержка между шагами

    jumpSteps.forEach((step, index) => {
      setTimeout(() => {
        const rabbitElement = document.querySelector("." + styles.rabbitImage);
        if (rabbitElement) {
          rabbitElement.style.left = `${step.x}%`;
          rabbitElement.style.top = `${step.y}%`;
          rabbitElement.style.width = `${step.size}%`;
          // Добавляем плавность перехода для каждого шага
          rabbitElement.style.transition = `all ${delayIncrement - 100}ms ease`;
        }
      }, totalDelay);

      totalDelay += delayIncrement; // Увеличиваем общую задержку для следующего шага
    });

    if (currentJumpIndex === jumps.length - 1) {
      setIsCarrotFoodVisible(false);
      playCarrotEatenSoundAndShowConfetti();
    }
  };

  const playCarrotEatenSoundAndShowConfetti = () => {
    eatCarrotSoundRef.current.currentTime = 0;
    eatCarrotSoundRef.current.play();

    setTimeout(() => {
      setShowConfetti(true);
      praiseSoundRef.current.currentTime = 0;
      praiseSoundRef.current.play();

      setTimeout(() => {
        setIsGameCompleted(true);
      }, 5000);
    }, 1000);
  };

  const handleNextTask = (event) => {
    event.preventDefault();
    setIsButtonClicked(true);
    setIsInteractionBlocked(true);
    setKeyPressed(false);

    if (startInstructionAudioRef.current) {
      startInstructionAudioRef.current.play();
      startInstructionAudioRef.current.onended = () => {
        setTimeout(() => {
          setIsButtonClicked(false);
          setIsInteractionBlocked(false);
          setCanJump(true);
          if (backgroundAudioRef.current) {
            backgroundAudioRef.current.loop = true;
            backgroundAudioRef.current.play();
          }
        }, 500);
      };
    }
    setIsButtonVisible(false);
  };

  useEffect(() => {
    return () => {
      startInstructionAudioRef.current.pause();
      startInstructionAudioRef.current.currentTime = 0;
      backgroundAudioRef.current.pause();
      backgroundAudioRef.current.currentTime = 0;

      eatCarrotSoundRef.current.pause();
      eatCarrotSoundRef.current.currentTime = 0;
      jumpSoundRef.current.pause();
      jumpSoundRef.current.currentTime = 0;

      praiseSoundRef.current.pause();
      praiseSoundRef.current.currentTime = 0;
    };
  }, []);

  return (
    <>
      {showConfetti && (
        <Confetti
          numberOfPieces={500}
          angle={180}
          spread={120}
          origin={{ x: 1, y: 0.5 }}
        />
      )}
      {isGameCompleted && <EndGameOptions />}
      <div className={`container ${styles.someOtherClass}`}>
        <div
          className={styles.backgroundContainer}
          style={{
            pointerEvents: isInteractionBlocked ? "none" : "auto",
          }}
        >
          <img className={styles.wallperImage} src={wallperImage} alt="" />
          <img className={styles.carrotImage} src={carrotImage} alt="" />
          {isCarrotFoodVisible && (
            <img className={styles.carrotFood} src={carrotFood} alt="" />
          )}
          <div className={styles.counter}>{counter}</div>
          <img
            className={styles.rabbitImage}
            src={rabbitImage}
            style={{ transition: "all 0.5s ease" }}
          />
        </div>
        <audio ref={audioRef} />
        {isButtonVisible && (
          <div className={styles.darkOverlay}>
            <button className={styles.button} onClick={handleNextTask}>
              {buttonText}
            </button>
          </div>
        )}
      </div>
    </>
  );
};

export default RabbitGame;
