import React, { useState, useRef, useEffect } from "react";
import { gsap } from "gsap";
import Confetti from "react-confetti";
import styles from "./owl-found-in-forest.module.css";
import useDynamicHeight from "../../../hooks/useDynamicHeight";
import EndGameOptions from "../../end-game-options/end-game-options";

import wallper from "./images/wallper.png";
import owl from "./images/owl.png";
import unactiveWings from "./images/unactiveWings.png";
import leftWing from "./images/leftWing.png";
import rightWing from "./images/rightWing.png";
import closedEyes from "./images/closedEyes.png";
import salad from "./images/salad.png";
import samovar from "./images/samovar.png";
import aircraft from "./images/aircraft.png";
import sled from "./images/sled.png";
import som from "./images/som.png";
import sausage from "./images/sausage.png";
import box from "./images/box.png";
import cracker from "./images/cracker.png";
import cheese from "./images/cheese.png";
import pacifier from "./images/pacifier.png";

import startInstructionSound from "./sounds/startInstruction.mp3";
import backgroundSound from "./sounds/backgroundSound.mp3";
import flySound from "./sounds/fly.mp3";
import praiseSound from "./sounds/praise.mp3";

const flowerData = [
  {
    id: 1,
    flowerSrc: salad,
    flowerPosition: { top: "77%", left: "4%" },
    soundPosition: { top: "66%", left: "5%" },
  },
  {
    id: 2,
    flowerSrc: samovar,
    flowerPosition: { top: "55%", left: "19%" },
    soundPosition: { top: "44%", left: "20%" },
  },
  {
    id: 3,
    flowerSrc: aircraft,
    flowerPosition: { top: "25%", left: "21%" },
    soundPosition: { top: "14%", left: "22%" },
  },
  {
    id: 4,
    flowerSrc: sled,
    flowerPosition: { top: "75%", left: "38%" },
    soundPosition: { top: "64%", left: "39%" },
  },
  {
    id: 5,
    flowerSrc: som,
    flowerPosition: { top: "50%", left: "43%" },
    soundPosition: { top: "39%", left: "44%" },
  },
  {
    id: 6,
    flowerSrc: sausage,
    flowerPosition: { top: "15%", left: "45%" },
    soundPosition: { top: "4%", left: "46%" },
  },

  {
    id: 7,
    flowerSrc: box,
    flowerPosition: { top: "28%", left: "62%" },
    soundPosition: { top: "17%", left: "63%" },
  },
  {
    id: 8,
    flowerSrc: cracker,
    flowerPosition: { top: "29%", left: "83%" },
    soundPosition: { top: "18%", left: "84%" },
  },
  {
    id: 9,
    flowerSrc: cheese,
    flowerPosition: { top: "65%", left: "60%" },
    soundPosition: { top: "54%", left: "61%" },
  },
  {
    id: 10,
    flowerSrc: pacifier,
    flowerPosition: { top: "65%", left: "80%" },
    soundPosition: { top: "54%", left: "81%" },
  },
];

const WhatOwlFoundInForestGame = () => {
  useDynamicHeight();
  const [isInteractionBlocked, setIsInteractionBlocked] = useState(false);
  const [buttonText, setButtonText] = useState("Начать игру");
  const [isButtonVisible, setIsButtonVisible] = useState(true);
  const [showConfetti, setShowConfetti] = useState(false);
  const [visited, setVisited] = useState([]);
  const [currentPosition, setCurrentPosition] = useState(-1);
  const [isGameCompleted, setIsGameCompleted] = useState(false);

  const owlRef = useRef(null);
  const leftWingRef = useRef(null);
  const rightWingRef = useRef(null);
  const closedEyesRef = useRef(null);

  const audioRef = useRef(null);
  const startInstructionAudioRef = useRef(new Audio(startInstructionSound));
  const backgroundAudioRef = useRef(new Audio(backgroundSound));
  const flySoundRef = useRef(new Audio(flySound));
  const praiseSoundRef = useRef(new Audio(praiseSound));

  const flyToPosition = (left, top, index) => {
    if (isInteractionBlocked) return;

    setIsInteractionBlocked(true);
    const peakY = "-=100";

    flySoundRef.current.play();

    gsap.fromTo(
      leftWingRef.current,
      { rotation: 0, transformOrigin: "top right" },
      { rotation: 20, duration: 0.3, yoyo: true, repeat: -1 }
    );

    gsap.fromTo(
      rightWingRef.current,
      { rotation: 0, transformOrigin: "top left" },
      { rotation: -20, duration: 0.3, yoyo: true, repeat: -1 }
    );

    const flightAnimation = gsap.timeline();
    flightAnimation
      .to(owlRef.current, {
        y: peakY,
        duration: 0.4,
        ease: "power2.out",
      })
      .to(owlRef.current, {
        left: left,
        top: top,
        duration: 0.8,
        ease: "power2.inOut",
      })
      .to(owlRef.current, {
        y: 0,
        duration: 0.4,
        ease: "power2.inOut",
        onComplete: () => {
          const newVisited = [...visited, index];
          setVisited(newVisited);
          setCurrentPosition(index);
          gsap.killTweensOf(leftWingRef.current);
          gsap.killTweensOf(rightWingRef.current);
          setIsInteractionBlocked(false);

          if (newVisited.length === flowerData.length) {
            praiseSoundRef.current.play();
            setShowConfetti(true);
            setTimeout(() => {
              setIsGameCompleted(true);
            }, 5000);
          }
        },
      });
  };

  useEffect(() => {
    const blinkAnimation = gsap.timeline({ repeat: -1, repeatDelay: 2 });
    blinkAnimation
      .to(closedEyesRef.current, {
        opacity: 1,
        duration: 0.1,
      })
      .to(closedEyesRef.current, {
        opacity: 0,
        duration: 0.1,
        delay: 0.1,
      });
  }, []);

  const handleNextTask = () => {
    setIsInteractionBlocked(true);

    if (startInstructionAudioRef.current) {
      startInstructionAudioRef.current.play();

      startInstructionAudioRef.current.onended = () => {
        setIsInteractionBlocked(false);
        if (backgroundAudioRef.current) {
          backgroundAudioRef.current.loop = true;
          backgroundAudioRef.current.play();
        }
      };
    }

    setIsButtonVisible(false);
  };

  useEffect(() => {
    const blinkAnimation = gsap.timeline({ repeat: -1, repeatDelay: 2 });
    blinkAnimation
      .to(closedEyesRef.current, {
        opacity: 1,
        duration: 0.1,
      })
      .to(closedEyesRef.current, {
        opacity: 0,
        duration: 0.1,
        delay: 0.1,
      });

    // Функция очистки
    return () => {
      // Остановить и сбросить все аудио
      startInstructionAudioRef.current.pause();
      startInstructionAudioRef.current.currentTime = 0;
      backgroundAudioRef.current.pause();
      backgroundAudioRef.current.currentTime = 0;

      flySoundRef.current.pause();
      flySoundRef.current.currentTime = 0;

      praiseSoundRef.current.pause();
      praiseSoundRef.current.currentTime = 0;

      // Остановить все анимации GSAP
      gsap.globalTimeline.clear();
    };
  }, []);

  return (
    <>
      {showConfetti && (
        <Confetti
          numberOfPieces={1000}
          angle={180}
          spread={120}
          origin={{ x: 1, y: 0.5 }}
        />
      )}
      {isGameCompleted && <EndGameOptions />}
      <div className={`container ${styles.someOtherClass}`}>
        <div
          className={styles.backgroundContainer}
          style={{
            pointerEvents: isInteractionBlocked ? "none" : "auto",
          }}
        >
          <div className={styles.dropContainer}>
            <img className={styles.wallper} src={wallper} alt="фон" />

            <div className={styles.zhuzhaContainer} ref={owlRef}>
              <img src={owl} alt="сова" className={styles.owl} />
              <img
                src={unactiveWings}
                alt="крылья неактивные"
                className={styles.unactiveWings}
              />
              <img
                src={leftWing}
                alt="левое крыло"
                className={styles.leftWing}
                ref={leftWingRef}
              />
              <img
                src={rightWing}
                alt="правое крыло"
                className={styles.rightWing}
                ref={rightWingRef}
              />

              <img
                src={closedEyes}
                alt="моргание"
                className={styles.closedEyes}
                ref={closedEyesRef}
              />
            </div>
            {flowerData.map(
              (flower, index) =>
                !visited.includes(index) && (
                  <div key={flower.id}>
                    <img
                      className={styles.flower}
                      src={flower.flowerSrc}
                      alt={`Flower ${flower.id}`}
                      style={{
                        top: flower.flowerPosition.top,
                        left: flower.flowerPosition.left,
                        position: "absolute",
                        width: "10%",
                        cursor: "grab",
                      }}
                      onClick={() =>
                        flyToPosition(
                          flower.soundPosition.left,
                          flower.soundPosition.top,
                          index
                        )
                      }
                    />
                  </div>
                )
            )}
          </div>
        </div>
      </div>
      <audio ref={audioRef} />
      {isButtonVisible && (
        <div className={styles.darkOverlay}>
          <button className={styles.button} onClick={handleNextTask}>
            {buttonText}
          </button>
        </div>
      )}
    </>
  );
};

export default WhatOwlFoundInForestGame;
