import React, { useState, useEffect, useRef } from "react";
import useDynamicHeight from "../../../hooks/useDynamicHeight";
import EndGameOptions from "../../end-game-options/end-game-options";
import Confetti from "react-confetti";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import DraggableItem from "./DraggableItem";
import DropTarget from "./DropTarget";
import styles from "./studying-triangle.module.css";

import triangle from "./images/triangle.png";
import circleOne from "./images/circleOne.svg";
import circleTwo from "./images/circleTwo.svg";
import ellipse from "./images/ellipse.svg";
import rhombOne from "./images/rhombOne.svg";
import rhombTwo from "./images/rhombTwo.svg";
import rhombThree from "./images/rhombThree.svg";
import rhombFour from "./images/rhombFour.svg";
import squareOne from "./images/squareOne.svg";
import squareTwo from "./images/squareTwo.svg";
import squareThree from "./images/squareThree.svg";
import squareFour from "./images/squareFour.svg";
import triangleOne from "./images/triangleOne.svg";
import triangleTwo from "./images/triangleTwo.svg";
import triangleThree from "./images/triangleThree.svg";
import cheese from "./images/cheese.png";
import bagel from "./images/bagel.png";
import box from "./images/box.png";
import fish from "./images/fish.png";
import pyramide from "./images/pyramide.png";
import table from "./images/table.png";
import tree from "./images/tree.png";
import window from "./images/window.png";
import greenHouse from "./images/greenHouse.png";
import pinkHouse from "./images/pinkHouse.png";
import blueHouse from "./images/blueHouse.png";
import postCircle from "./images/postCircle.svg";
import postSquare from "./images/postSquare.svg";
import postTriangle from "./images/postTriangle.svg";
import boatDown from "./images/boatDown.png";
import boatUp from "./images/boatUp.png";
import hangerDown from "./images/hangerDown.svg";
import hangerUp from "./images/hangerUp.svg";
import houseDown from "./images/houseDown.png";
import houseUp from "./images/houseUp.png";
import iceDown from "./images/iceDown.png";
import iceUp from "./images/iceUp.png";
import treeDown from "./images/treeDown.svg";
import treeUp from "./images/treeUp.svg";

import backgroundSound from "./sounds/backgroundSound.mp3";
import incorrectAnswerSound from "./sounds/no.mp3";
import correctAnswerSound from "./sounds/correct.mp3";
import correctAnswerSoundTwo from "./sounds/correctTwo.mp3";

import taskZeroSound from "./sounds/taskZero.mp3";
import taskTwoSound from "./sounds/taskTwo.mp3";
import taskThreeSound from "./sounds/taskThree.mp3";
import taskFourSound from "./sounds/taskFour.mp3";
import taskFiveSound from "./sounds/taskFive.mp3";

import finalSound from "./sounds/finalSound.mp3";
import praiseSound from "./sounds/praise.mp3";

const StudyingTriangleGame = () => {
  useDynamicHeight();
  const [isGameCompleted, setIsGameCompleted] = useState(false);
  const [currentTaskIndex, setCurrentTaskIndex] = useState(0);
  const [imageOpacity, setImageOpacity] = useState(1);
  const [buttonText, setButtonText] = useState("Послушать");
  const [isInteractionBlocked, setIsInteractionBlocked] = useState(true);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [selectedTriangles, setSelectedTriangles] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [foundCirclesCount, setFoundCirclesCount] = useState(0);
  const [droppedItems, setDroppedItems] = useState([]);

  const [showConfetti, setShowConfetti] = useState(false);

  const audioRef = useRef(null);
  const backgroundAudioRef = useRef(new Audio(backgroundSound));
  const taskZeroSoundSoundRef = useRef(new Audio(taskZeroSound));
  const taskTwoSoundSoundRef = useRef(new Audio(taskTwoSound));
  const taskThreeSoundSoundRef = useRef(new Audio(taskThreeSound));
  const taskFourSoundSoundRef = useRef(new Audio(taskFourSound));
  const taskFiveSoundSoundRef = useRef(new Audio(taskFiveSound));
  const finalSoundRef = useRef(new Audio(finalSound));
  const praiseSoundRef = useRef(new Audio(praiseSound));
  const correctSoundRef = useRef(new Audio(correctAnswerSound));
  const incorrectSoundRef = useRef(new Audio(incorrectAnswerSound));

  useEffect(() => {
    document.body.style.backgroundColor = "rgba(252, 229, 170, 1)";
  }, [currentTaskIndex]);

  const tasks = [
    {
      question: "Это треугольник",
      images: [{ src: triangle, alt: "треугольник", class: styles.triangle }],
      audioRef: taskZeroSoundSoundRef,
    },
    {
      question: "Найди треугольники среди фигур",
      images: [
        {
          src: circleOne,
          alt: "круг",
          class: styles.circleOne,
          isTriangle: false,
        },
        {
          src: circleTwo,
          alt: "круг",
          class: styles.circleTwo,
          isTriangle: false,
        },
        {
          src: ellipse,
          alt: "эллипс",
          class: styles.ellipse,
          isTriangle: false,
        },
        {
          src: rhombOne,
          alt: "ромб",
          class: styles.rhombOne,
          isTriangle: false,
        },
        {
          src: rhombTwo,
          alt: "ромб",
          class: styles.rhombTwo,
          isTriangle: false,
        },
        {
          src: rhombThree,
          alt: "ромб",
          class: styles.rhombThree,
          isTriangle: false,
        },
        {
          src: rhombFour,
          alt: "ромб",
          class: styles.rhombFour,
          isTriangle: false,
        },
        {
          src: squareOne,
          alt: "квадрат",
          class: styles.squareOne,
          isTriangle: false,
        },
        {
          src: squareTwo,
          alt: "квадрат",
          class: styles.squareTwo,
          isTriangle: false,
        },
        {
          src: squareThree,
          alt: "квадрат",
          class: styles.squareThree,
          isTriangle: false,
        },
        {
          src: squareFour,
          alt: "квадрат",
          class: styles.squareFour,
          isTriangle: false,
        },
        {
          src: triangleOne,
          alt: "треугольник",
          class: styles.triangleOne,
          isTriangle: true,
        },
        {
          src: triangleTwo,
          alt: "треугольник",
          class: styles.triangleTwo,
          isTriangle: true,
        },
        {
          src: triangleThree,
          alt: "треугольник",
          class: styles.triangleThree,
          isTriangle: true,
        },
      ],
      audioRef: taskTwoSoundSoundRef,
    },
    {
      question: "Найди предметы похожие на треугольник",
      images: [
        { src: triangle, alt: "треугольник", class: styles.triangleThirdTask },
        {
          src: bagel,
          alt: "бублик",
          class: styles.bagel,
          isTriangle: false,
        },
        { src: cheese, alt: "сыр", class: styles.cheese, isTriangle: true },
        { src: box, alt: "коробка", class: styles.box, isTriangle: false },
        { src: fish, alt: "рыба", class: styles.fish, isTriangle: false },
        {
          src: pyramide,
          alt: "пирамида",
          class: styles.pyramide,
          isTriangle: true,
        },
        { src: table, alt: "стол", class: styles.table, isTriangle: false },
        { src: tree, alt: "дерево", class: styles.tree, isTriangle: true },
        {
          src: window,
          alt: "окно",
          class: styles.window,
          isTriangle: false,
        },
      ],
      audioRef: taskThreeSoundSoundRef,
    },
    {
      question: "Найди подходящий дом",
      images: [
        {
          src: greenHouse,
          alt: "дом",
          class: styles.house,
          isTriangle: false,
        },
        { src: postSquare, alt: "почта", class: styles.post },
        {
          src: blueHouse,
          alt: "дом",
          class: styles.house,
          isTriangle: true,
        },
        { src: postTriangle, alt: "почта", class: styles.post },
        {
          src: pinkHouse,
          alt: "дом",
          class: styles.house,
          isTriangle: false,
        },
        { src: postCircle, alt: "почта", class: styles.post },
      ],
      audioRef: taskFourSoundSoundRef,
    },

    {
      question: "Расставь треугольные предметы по своим местам и назови их",
      images: [
        {
          src: boatUp,
          alt: "парус",
          class: styles.boatUp,
          type: "UP",
        },
        {
          src: iceUp,
          alt: "мороженое",
          class: styles.iceUp,
          type: "UP",
        },
        { src: hangerUp, alt: "вешалка", class: styles.hangerUp, type: "UP" },
        {
          src: treeUp,
          alt: "елка",
          class: styles.treeUp,
          type: "UP",
        },
        {
          src: houseUp,
          alt: "дом",
          class: styles.houseUp,
          type: "UP",
          coordinates: { top: "0%", left: "0%" },
        },
        {
          src: houseDown,
          alt: "дом",
          class: styles.houseDown,
          type: "DOWN",
          coordinates: { top: "-69%", left: "8%" },
          selectedWidth: "87%",
        },
        {
          src: treeDown,
          alt: "елка",
          class: styles.treeDown,
          type: "DOWN",
          selectedWidth: "82%",
          coordinates: { top: "-41%", left: "10%" },
        },
        {
          src: iceDown,
          alt: "мороженое",
          class: styles.iceDown,
          selectedWidth: "100%",
          type: "DOWN",
          coordinates: { top: "-67%", left: "0%" },
        },
        {
          src: hangerDown,
          alt: "вешалка",
          class: styles.hangerDown,
          type: "DOWN",
          selectedWidth: "22%",
          coordinates: { top: "-58%", left: "42%" },
        },
        {
          src: boatDown,
          alt: "парус",
          class: styles.boatDown,
          type: "DOWN",
          selectedWidth: "40%",
          coordinates: { top: "12%", left: "53%" },
        },
      ],
      audioRef: taskFiveSoundSoundRef,
    },
  ];

  const currentTask = tasks[currentTaskIndex];

  const playSound = () => {
    const taskAudioRef = currentTask.audioRef;

    if (taskAudioRef && taskAudioRef.current) {
      setIsInteractionBlocked(true);
      setButtonDisabled(true);

      taskAudioRef.current.play().catch((error) => {
        console.error("Playback failed:", error);
        setButtonDisabled(false);
        setIsInteractionBlocked(false);
      });

      taskAudioRef.current.onended = () => {
        setIsInteractionBlocked(false);
        setButtonDisabled(false);
        setButtonText("Далее");
      };
    }
  };

  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.onended = () => {
        setButtonDisabled(false);
        setButtonText("Далее");
      };
    }
  }, [currentTaskIndex]);

  useEffect(() => {
    // задача 1
    if (currentTaskIndex === 1) {
      const trianglesSelected = selectedTriangles.length >= 3;
      setButtonDisabled(!trianglesSelected && buttonText === "Далее");
    }
  }, [selectedTriangles, currentTaskIndex, buttonText]);

  useEffect(() => {
    // задача 2
    if (currentTaskIndex === 2) {
      const itemsSelected = selectedItems.length >= 3;
      setButtonDisabled(!itemsSelected && buttonText === "Далее");
    }
  }, [selectedItems, currentTaskIndex, buttonText]);

  useEffect(() => {
    // задача 3
    if (currentTaskIndex === 3) {
      const correctItemsSelected = foundCirclesCount >= 1;
      setButtonDisabled(!correctItemsSelected && buttonText === "Далее");
    }
  }, [foundCirclesCount, currentTaskIndex, buttonText]);

  const handleNextTask = () => {
    if (buttonText === "Послушать") {
      setIsInteractionBlocked(true);
      playSound();
    } else if (buttonText === "Далее" && !buttonDisabled) {
      setImageOpacity(0);
      setTimeout(() => {
        if (currentTaskIndex + 1 < tasks.length) {
          setCurrentTaskIndex((prevIndex) => {
            const nextIndex = prevIndex + 1;
            if (nextIndex === 3) {
              setSelectedItems([]);
            }
            return nextIndex;
          });
          setButtonText("Послушать");
          setButtonDisabled(false);
          setIsInteractionBlocked(true);
          if (backgroundAudioRef.current) {
            setTimeout(() => {
              backgroundAudioRef.current.play();
            }, 10);
          }
        }
        setImageOpacity(1);
      }, 500);
    }
  };

  const handleTriangleClick = (index, isTriangle) => {
    if (!selectedTriangles.includes(index)) {
      if (isTriangle) {
        const newSelectedTriangles = [...selectedTriangles, index];
        setSelectedTriangles(newSelectedTriangles);

        const correctSound = new Audio(correctAnswerSound);
        correctSound.play();

        if (newSelectedTriangles.length === 3) {
          setButtonDisabled(false);
          setButtonText("Далее");
        }
      } else {
        const incorrectSound = new Audio(incorrectAnswerSound);
        incorrectSound.play();
      }
    }
  };

  const handleItemClick = (index, isTriangle) => {
    if (!selectedItems.includes(index)) {
      if (isTriangle) {
        const newSelectedItems = [...selectedItems, index];
        setSelectedItems(newSelectedItems);
        setFoundCirclesCount(newSelectedItems.length);

        const correctSound = new Audio(correctAnswerSoundTwo);
        correctSound.play();

        if (newSelectedItems.length === 3) {
          setButtonDisabled(false);
          setButtonText("Далее");
        }
      } else {
        const incorrectSound = new Audio(incorrectAnswerSound);
        incorrectSound.play();
      }
    }
  };

  const handleHouseClick = (index, isTriangle) => {
    if (!selectedItems.includes(index)) {
      if (isTriangle) {
        const newSelectedItems = [...selectedItems, index];
        setSelectedItems(newSelectedItems);
        setFoundCirclesCount(newSelectedItems.length);

        const correctSound = new Audio(correctAnswerSoundTwo);
        correctSound.play();

        if (newSelectedItems.length === 1) {
          setButtonDisabled(false);
          setButtonText("Далее");
        }
      } else {
        const incorrectSound = new Audio(incorrectAnswerSound);
        incorrectSound.play();
      }
    }
  };

  const handleDrop = (item, targetItem) => {
    if (item.alt === targetItem.alt) {
      const newItem = {
        ...item,
        coordinates: targetItem.coordinates,
        selectedWidth: targetItem.selectedWidth || "100%",
      };

      correctSoundRef.current.play();

      setSelectedItems((prevItems) => [...prevItems, newItem]);
      setDroppedItems((prevItems) => {
        const updatedItems = [...prevItems, item.alt];

        if (
          updatedItems.length ===
          currentTask.images.filter((img) => img.type === "DOWN").length
        ) {
          finalSoundRef.current.play().then(() => {
            setShowConfetti(true);
            setTimeout(() => {
              setIsGameCompleted(true);
            }, 5000);
            praiseSoundRef.current.play();
          });
        }

        return updatedItems;
      });
    } else {
      incorrectSoundRef.current.play();
    }
  };

  useEffect(() => {
    document.body.style.backgroundColor = "rgba(252, 229, 170, 1)";

    return () => {
      if (taskZeroSoundSoundRef.current) {
        taskZeroSoundSoundRef.current.pause();
        taskZeroSoundSoundRef.current.currentTime = 0;
      }

      if (taskTwoSoundSoundRef.current) {
        taskTwoSoundSoundRef.current.pause();
        taskTwoSoundSoundRef.current.currentTime = 0;
      }

      if (taskThreeSoundSoundRef.current) {
        taskThreeSoundSoundRef.current.pause();
        taskThreeSoundSoundRef.current.currentTime = 0;
      }

      if (taskFourSoundSoundRef.current) {
        taskFourSoundSoundRef.current.pause();
        taskFourSoundSoundRef.current.currentTime = 0;
      }

      if (taskFiveSoundSoundRef.current) {
        taskFiveSoundSoundRef.current.pause();
        taskFiveSoundSoundRef.current.currentTime = 0;
      }

      if (praiseSoundRef.current) {
        praiseSoundRef.current.pause();
        praiseSoundRef.current.currentTime = 0;
      }

      if (finalSoundRef.current) {
        finalSoundRef.current.pause();
        finalSoundRef.current.currentTime = 0;
      }

      if (backgroundAudioRef.current) {
        backgroundAudioRef.current.pause();
        backgroundAudioRef.current.currentTime = 0;
      }

      document.body.style.backgroundColor = "";
    };
  }, [currentTaskIndex]);

  return (
    <DndProvider backend={HTML5Backend}>
      {showConfetti && (
        <Confetti numberOfPieces={500} angle={90} spread={55} gravity={0.3} />
      )}

      {isGameCompleted && <EndGameOptions />}
      <div className={`container ${styles.someOtherClass}`}>
        <div
          className={styles.backgroundContainer}
          style={{
            pointerEvents: isInteractionBlocked ? "none" : "auto",
          }}
        >
          <h3 className={styles.header}>{currentTask.question}</h3>
          <div
            style={{
              opacity: imageOpacity,
              transition: "opacity 0.5s ease-in-out",
            }}
            className={styles.taskContainer}
          >
            {currentTaskIndex === 0 &&
              currentTask.images.map((img, index) => (
                <img
                  key={index}
                  src={img.src}
                  alt={img.alt}
                  className={img.class}
                />
              ))}
            {currentTaskIndex === 1 && (
              <div className={styles.figures}>
                {currentTask.images.map((image, index) => (
                  <img
                    key={index}
                    src={image.src}
                    alt={image.alt}
                    className={`${image.class} ${
                      selectedTriangles.includes(index)
                        ? styles.selectedTriangles
                        : ""
                    }`}
                    onClick={() => handleTriangleClick(index, image.isTriangle)}
                  />
                ))}
              </div>
            )}
            {currentTaskIndex === 2 && (
              <div className={styles.triangleTaskContainer}>
                <h4 className={styles.subHeader}>
                  Найдено: {foundCirclesCount}/3
                </h4>
                <div className={styles.triangleAndItems}>
                  <div className={styles.itemsLeft}>
                    {currentTask.images.slice(1, 5).map((image, index) => (
                      <div
                        key={index}
                        className={`${styles.itemContainer} ${
                          selectedItems.includes(index + 1) ? styles.hidden : ""
                        }`}
                      >
                        <img
                          src={image.src}
                          alt={image.alt}
                          className={image.class}
                          onClick={() =>
                            handleItemClick(index + 1, image.isTriangle)
                          }
                        />
                      </div>
                    ))}
                  </div>
                  <div className={styles.centerTriangle}>
                    <img
                      src={currentTask.images[0].src}
                      alt={currentTask.images[0].alt}
                      className={styles.largeTriangle}
                    />
                  </div>
                  <div className={styles.itemsRight}>
                    {currentTask.images.slice(5).map((image, index) => (
                      <div
                        key={index + 5}
                        className={`${styles.itemContainer} ${
                          selectedItems.includes(index + 5) ? styles.hidden : ""
                        }`}
                      >
                        <img
                          src={image.src}
                          alt={image.alt}
                          className={image.class}
                          onClick={() =>
                            handleItemClick(index + 5, image.isTriangle)
                          }
                        />
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            )}

            {currentTaskIndex === 3 && (
              <div className={styles.houseTaskContainer}>
                <div className={styles.housesContainer}>
                  {currentTask.images
                    .filter((img) => img.alt === "дом")
                    .map((image, index) => (
                      <div
                        key={index}
                        className={`${styles.houseContainer} ${
                          selectedItems.includes(index)
                            ? styles.selectedHouse
                            : ""
                        }`}
                        onClick={() =>
                          handleHouseClick(index, image.isTriangle)
                        }
                      >
                        <img
                          src={image.src}
                          alt={image.alt}
                          className={image.class}
                        />
                      </div>
                    ))}
                </div>
                <div className={styles.mailboxesContainer}>
                  {currentTask.images
                    .filter((img) => img.alt === "почта")
                    .map((image, index) => (
                      <div key={index} className={styles.mailboxContainer}>
                        <img
                          src={image.src}
                          alt={image.alt}
                          className={image.class}
                        />
                      </div>
                    ))}
                </div>
              </div>
            )}
            {currentTaskIndex === 4 && (
              <div className={styles.triangleTaskContainer}>
                <div className={styles.itemsUpContainer}>
                  {currentTask.images
                    .filter(
                      (img) =>
                        img.type === "UP" && !droppedItems.includes(img.alt)
                    ) // Фильтруем предметы, которые были перетащены
                    .map((image, index) => (
                      <DraggableItem key={index} item={image} type="UP" />
                    ))}
                </div>
                <div className={styles.itemsDownContainer}>
                  {currentTask.images
                    .filter((img) => img.type === "DOWN")
                    .map((image, index) => (
                      <DropTarget
                        key={index}
                        accept="UP"
                        onDrop={(item) => handleDrop(item, image)}
                        className={styles.itemContainer}
                      >
                        <div style={{ position: "relative" }}>
                          <img
                            src={image.src}
                            alt={image.alt}
                            className={image.class}
                          />
                          {selectedItems
                            .filter((selected) => selected.alt === image.alt)
                            .map((selected, i) => (
                              <img
                                key={i}
                                src={selected.src}
                                alt={selected.alt}
                                className={selected.class}
                                style={{
                                  position: "absolute",
                                  top: selected.coordinates?.top,
                                  left: selected.coordinates?.left,
                                  width: selected.selectedWidth || "100%",
                                }}
                              />
                            ))}
                        </div>
                      </DropTarget>
                    ))}
                </div>
              </div>
            )}
          </div>
        </div>
        {currentTaskIndex < tasks.length - 1 ? (
          <button
            className={styles.button}
            onClick={handleNextTask}
            disabled={buttonDisabled}
          >
            {buttonText}
          </button>
        ) : (
          buttonText === "Послушать" && (
            <button
              className={styles.button}
              onClick={() => {
                playSound();
                setButtonText("");
              }}
              disabled={buttonDisabled}
            >
              {buttonText}
            </button>
          )
        )}
      </div>
      <audio ref={audioRef} />
      <audio ref={backgroundAudioRef} src={backgroundSound} loop />
    </DndProvider>
  );
};
export default StudyingTriangleGame;
