import React, { useState, useEffect, useRef } from "react";
import useDynamicHeight from "../../../hooks/useDynamicHeight";
import EndGameOptions from "../../end-game-options/end-game-options";
import { gsap } from "gsap";
import styles from "./bigger-smaller-game.module.css";
import wallperImage from "./images/wallper.png";
import elephant from "./images/elephant.svg";
import mouse from "./images/mouse.svg";
import bigHouse from "./images/bigHouse.svg";
import smallHouse from "./images/smallHouse.svg";
import giraffe from "./images/giraffe.svg";
import zebra from "./images/zebra.svg";
import aircraft from "./images/aircraft.svg";
import bus from "./images/bus.svg";
import sparrow from "./images/sparrow.svg";
import peacock from "./images/peacock.svg";
import notebook from "./images/notebook.svg";
import book from "./images/book.svg";
import table from "./images/table.svg";
import chair from "./images/chair.svg";
import tree from "./images/tree.svg";
import bush from "./images/bush.svg";
import backgroundSound from "./sounds/background.mp3";
import startInstructionSound from "./sounds/instruction.mp3";
import rightAnswerSound from "./sounds/yes.mp3";
import wrongAnswerSound from "./sounds/no.mp3";
import whatBiggerSound from "./sounds/whatBigger.mp3";
import whatSmallerSound from "./sounds/whatSmaller.mp3";
import whoBiggerSound from "./sounds/whoBigger.mp3";
import whoSmallerSound from "./sounds/whoSmaller.mp3";
import finalSound from "./sounds/praise.mp3";

const colors = [
  "#FF0000",
  "#FF7F00",
  "#FFFF00",
  "#00FF00",
  "#0000FF",
  "#4B0082",
  "#8B00FF",
];

const triggerFireworks = () => {
  const fireworksContainer = document.querySelector(
    `.${styles.fireworksContainer}`
  );
  if (fireworksContainer) {
    for (let i = 0; i < 10; i++) {
      setTimeout(() => createFirework(fireworksContainer), i * 500);
    }
  } else {
    console.error("Fireworks container not found");
  }
};

const createFirework = (container) => {
  const firework = document.createElement("div");
  firework.className = styles.firework;
  firework.style.left = `${Math.random() * 100}%`;
  firework.style.top = `${Math.random() * 100}%`;
  container.appendChild(firework);

  const particles = [];
  for (let i = 0; i < 30; i++) {
    const particle = document.createElement("div");
    particle.className = styles.particle;
    particle.style.background =
      colors[Math.floor(Math.random() * colors.length)];
    firework.appendChild(particle);
    particles.push(particle);
  }

  const tl = gsap.timeline({
    onComplete: () => {
      firework.remove();
    },
  });

  particles.forEach((particle) => {
    const angle = Math.random() * Math.PI * 2;
    const distance = Math.random() * 200 + 50;
    tl.fromTo(
      particle,
      { x: 0, y: 0, opacity: 1 },
      {
        x: Math.cos(angle) * distance,
        y: Math.sin(angle) * distance,
        opacity: 0,
        duration: 2,
        ease: "power2.out",
      },
      0
    );
  });
};

const BiggerSmallerGame = () => {
  useDynamicHeight();
  const [isInteractionBlocked, setIsInteractionBlocked] = useState(false);
  const [currentTaskIndex, setCurrentTaskIndex] = useState(0);
  const [isAnswerCorrect, setIsAnswerCorrect] = useState(null);
  const [selectedAnswer, setSelectedAnswer] = useState(null);
  const [buttonText, setButtonText] = useState("Начать игру");
  const [isButtonVisible, setIsButtonVisible] = useState(true);
  const [isGameCompleted, setIsGameCompleted] = useState(false);
  const audioRef = useRef(null);
  const startInstructionAudioRef = useRef(new Audio(startInstructionSound));
  const backgroundAudioRef = useRef(new Audio(backgroundSound));

  const tasks = [
    {
      question: "Кто больше?",
      sound: whoBiggerSound,
      images: [
        { src: elephant, alt: "elephant" },
        { src: mouse, alt: "Мышь" },
      ],
      correctAnswer: "elephant",
    },
    {
      question: "Что меньше?",
      sound: whatSmallerSound,
      images: [
        { src: bigHouse, alt: "ДомБ" },
        { src: smallHouse, alt: "ДомМ" },
      ],
      correctAnswer: "ДомМ",
    },
    {
      question: "Кто больше?",
      sound: whoBiggerSound,
      images: [
        { src: giraffe, alt: "giraffe" },
        { src: zebra, alt: "zebra" },
      ],
      correctAnswer: "giraffe",
    },
    {
      question: "Что больше?",
      sound: whatBiggerSound,
      images: [
        { src: aircraft, alt: "aircraft" },
        { src: bus, alt: "Автобус" },
      ],
      correctAnswer: "aircraft",
    },
    {
      question: "Кто меньше?",
      sound: whoSmallerSound,
      images: [
        { src: sparrow, alt: "Воробей" },
        { src: peacock, alt: "peacock" },
      ],
      correctAnswer: "Воробей",
    },
    {
      question: "Что меньше?",
      sound: whatSmallerSound,
      images: [
        { src: book, alt: "Книга" },
        { src: notebook, alt: "Блокнот" },
      ],
      correctAnswer: "Блокнот",
    },
    {
      question: "Что больше?",
      sound: whatBiggerSound,
      images: [
        { src: table, alt: "Стол" },
        { src: chair, alt: "Стул" },
      ],
      correctAnswer: "Стол",
    },
    {
      question: "Что меньше?",
      sound: whatSmallerSound,
      images: [
        { src: tree, alt: "tree" },
        { src: bush, alt: "bush" },
      ],
      correctAnswer: "bush",
    },
  ];

  const currentTask = tasks[currentTaskIndex];

  const handleImageClick = (answer) => {
    if (isAnswerCorrect) return;

    setSelectedAnswer(answer);
    const isCorrect = answer === currentTask.correctAnswer;
    setIsAnswerCorrect(isCorrect);

    const audio = new Audio(isCorrect ? rightAnswerSound : wrongAnswerSound);
    audio.play();

    if (isCorrect) {
      setTimeout(() => {
        setIsAnswerCorrect(null);
        setSelectedAnswer(null);

        if (currentTaskIndex + 1 === tasks.length) {
          // Игра окончена
          const finalAudio = new Audio(finalSound);
          finalAudio.play();

          // Триггер салюта
          triggerFireworks();
          setTimeout(() => {
            setIsGameCompleted(true);
          }, 5000);
        } else {
          const nextIndex = currentTaskIndex + 1; // Получаем следующий индекс перед обновлением
          setCurrentTaskIndex(nextIndex);

          // Воспроизведение следующего вопроса
          setTimeout(() => {
            const nextQuestionAudio = new Audio(tasks[nextIndex].sound);
            nextQuestionAudio.play();
          }, 1000); // Добавляем небольшую задержку, чтобы аудио воспроизвелось после обновления состояния
        }
      }, 1000);
    }
  };

  const handleNextTask = () => {
    setIsInteractionBlocked(true);

    if (startInstructionAudioRef.current) {
      startInstructionAudioRef.current.play();

      startInstructionAudioRef.current.onended = () => {
        setIsInteractionBlocked(false);
        if (backgroundAudioRef.current) {
          backgroundAudioRef.current.loop = true;
          backgroundAudioRef.current.play();
        }

        // Воспроизведение первого вопроса
        const firstQuestionAudio = new Audio(tasks[0].sound);
        firstQuestionAudio.play();
      };
    }

    setIsButtonVisible(false);
  };

  useEffect(() => {
    return () => {
      startInstructionAudioRef.current.pause();
      startInstructionAudioRef.current.currentTime = 0;

      backgroundAudioRef.current.pause();
      backgroundAudioRef.current.currentTime = 0;

      tasks.forEach((task) => {
        const questionAudio = new Audio(task.sound);
        questionAudio.pause();
        questionAudio.currentTime = 0;
      });

      gsap.globalTimeline.clear();
    };
  }, []);

  return (
    <>
      {isGameCompleted && <EndGameOptions />}
      <div className={`container ${styles.someOtherClass}`}>
        <div
          className={styles.backgroundContainer}
          style={{
            pointerEvents: isInteractionBlocked ? "none" : "auto",
          }}
        >
          <h3 className={styles.header}>{currentTask.question}</h3>
          <h4 className={styles.headerCounter}>
            Вопрос {currentTaskIndex + 1} из {tasks.length}
          </h4>
          <img className={styles.wallperImage} src={wallperImage} alt="" />
          <div className={styles.positionContainer}>
            <div className={styles.imagesContainer}>
              {currentTask.images.map((image) => (
                <img
                  key={image.alt}
                  src={image.src}
                  alt={image.alt}
                  className={`${styles.taskImage} ${
                    styles[`${image.alt}Image`]
                  }`}
                  onClick={() => handleImageClick(image.alt)}
                  style={{
                    filter:
                      selectedAnswer === image.alt && isAnswerCorrect
                        ? "drop-shadow(0 10px 50px rgba(50, 205, 50, 0.5))"
                        : selectedAnswer === image.alt &&
                          selectedAnswer !== null
                        ? "none"
                        : "",
                  }}
                />
              ))}
            </div>
          </div>
          <div className={styles.fireworksContainer}></div>
        </div>
        <audio ref={audioRef} src={backgroundSound} loop />
        {isButtonVisible && (
          <div className={styles.darkOverlay}>
            <button className={styles.button} onClick={handleNextTask}>
              {buttonText}
            </button>
          </div>
        )}
      </div>
    </>
  );
};

export default BiggerSmallerGame;
