import React, { useState, useEffect, useRef } from "react";
import useDynamicHeight from "../../../hooks/useDynamicHeight";
import EndGameOptions from "../../end-game-options/end-game-options";
import { gsap } from "gsap";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import styles from "./animals-hot-countries.module.css";
import DraggableItem from "../../DnD/drag";
import DropTarget from "../../DnD/drop";

import wallperImage from "./images/wallper.png";
import hippopotamus from "./images/hippopotamus.png";
import camel from "./images/camel.svg";
import giraffee from "./images/giraffee.png";
import zebra from "./images/zebra.png";
import kangaroo from "./images/kangaroo.png";
import crocodile from "./images/crocodile.png";
import lion from "./images/lion.png";
import elephant from "./images/elephant.png";
import tiger from "./images/tiger.png";

import camelShadow from "./images/camelShadow.svg";
import crocodileShadow from "./images/crocShadow.svg";
import elephantShadow from "./images/elephantShadow.svg";
import giraffeeShadow from "./images/giraffeShadow.svg";
import kangarooShadow from "./images/kangarooShadow.svg";
import lionShadow from "./images/lionShadow.svg";
import tigerShadow from "./images/tigerShadow.svg";
import zebraShadow from "./images/zebraShadow.svg";
import hippopotamusShadow from "./images/hippopotamusShadow.svg";

import backgroundSound from "./sounds/backgroundSound.mp3";
import startInstructionSound from "./sounds/startInstructionSound.mp3";
import incorrectAnswerSound from "./sounds/no.mp3";
import finalSound from "./sounds/finalSound.mp3";
import camelSound from "./sounds/camel.mp3";
import crocodileSound from "./sounds/crocodile.mp3";
import elephantSound from "./sounds/elephant.mp3";
import giraffeeSound from "./sounds/giraffee.mp3";
import hippopotamusSound from "./sounds/hippopotamus.mp3";
import kangarooSound from "./sounds/kangaroo.mp3";
import lionSound from "./sounds/lion.mp3";
import tigerSound from "./sounds/tiger.mp3";
import zebraSound from "./sounds/zebra.mp3";

const colors = [
  "#FF0000",
  "#FF7F00",
  "#FFFF00",
  "#00FF00",
  "#0000FF",
  "#4B0082",
  "#8B00FF",
];

const triggerFireworks = () => {
  const fireworksContainer = document.querySelector(
    `.${styles.fireworksContainer}`
  );
  if (fireworksContainer) {
    for (let i = 0; i < 10; i++) {
      setTimeout(() => createFirework(fireworksContainer), i * 500);
    }
  } else {
    console.error("Fireworks container not found");
  }
};

const createFirework = (container) => {
  const firework = document.createElement("div");
  firework.className = styles.firework;
  firework.style.left = `${Math.random() * 100}%`;
  firework.style.top = `${Math.random() * 100}%`;
  container.appendChild(firework);

  const particles = [];
  for (let i = 0; i < 30; i++) {
    const particle = document.createElement("div");
    particle.className = styles.particle;
    particle.style.background =
      colors[Math.floor(Math.random() * colors.length)];
    firework.appendChild(particle);
    particles.push(particle);
  }

  const tl = gsap.timeline({
    onComplete: () => {
      firework.remove();
    },
  });

  particles.forEach((particle) => {
    const angle = Math.random() * Math.PI * 2;
    const distance = Math.random() * 200 + 50;
    tl.fromTo(
      particle,
      { x: 0, y: 0, opacity: 1 },
      {
        x: Math.cos(angle) * distance,
        y: Math.sin(angle) * distance,
        opacity: 0,
        duration: 2,
        ease: "power2.out",
      },
      0
    );
  });
};

const images = [
  {
    id: "бегемот",
    src: hippopotamus,
    alt: "бегемот",
    className: styles.hippopotamus,
    dropStyle: {
      left: "65%",
      top: "66%",
      width: "11%",
    },
    shadowStyle: {
      left: "67",
      top: "65",
    },
    targetId: "бегемот",
  },
  {
    id: "верблюд",
    src: camel,
    alt: "верблюд",
    className: styles.camel,
    dropStyle: {
      left: "11%",
      top: "27%",
      width: "11%",
    },
    shadowStyle: {
      left: "10",
      top: "20",
    },
    targetId: "верблюд",
  },
  {
    id: "жираф",
    src: giraffee,
    alt: "жираф",
    className: styles.giraffee,
    dropStyle: {
      left: "34%",
      top: "53%",
      width: "8.5%",
    },
    shadowStyle: {
      left: "33",
      top: "57",
    },
    targetId: "жираф",
  },
  {
    id: "зебра",
    src: zebra,
    alt: "зебра",
    className: styles.zebra,
    dropStyle: {
      left: "26%",
      top: "24%",
      width: "8%",
    },
    shadowStyle: {
      left: "26",
      top: "24",
    },
    targetId: "зебра",
  },
  {
    id: "кенгуру",
    src: kangaroo,
    alt: "кенгуру",
    className: styles.kangaroo,
    dropStyle: {
      left: "42%",
      top: "18%",
      width: "9%",
    },
    shadowStyle: {
      left: "41",
      top: "21",
    },
    targetId: "кенгуру",
  },
  {
    id: "крокодил",
    src: crocodile,
    alt: "крокодил",
    className: styles.crocodile,
    dropStyle: {
      left: "56%",
      top: "48%",
      width: "15%",
    },
    shadowStyle: {
      left: "59",
      top: "48",
    },
    targetId: "крокодил",
  },
  {
    id: "лев",
    src: lion,
    alt: "лев",
    className: styles.lion,
    dropStyle: {
      left: "52%",
      top: "25%",
      width: "10%",
    },
    shadowStyle: {
      left: "53",
      top: "24",
    },
    targetId: "лев",
  },
  {
    id: "тигр",
    src: tiger,
    alt: "тигр",
    className: styles.tiger,
    dropStyle: {
      left: "84%",
      top: "47%",
      width: "10%",
    },
    shadowStyle: {
      left: "82",
      top: "45",
    },
    targetId: "тигр",
  },
  {
    id: "слон",
    src: elephant,
    alt: "слон",
    className: styles.elephant,
    dropStyle: {
      left: "46%",
      top: "49%",
      width: "10%",
    },
    shadowStyle: {
      left: "45",
      top: "50",
    },
    targetId: "слон",
  },
];

const AnimalsHotCountriesGame = () => {
  useDynamicHeight();
  const [isInteractionBlocked, setIsInteractionBlocked] = useState(false);
  const [isGameCompleted, setIsGameCompleted] = useState(false);
  const [buttonText, setButtonText] = useState("Начать игру");
  const [isButtonVisible, setIsButtonVisible] = useState(true);
  const audioRef = useRef(null);
  const startInstructionAudioRef = useRef(new Audio(startInstructionSound));
  const backgroundAudioRef = useRef(new Audio(backgroundSound));
  const [itemPlaced, setItemPlaced] = useState(
    images.reduce(
      (acc, image) => ({
        ...acc,
        [image.alt]: false,
      }),
      {}
    )
  );

  const handleDrop = (itemId, targetId) => {
    if (itemId === targetId) {
      setItemPlaced((prev) => {
        const updatedItemPlaced = {
          ...prev,
          [itemId]: true,
        };

        // Воспроизведение звука при успешном размещении животного
        let sound;
        switch (itemId) {
          case "бегемот":
            sound = new Audio(hippopotamusSound);
            break;
          case "верблюд":
            sound = new Audio(camelSound);
            break;
          case "жираф":
            sound = new Audio(giraffeeSound);
            break;
          case "зебра":
            sound = new Audio(zebraSound);
            break;
          case "кенгуру":
            sound = new Audio(kangarooSound);
            break;
          case "крокодил":
            sound = new Audio(crocodileSound);
            break;
          case "лев":
            sound = new Audio(lionSound);
            break;
          case "тигр":
            sound = new Audio(tigerSound);
            break;
          case "слон":
            sound = new Audio(elephantSound);
            break;
          default:
            break;
        }

        if (sound) {
          sound.play();
          sound.onended = () => {
            const allPlaced = Object.values(updatedItemPlaced).every(Boolean);
            if (allPlaced) {
              const finalAudio = new Audio(finalSound);
              triggerFireworks();
              setTimeout(() => {
                setIsGameCompleted(true);
              }, 5000);
              finalAudio.play();
            }
          };
        }
        return updatedItemPlaced;
      });
    } else {
      const incorrectSound = new Audio(incorrectAnswerSound);
      incorrectSound.play();
    }
  };

  const handleNextTask = () => {
    setIsInteractionBlocked(true);

    if (startInstructionAudioRef.current) {
      startInstructionAudioRef.current.play();

      startInstructionAudioRef.current.onended = () => {
        setIsInteractionBlocked(false);
        if (backgroundAudioRef.current) {
          backgroundAudioRef.current.loop = true;
          backgroundAudioRef.current.play();
        }
      };
    }

    setIsButtonVisible(false);
  };

  useEffect(() => {
    return () => {
      // Остановить и сбросить все аудио
      startInstructionAudioRef.current.pause();
      startInstructionAudioRef.current.currentTime = 0;
      backgroundAudioRef.current.pause();
      backgroundAudioRef.current.currentTime = 0;

      // Остановить все анимации GSAP
      gsap.globalTimeline.clear();
    };
  }, []);

  return (
    <>
      <DndProvider backend={HTML5Backend}>
        {isGameCompleted && <EndGameOptions />}
        <div className={`container ${styles.someOtherClass}`}>
          <div
            className={styles.backgroundContainer}
            style={{
              pointerEvents: isInteractionBlocked ? "none" : "auto",
            }}
          >
            <div className={styles.dropContainer}>
              <img className={styles.wallperImage} src={wallperImage} alt="" />
              {!itemPlaced["бегемот"] && (
                <img
                  className={styles.hippopotamusShadow}
                  src={hippopotamusShadow}
                  alt=""
                />
              )}
              {!itemPlaced["верблюд"] && (
                <img className={styles.camelShadow} src={camelShadow} alt="" />
              )}
              {!itemPlaced["крокодил"] && (
                <img
                  className={styles.crocodileShadow}
                  src={crocodileShadow}
                  alt=""
                />
              )}
              {!itemPlaced["слон"] && (
                <img
                  className={styles.elephantShadow}
                  src={elephantShadow}
                  alt=""
                />
              )}
              {!itemPlaced["жираф"] && (
                <img
                  className={styles.giraffeeShadow}
                  src={giraffeeShadow}
                  alt=""
                />
              )}
              {!itemPlaced["кенгуру"] && (
                <img
                  className={styles.kangarooShadow}
                  src={kangarooShadow}
                  alt=""
                />
              )}
              {!itemPlaced["лев"] && (
                <img className={styles.lionShadow} src={lionShadow} alt="" />
              )}
              {!itemPlaced["тигр"] && (
                <img className={styles.tigerShadow} src={tigerShadow} alt="" />
              )}
              {!itemPlaced["зебра"] && (
                <img className={styles.zebraShadow} src={zebraShadow} alt="" />
              )}

              {images.map((image, index) => (
                <DropTarget
                  key={index}
                  id={image.id}
                  targetId={image.targetId}
                  onDrop={(itemId, targetId) => handleDrop(itemId, targetId)}
                  left={image.shadowStyle.left}
                  top={image.shadowStyle.top}
                />
              ))}

              {images.map(
                (image, index) =>
                  itemPlaced[image.id] && (
                    <img
                      key={index}
                      src={image.src}
                      alt={image.alt}
                      className={image.className}
                      style={{
                        position: "absolute",
                        ...image.dropStyle,
                      }}
                    />
                  )
              )}
            </div>

            <div className={styles.gallery}>
              {images.map((image, index) => {
                if (!itemPlaced[image.alt]) {
                  return (
                    <div key={index} className={styles.frameImage}>
                      <DraggableItem
                        id={image.id}
                        src={image.src}
                        alt={image.alt}
                        className={image.className}
                      />
                    </div>
                  );
                }
                return null;
              })}
            </div>
            <div className={styles.fireworksContainer}></div>
          </div>
          <audio ref={audioRef} />
          {isButtonVisible && (
            <div className={styles.darkOverlay}>
              <button className={styles.button} onClick={handleNextTask}>
                {buttonText}
              </button>
            </div>
          )}
        </div>
      </DndProvider>
    </>
  );
};

export default AnimalsHotCountriesGame;
