import { useEffect, useState, useRef } from "react";
import { gsap } from "gsap";
import useDynamicHeight from "../../../hooks/useDynamicHeight";
import EndGameOptions from "../../end-game-options/end-game-options";
import styles from "./assotiation.module.css";
import wallper from "./images/wallper.png";
import goldStar from "./images/gold-star-counter.png";
import star from "./images/star.png";
import belka from "./images/belka.png";
import bearHouse from "./images/bear-house.png";
import bear from "./images/bear.png";
import beeHouse from "./images/bee-house.png";
import cow from "./images/cow.png";
import dogHouse from "./images/dog-house.png";
import dog from "./images/dog.png";
import duplo from "./images/duplo.png";
import gnezdo from "./images/gnezdo.png";
import house from "./images/house.png";
import kit from "./images/kit.png";
import mouseHouse from "./images/mouse-house.png";
import mouse from "./images/mouse.png";
import ocean from "./images/ocean.png";
import pchela from "./images/pchela.png";
import ptica from "./images/ptica.png";

import startInstructionSound from "./sounds/startInstruction.mp3";
import correctAnswerSound from "./sounds/correctAnswer.mp3";
import incorrectAnswerSound from "./sounds/no.mp3";
import finalSound from "./sounds/finalSound.mp3";
import praiseSound from "./sounds/praise.mp3";

const colors = [
  "#FF0000",
  "#FF7F00",
  "#FFFF00",
  "#00FF00",
  "#0000FF",
  "#4B0082",
  "#8B00FF",
];

const triggerFireworks = () => {
  const fireworksContainer = document.querySelector(
    `.${styles.fireworksContainer}`
  );
  if (fireworksContainer) {
    for (let i = 0; i < 10; i++) {
      setTimeout(() => createFirework(fireworksContainer), i * 500);
    }
  } else {
    console.error("Fireworks container not found");
  }
};

const createFirework = (container) => {
  const firework = document.createElement("div");
  firework.className = styles.firework;
  firework.style.left = `${Math.random() * 100}%`;
  firework.style.top = `${Math.random() * 100}%`;
  container.appendChild(firework);

  const particles = [];
  for (let i = 0; i < 30; i++) {
    const particle = document.createElement("div");
    particle.className = styles.particle;
    particle.style.background =
      colors[Math.floor(Math.random() * colors.length)];
    firework.appendChild(particle);
    particles.push(particle);
  }

  const tl = gsap.timeline({
    onComplete: () => {
      firework.remove();
    },
  });

  particles.forEach((particle) => {
    const angle = Math.random() * Math.PI * 2;
    const distance = Math.random() * 200 + 50;
    tl.fromTo(
      particle,
      { x: 0, y: 0, opacity: 1 },
      {
        x: Math.cos(angle) * distance,
        y: Math.sin(angle) * distance,
        opacity: 0,
        duration: 2,
        ease: "power2.out",
      },
      0
    );
  });
};

function AssociationsGame() {
  useDynamicHeight();
  const [isInteractionBlocked, setIsInteractionBlocked] = useState(false);
  const [buttonText, setButtonText] = useState("Начать игру");
  const [isButtonVisible, setIsButtonVisible] = useState(true);
  const [isGameCompleted, setIsGameCompleted] = useState(false);

  const audioRef = useRef(null);
  const startInstructionAudioRef = useRef(new Audio(startInstructionSound));
  const correctAnswerAudioRef = useRef(new Audio(correctAnswerSound));
  const incorrectAnswerAudioRef = useRef(new Audio(incorrectAnswerSound));
  const finalAudioRef = useRef(new Audio(finalSound));
  const praiseSoundRef = useRef(new Audio(praiseSound));

  const images = [
    { id: "belka", src: belka },
    { id: "mouseHouse", src: mouseHouse },
    { id: "kit", src: kit },
    { id: "gnezdo", src: gnezdo },
    { id: "house", src: house },
    { id: "ptica", src: ptica },
    { id: "duplo", src: duplo },
    { id: "pchela", src: pchela },
    { id: "dog", src: dog },
    { id: "ocean", src: ocean },
    { id: "bear", src: bear },
    { id: "dogHouse", src: dogHouse },
    { id: "bearHouse", src: bearHouse },
    { id: "cow", src: cow },
    { id: "beeHouse", src: beeHouse },
    { id: "mouse", src: mouse },
  ];

  const matchingPairs = {
    belka: "duplo",
    duplo: "belka",
    dog: "dogHouse",
    dogHouse: "dog",
    mouseHouse: "mouse",
    mouse: "mouseHouse",
    kit: "ocean",
    ocean: "kit",
    ptica: "gnezdo",
    gnezdo: "ptica",
    pchela: "beeHouse",
    beeHouse: "pchela",
    bear: "bearHouse",
    bearHouse: "bear",
    cow: "house",
    house: "cow",
  };

  const [score, setScore] = useState(0);
  const [starsCount, setStarsCount] = useState(8);
  const [allImages, setAllImages] = useState(images);
  const [selectedImages, setSelectedImages] = useState([]);
  const [matchedPairs, setMatchedPairs] = useState([]);
  const [incorrectPairs, setIncorrectPairs] = useState([]);

  function handleImageClick(imageId) {
    if (selectedImages.length < 2 && !selectedImages.includes(imageId)) {
      const newSelectedImages = [...selectedImages, imageId];
      setSelectedImages(newSelectedImages);

      if (newSelectedImages.length === 2) {
        if (isCorrectPair(newSelectedImages[0], newSelectedImages[1])) {
          setMatchedPairs(newSelectedImages);

          if (correctAnswerAudioRef.current) {
            correctAnswerAudioRef.current.currentTime = 0;
            correctAnswerAudioRef.current.play();
          }

          setTimeout(() => {
            setScore(score + 1);
            setStarsCount(starsCount - 1);
            setAllImages(
              allImages.filter((image) => !newSelectedImages.includes(image.id))
            );

            setMatchedPairs([]);
            setSelectedImages([]);

            // Проверка завершения игры
            if (allImages.length - newSelectedImages.length === 0) {
              handleGameCompletion();
            }
          }, 1000);
        } else {
          setIncorrectPairs(newSelectedImages);

          if (incorrectAnswerAudioRef.current) {
            incorrectAnswerAudioRef.current.currentTime = 0;
            incorrectAnswerAudioRef.current.play();
          }

          setTimeout(() => {
            setIncorrectPairs([]);
            setSelectedImages([]);
          }, 1500);
        }
      }
    }
  }
  const handleGameCompletion = () => {
    if (finalAudioRef.current) {
      finalAudioRef.current.currentTime = 0;
      finalAudioRef.current.play();
    }
    finalAudioRef.current.onended = () => {
      if (praiseSoundRef.current) {
        praiseSoundRef.current.currentTime = 0;
        praiseSoundRef.current.play();
      }
      triggerFireworks();
      setTimeout(() => {
        setIsGameCompleted(true);
      }, 5000);
    };
  };
  function isCorrectPair(image1, image2) {
    return matchingPairs[image1] === image2;
  }

  const handleNextTask = () => {
    setIsInteractionBlocked(true);

    if (startInstructionAudioRef.current) {
      startInstructionAudioRef.current.play();

      startInstructionAudioRef.current.onended = () => {
        setIsInteractionBlocked(false);
      };
    }

    setIsButtonVisible(false);
  };

  useEffect(() => {
    return () => {
      startInstructionAudioRef.current.pause();
      startInstructionAudioRef.current.currentTime = 0;

      finalAudioRef.current.pause();
      finalAudioRef.current.currentTime = 0;

      praiseSoundRef.current.pause();
      praiseSoundRef.current.currentTime = 0;

      gsap.globalTimeline.clear();
    };
  }, []);

  return (
    <div className={`container ${styles.someOtherClass}`}>
      {isGameCompleted && <EndGameOptions />}
      <div
        className={styles.gameContainer}
        style={{
          pointerEvents: isInteractionBlocked ? "none" : "auto",
        }}
      >
        <img className={styles.wallper} src={wallper} alt="" />

        <img className={styles.goldStar} src={goldStar} alt="" />
        <span className={styles.counter}>{score}</span>
        <div className={styles.starsContainer}>
          {Array.from({ length: starsCount }).map((_, index) => (
            <img key={index} className={styles.littleStar} src={star} alt="" />
          ))}
        </div>

        <div className={styles.imagesContainer}>
          {allImages.map((image) => (
            <img
              key={image.id}
              className={`${styles.images} ${
                selectedImages.includes(image.id) ||
                incorrectPairs.includes(image.id)
                  ? styles.highlighted
                  : ""
              } ${matchedPairs.includes(image.id) ? styles.matched : ""} ${
                incorrectPairs.includes(image.id) ? styles.incorrect : ""
              }`}
              src={image.src}
              alt=""
              onClick={() => handleImageClick(image.id)}
            />
          ))}
        </div>
        <div className={styles.fireworksContainer}></div>
      </div>
      <audio ref={audioRef} />
      {isButtonVisible && (
        <div className={styles.darkOverlay}>
          <button className={styles.button} onClick={handleNextTask}>
            {buttonText}
          </button>
        </div>
      )}
    </div>
  );
}

export default AssociationsGame;
