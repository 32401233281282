import "./App.css";
import { useEffect } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import AuthCheck from "../components/auth/auth-check";
import SubscriptionProtectedRoute from "../components/protected-routes/subscription-protected-route";
import AppHeader from "../components/app-header/app-header";
import NotFound from "../components/not-found/not-found";
import Profile from "../components/profile/profile";

import Subscription from "../components/subscription/subscription";
import Pricing from "../components/pricing/pricing";
import Games from "../components/games/games";
import { useState } from "react";
import Modal from "../components/modal/modal";
import SuccessChangePasswordModal from "../components/success-change-password-modal/success-change-password-modal";
import FailedChangePasswordModal from "../components/failed-change-password-modal/failed-change-password-modal";
import GameRoutes from "../components/games-routes/games-routes";
import PromocodeModal from "../components/promocode-modal/promocode-modal";
import { postUserSubscription } from "../components/api/subscription";
import { getUserSubscriptionInfo } from "../components/api/user-subscription";

function App() {
  const [isAuthCheckComplete, setIsAuthCheckComplete] = useState(false);
  const [paymentInfo, setPaymentInfo] = useState(null);
  const userId = localStorage.getItem("userId")?.replace(/"/g, "");
  const [isChangePasswordSuccess, setIsChangePasswordSuccess] = useState({
    open: false,
  });

  const [isChangePasswordNotSuccess, setIsChangePasswordNotSuccess] = useState({
    open: false,
  });

  const [isPromocodeModalOpen, setIsPromocodeModalOpen] = useState({
    open: false,
  });

  function closeAllModals() {
    setIsChangePasswordSuccess({ open: false });
    setIsChangePasswordNotSuccess({ open: false });
    setIsPromocodeModalOpen({ open: false });
  }

  function openPasswordSuccessModal() {
    setIsChangePasswordSuccess({ open: true });
  }

  function coloseSuccessPasswordModal() {
    setIsChangePasswordSuccess({ open: false });
  }

  function openPasswordNotSuccessModal() {
    setIsChangePasswordNotSuccess({ open: true });
  }

  function closePasswordNotSuccessModal() {
    setIsChangePasswordNotSuccess({ open: false });
  }

  function openPromocodeModal(duration) {
    setSelectedDuration(duration);
    setIsPromocodeModalOpen({ open: true });
  }

  function closePromocodeModal() {
    setIsPromocodeModalOpen({ open: false });
  }

  const [selectedDuration, setSelectedDuration] = useState(null);

  const handleSubscription = (
    durationMonths,
    recurrent = true,
    promocode = ""
  ) => {
    const subscriptionData = {
      durationMonths,
      recurrent,
    };

    if (promocode) {
      subscriptionData.promocode = promocode;
    }

    return postUserSubscription(subscriptionData)
      .then((response) => {
        if (response.paymentUrl) {
          window.location.href = response.paymentUrl;
        } else {
          throw new Error("Ошибка при оформлении подписки");
        }
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  };

  useEffect(() => {
    getUserSubscriptionInfo(userId)
      .then((data) => {
        setPaymentInfo(data);
      })
      .catch((error) => {
        console.error("Ошибка при получении информации о подписке:", error);
        setPaymentInfo(null);
      });
  }, [userId, isAuthCheckComplete]);

  return (
    <AuthCheck onAuthCheckComplete={() => setIsAuthCheckComplete(true)}>
      <AppHeader />
      <Routes>
        <Route path="/" element={<Navigate to="/games" replace />} />
        <Route
          path="/profile"
          element={
            <Profile
              openPasswordSuccessModal={openPasswordSuccessModal}
              openPasswordNotSuccessModal={openPasswordNotSuccessModal}
            />
          }
        />
        <Route path="*" element={<NotFound />} />
        <Route path="/subscription" element={<Subscription />} />
        <Route
          path="/subscription/pricing"
          element={
            <Pricing
              openPromocodeModal={openPromocodeModal}
              closePromocodeModal={closePromocodeModal}
            />
          }
        />
        <Route
          path="/games"
          element={
            <SubscriptionProtectedRoute
              paymentInfo={paymentInfo}
              element={<Games />}
            />
          }
        />
        <Route
          path="/games/:gameId"
          element={
            <SubscriptionProtectedRoute
              paymentInfo={paymentInfo}
              element={<GameRoutes />}
            />
          }
        />
      </Routes>

      {isChangePasswordSuccess.open && (
        <Modal closeModal={closeAllModals}>
          <SuccessChangePasswordModal
            coloseSuccessPasswordModal={coloseSuccessPasswordModal}
          />
        </Modal>
      )}

      {isChangePasswordNotSuccess.open && (
        <Modal closeModal={closeAllModals}>
          <FailedChangePasswordModal
            closePasswordNotSuccessModal={closePasswordNotSuccessModal}
          />
        </Modal>
      )}
      {isPromocodeModalOpen.open && (
        <Modal closeModal={closeAllModals}>
          <PromocodeModal
            closePromocodeModal={closePromocodeModal}
            handleSubscription={() =>
              handleSubscription(selectedDuration, true)
            }
            durationMonths={selectedDuration}
          />
        </Modal>
      )}
    </AuthCheck>
  );
}

export default App;
