import styles from "./dinosaur.module.css";
import { useEffect, useState, useRef } from "react";
import useDynamicHeight from "../../../hooks/useDynamicHeight";
import EndGameOptions from "../../end-game-options/end-game-options";
import Confetti from "react-confetti";
import wallper from "./images/wallper.png";
import dinosaurDirt from "./images/dinosaurDirt.png";
import dinoClear from "./images/dinoClear.png";
import toothbrush from "./images/toothbrush.png";
import ar from "./images/ar.png";
import er from "./images/er.png";
import ir from "./images/ir.png";
import or from "./images/or.png";
import ra from "./images/ra.png";
import re from "./images/re.png";
import ro from "./images/ro.png";
import ru from "./images/ru.png";
import ry from "./images/ry.png";
import ur from "./images/ur.png";
import backgroundSound from "./sounds/backgroundSound.mp3";
import startInstructionSound from "./sounds/instruction.mp3";
import actionSound from "./sounds/toothbrashsound.mp3";
import finalSound from "./sounds/praise.mp3";

function DinosaurGame() {
  useDynamicHeight();
  const [isInteractionBlocked, setIsInteractionBlocked] = useState(false);
  const [isGameCompleted, setIsGameCompleted] = useState(false);
  const [buttonText, setButtonText] = useState("Начать игру");
  const [isButtonVisible, setIsButtonVisible] = useState(true);
  const [showConfetti, setShowConfetti] = useState(false);
  const audioRef = useRef(null);

  const startInstructionAudioRef = useRef(new Audio(startInstructionSound));
  const backgroundAudioRef = useRef(new Audio(backgroundSound));
  const finalSoundRef = useRef(new Audio(finalSound));

  const [activeSounds, setActiveSounds] = useState([
    "ar",
    "er",
    "ir",
    "or",
    "ur",
    "ra",
    "ro",
    "ru",
    "ry",
    "re",
  ]);

  const handleNextTask = () => {
    setIsInteractionBlocked(true);

    if (startInstructionAudioRef.current) {
      startInstructionAudioRef.current.play();

      startInstructionAudioRef.current.onended = () => {
        setIsInteractionBlocked(false);
        if (backgroundAudioRef.current) {
          backgroundAudioRef.current.loop = true;
          backgroundAudioRef.current.play();
        }
      };
    }

    setIsButtonVisible(false);
  };

  const clickOnSubject = (e) => {
    const clickedSound = e.target.alt; // предполагаем, что атрибут alt содержит название звука
    if (activeSounds.includes(clickedSound)) {
      const newActiveSounds = activeSounds.filter(
        (sound) => sound !== clickedSound
      );
      setActiveSounds(newActiveSounds);

      const actionAudio = new Audio(actionSound);
      actionAudio.play();

      e.target.classList.add(styles.growAndFade);
      const handleAnimationEnd = () => {
        e.target.style.display = "none";
        e.target.removeEventListener("animationend", handleAnimationEnd);

        // Play final sound if all sounds are removed
        if (newActiveSounds.length === 0) {
          setShowConfetti(true);
          setTimeout(() => {
            setIsGameCompleted(true);
          }, 5000);
          finalSoundRef.current.play();
        }
      };
      e.target.addEventListener("animationend", handleAnimationEnd);
    }
  };

  useEffect(() => {
    return () => {
      startInstructionAudioRef.current.pause();
      startInstructionAudioRef.current.currentTime = 0;
      backgroundAudioRef.current.pause();
      backgroundAudioRef.current.currentTime = 0;

      finalSoundRef.current.pause();
      finalSoundRef.current.currentTime = 0;
    };
  }, []);

  return (
    <>
      {showConfetti && (
        <Confetti
          numberOfPieces={1000}
          angle={180}
          spread={120}
          origin={{ x: 1, y: 0.5 }}
        />
      )}
      {isGameCompleted && <EndGameOptions />}

      <div className={`container ${styles.someOtherClass}`}>
        <div
          className={styles.backgroundContainer}
          onClick={clickOnSubject}
          style={{
            pointerEvents: isInteractionBlocked ? "none" : "auto",
          }}
        >
          <img className={styles.wallper} src={wallper} alt="обои" />
          <img
            className={styles.dinosaurDirt}
            src={activeSounds.length === 0 ? dinoClear : dinosaurDirt}
            alt="динозавр"
          />
          <img className={styles.ar} src={ar} alt="ar" />
          <img className={styles.er} src={er} alt="er" />
          <img className={styles.ir} src={ir} alt="ir" />
          <img className={styles.or} src={or} alt="or" />
          <img className={styles.ur} src={ur} alt="ur" />
          <img className={styles.ra} src={ra} alt="ra" />
          <img className={styles.ro} src={ro} alt="ro" />
          <img className={styles.ru} src={ru} alt="ru" />
          <img className={styles.ry} src={ry} alt="ry" />
          <img className={styles.re} src={re} alt="re" />
        </div>
        <audio ref={audioRef} />
        {isButtonVisible && (
          <div className={styles.darkOverlay}>
            <button className={styles.button} onClick={handleNextTask}>
              {buttonText}
            </button>
          </div>
        )}
      </div>
    </>
  );
}

export default DinosaurGame;
