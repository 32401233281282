import React, { useState, useRef, useEffect } from "react";
import useDynamicHeight from "../../../hooks/useDynamicHeight";
import EndGameOptions from "../../end-game-options/end-game-options";
import { gsap } from "gsap";
import styles from "./angry-goat.module.css";

import wallper from "./images/wallper.png";
import goat from "./images/goat.png";
import goatRearUp from "./images/goatRearUp.png";
import ba from "./images/ba.png";
import bu from "./images/bu.png";
import bo from "./images/bo.png";
import by from "./images/by.png";
import be from "./images/be.png";
import ab from "./images/ab.png";
import ob from "./images/ob.png";
import ib from "./images/ib.png";
import eb from "./images/eb.png";

import startInstructionSound from "./sounds/startInstruction.mp3";
import backgroundSound from "./sounds/backgroundSound.mp3";
import firstStoneSound from "./sounds/firstStone.mp3";
import nextStoneSound from "./sounds/nextStone.mp3";
import praiseSound from "./sounds/praise.mp3";
import shootSound from "./sounds/shoot.mp3";

const colors = [
  "#FF0000",
  "#FF7F00",
  "#FFFF00",
  "#00FF00",
  "#0000FF",
  "#4B0082",
  "#8B00FF",
];

const triggerFireworks = () => {
  const fireworksContainer = document.querySelector(
    `.${styles.fireworksContainer}`
  );
  if (fireworksContainer) {
    for (let i = 0; i < 10; i++) {
      setTimeout(() => createFirework(fireworksContainer), i * 500);
    }
  } else {
    console.error("Fireworks container not found");
  }
};

const createFirework = (container) => {
  const firework = document.createElement("div");
  firework.className = styles.firework;
  firework.style.left = `${Math.random() * 100}%`;
  firework.style.top = `${Math.random() * 100}%`;
  container.appendChild(firework);

  const particles = [];
  for (let i = 0; i < 30; i++) {
    const particle = document.createElement("div");
    particle.className = styles.particle;
    particle.style.background =
      colors[Math.floor(Math.random() * colors.length)];
    firework.appendChild(particle);
    particles.push(particle);
  }

  const tl = gsap.timeline({
    onComplete: () => {
      firework.remove();
    },
  });

  particles.forEach((particle) => {
    const angle = Math.random() * Math.PI * 2;
    const distance = Math.random() * 200 + 50;
    tl.fromTo(
      particle,
      { x: 0, y: 0, opacity: 1 },
      {
        x: Math.cos(angle) * distance,
        y: Math.sin(angle) * distance,
        opacity: 0,
        duration: 2,
        ease: "power2.out",
      },
      0
    );
  });
};

const initialStoneStates = [
  { id: "ba", src: ba, isRolling: false, isAtBottom: false },
  { id: "bu", src: bu, isRolling: false, isAtBottom: false },
  { id: "bo", src: bo, isRolling: false, isAtBottom: false },
  { id: "by", src: by, isRolling: false, isAtBottom: false },
  { id: "be", src: be, isRolling: false, isAtBottom: false },
  { id: "ab", src: ab, isRolling: false, isAtBottom: false },
  { id: "ob", src: ob, isRolling: false, isAtBottom: false },
  { id: "ib", src: ib, isRolling: false, isAtBottom: false },
  { id: "eb", src: eb, isRolling: false, isAtBottom: false },
];

const AngryGoatGame = () => {
  useDynamicHeight();
  const [isInteractionBlocked, setIsInteractionBlocked] = useState(false);
  const [isAnimating, setIsAnimating] = useState(false);
  const [isGameCompleted, setIsGameCompleted] = useState(false);
  const [buttonText, setButtonText] = useState("Начать игру");
  const [isButtonVisible, setIsButtonVisible] = useState(true);
  const [isGoatRearing, setIsGoatRearing] = useState(false);
  const [currentStoneIndex, setCurrentStoneIndex] = useState(0);
  const [stones, setStones] = useState(initialStoneStates);
  const audioRef = useRef(null);
  const startInstructionAudioRef = useRef(new Audio(startInstructionSound));
  const backgroundAudioRef = useRef(new Audio(backgroundSound));
  const shootSoundRef = useRef(new Audio(shootSound));
  const firstStoneSoundRef = useRef(new Audio(firstStoneSound));
  const nextStoneSoundRef = useRef(new Audio(nextStoneSound));
  const praiseSoundRef = useRef(new Audio(praiseSound));

  const handleGoatClick = () => {
    if (isAnimating) return;

    setIsAnimating(true);
    setIsGoatRearing(true);

    shootSoundRef.current.play();

    setTimeout(() => {
      setIsGoatRearing(false);
    }, 500);

    setStones((prevStones) => {
      const newStones = [...prevStones];
      newStones[currentStoneIndex].isRolling = true;
      return newStones;
    });

    setTimeout(() => {
      setStones((prevStones) => {
        const newStones = [...prevStones];
        newStones[currentStoneIndex].isRolling = false;
        newStones[currentStoneIndex].isAtBottom = true;
        newStones[currentStoneIndex].position =
          newStones[currentStoneIndex].end;

        if (currentStoneIndex === 0) {
          firstStoneSoundRef.current.play();
        } else {
          nextStoneSoundRef.current.play();
        }

        if (currentStoneIndex < stones.length - 1) {
          setCurrentStoneIndex((prevIndex) => prevIndex + 1);
        } else {
          triggerFireworks();
          setTimeout(() => {
            setIsGameCompleted(true);
          }, 5000);
          praiseSoundRef.current.play();
        }

        setIsAnimating(false);

        return newStones;
      });
    }, 2000);
  };

  const handleNextTask = () => {
    setIsInteractionBlocked(true);

    if (startInstructionAudioRef.current) {
      startInstructionAudioRef.current.play();

      startInstructionAudioRef.current.onended = () => {
        setIsInteractionBlocked(false);
        if (backgroundAudioRef.current) {
          backgroundAudioRef.current.loop = true;
          backgroundAudioRef.current.play();
        }
      };
    }

    setIsButtonVisible(false);
  };

  useEffect(() => {
    return () => {
      startInstructionAudioRef.current.pause();
      startInstructionAudioRef.current.currentTime = 0;
      backgroundAudioRef.current.pause();
      backgroundAudioRef.current.currentTime = 0;

      praiseSoundRef.current.pause();
      praiseSoundRef.current.currentTime = 0;

      gsap.globalTimeline.clear();
    };
  }, []);
  return (
    <>
      {isGameCompleted && <EndGameOptions />}
      <div
        className={`container ${styles.someOtherClass}`}
        onClick={isInteractionBlocked ? null : handleGoatClick}
      >
        <div className={styles.backgroundContainer}>
          <div className={styles.dropContainer}>
            <img className={styles.wallper} src={wallper} alt="" />
            <img
              className={`${styles.goat} ${
                isGoatRearing ? styles.goatRearUp : ""
              }`}
              src={isGoatRearing ? goatRearUp : goat}
              alt="Goat"
            />
            {stones.map((stone, index) => (
              <img
                key={stone.id}
                className={`${styles.stone} ${
                  stone.isRolling
                    ? styles.stoneRolling
                    : stone.isAtBottom
                    ? styles.stoneAtBottom
                    : ""
                }`}
                src={stone.src}
                alt="Stone"
                style={{
                  top: "20%",
                  left: "71%",
                  display:
                    index === currentStoneIndex || stone.isAtBottom
                      ? "block"
                      : "none",
                  "--end-x": `${-600 + index * 60}%`,
                  "--end-y": `${550 + index * 10}%`,
                }}
              />
            ))}
          </div>
        </div>
      </div>
      <div className={styles.fireworksContainer}></div>
      <audio ref={audioRef} />
      {isButtonVisible && (
        <div className={styles.darkOverlay}>
          <button className={styles.button} onClick={handleNextTask}>
            {buttonText}
          </button>
        </div>
      )}
    </>
  );
};

export default AngryGoatGame;
