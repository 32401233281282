import { useDrag } from "react-dnd";

const DraggableItem = ({ id, src, alt, className }) => {
  const [{ isDragging }, drag] = useDrag(() => ({
    type: "fish",
    item: { id },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  }));

  return (
    <img
      ref={drag}
      src={src}
      alt={alt}
      className={className}
      style={{ opacity: isDragging ? 0.5 : 1 }}
    />
  );
};

export default DraggableItem;
