import React from "react";
import { useDrop } from "react-dnd";

const DropTarget = ({ id, onDrop, className, children }) => {
  const [{ isOver }, drop] = useDrop({
    accept: "car",
    drop: (item) => {
      onDrop(item, id);
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
  });

  return (
    <div ref={drop} className={className}>
      {children}
    </div>
  );
};

export default DropTarget;
