import React from "react";
import { useNavigate } from "react-router-dom";
import styles from "./end-game-options.module.css";
import menu from "./images/menu.png";
import restart from "./images/repeat.png";

const EndGameOptions = ({ onRestart }) => {
  const navigate = useNavigate();

  const handleRestart = () => {
    if (onRestart) {
      onRestart();
    } else {
      window.location.reload();
    }
  };

  const handleExitToMenu = () => {
    navigate("/games");
  };

  return (
    <div className={styles.darkOverlay}>
      <div className={styles.btnsContainer}>
        <div className={styles.oneBtnContainer}>
          <img src={restart} alt="перезапуск" className={styles.restart} />
          <button className={styles.button} onClick={handleRestart}>
            Начать заново
          </button>
        </div>
        <div className={styles.oneBtnContainer}>
          <img src={menu} alt="меню" className={styles.menu} />
          <button className={styles.button} onClick={handleExitToMenu}>
            Выйти в меню
          </button>
        </div>
      </div>
    </div>
  );
};

export default EndGameOptions;
