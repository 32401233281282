import React from "react";
import { useDrag } from "react-dnd";

const DraggableItem = ({ id, src, alt, className, isBearInBox }) => {
  const [{ isDragging }, drag] = useDrag(
    () => ({
      type: "BEAR",
      item: { id },
      canDrag: () => !isBearInBox,
      collect: (monitor) => ({
        isDragging: !!monitor.isDragging(),
      }),
    }),
    [isBearInBox]
  );

  return (
    <img
      ref={drag}
      src={src}
      alt={alt}
      className={className}
      style={{ opacity: isDragging ? 0.5 : 1 }}
    />
  );
};

export default DraggableItem;
