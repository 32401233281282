import React, { useState, useEffect, useRef } from "react";
import useDynamicHeight from "../../../hooks/useDynamicHeight";
import EndGameOptions from "../../end-game-options/end-game-options";
import styles from "./insects-sounds.module.css";
import Confetti from "react-confetti";
import dynamic from "./images/dynamic.svg";

import juke from "./images/juke.png";
import mosquito from "./images/mosquito.png";
import grasshopper from "./images/grasshopper.png";
import fly from "./images/fly.png";
import bee from "./images/bee.png";
import dragonfly from "./images/dragonfly.png";

import startInstructionSound from "./sounds/startInstruction.mp3";
import rightAnswerSound from "./sounds/rightAnswer.mp3";
import finalSound from "./sounds/finalSound.mp3";
import incorrectAnswerSound from "./sounds/no.mp3";

import jukeSound from "./sounds/juke.mp3";
import mosquitoSound from "./sounds/mosquito.mp3";
import grasshopperSound from "./sounds/grasshopper.mp3";
import flySound from "./sounds/fly.mp3";
import beeSound from "./sounds/bee.mp3";
import dragonflySound from "./sounds/dragonfly.mp3";

const InsectsSoundsGame = () => {
  useDynamicHeight();
  const [isInteractionBlocked, setIsInteractionBlocked] = useState(false);
  const [isGameCompleted, setIsGameCompleted] = useState(false);
  const [currentTaskIndex, setCurrentTaskIndex] = useState(0);
  const [isAnswerCorrect, setIsAnswerCorrect] = useState(null);
  const [selectedAnswer, setSelectedAnswer] = useState(null);
  const [audioPlayed, setAudioPlayed] = useState(false);
  const [buttonText, setButtonText] = useState("Начать игру");
  const [isButtonVisible, setIsButtonVisible] = useState(true);
  const [showConfetti, setShowConfetti] = useState(false);
  const audioRef = useRef(null);
  const startInstructionRef = useRef(new Audio(startInstructionSound));

  const tasks = [
    {
      question: (
        <>
          Послушай звук.
          <br /> Какое насекомое издает его?
        </>
      ),
      images: [
        { src: fly, alt: "fly" },
        { src: bee, alt: "bee" },
        { src: dragonfly, alt: "dragonfly" },
        { src: juke, alt: "juke" },
      ],
      correctAnswer: "fly",
    },
    {
      question: (
        <>
          Послушай звук.
          <br /> Какое насекомое издает его?
        </>
      ),
      images: [
        { src: bee, alt: "bee" },
        { src: juke, alt: "juke" },
        { src: mosquito, alt: "mosquito" },
        { src: dragonfly, alt: "dragonfly" },
      ],
      correctAnswer: "mosquito",
    },
    {
      question: (
        <>
          Послушай звук.
          <br /> Какое насекомое издает его?
        </>
      ),
      images: [
        { src: grasshopper, alt: "grasshopper" },
        { src: juke, alt: "juke" },
        { src: fly, alt: "fly" },
        { src: bee, alt: "bee" },
      ],
      correctAnswer: "bee",
    },
    {
      question: (
        <>
          Послушай звук.
          <br /> Какое насекомое издает его?
        </>
      ),
      images: [
        { src: grasshopper, alt: "grasshopper" },
        { src: dragonfly, alt: "dragonfly" },
        { src: fly, alt: "fly" },
        { src: mosquito, alt: "mosquito" },
      ],
      correctAnswer: "grasshopper",
    },
    {
      question: (
        <>
          Послушай звук.
          <br /> Какое насекомое издает его?
        </>
      ),
      images: [
        { src: juke, alt: "juke" },
        { src: dragonfly, alt: "dragonfly" },
        { src: bee, alt: "bee" },
        { src: fly, alt: "fly" },
      ],
      correctAnswer: "dragonfly",
    },
    {
      question: (
        <>
          Послушай звук.
          <br /> Какое насекомое издает его?
        </>
      ),
      images: [
        { src: mosquito, alt: "mosquito" },
        { src: bee, alt: "bee" },
        { src: dragonfly, alt: "dragonfly" },
        { src: juke, alt: "juke" },
      ],
      correctAnswer: "juke",
    },
  ];

  const currentTask = tasks[currentTaskIndex];

  const handleImageClick = (answer) => {
    if (isAnswerCorrect) return;

    setSelectedAnswer(answer);
    const isCorrect = answer === currentTask.correctAnswer;
    setIsAnswerCorrect(isCorrect);

    const audio = new Audio(
      isCorrect ? rightAnswerSound : incorrectAnswerSound
    );
    audio.play();

    if (isCorrect) {
      setTimeout(() => {
        setIsAnswerCorrect(null);
        setSelectedAnswer(null);

        if (currentTaskIndex + 1 < tasks.length) {
          setCurrentTaskIndex((prevIndex) => prevIndex + 1);
        } else {
          setShowConfetti(true);
          setTimeout(() => {
            setIsGameCompleted(true);
          }, 5000);
          const finalAudio = new Audio(finalSound);
          finalAudio.play();
        }
      }, 1000);
    } else {
      setTimeout(() => {
        setSelectedAnswer(null);
      }, 1000);
    }
  };

  const handleStartGame = () => {
    setIsInteractionBlocked(true);
    if (startInstructionRef.current) {
      startInstructionRef.current.play();

      startInstructionRef.current.onended = () => {
        setIsInteractionBlocked(false);
      };
    }
    setIsButtonVisible(false);
  };

  const handlePlaySound = () => {
    const soundMap = {
      juke: jukeSound,
      mosquito: mosquitoSound,
      grasshopper: grasshopperSound,
      fly: flySound,
      bee: beeSound,
      dragonfly: dragonflySound,
    };

    const currentSound = soundMap[currentTask.correctAnswer];
    if (currentSound) {
      const sound = new Audio(currentSound);
      sound.play();
    }
  };

  useEffect(() => {
    return () => {
      startInstructionRef.current.pause();
      startInstructionRef.current.currentTime = 0;
    };
  }, []);

  return (
    <>
      {isGameCompleted && <EndGameOptions />}
      <div className={`container ${styles.someOtherClass}`}>
        {showConfetti && (
          <Confetti
            numberOfPieces={500}
            angle={180}
            spread={120}
            origin={{ x: 1, y: 0.5 }}
          />
        )}
        <h3 className={styles.header}>{currentTask.question}</h3>
        <h4 className={styles.headerCounter}>
          {" "}
          Вопрос {currentTaskIndex + 1} из {tasks.length}
        </h4>
        <div
          className={styles.backgroundContainer}
          style={{
            pointerEvents: isInteractionBlocked ? "none" : "auto",
          }}
        >
          <div className={styles.imagesContainer}>
            {currentTask.images.map((image) => (
              <div className={styles.imageWrapper}>
                <img
                  key={image.alt}
                  src={image.src}
                  alt={image.alt}
                  className={`${styles.taskImage} ${
                    styles[`${image.alt}Image`]
                  }`}
                  onClick={() => handleImageClick(image.alt)}
                  style={{
                    filter:
                      selectedAnswer === image.alt && isAnswerCorrect
                        ? "drop-shadow(0 10px 50px rgba(50, 205, 50, 0.5))"
                        : selectedAnswer === image.alt &&
                          selectedAnswer !== null
                        ? "none"
                        : "",
                  }}
                />
              </div>
            ))}
            <button className={styles.centralButton} onClick={handlePlaySound}>
              <img src={dynamic} alt="Dynamic" />
              прослушать
            </button>
          </div>
        </div>
        <audio ref={audioRef} />
        {isButtonVisible && (
          <div className={styles.darkOverlay}>
            <button
              className={styles.buttonStartGame}
              onClick={handleStartGame}
            >
              {buttonText}
            </button>
          </div>
        )}
      </div>
    </>
  );
};

export default InsectsSoundsGame;
