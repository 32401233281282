import React, { useState, useEffect, useRef } from "react";
import useDynamicHeight from "../../../hooks/useDynamicHeight";
import EndGameOptions from "../../end-game-options/end-game-options";
import styles from "./transport-sounds.module.css";
import { gsap } from "gsap";

import dynamic from "./images/dynamic.svg";

import helicopter from "./images/helicopter.png";
import sportcar from "./images/sportcar.png";
import boat from "./images/boat.png";
import car from "./images/car.png";
import metro from "./images/metro.png";
import moto from "./images/moto.png";
import steamship from "./images/steamship.png";
import train from "./images/train.png";
import aircraft from "./images/aircraft.png";
import tram from "./images/tram.png";
import trolleybus from "./images/trolleybus.png";
import elecTrain from "./images/elecTrain.png";

import startInstructionSound from "./sounds/startInstruction.mp3";
import rightAnswerSound from "./sounds/rightAnswer.mp3";
import finalSound from "./sounds/finalSound.mp3";
import incorrectAnswerSound from "./sounds/no.mp3";

import helicopterSound from "./sounds/helicopter.mp3";
import sportcarSound from "./sounds/sportcar.mp3";
import boatSound from "./sounds/boat.mp3";
import carSound from "./sounds/car.mp3";
import metroSound from "./sounds/metro.mp3";
import motoSound from "./sounds/moto.mp3";
import steamshipSound from "./sounds/steamship.mp3";
import trainSound from "./sounds/train.mp3";
import aircraftSound from "./sounds/aircraft.mp3";
import tramSound from "./sounds/tram.mp3";
import trolleybusSound from "./sounds/trolleybus.mp3";
import elecTrainSound from "./sounds/elecTrain.mp3";

const colors = [
  "#FF0000",
  "#FF7F00",
  "#FFFF00",
  "#00FF00",
  "#0000FF",
  "#4B0082",
  "#8B00FF",
];

const triggerFireworks = () => {
  const fireworksContainer = document.getElementsByClassName(
    styles.fireworksContainer
  )[0];
  if (fireworksContainer) {
    for (let i = 0; i < 10; i++) {
      setTimeout(() => createFirework(fireworksContainer), i * 500);
    }
  } else {
    console.error("Fireworks container not found");
  }
};

const createFirework = (container) => {
  const firework = document.createElement("div");
  firework.className = styles.firework;
  firework.style.left = `${Math.random() * 100}%`;
  firework.style.top = `${Math.random() * 100}%`;
  container.appendChild(firework);

  const particles = [];
  for (let i = 0; i < 30; i++) {
    const particle = document.createElement("div");
    particle.className = styles.particle;
    particle.style.background =
      colors[Math.floor(Math.random() * colors.length)];
    firework.appendChild(particle);
    particles.push(particle);
  }

  const tl = gsap.timeline({
    onComplete: () => {
      firework.remove();
    },
  });

  particles.forEach((particle) => {
    const angle = Math.random() * Math.PI * 2;
    const distance = Math.random() * 200 + 50;
    tl.fromTo(
      particle,
      { x: 0, y: 0, opacity: 1 },
      {
        x: Math.cos(angle) * distance,
        y: Math.sin(angle) * distance,
        opacity: 0,
        duration: 2,
        ease: "power2.out",
      },
      0
    );
  });
};

const TransportSoundsGame = () => {
  useDynamicHeight();
  const [isInteractionBlocked, setIsInteractionBlocked] = useState(false);
  const [isGameCompleted, setIsGameCompleted] = useState(false);
  const [currentTaskIndex, setCurrentTaskIndex] = useState(0);
  const [isAnswerCorrect, setIsAnswerCorrect] = useState(null);
  const [selectedAnswer, setSelectedAnswer] = useState(null);
  const [audioPlayed, setAudioPlayed] = useState(false);
  const [buttonText, setButtonText] = useState("Начать игру");
  const [isButtonVisible, setIsButtonVisible] = useState(true);
  const audioRef = useRef(null);
  const startInstructionRef = useRef(new Audio(startInstructionSound));

  const tasks = [
    {
      question: (
        <>
          Послушай звук.
          <br /> На что он похож?
        </>
      ),
      images: [
        { src: tram, alt: "tram" },
        { src: train, alt: "train" },
        { src: moto, alt: "moto" },
        { src: steamship, alt: "steamship" },
      ],
      correctAnswer: "train",
    },
    {
      question: (
        <>
          Послушай звук.
          <br /> На что он похож?
        </>
      ),
      images: [
        { src: metro, alt: "metro" },
        { src: aircraft, alt: "aircraft" },
        { src: elecTrain, alt: "elecTrain" },
        { src: trolleybus, alt: "trolleybus" },
      ],
      correctAnswer: "elecTrain",
    },
    {
      question: (
        <>
          Послушай звук.
          <br /> На что он похож?
        </>
      ),
      images: [
        { src: sportcar, alt: "sportcar" },
        { src: boat, alt: "boat" },
        { src: helicopter, alt: "helicopter" },
        { src: tram, alt: "tram" },
      ],
      correctAnswer: "tram",
    },
    {
      question: (
        <>
          Послушай звук.
          <br /> На что он похож?
        </>
      ),
      images: [
        { src: metro, alt: "metro" },
        { src: trolleybus, alt: "trolleybus" },
        { src: train, alt: "train" },
        { src: car, alt: "car" },
      ],
      correctAnswer: "metro",
    },
    {
      question: (
        <>
          Послушай звук.
          <br /> На что он похож?
        </>
      ),
      images: [
        { src: train, alt: "train" },
        { src: boat, alt: "boat" },
        { src: car, alt: "car" },
        { src: sportcar, alt: "sportcar" },
      ],
      correctAnswer: "car",
    },
    {
      question: (
        <>
          Послушай звук.
          <br /> На что он похож?
        </>
      ),
      images: [
        { src: aircraft, alt: "aircraft" },
        { src: helicopter, alt: "helicopter" },
        { src: sportcar, alt: "sportcar" },
        { src: moto, alt: "moto" },
      ],
      correctAnswer: "moto",
    },
    {
      question: (
        <>
          Послушай звук.
          <br /> На что он похож?
        </>
      ),
      images: [
        { src: aircraft, alt: "aircraft" },
        { src: train, alt: "train" },
        { src: helicopter, alt: "helicopter" },
        { src: steamship, alt: "steamship" },
      ],
      correctAnswer: "helicopter",
    },
    {
      question: (
        <>
          Послушай звук.
          <br /> На что он похож?
        </>
      ),
      images: [
        { src: boat, alt: "boat" },
        { src: metro, alt: "metro" },
        { src: elecTrain, alt: "elecTrain" },
        { src: aircraft, alt: "aircraft" },
      ],
      correctAnswer: "aircraft",
    },
    {
      question: (
        <>
          Послушай звук.
          <br /> На что он похож?
        </>
      ),
      images: [
        { src: trolleybus, alt: "trolleybus" },
        { src: train, alt: "train" },
        { src: metro, alt: "metro" },
        { src: car, alt: "car" },
      ],
      correctAnswer: "trolleybus",
    },
    {
      question: (
        <>
          Послушай звук.
          <br /> На что он похож?
        </>
      ),
      images: [
        { src: steamship, alt: "steamship" },
        { src: sportcar, alt: "sportcar" },
        { src: moto, alt: "moto" },
        { src: train, alt: "train" },
      ],
      correctAnswer: "sportcar",
    },
    {
      question: (
        <>
          Послушай звук.
          <br /> На что он похож?
        </>
      ),
      images: [
        { src: boat, alt: "boat" },
        { src: steamship, alt: "steamship" },
        { src: aircraft, alt: "aircraft" },
        { src: tram, alt: "tram" },
      ],
      correctAnswer: "steamship",
    },
    {
      question: (
        <>
          Послушай звук.
          <br /> На что он похож?
        </>
      ),
      images: [
        { src: boat, alt: "boat" },
        { src: helicopter, alt: "helicopter" },
        { src: train, alt: "train" },
        { src: aircraft, alt: "aircraft" },
      ],
      correctAnswer: "boat",
    },
  ];

  const currentTask = tasks[currentTaskIndex];

  const handleImageClick = (answer) => {
    if (isAnswerCorrect) return;

    setSelectedAnswer(answer);
    const isCorrect = answer === currentTask.correctAnswer;
    setIsAnswerCorrect(isCorrect);

    const audio = new Audio(
      isCorrect ? rightAnswerSound : incorrectAnswerSound
    );
    audio.play();

    if (isCorrect) {
      setTimeout(() => {
        setIsAnswerCorrect(null);
        setSelectedAnswer(null);

        if (currentTaskIndex + 1 < tasks.length) {
          setCurrentTaskIndex((prevIndex) => prevIndex + 1);
        } else {
          triggerFireworks();
          const finalAudio = new Audio(finalSound);
          finalAudio.play();
          setTimeout(() => {
            setIsGameCompleted(true);
          }, 5000);
        }
      }, 1000);
    } else {
      setTimeout(() => {
        setSelectedAnswer(null);
      }, 1000);
    }
  };

  const handleStartGame = () => {
    setIsInteractionBlocked(true);
    if (startInstructionRef.current) {
      startInstructionRef.current.play();

      startInstructionRef.current.onended = () => {
        setIsInteractionBlocked(false);
      };
    }
    setIsButtonVisible(false);
  };

  const handlePlaySound = () => {
    const soundMap = {
      helicopter: helicopterSound,
      sportcar: sportcarSound,
      boat: boatSound,
      car: carSound,
      metro: metroSound,
      moto: motoSound,
      steamship: steamshipSound,
      train: trainSound,
      aircraft: aircraftSound,
      tram: tramSound,
      trolleybus: trolleybusSound,
      elecTrain: elecTrainSound,
    };

    const currentSound = soundMap[currentTask.correctAnswer];
    if (currentSound) {
      const sound = new Audio(currentSound);
      sound.play();
      setAudioPlayed(true); // Устанавливаем состояние, чтобы звук был воспроизведен
    }
  };

  useEffect(() => {
    return () => {
      startInstructionRef.current.pause();
      startInstructionRef.current.currentTime = 0;
    };
  }, []);

  return (
    <>
      {isGameCompleted && <EndGameOptions />}
      <div className={`container ${styles.someOtherClass}`}>
        <h3 className={styles.header}>{currentTask.question}</h3>
        <h4 className={styles.headerCounter}>
          {" "}
          Вопрос {currentTaskIndex + 1} из {tasks.length}
        </h4>
        <div
          className={styles.backgroundContainer}
          style={{
            pointerEvents: isInteractionBlocked ? "none" : "auto",
          }}
        >
          <div className={styles.imagesContainer}>
            {currentTask.images.map((image) => (
              <div className={styles.imageWrapper}>
                <img
                  key={image.alt}
                  src={image.src}
                  alt={image.alt}
                  className={`${styles.taskImage} ${
                    styles[`${image.alt}Image`]
                  }`}
                  onClick={() => handleImageClick(image.alt)}
                  style={{
                    filter:
                      selectedAnswer === image.alt && isAnswerCorrect
                        ? "drop-shadow(0 10px 50px rgba(50, 205, 50, 0.5))"
                        : selectedAnswer === image.alt &&
                          selectedAnswer !== null
                        ? "none"
                        : "",
                  }}
                />
              </div>
            ))}
            <button className={styles.centralButton} onClick={handlePlaySound}>
              <img src={dynamic} alt="Dynamic" />
              прослушать
            </button>
          </div>
        </div>
        <audio ref={audioRef} />
        {isButtonVisible && (
          <div className={styles.darkOverlay}>
            <button
              className={styles.buttonStartGame}
              onClick={handleStartGame}
            >
              {buttonText}
            </button>
          </div>
        )}
        <div className={styles.fireworksContainer}></div>
      </div>
    </>
  );
};

export default TransportSoundsGame;
