import React from "react";
import { useDrag } from "react-dnd";

const isChrome =
  /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);

const DraggableItem = ({ id, src, alt, className }) => {
  const [{ isDragging }, drag] = useDrag({
    type: "car",
    item: { id },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  return (
    <img
      ref={drag}
      src={src}
      alt={alt}
      className={className}
      style={{
        opacity: isDragging ? 0.5 : 1,
        background: "transparent",
        border: "none",
      }}
    />
  );
};

export default DraggableItem;
