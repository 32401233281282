import React from "react";
import styles from "./not-found.module.css";
import notFoundImg from "./images/notFound.jpg";

const NotFound = () => {
  return (
    <div className={styles.container}>
      <h4 className={styles.title}>Такой страницы не существует</h4>
      <img src={notFoundImg} alt="Page not found" className={styles.image} />
    </div>
  );
};

export default NotFound;
