import React, { useState, useEffect, useRef } from "react";
import EndGameOptions from "../../end-game-options/end-game-options";
import Confetti from "react-confetti";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

import styles from "./math-five.module.css";

import figureFive from "./images/figureFive.png";
import baloonOne from "./images/baloonOne.png";
import baloonTwo from "./images/baloonTwo.png";
import baloonThree from "./images/baloonThree.png";
import baloonFour from "./images/baloonFour.png";
import baloonFive from "./images/baloonFive.png";
import baloonSix from "./images/baloonSix.png";
import baloonSeven from "./images/baloonTwo.png";
import baloonEight from "./images/baloonThree.png";
import baloonNine from "./images/baloonOne.png";
import baloonTen from "./images/baloonSix.png";
import puzzleOne from "./images/1.png";
import puzzleTwo from "./images/2.png";
import puzzleThree from "./images/3.png";
import puzzleFour from "./images/4.png";

import startInstructionSound from "./sounds/startInstruction.mp3";
import backgroundSound from "./sounds/backgroundSound.mp3";
import taskZeroSound from "./sounds/taskZero.mp3";
import taskTwoSound from "./sounds/taskTwo.mp3";
import taskThreeSound from "./sounds/taskThree.mp3";
import taskFourSound from "./sounds/taskFour.mp3";
import baloonSound from "./sounds/baloon.mp3";
import puzzleSound from "./sounds/puzzle.mp3";
import finalSound from "./sounds/finalSound.mp3";
import praiseSound from "./sounds/praise.mp3";

const MathFigureFiveGame = () => {
  const [isGameCompleted, setIsGameCompleted] = useState(false);
  const [currentTaskIndex, setCurrentTaskIndex] = useState(0);
  const [imageOpacity, setImageOpacity] = useState(1);
  const [buttonText, setButtonText] = useState("Послушать");
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [isButtonVisible, setIsButtonVisible] = useState(true);
  const [isInteractionBlocked, setIsInteractionBlocked] = useState(true);
  const [showConfetti, setShowConfetti] = useState(false);
  const [visibleBalloons, setVisibleBalloons] = useState({
    baloonOne: true,
    baloonTwo: true,
    baloonThree: true,
    baloonFour: true,
    baloonFive: true,
    baloonSix: true,
    baloonSeven: true,
    baloonEight: true,
    baloonNine: true,
    baloonTen: true,
  });
  const [poppedCount, setPoppedCount] = useState(0);
  const [puzzleSolved, setPuzzleSolved] = useState(false);

  const [puzzles, setPuzzels] = useState([
    { id: "puzzle1", angle: 90, correctAngle: 0, fixed: false, src: puzzleOne },
    {
      id: "puzzle2",
      angle: 180,
      correctAngle: 0,
      fixed: false,
      src: puzzleTwo,
    },
    {
      id: "puzzle3",
      angle: 90,
      correctAngle: 0,
      fixed: false,
      src: puzzleThree,
    },
    {
      id: "puzzle4",
      angle: 180,
      correctAngle: 0,
      fixed: false,
      src: puzzleFour,
    },
  ]);

  const audioRef = useRef(null);
  const startInstructionAudioRef = useRef(new Audio(startInstructionSound));
  const backgroundAudioRef = useRef(new Audio(backgroundSound));
  const puzzleSoundAudio = useRef(new Audio(puzzleSound));
  const finalSoundAudio = useRef(new Audio(finalSound));
  const praiseSoundAudio = useRef(new Audio(praiseSound));

  const tasks = [
    {
      question: "Это цифра пять",
      images: [
        { src: figureFive, alt: "Цифра пять", class: styles.figureFive },
      ],
      audioSrc: taskZeroSound,
    },
    {
      question: "А это пять шариков",
      images: [
        {
          src: figureFive,
          alt: "Цифра пять",
          class: styles.figureFiveWithBaloons,
        },
        { src: baloonOne, alt: "шарик", class: styles.baloon },
        { src: baloonTwo, alt: "шарик", class: styles.baloon },
        { src: baloonThree, alt: "шарик", class: styles.baloon },
        { src: baloonFour, alt: "шарик", class: styles.baloon },
        { src: baloonFive, alt: "шарик", class: styles.baloon },
      ],
      audioSrc: taskTwoSound,
    },
    {
      question: "Лопни пять шариков!",
      images: [
        {
          src: baloonOne,
          alt: "Воздушный шарик",
          class: styles.baloon,
          isBalloon: true,
          id: "baloonOne",
        },
        {
          src: baloonTwo,
          alt: "Воздушный шарик",
          class: styles.baloon,
          isBalloon: true,
          id: "baloonTwo",
        },
        {
          src: baloonThree,
          alt: "Воздушный шарик",
          class: styles.baloon,
          isBalloon: true,
          id: "baloonThree",
        },
        {
          src: baloonFour,
          alt: "Воздушный шарик",
          class: styles.baloon,
          isBalloon: true,
          id: "baloonFour",
        },
        {
          src: baloonFive,
          alt: "Воздушный шарик",
          class: styles.baloon,
          isBalloon: true,
          id: "baloonFive",
        },

        {
          src: baloonSix,
          alt: "Воздушный шарик",
          class: styles.baloon,
          isBalloon: true,
          id: "baloonSix",
        },
        {
          src: baloonSeven,
          alt: "Воздушный шарик",
          class: styles.baloon,
          isBalloon: true,
          id: "baloonSeven",
        },
        {
          src: baloonEight,
          alt: "Воздушный шарик",
          class: styles.baloon,
          isBalloon: true,
          id: "baloonEight",
        },
        {
          src: baloonNine,
          alt: "Воздушный шарик",
          class: styles.baloon,
          isBalloon: true,
          id: "baloonNine",
        },
        {
          src: baloonTen,
          alt: "Воздушный шарик",
          class: styles.baloon,
          isBalloon: true,
          id: "baloonTen",
        },
      ],
      audioSrc: taskThreeSound,
    },
    {
      question: "Поверни пазлы так, чтобы получилась цифра пять",
      images: [
        { src: puzzleOne, alt: "пазл1", class: styles.puzzleOne },
        { src: puzzleTwo, alt: "пазл2", class: styles.puzzleTwo },
        { src: puzzleThree, alt: "пазл3", class: styles.puzzleThree },
        { src: puzzleFour, alt: "пазл4", class: styles.puzzleFour },
      ],
      audioSrc: taskFourSound,
    },
  ];

  const currentTask = tasks[currentTaskIndex];

  const playSound = () => {
    if (currentTask.audioSrc) {
      setIsInteractionBlocked(true);
      setButtonDisabled(true);

      const taskAudio = new Audio(currentTask.audioSrc);
      taskAudio.play().catch((error) => {
        console.error("Playback failed:", error);
        setButtonDisabled(false);
        setIsInteractionBlocked(false);
      });

      taskAudio.onended = () => {
        setButtonDisabled(false);
        setIsInteractionBlocked(false);

        if (currentTaskIndex === tasks.length - 1) {
          setButtonText("");
        } else {
          setButtonText("Далее");
        }
      };
    }
  };

  const handleNextTask = () => {
    if (buttonText === "Послушать") {
      setIsInteractionBlocked(true); // Блокируем взаимодействие сразу после нажатия
      playSound(); // Воспроизводим звук для текущей задачи
    } else if (buttonText === "Далее" && !buttonDisabled) {
      setImageOpacity(0);
      setTimeout(() => {
        if (currentTaskIndex + 1 < tasks.length) {
          setCurrentTaskIndex(currentTaskIndex + 1);
          setButtonText("Послушать");
          setButtonDisabled(false);
          setIsInteractionBlocked(true); // Снова блокируем после перехода к следующему заданию
        }
        setImageOpacity(1);
      }, 500);
    }
  };

  const handleRotate = (puzzleId) => {
    setPuzzels((prevPuzzels) => {
      const updatedPuzzles = prevPuzzels.map((puzzle) => {
        if (puzzle.id === puzzleId && !puzzle.fixed) {
          const newAngle = (puzzle.angle + 90) % 360;
          const isCorrect = newAngle === puzzle.correctAngle;
          if (isCorrect) {
            puzzleSoundAudio.current.play(); // Воспроизводим заранее загруженный звук
          }
          return { ...puzzle, angle: newAngle, fixed: isCorrect };
        }
        return puzzle;
      });

      const allCorrect = updatedPuzzles.every((puzzle) => puzzle.fixed);
      if (allCorrect && !puzzleSolved) {
        setPuzzleSolved(true); // Устанавливаем флаг, что пазл решен
        finalSoundAudio.current.play(); // Воспроизводим финальный звук
        setShowConfetti(true);
        setTimeout(() => {
          setIsGameCompleted(true);
        }, 5000);
        finalSoundAudio.current.onended = () => {
          praiseSoundAudio.current.play(); // Воспроизводим звук похвалы
        };
      }

      return updatedPuzzles;
    });
  };
  useEffect(() => {
    if (currentTaskIndex === 2) {
      const allBalloonsPopped = poppedCount >= 5;
      setButtonDisabled(!allBalloonsPopped && buttonText === "Далее");
    }
  }, [poppedCount, currentTaskIndex, buttonText]);

  const handleBalloonClick = (balloonId) => {
    if (poppedCount < 5 && visibleBalloons[balloonId] !== "popped") {
      setVisibleBalloons((prevState) => ({
        ...prevState,
        [balloonId]: "popped", // Устанавливаем статус "popped" для активации анимации
      }));

      const popSound = new Audio(baloonSound);
      popSound.play();

      setTimeout(() => {
        setVisibleBalloons((prevState) => ({
          ...prevState,
          [balloonId]: false, // Полностью убираем шарик после анимации
        }));
        setPoppedCount((prevCount) => {
          const newCount = prevCount + 1;
          if (newCount >= 5) {
            setButtonDisabled(false);
            setButtonText("Далее");
          }
          return newCount;
        });
      }, 500); // Длительность анимации лопания
    }
  };

  const handleStartGame = () => {
    setButtonDisabled(true);
    setIsInteractionBlocked(true);
    const instructionAudio = startInstructionAudioRef.current;
    instructionAudio.play();

    instructionAudio.onended = () => {
      if (audioRef.current) {
        audioRef.current.play();
      }
      if (backgroundAudioRef.current) {
        backgroundAudioRef.current.play();
      }
      setButtonDisabled(false);
    };

    setIsButtonVisible(false);
  };

  useEffect(() => {
    document.body.style.backgroundColor = "rgba(252, 229, 170, 1)";
    return () => {
      if (startInstructionAudioRef.current) {
        startInstructionAudioRef.current.pause();
        startInstructionAudioRef.current.currentTime = 0;
      }

      if (backgroundAudioRef.current) {
        backgroundAudioRef.current.pause();
        backgroundAudioRef.current.currentTime = 0;
      }

      document.body.style.backgroundColor = "";
    };
  }, []);

  return (
    <DndProvider backend={HTML5Backend}>
      {isGameCompleted && <EndGameOptions />}
      {showConfetti && (
        <Confetti
          numberOfPieces={500}
          angle={180}
          spread={120}
          origin={{ x: 1, y: 0.5 }}
        />
      )}
      <div className={styles.container}>
        <div
          className={styles.backgroundContainer}
          style={{
            pointerEvents: isInteractionBlocked ? "none" : "auto",
          }}
        >
          <h3 className={styles.header}>{currentTask.question}</h3>
          <div
            style={{
              opacity: imageOpacity,
              transition: "opacity 0.5s ease-in-out",
            }}
            className={styles.taskContainer}
          >
            {currentTaskIndex === 0 &&
              currentTask.images.map((img, index) => (
                <img
                  key={index}
                  src={img.src}
                  alt={img.alt}
                  className={img.class}
                />
              ))}
            {currentTaskIndex === 1 && (
              <div className={styles.figureAndBaloonsContainer}>
                <img
                  src={figureFive}
                  alt="Цифра пять"
                  className={styles.figureFive}
                />
                <div className={styles.fiveBaloons}>
                  <div className={styles.baloonRow}>
                    {currentTask.images
                      .filter((img, index) => img.alt === "шарик" && index <= 2)
                      .map((img, index) => (
                        <img
                          key={index}
                          src={img.src}
                          alt={img.alt}
                          className={styles.baloon}
                        />
                      ))}
                  </div>
                  <div className={styles.baloonRow}>
                    {currentTask.images
                      .filter((img, index) => img.alt === "шарик" && index >= 3)
                      .map((img, index) => (
                        <img
                          key={index}
                          src={img.src}
                          alt={img.alt}
                          className={styles.baloon}
                        />
                      ))}
                  </div>
                </div>
              </div>
            )}
            {currentTaskIndex === 2 && (
              <div className={styles.balloonsContainer}>
                {currentTask.images.map((img, index) => {
                  const isPopped = visibleBalloons[img.id] === "popped";
                  const isVisible = visibleBalloons[img.id] !== false;
                  return (
                    <img
                      key={index}
                      src={img.src}
                      alt={img.alt}
                      className={`${img.class} ${
                        img.isBalloon ? styles.balloon : ""
                      } ${isPopped ? styles.pop : ""}`}
                      onClick={() =>
                        img.isBalloon && handleBalloonClick(img.id)
                      }
                      style={{ display: isVisible ? "block" : "none" }}
                    />
                  );
                })}
              </div>
            )}

            {currentTaskIndex === 3 && (
              <div className={styles.cubesContainerMain}>
                <div className={styles.cubesContainer}>
                  {puzzles.map((puzzle) => (
                    <img
                      key={puzzle.id}
                      src={puzzle.src}
                      alt={puzzle.id}
                      className={styles[puzzle.id]}
                      style={{ transform: `rotate(${puzzle.angle}deg)` }}
                      onClick={() => handleRotate(puzzle.id)}
                    />
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>
        {currentTaskIndex < tasks.length - 1 ? (
          <button
            className={styles.button}
            onClick={handleNextTask}
            disabled={buttonDisabled}
          >
            {buttonText}
          </button>
        ) : (
          buttonText === "Послушать" && (
            <button
              className={styles.button}
              onClick={() => {
                playSound();
                setButtonText("");
              }}
              disabled={buttonDisabled}
            >
              {buttonText}
            </button>
          )
        )}
      </div>
      <audio ref={audioRef} />
      <audio ref={backgroundAudioRef} src={backgroundSound} loop />
      {isButtonVisible && (
        <div className={styles.darkOverlay}>
          <button className={styles.buttonStartGame} onClick={handleStartGame}>
            Начать игру
          </button>
        </div>
      )}
    </DndProvider>
  );
};

export default MathFigureFiveGame;
