import { useDrop } from "react-dnd";

const DropTarget = ({ children, onDrop, className, accept }) => {
  const [, drop] = useDrop({
    accept: accept,
    drop: (item, monitor) => {
      const offset = monitor.getClientOffset();

      if (offset) {
        onDrop(item, offset);
      }
    },
  });

  return (
    <div ref={drop} className={className}>
      {children}
    </div>
  );
};

export default DropTarget;
