import React, { useState, useEffect, useRef } from "react";
import useDynamicHeight from "../../../hooks/useDynamicHeight";
import EndGameOptions from "../../end-game-options/end-game-options";
import Confetti from "react-confetti";
import styles from "./seasons-game.module.css";

import autumnIncorrect from "./images/autumnIncorrect.svg";
import autumn from "./images/autumn.svg";
import springIncorrect from "./images/springIncorrect.png";
import spring from "./images/vesna.svg";
import rainIncorrect from "./images/rainIncorrect.svg";
import rain from "./images/rain.svg";
import gradenSpringIncorrect from "./images/gardenSpringIncorrect.svg";
import gradenSpring from "./images/gardenSpring.svg";
import birdOnTreeIncorrect from "./images/birdOnTreeIncorrect.svg";
import birdOnTree from "./images/birdOnTree.svg";
import cleanerIncorrect from "./images/cleanerIncorrect.svg";
import cleaner from "./images/cleaner.svg";
import winterGirlIncorrect from "./images/winterGirlIncorrect.svg";
import winterGirl from "./images/winterGirl.svg";
import gardenIncorrect from "./images/gardenIncorrect.svg";
import garden from "./images/garden.svg";
import boyAutumnIncorrect from "./images/boyAutumnIncorrect.svg";
import boyAutumn from "./images/boyAutumn.svg";
import boyOnIceIncorrect from "./images/boyOnIceIncorrect.svg";
import boyOnIce from "./images/boyOnIce.svg";

import startInstructionSound from "./sounds/startInstruction.mp3";
import backgroundSound from "./sounds/backgroundSound.mp3";
import rightAnswerSound from "./sounds/rightAnswer.mp3";
import finalSound from "./sounds/finalSound.mp3";

const SeasonsGame = () => {
  useDynamicHeight();
  const [isGameCompleted, setIsGameCompleted] = useState(false);
  const [buttonText, setButtonText] = useState("Начать игру");
  const [isButtonVisible, setIsButtonVisible] = useState(true);
  const audioRef = useRef(null);
  const startInstructionAudioRef = useRef(new Audio(startInstructionSound));
  const backgroundAudioRef = useRef(new Audio(backgroundSound));
  const [showConfetti, setShowConfetti] = useState(false);
  const [showAnswer, setShowAnswer] = useState(false);
  const [currentTaskIndex, setCurrentTaskIndex] = useState(0);
  const [imageOpacity, setImageOpacity] = useState(1);

  const tasks = [
    {
      question: "Что здесь неверно?",
      incorrectImage: autumnIncorrect,
      correctImage: autumn,
    },
    {
      question: "Что здесь неверно?",
      incorrectImage: springIncorrect,
      correctImage: spring,
    },
    {
      question: "Что здесь неверно?",
      incorrectImage: rainIncorrect,
      correctImage: rain,
    },
    {
      question: "Что здесь неверно?",
      incorrectImage: gradenSpringIncorrect,
      correctImage: gradenSpring,
    },
    {
      question: "Что здесь неверно?",
      incorrectImage: birdOnTreeIncorrect,
      correctImage: birdOnTree,
    },
    {
      question: "Что здесь неверно?",
      incorrectImage: cleanerIncorrect,
      correctImage: cleaner,
    },
    {
      question: "Что здесь неверно?",
      incorrectImage: winterGirlIncorrect,
      correctImage: winterGirl,
    },
    {
      question: "Что здесь неверно?",
      incorrectImage: gardenIncorrect,
      correctImage: garden,
    },
    {
      question: "Что здесь неверно?",
      incorrectImage: boyAutumnIncorrect,
      correctImage: boyAutumn,
    },
    {
      question: "Что здесь неверно?",
      incorrectImage: boyOnIceIncorrect,
      correctImage: boyOnIce,
    },
  ];

  const currentTask = tasks[currentTaskIndex];

  const handleShowAnswer = () => {
    const rightAnswerAudio = new Audio(rightAnswerSound);
    rightAnswerAudio.play();
    setImageOpacity(0);
    setTimeout(() => {
      setShowAnswer(true);
      setImageOpacity(1);
    }, 500); // Полусекундная задержка для анимации исчезновения
  };

  const handleNextTask = () => {
    if (currentTaskIndex + 1 < tasks.length) {
      // Анимация при переходе к следующему заданию
      setImageOpacity(0);
      setTimeout(() => {
        setShowAnswer(false);
        setCurrentTaskIndex((prevIndex) => prevIndex + 1);
        setImageOpacity(1);
      }, 500); // Полусекундная задержка для анимации исчезновения
    } else {
      setShowConfetti(true);
      setTimeout(() => {
        setIsGameCompleted(true);
      }, 5000);
      const finalAudio = new Audio(finalSound);
      finalAudio.play();
    }
  };

  const handleStartGame = () => {
    startInstructionAudioRef.current.play();

    startInstructionAudioRef.current.onended = () => {
      if (backgroundAudioRef.current) {
        backgroundAudioRef.current.loop = true;
        backgroundAudioRef.current.play();
      }
      if (audioRef.current) {
        audioRef.current.play();
      }
    };
    setIsButtonVisible(false);
  };

  useEffect(() => {
    return () => {
      startInstructionAudioRef.current.pause();
      startInstructionAudioRef.current.currentTime = 0;
      backgroundAudioRef.current.pause();
      backgroundAudioRef.current.currentTime = 0;
    };
  }, []);

  return (
    <>
      {isGameCompleted && <EndGameOptions />}
      <div className={`container ${styles.someOtherClass}`}>
        {showConfetti && (
          <Confetti
            numberOfPieces={500}
            angle={180}
            spread={120}
            origin={{ x: 1, y: 0.5 }}
          />
        )}
        <div className={styles.backgroundContainer}>
          <h3 className={styles.header}>
            {showAnswer ? "Правильный ответ:" : currentTask.question}
          </h3>
          <h4 className={styles.headerCounter}>
            Вопрос {currentTaskIndex + 1} из {tasks.length}
          </h4>
          <div className={styles.imagesContainer}>
            <img
              src={
                showAnswer
                  ? tasks[currentTaskIndex].correctImage
                  : tasks[currentTaskIndex].incorrectImage
              }
              alt={showAnswer ? "Правильный ответ" : "Что здесь неверно?"}
              style={{
                opacity: imageOpacity,
                transition: "opacity 0.5s ease-in-out",
              }}
              className={styles.taskImage}
            />
          </div>
        </div>
        <button
          className={styles.button}
          onClick={showAnswer ? handleNextTask : handleShowAnswer}
        >
          {showAnswer
            ? currentTaskIndex + 1 < tasks.length
              ? "Перейти к следующему заданию"
              : "Завершить игру"
            : "Узнать правильный ответ!"}
        </button>
        <audio ref={audioRef} src={backgroundSound} loop />
        {isButtonVisible && (
          <div className={styles.darkOverlay}>
            <button
              className={styles.buttonStartGame}
              onClick={handleStartGame}
            >
              {buttonText}
            </button>
          </div>
        )}
      </div>
    </>
  );
};

export default SeasonsGame;
