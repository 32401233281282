import React, { useState, useEffect, useRef } from "react";

import EndGameOptions from "../../end-game-options/end-game-options";
import { gsap } from "gsap";
import styles from "./guess-action.module.css";
import motherEat from "./images/motherEating.png";
import motherPlay from "./images/motherPlaying.png";
import motherGo from "./images/motherGo.png";
import motherLie from "./images/motherLie.png";
import motherCLothes from "./images/motherClothes.png";
import motherRead from "./images/motherRead.png";
import motherWalk from "./images/motherWalk.png";
import motherCook from "./images/motherCooking.png";
import fatherBuy from "./images/fatherBuy.png";
import fatherEat from "./images/fatherEat.png";
import fatherPlay from "./images/fatherPlay.png";
import fatherRead from "./images/fatherRead.png";
import fatherRun from "./images/fatherRun.png";
import fatherWash from "./images/fatherWash.png";
import fatherWork from "./images/fatherWork.png";
import fatherLie from "./images/fatherLie.png";
import grandmaClean from "./images/grandmaClean.png";
import grandmaRead from "./images/grandmaRead.png";
import grandmaSeat from "./images/grandmaSeat.png";
import grandmaSing from "./images/grandmaSing.png";
import grandmaSleep from "./images/grandmaSleep.png";
import grandmaWash from "./images/grandmaWash.png";
import grandmaWork from "./images/grandmaWork.png";
import repeatTask from "./images/repeatTask.png";

import startInstructionSound from "./sounds/startInstruction.mp3";
import finalSound from "./sounds/finalSound.mp3";
import correctAnswerSound from "./sounds/rightAnswer.mp3";
import incorrectAnswerSound from "./sounds/incorrectAnswer.mp3";
import fatherBuySound from "./sounds/fatherBuy.mp3";
import fatherEatSound from "./sounds/fatherEat.mp3";
import fatherWashSound from "./sounds/fatherWash.mp3";
import grandmaCleanSound from "./sounds/grandmaClean.mp3";
import grandmaSingSound from "./sounds/grandmaSong.mp3";
import grandmaWashSound from "./sounds/grandmaWash.mp3";
import motherCookSound from "./sounds/motherCook.mp3";
import motherGoSound from "./sounds/motherGo.mp3";
import motherSleepSound from "./sounds/motherSleep.mp3";

const colors = [
  "#FF0000",
  "#FF7F00",
  "#FFFF00",
  "#00FF00",
  "#0000FF",
  "#4B0082",
  "#8B00FF",
];

const triggerFireworks = () => {
  const fireworksContainer = document.querySelector(
    `.${styles.fireworksContainer}`
  );
  if (fireworksContainer) {
    for (let i = 0; i < 10; i++) {
      setTimeout(() => createFirework(fireworksContainer), i * 500);
    }
  } else {
    console.error("Fireworks container not found");
  }
};

const createFirework = (container) => {
  const firework = document.createElement("div");
  firework.className = styles.firework;
  firework.style.left = `${Math.random() * 100}%`;
  firework.style.top = `${Math.random() * 100}%`;
  container.appendChild(firework);

  const particles = [];
  for (let i = 0; i < 30; i++) {
    const particle = document.createElement("div");
    particle.className = styles.particle;
    particle.style.background =
      colors[Math.floor(Math.random() * colors.length)];
    firework.appendChild(particle);
    particles.push(particle);
  }

  const tl = gsap.timeline({
    onComplete: () => {
      firework.remove();
    },
  });

  particles.forEach((particle) => {
    const angle = Math.random() * Math.PI * 2;
    const distance = Math.random() * 200 + 50;
    tl.fromTo(
      particle,
      { x: 0, y: 0, opacity: 1 },
      {
        x: Math.cos(angle) * distance,
        y: Math.sin(angle) * distance,
        opacity: 0,
        duration: 2,
        ease: "power2.out",
      },
      0
    );
  });
};

const GuessActionGame = () => {
  const [isInteractionBlocked, setIsInteractionBlocked] = useState(false);
  const [isGameCompleted, setIsGameCompleted] = useState(false);
  const [buttonText, setButtonText] = useState("Начать игру");
  const [isButtonVisible, setIsButtonVisible] = useState(true);
  const audioRef = useRef(null);
  const startInstructionAudioRef = useRef(new Audio(startInstructionSound));
  const [currentTaskIndex, setCurrentTaskIndex] = useState(0);
  const [isAnswerCorrect, setIsAnswerCorrect] = useState(null);
  const [selectedAnswer, setSelectedAnswer] = useState(null);

  const tasks = [
    {
      question: "Какое действие верно?",
      images: [
        { src: motherEat, alt: "motherEat" },
        { src: motherPlay, alt: "motherPlay" },
        { src: motherGo, alt: "motherGo" },
        { src: motherLie, alt: "motherLie" },
      ],
      correctAnswer: "motherGo",
      instructionSound: motherGoSound,
    },
    {
      question: "Какое действие верно?",
      images: [
        { src: motherCLothes, alt: "motherCLothes" },
        { src: motherRead, alt: "motherRead" },
        { src: motherWalk, alt: "motherWalk" },
        { src: motherLie, alt: "motherLie" },
      ],
      correctAnswer: "motherLie",
      instructionSound: motherSleepSound,
    },
    {
      question: "Какое действие верно?",
      images: [
        { src: motherEat, alt: "motherEat" },
        { src: motherCLothes, alt: "motherCLothes" },
        { src: motherCook, alt: "motherCook" },
        { src: motherLie, alt: "motherLie" },
      ],
      correctAnswer: "motherCook",
      instructionSound: motherCookSound,
    },
    {
      question: "Какое действие верно?",
      images: [
        { src: fatherLie, alt: "fatherLie" },
        { src: fatherPlay, alt: "fatherPlay" },
        { src: fatherRead, alt: "fatherRead" },
        { src: fatherWash, alt: "fatherWash" },
      ],
      correctAnswer: "fatherWash",
      instructionSound: fatherWashSound,
    },
    {
      question: "Какое действие верно?",
      images: [
        { src: fatherWork, alt: "fatherWork" },
        { src: fatherBuy, alt: "fatherBuy" },
        { src: fatherRun, alt: "fatherRun" },
        { src: fatherWash, alt: "fatherWash" },
      ],
      correctAnswer: "fatherBuy",
      instructionSound: fatherBuySound,
    },
    {
      question: "Какое действие верно?",
      images: [
        { src: fatherBuy, alt: "fatherBuy" },
        { src: fatherRead, alt: "fatherRead" },
        { src: fatherWork, alt: "fatherWork" },
        { src: fatherEat, alt: "fatherEat" },
      ],
      correctAnswer: "fatherEat",
      instructionSound: fatherEatSound,
    },
    {
      question: "Какое действие верно?",
      images: [
        { src: grandmaSleep, alt: "grandmaSleep" },
        { src: grandmaSing, alt: "grandmaSing" },
        { src: grandmaWash, alt: "grandmaWash" },
        { src: grandmaWork, alt: "grandmaWork" },
      ],
      correctAnswer: "grandmaSing",
      instructionSound: grandmaSingSound,
    },
    {
      question: "Какое действие верно?",
      images: [
        { src: grandmaRead, alt: "grandmaRead" },
        { src: grandmaSleep, alt: "grandmaSleep" },
        { src: grandmaWash, alt: "grandmaWash" },
        { src: grandmaSeat, alt: "grandmaSeat" },
      ],
      correctAnswer: "grandmaWash",
      instructionSound: grandmaWashSound,
    },
    {
      question: "Какое действие верно?",
      images: [
        { src: grandmaSing, alt: "grandmaSleep" },
        { src: grandmaRead, alt: "grandmaRead" },
        { src: grandmaClean, alt: "grandmaClean" },
        { src: grandmaWork, alt: "grandmaWork" },
      ],
      correctAnswer: "grandmaClean",
      instructionSound: grandmaCleanSound,
    },
  ];

  const currentTask = tasks[currentTaskIndex];

  const handleImageClick = (answer) => {
    if (isAnswerCorrect) return;

    setSelectedAnswer(answer);
    const isCorrect = answer === currentTask.correctAnswer;
    setIsAnswerCorrect(isCorrect);

    if (isCorrect) {
      const correctAudio = new Audio(correctAnswerSound);
      correctAudio.play();
      correctAudio.onended = () => {
        setTimeout(() => {
          setIsAnswerCorrect(null);
          setSelectedAnswer(null);
          const nextIndex = (currentTaskIndex + 1) % tasks.length;

          if (nextIndex === 0) {
            const finalAudio = new Audio(finalSound);
            finalAudio.play();
            triggerFireworks();
            setTimeout(() => {
              setIsGameCompleted(true);
            }, 5000);
          } else {
            setCurrentTaskIndex(nextIndex);

            setTimeout(() => {
              const taskAudio = new Audio(tasks[nextIndex].instructionSound);
              taskAudio.play();
            }, 1000); // Задержка перед воспроизведением звука следующего задания
          }
        }, 1000);
      };
    } else {
      const incorrectAudio = new Audio(incorrectAnswerSound);
      incorrectAudio.play();
    }
  };

  const handleNextTask = () => {
    if (audioRef.current) {
      setIsInteractionBlocked(true);
      startInstructionAudioRef.current.play();

      startInstructionAudioRef.current.onended = () => {
        setTimeout(() => {
          const taskAudio = new Audio(tasks[0].instructionSound);
          taskAudio.play();
          taskAudio.onended = () => {
            setIsInteractionBlocked(false);
            setCurrentTaskIndex(0);
          };
        }, 200);
      };
    }
    setIsButtonVisible(false);
  };

  const handleRepeatTask = () => {
    const currentInstructionSound = new Audio(
      tasks[currentTaskIndex].instructionSound || startInstructionSound
    );
    currentInstructionSound.play();
  };

  useEffect(() => {
    document.body.style.backgroundColor = "#FCE5AA";
    return () => {
      // Остановить и сбросить все аудио
      startInstructionAudioRef.current.pause();
      startInstructionAudioRef.current.currentTime = 0;

      document.body.style.backgroundColor = "";

      // Остановить все анимации GSAP
      gsap.globalTimeline.clear();
    };
  }, []);

  return (
    <>
      {isGameCompleted && <EndGameOptions />}
      <div className={styles.container}>
        <h3 className={styles.header}>{currentTask.question}</h3>
        <h4 className={styles.headerCounter}>
          Вопрос {currentTaskIndex + 1} из {tasks.length}
        </h4>
        <div
          className={styles.backgroundContainer}
          style={{
            pointerEvents: isInteractionBlocked ? "none" : "auto",
          }}
        >
          <div className={styles.imagesContainer}>
            {currentTask.images.map((image) => (
              <img
                key={image.alt}
                src={image.src}
                alt={image.alt}
                className={`${styles.taskImage} ${styles[`${image.alt}Image`]}`}
                onClick={() => handleImageClick(image.alt)}
                style={{
                  filter:
                    selectedAnswer === image.alt && isAnswerCorrect
                      ? "drop-shadow(0 10px 50px rgba(50, 205, 50, 0.5))"
                      : selectedAnswer === image.alt && selectedAnswer !== null
                      ? "none"
                      : "",
                }}
              />
            ))}
            <div className={styles.repeatButtonContainer}>
              <button
                className={styles.repeatButton}
                onClick={handleRepeatTask}
              >
                <img src={repeatTask} alt="Повторить инструкцию" />
                <span className={styles.tooltip}>
                  Нажмите для повторного прослушивания инструкции
                </span>
              </button>
            </div>
          </div>
          <div className={styles.fireworksContainer}></div>
        </div>
        <audio ref={audioRef} />
        {isButtonVisible && (
          <div className={styles.darkOverlay}>
            <button className={styles.button} onClick={handleNextTask}>
              {buttonText}
            </button>
          </div>
        )}
      </div>
    </>
  );
};

export default GuessActionGame;
