import React from "react";
import { useDrag } from "react-dnd";

const DraggableItem = ({ id, src, alt, className, isDisabled }) => {
  const [{ isDragging }, drag] = useDrag({
    type: "RING",
    item: { id },
    canDrag: !isDisabled,
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  return (
    <img
      ref={drag}
      src={src}
      alt={alt}
      className={className}
      style={{
        opacity: isDragging ? 0.5 : 1,
        display: isDisabled ? "none" : "block",
      }}
    />
  );
};

export default DraggableItem;
