import React, { useState, useRef, useEffect } from "react";
import useDynamicHeight from "../../../hooks/useDynamicHeight";
import EndGameOptions from "../../end-game-options/end-game-options";
import { gsap } from "gsap";
import styles from "./dating-with-zhuzha-part-two.module.css";

import wallper from "./images/wallperTwo.png";
import zhuzha from "./images/zhuzha.png";
import girl from "./images/girl.png";
import gift from "./images/gift.png";
import table from "./images/table.png";
import soundZh from "./images/soundZh.svg";

import beeZhhSound from "./sounds/beeZhh.mp3";
import giftSound from "./sounds/gift.mp3";
import praiseSound from "./sounds/praise.mp3";

const colors = [
  "#FF0000",
  "#FF7F00",
  "#FFFF00",
  "#00FF00",
  "#0000FF",
  "#4B0082",
  "#8B00FF",
];

const triggerFireworks = () => {
  const fireworksContainer = document.querySelector(
    `.${styles.fireworksContainer}`
  );
  if (fireworksContainer) {
    for (let i = 0; i < 10; i++) {
      setTimeout(() => createFirework(fireworksContainer), i * 500);
    }
  } else {
    console.error("Fireworks container not found");
  }
};

const createFirework = (container) => {
  const firework = document.createElement("div");
  firework.className = styles.firework;
  firework.style.left = `${Math.random() * 100}%`;
  firework.style.top = `${Math.random() * 100}%`;
  container.appendChild(firework);

  const particles = [];
  for (let i = 0; i < 30; i++) {
    const particle = document.createElement("div");
    particle.className = styles.particle;
    particle.style.background =
      colors[Math.floor(Math.random() * colors.length)];
    firework.appendChild(particle);
    particles.push(particle);
  }

  const tl = gsap.timeline({
    onComplete: () => {
      firework.remove();
    },
  });

  particles.forEach((particle) => {
    const angle = Math.random() * Math.PI * 2;
    const distance = Math.random() * 200 + 50;
    tl.fromTo(
      particle,
      { x: 0, y: 0, opacity: 1 },
      {
        x: Math.cos(angle) * distance,
        y: Math.sin(angle) * distance,
        opacity: 0,
        duration: 2,
        ease: "power2.out",
      },
      0
    );
  });
};

const flowerData = [
  {
    id: 1,
    flowerSrc: soundZh,
    flowerPosition: { top: "78%", left: "15%" },
    soundPosition: { top: "57%", left: "13%" },
  },
  {
    id: 2,
    flowerSrc: soundZh,
    flowerPosition: { top: "62%", left: "28%" },
    soundPosition: { top: "42%", left: "26%" },
  },
  {
    id: 3,
    flowerSrc: soundZh,
    flowerPosition: { top: "79%", left: "39%" },
    soundPosition: { top: "60%", left: "37%" },
  },
  {
    id: 4,
    flowerSrc: soundZh,
    flowerPosition: { top: "58%", left: "45%" },
    soundPosition: { top: "39%", left: "43%" },
  },
  {
    id: 5,
    flowerSrc: soundZh,
    flowerPosition: { top: "80%", left: "54%" },
    soundPosition: { top: "60%", left: "52%" },
  },
];

const DatingWithZhuzhaSecondPart = () => {
  useDynamicHeight();
  const [isGameCompleted, setIsGameCompleted] = useState(false);
  const [visited, setVisited] = useState([]);
  const [currentPosition, setCurrentPosition] = useState(-1);
  const zhuzhaRef = useRef(null);
  const giftRef = useRef(null);
  const beeZhhSoundRef = useRef(new Audio(beeZhhSound));
  const giftSoundRef = useRef(new Audio(giftSound));
  const praiseSoundRef = useRef(new Audio(praiseSound));

  const jumpToNextPosition = () => {
    let nextPosition = currentPosition + 1;
    if (nextPosition < flowerData.length) {
      const { left, top } = flowerData[nextPosition].soundPosition;
      const peakY = "-=100";
      beeZhhSoundRef.current.play();
      setTimeout(() => {
        beeZhhSoundRef.current.pause();
        beeZhhSoundRef.current.currentTime = 0;
      }, 1500);
      gsap
        .timeline()
        .to(zhuzhaRef.current, {
          y: peakY,
          duration: 0.4,
          ease: "power2.out",
        })
        .to(zhuzhaRef.current, {
          left: left,
          top: top,
          duration: 0.8,
          ease: "power2.inOut",
        })
        .to(zhuzhaRef.current, {
          y: 0,
          duration: 0.4,
          ease: "power2.inOut",
          onComplete: () => {
            setCurrentPosition(nextPosition);
            setVisited((prev) => [...prev, nextPosition]);
            if (nextPosition === flowerData.length - 1) {
              sendGiftToGirl();
            }
          },
        });
    }
  };

  useEffect(() => {
    window.addEventListener("click", jumpToNextPosition);
    return () => window.removeEventListener("click", jumpToNextPosition);
  }, [currentPosition]);

  const sendGiftToGirl = () => {
    const girlTop = "4%"; // Примерная координата вертикальной позиции девочки
    const girlLeft = "226%"; // Примерная координата горизонтальной позиции девочки

    giftSoundRef.current.play();
    // Начало анимации с подсветкой
    gsap.to(giftRef.current, {
      boxShadow: "0 0 20px 10px rgba(255, 215, 0, 0.75)", // Усиленная подсветка
      duration: 0.5,
      repeat: 1,
      yoyo: true,
      ease: "power1.inOut",
    });

    // Анимация перемещения цветка к девочке
    gsap
      .timeline({
        onComplete: () => {
          // Остановка подсветки после завершения полёта
          gsap.to(giftRef.current, {
            boxShadow: "none", // Сброс подсветки
            duration: 0.5,
          });
          triggerFireworks();
          setTimeout(() => {
            setIsGameCompleted(true);
          }, 5000);
          praiseSoundRef.current.play();
        },
      })
      .to(giftRef.current, {
        left: girlLeft,
        top: girlTop,
        duration: 2, // Увеличенная продолжительность для более заметного и плавного полёта
        ease: "power4.out", // Плавное замедление к концу анимации
      });
  };

  useEffect(() => {
    return () => {
      gsap.globalTimeline.clear();
    };
  }, []);

  return (
    <>
      {isGameCompleted && <EndGameOptions />}
      <div className={`container ${styles.someOtherClass}`}>
        <div className={styles.backgroundContainer}>
          <div className={styles.dropContainer}>
            <img className={styles.wallper} src={wallper} alt="фон" />
            <img className={styles.table} src={table} alt="стол" />
            <img className={styles.girl} src={girl} alt="девочка" />
            <div className={styles.zhuzhaContainer} ref={zhuzhaRef}>
              <img src={zhuzha} alt="Zhuzha" className={styles.zhuzha} />
              <img
                src={gift}
                ref={giftRef}
                alt="Gift"
                className={styles.gift}
              />
            </div>
            {flowerData.map(
              (flower) =>
                !visited.includes(flower.id - 1) && (
                  <div key={flower.id}>
                    <img
                      className={styles.flower}
                      src={flower.flowerSrc}
                      alt={`Flower ${flower.id}`}
                      style={{
                        top: flower.flowerPosition.top,
                        left: flower.flowerPosition.left,
                        position: "absolute",
                        width: "9%",
                      }}
                    />
                  </div>
                )
            )}
          </div>
        </div>
      </div>
      <div className={styles.fireworksContainer}></div>
    </>
  );
};

export default DatingWithZhuzhaSecondPart;
