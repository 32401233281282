import React, { useState } from "react";
import styles from "./promocode-modal.module.css";
import { postUserSubscription } from "../api/subscription";

function PromocodeModal({
  closePromocodeModal,
  handleSubscription,
  durationMonths,
}) {
  const [promocode, setPromocode] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const handleNoPromocode = () => {
    handleSubscription(durationMonths, true);
    closePromocodeModal();
  };

  const handlePromocodeChange = (event) => {
    setPromocode(event.target.value);
    if (errorMessage) {
      setErrorMessage("");
    }
  };
  const checkPromocode = (durationMonths, recurrent, promocode) => {
    const subscriptionData = {
      durationMonths,
      recurrent,
      promocode,
    };

    return postUserSubscription(subscriptionData)
      .then((response) => {
        if (response.paymentUrl) {
          return Promise.resolve(response);
        } else {
          return Promise.reject(
            new Error("Промокод недействителен или срок действия истек")
          );
        }
      })
      .catch((error) => {
        return Promise.reject(
          new Error("Промокод недействителен или срок действия истек")
        );
      });
  };

  const handleApplyPromocode = () => {
    checkPromocode(durationMonths, true, promocode)
      .then((response) => {
        if (response.paymentUrl) {
          window.location.assign(response.paymentUrl);
        }
      })
      .catch((error) => {
        setErrorMessage(error.message);
      });
  };
  return (
    <div className={styles.container}>
      <h3 className={styles.header}>
        У вас есть промокод?
        <br /> Введите его в поле ниже!
      </h3>
      <input
        type="text"
        className={styles.input}
        placeholder="Введите промокод"
        value={promocode}
        onChange={handlePromocodeChange}
      />
      {errorMessage && <div className={styles.error}>{errorMessage}</div>}
      <button
        type="button"
        className={styles.btnLogin}
        onClick={handleApplyPromocode}
      >
        Применить промокод
      </button>
      <button
        type="button"
        className={styles.btnNoPromocode}
        onClick={handleNoPromocode}
      >
        У меня нет промокода
      </button>
    </div>
  );
}

export default PromocodeModal;
