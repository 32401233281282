import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import resourcesConfig from "./resources-config";

const GameLoader = ({ children }) => {
  const { gameId } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [failedResources, setFailedResources] = useState([]);

  useEffect(() => {
    const loadResources = async () => {
      try {
        const gameResources = resourcesConfig[gameId];
        if (!gameResources) {
          throw new Error("Ресурсы не найдены для этой игры");
        }

        const loadImage = (src) => {
          return new Promise((resolve, reject) => {
            const img = new Image();
            img.src = typeof src === "string" ? src : src.default;
            img.onload = resolve;
            img.onerror = () => reject(src);
          });
        };

        const loadAudio = (src) => {
          return new Promise((resolve, reject) => {
            const audio = new Audio(src);
            audio.preload = "auto";
            audio.onloadeddata = resolve;
            audio.onerror = () => reject(src);
          });
        };

        const imagePromises = Object.values(gameResources.images).map((src) =>
          loadImage(src).catch((error) => {
            setFailedResources((prev) => [...prev, error]);
            console.error("Ошибка загрузки изображения:", error);
          })
        );

        const audioPromises = Object.values(gameResources.sounds).map((src) =>
          loadAudio(src).catch((error) => {
            setFailedResources((prev) => [...prev, error]);
            console.error("Ошибка загрузки звука:", error);
          })
        );

        await Promise.all([...imagePromises, ...audioPromises]);

        setIsLoading(false);
      } catch (error) {
        console.error("Ошибка загрузки ресурсов:", error);
        setIsLoading(false);
      }
    };

    loadResources();
  }, [gameId]);

  if (isLoading) {
    return (
      <div
        className="loaderContainer"
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <ClipLoader size={50} color={"#00BFFF"} />
        <p
          style={{
            marginTop: "10px",
            fontSize: "18px",
            color: "#555",
            fontStyle: "Nunito Regular",
          }}
        >
          Загружаем материалы...
        </p>
      </div>
    );
  }

  return children;
};

export default GameLoader;
