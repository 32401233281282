import React from "react";
import styles from "./failed-change-modal.module.css";

function FailedChangePasswordModal({ closePasswordNotSuccessModal }) {
  const handleClick = () => {
    closePasswordNotSuccessModal();
  };
  return (
    <div className={styles.container}>
      <h3 className={styles.header}>Ошибка!</h3>
      <span className={styles.text}>Вы ввели неверный пароль</span>
      <button type="button" className={styles.btnLogin} onClick={handleClick}>
        Понятно!
      </button>
    </div>
  );
}

export default FailedChangePasswordModal;
