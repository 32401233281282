import React from "react";
import { useDrop } from "react-dnd";

const DropTarget = ({ targetId, onDrop, children, className, canDrop }) => {
  const [{ isOver, canDrop: isDroppable }, drop] = useDrop(() => ({
    accept: "ECLAIR",
    canDrop: () => canDrop,
    drop: (item) => {
      if (canDrop) {
        onDrop(item.id, targetId);
      }
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  }));

  return (
    <div ref={drop} className={className}>
      {children}
    </div>
  );
};

export default DropTarget;
