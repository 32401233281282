import React from "react";
import { useDrop } from "react-dnd";

const DropTarget = ({ targetId, onDrop, children, className }) => {
  const [{ isOver, canDrop }, drop] = useDrop(() => ({
    accept: "FLOWER",
    drop: (item) => onDrop(item.id, targetId),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  }));

  return (
    <div ref={drop} className={className}>
      {children}
    </div>
  );
};

export default DropTarget;
