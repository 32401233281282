import React, { useState, useEffect, useRef } from "react";
import useDynamicHeight from "../../../hooks/useDynamicHeight";
import EndGameOptions from "../../end-game-options/end-game-options";
import { gsap } from "gsap";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import DraggableItem from "./DraggableItem";
import DropTarget from "./DropTarget";
import styles from "./math-two.module.css";
import figureTwo from "./images/2.png";
import garageLarge from "./images/garageLarge.png";
import garageSmall from "./images/garageSmall.png";
import car from "./images/car.png";

import startInstructionSound from "./sounds/startInstruction.mp3";
import backgroundSound from "./sounds/backgroundSound.mp3";
import taskZeroSound from "./sounds/taskZero.mp3";
import taskTwoSound from "./sounds/taskTwo.mp3";
import taskThreeSound from "./sounds/taskThree.mp3";
import taskFourSound from "./sounds/taskFour.mp3";
import carInGarageSound from "./sounds/carInGarage.mp3";
import figureSound from "./sounds/figureSound.mp3";
import praiseSound from "./sounds/praise.mp3";

const colors = [
  "#FF0000",
  "#FF7F00",
  "#FFFF00",
  "#00FF00",
  "#0000FF",
  "#4B0082",
  "#8B00FF",
];

const triggerFireworks = () => {
  const fireworksContainer = document.querySelector(
    `.${styles.fireworksContainer}`
  );
  if (fireworksContainer) {
    for (let i = 0; i < 10; i++) {
      setTimeout(() => createFirework(fireworksContainer), i * 500);
    }
  } else {
    console.error("Fireworks container not found");
  }
};

const createFirework = (container) => {
  const firework = document.createElement("div");
  firework.className = styles.firework;
  firework.style.left = `${Math.random() * 100}%`;
  firework.style.top = `${Math.random() * 100}%`;
  container.appendChild(firework);

  const particles = [];
  for (let i = 0; i < 30; i++) {
    const particle = document.createElement("div");
    particle.className = styles.particle;
    particle.style.background =
      colors[Math.floor(Math.random() * colors.length)];
    firework.appendChild(particle);
    particles.push(particle);
  }

  const tl = gsap.timeline({
    onComplete: () => {
      firework.remove();
    },
  });

  particles.forEach((particle) => {
    const angle = Math.random() * Math.PI * 2;
    const distance = Math.random() * 200 + 50;
    tl.fromTo(
      particle,
      { x: 0, y: 0, opacity: 1 },
      {
        x: Math.cos(angle) * distance,
        y: Math.sin(angle) * distance,
        opacity: 0,
        duration: 2,
        ease: "power2.out",
      },
      0
    );
  });
};

const MathFigureTwoGame = () => {
  useDynamicHeight();
  const [isGameCompleted, setIsGameCompleted] = useState(false);
  const [currentTaskIndex, setCurrentTaskIndex] = useState(0);
  const [imageOpacity, setImageOpacity] = useState(1);
  const [buttonText, setButtonText] = useState("Послушать");
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [isButtonVisible, setIsButtonVisible] = useState(true);
  const [isInteractionBlocked, setIsInteractionBlocked] = useState(true);
  const [clickedHouses, setClickedHouses] = useState([]);
  const [carsInGarages, setCarsInGarages] = useState({});
  const [carsRemoved, setCarsRemoved] = useState([]);
  const [order, setOrder] = useState([]);
  const audioRef = useRef(null);
  const startInstructionAudioRef = useRef(new Audio(startInstructionSound));
  const backgroundAudioRef = useRef(new Audio(backgroundSound));

  const tasks = [
    {
      question: "Это цифра два",
      images: [{ src: figureTwo, alt: "Цифра два", class: styles.figureTwo }],
      audioSrc: taskZeroSound,
    },
    {
      question: "А это две машинки",
      images: [
        { src: figureTwo, alt: "Цифра два", class: styles.figureTwoWithCars },
        { src: car, alt: "машинка", class: styles.car },
        { src: car, alt: "машинка", class: styles.car },
      ],
      audioSrc: taskTwoSound,
    },
    {
      question: "Посчитай гаражи. Нажми на каждый!",
      images: [
        { src: garageLarge, alt: "домик", class: styles.garageLarge },
        { src: garageLarge, alt: "домик", class: styles.garageLarge },
      ],
      audioSrc: taskThreeSound,
    },
    {
      question: "Припаркуй машинки в гаражи",
      images: [],
      audioSrc: taskFourSound,
    },
  ];

  const currentTask = tasks[currentTaskIndex];

  const playSound = () => {
    if (currentTask.audioSrc) {
      setIsInteractionBlocked(true);
      setButtonDisabled(true);

      const taskAudio = new Audio(currentTask.audioSrc);
      taskAudio.play().catch((error) => {
        console.error("Playback failed:", error);
        setButtonDisabled(false);
        setIsInteractionBlocked(false);
      });

      taskAudio.onended = () => {
        setButtonDisabled(false);
        setIsInteractionBlocked(false);

        if (currentTaskIndex === tasks.length - 1) {
          setButtonText("");
        } else {
          setButtonText("Далее");
        }
      };
    }
  };

  useEffect(() => {
    if (currentTaskIndex === 2) {
      const allHousesClicked = clickedHouses.length === 2;
      setButtonDisabled(!allHousesClicked && buttonText === "Далее");
    }
  }, [clickedHouses, currentTaskIndex, buttonText]);

  const handleNextTask = () => {
    if (buttonText === "Послушать") {
      setIsInteractionBlocked(true); // Блокируем взаимодействие сразу после нажатия
      playSound(); // Воспроизводим звук для текущей задачи
    } else if (buttonText === "Далее" && !buttonDisabled) {
      setImageOpacity(0);
      setTimeout(() => {
        if (currentTaskIndex + 1 < tasks.length) {
          setCurrentTaskIndex(currentTaskIndex + 1);
          setButtonText("Послушать");
          setButtonDisabled(false);
          setIsInteractionBlocked(true); // Снова блокируем после перехода к следующему заданию
        }
        setImageOpacity(1);
      }, 500);
    }
  };

  const handleHouseClick = (houseIndex) => {
    setClickedHouses((prevClickedHouses) => {
      if (!prevClickedHouses.includes(houseIndex)) {
        const clickAudio = new Audio(figureSound);
        clickAudio.play();
        return [...prevClickedHouses, houseIndex];
      }
      return prevClickedHouses;
    });
  };

  const handleDrop = (itemId, targetId) => {
    if (isInteractionBlocked) return;

    setCarsInGarages((prev) => {
      if (!prev[targetId]) {
        return { ...prev, [targetId]: [itemId] };
      }
      return { ...prev, [targetId]: [...prev[targetId], itemId] };
    });

    setCarsRemoved((prev) => {
      if (!prev.includes(itemId)) {
        setOrder([...order, itemId]); // Добавляем машинку в порядок перемещения
        const carSound = new Audio(carInGarageSound); // Создаем объект Audio для звука
        carSound.play(); // Воспроизводим звук при добавлении машинки в гараж
        return [...prev, itemId];
      }
      return prev;
    });

    if (carsRemoved.length + 1 === 2) {
      const praiseSoundAudio = new Audio(praiseSound);
      triggerFireworks();
      setTimeout(() => {
        setIsGameCompleted(true);
      }, 5000);
      praiseSoundAudio.play();
    }
  };

  const handleStartGame = () => {
    setButtonDisabled(true);
    setIsInteractionBlocked(true);
    const instructionAudio = startInstructionAudioRef.current;
    instructionAudio.play();

    instructionAudio.onended = () => {
      if (audioRef.current) {
        audioRef.current.play();
      }
      if (backgroundAudioRef.current) {
        backgroundAudioRef.current.play();
      }
      setButtonDisabled(false);
    };

    setIsButtonVisible(false);
  };

  useEffect(() => {
    document.body.style.backgroundColor = "rgba(252, 229, 170, 1)";
    return () => {
      if (startInstructionAudioRef.current) {
        startInstructionAudioRef.current.pause();
        startInstructionAudioRef.current.currentTime = 0;
      }

      if (backgroundAudioRef.current) {
        backgroundAudioRef.current.pause();
        backgroundAudioRef.current.currentTime = 0;
      }

      document.body.style.backgroundColor = "";
    };
  }, []);

  return (
    <DndProvider backend={HTML5Backend}>
      {isGameCompleted && <EndGameOptions />}

      <div className={`container ${styles.someOtherClass}`}>
        <div
          className={styles.backgroundContainer}
          style={{
            pointerEvents: isInteractionBlocked ? "none" : "auto",
          }}
        >
          <h3 className={styles.header}>{currentTask.question}</h3>
          <div
            style={{
              opacity: imageOpacity,
              transition: "opacity 0.5s ease-in-out",
            }}
            className={styles.bearAndOneContainer}
          >
            {currentTask.images.map((img, index) => {
              if (currentTaskIndex === 2) {
                return (
                  <div
                    key={index}
                    className={styles.garageContainer}
                    onClick={() => handleHouseClick(index)}
                  >
                    <img src={img.src} alt={img.alt} className={img.class} />
                    {clickedHouses.includes(index) && (
                      <div className={styles.number}>
                        {clickedHouses.indexOf(index) + 1}
                      </div>
                    )}
                  </div>
                );
              }

              return (
                <img
                  key={index}
                  src={img.src}
                  alt={img.alt}
                  className={`${img.class}`}
                  style={{ display: "block" }}
                />
              );
            })}
          </div>
        </div>
        {currentTaskIndex === 3 && (
          <div className={styles.task3Container}>
            <div className={styles.garageCarRow}>
              <DropTarget
                id="garage0"
                onDrop={(item) => handleDrop(item.id, "garage0")}
                className={styles.garageThirdContainer}
              >
                <img
                  src={garageSmall}
                  alt="домик 1"
                  className={styles.garageSmallLastTask}
                />
                {carsInGarages["garage0"] && (
                  <div className={styles.number}>
                    {carsInGarages["garage0"]}
                  </div>
                )}
              </DropTarget>
              {!carsInGarages["garage0"] && !carsRemoved.includes("car0") && (
                <DraggableItem
                  id="car0"
                  src={car}
                  alt="машинка 1"
                  className={styles.carLastTask}
                />
              )}
              {carsRemoved.includes("car0") && (
                <div className={styles.figure}>{order.indexOf("car0") + 1}</div>
              )}
            </div>
            <div className={styles.garageCarRow}>
              <DropTarget
                id="garage1"
                onDrop={(item) => handleDrop(item.id, "garage1")}
                className={styles.garageThirdContainer}
              >
                <img
                  src={garageSmall}
                  alt="домик 2"
                  className={styles.garageSmallLastTask}
                />
                {carsInGarages["garage1"] && (
                  <div className={styles.number}>
                    {carsInGarages["garage1"]}
                  </div>
                )}
              </DropTarget>
              {!carsInGarages["garage1"] && !carsRemoved.includes("car1") && (
                <DraggableItem
                  id="car1"
                  src={car}
                  alt="машинка 2"
                  className={styles.carLastTask}
                />
              )}
              {carsRemoved.includes("car1") && (
                <div className={styles.figure}>{order.indexOf("car1") + 1}</div>
              )}
            </div>
          </div>
        )}
        {currentTaskIndex < tasks.length - 1 ? (
          <button
            className={styles.button}
            onClick={handleNextTask}
            disabled={buttonDisabled}
          >
            {buttonText}
          </button>
        ) : (
          buttonText === "Послушать" && (
            <button
              className={styles.button}
              onClick={() => {
                playSound();
                setButtonText("");
              }}
              disabled={buttonDisabled}
            >
              {buttonText}
            </button>
          )
        )}
        <div className={styles.fireworksContainer}></div>
      </div>
      <audio ref={audioRef} />
      <audio ref={backgroundAudioRef} src={backgroundSound} loop />
      {isButtonVisible && (
        <div className={styles.darkOverlay}>
          <button className={styles.buttonStartGame} onClick={handleStartGame}>
            Начать игру
          </button>
        </div>
      )}
    </DndProvider>
  );
};

export default MathFigureTwoGame;
