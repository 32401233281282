import { useParams } from "react-router-dom";
import NotFound from "../not-found/not-found";
import GameLoader from "../loader/game-loader";
import RabbitGame from "../games-folder/rabbit-game/rabbit-game";
import AssociationsGame from "../games-folder/associations/associations";
import FrogGame from "../games-folder/frogs-game/frogs-game";
import FishGame from "../games-folder/fish-game/fish-game";
import BiggerSmallerGame from "../games-folder/bigger-smaller/bigger-smaller-game";
import SeasonsGame from "../games-folder/seasons-game/seasons-game";
import ArtistMistakesGame from "../games-folder/artist-mistakes/artist-mistakes-game";
import FindInRoomGame from "../games-folder/find-in-room-game/find-in-room";
import AnimalsInForestGame from "../games-folder/animals-in-forest/animals-in-forest";
import AnimalsHotCountriesGame from "../games-folder/animals-hot-countries/animals-hot-countries";
import GuessActionGame from "../games-folder/guess-action/guess-action";
import AnimalsAtFarm from "../games-folder/animals-farm/animals-farm";
import FindInParkGame from "../games-folder/find-in-park/find-in-park";
import DinosaurGame from "../games-folder/dinosaur/dinosaur";
import HomeAppliancesGame from "../games-folder/home-appliances/home-appliances";
import AnimalsSoundsGame from "../games-folder/animals-sounds/animals-sounds";
import CountrysideGame from "../games-folder/countryside/countryside";
import StreerSoundsGame from "../games-folder/street-sounds/street-sounds";
import TransportSoundsGame from "../games-folder/transport-sounds/transport-sounds";
import NatureSoundsGame from "../games-folder/nature-sounds/nature-sounds";
import EmotionsSoundsGame from "../games-folder/emotions-sounds/emotions-sounds";
import BirdsSoundsGame from "../games-folder/birds-sounds/birds-sounds";
import MusicalInstrumentsSoundsGames from "../games-folder/musical-instruments-sounds/musical-instruments-sounds";
import InsectsSoundsGame from "../games-folder/insects-sounds/insects-sounds";
import AnimalsSoundsTutorial from "../games-folder/tutorials/animals-sounds-tutorial/animals-sounds-tutorial";
import HomeAppliancesTutorial from "../games-folder/tutorials/home-appliances-tutorial/home-appliances-tutorial";
import CountrysideTutorial from "../games-folder/tutorials/countryside-tutorial/countryside-tutorial";
import StreetSoundsTutorial from "../games-folder/tutorials/street-sounds-tutorial/street-sounds-tutorial";
import TransportSoundsTutorial from "../games-folder/tutorials/transport-sounds-tutorial/transport-sounds-tutorial";
import NatureSoundsTutorial from "../games-folder/tutorials/nature-sounds-tutorial/nature-sounds-tutorial";
import EmotionsSoundsTutorial from "../games-folder/tutorials/emotions-sounds-tutorial/emotions-sounds-tutorial";
import BirdsSoundsTutorial from "../games-folder/tutorials/birds-sounds-tutorial/birds-sounds-tutorial";
import MusicalInstrumentsSoundsTutorial from "../games-folder/tutorials/musical-instruments-sounds-tutorial/musical-instruments-sounds-tutorial";
import InsectsSoundsTutorial from "../games-folder/tutorials/insects-sounds-tutorial/insects-sounds-tutorial";
import MathFigureOneGame from "../games-folder/math-one/math-one";
import MathFigureTwoGame from "../games-folder/math-two/math-two";
import MathFigureThreeGame from "../games-folder/math-three/math-three";
import MathFigureFourGame from "../games-folder/math-four/math-four";
import MathFigureFiveGame from "../games-folder/math-five/math-five";
import MathFigureSixGame from "../games-folder/math-six/math-six";
import MathFigureSevenGame from "../games-folder/math-seven/math-seven";
import MathFigureEightGame from "../games-folder/math-eight/math-eight";
import MathFigureNineGame from "../games-folder/math-nine/math-nine";
import MathFigureTenGame from "../games-folder/math-ten/math-ten";
import AngryGoatGame from "../games-folder/angry-goat/angry-goat";
import MaigcLampsGame from "../games-folder/magic-lamps/magic-lamps";
import BumbleBeeGame from "../games-folder/bumblebee/bumblebee";
import WhatIsInSnakesHoleGame from "../games-folder/snakes-hole/snakes-hole";
import DatingWithZhuzhaMain from "../games-folder/dating-with-zhuzha/dating-with-zhuzha-main";
import AcornWithSurpriseGame from "../games-folder/acorn-with-surprise/acorn-with-surprise";
import PickingFruitsGame from "../games-folder/picking-fruits/picking-fruits";
import WhatOwlFoundInForestGame from "../games-folder/what-owl-found-in-forest/owl-found-in-forest";
import StudyingCircleGame from "../games-folder/studying-circle/studying-circle";
import StudyingSquareGame from "../games-folder/studying-square/studying-square";
import StudyingTriangleGame from "../games-folder/studying-triangle/studying-triangle";
import StudyingRectangleGame from "../games-folder/studying-rectangle/studying-rectangle";
import StudyingRhombGame from "../games-folder/studying-rhomb/studying-rhomb";
import StudyingOvalGame from "../games-folder/studying-oval/studying-oval";

const GameRoutes = () => {
  const { gameId } = useParams();
  // console.log("Current gameId:", gameId);

  switch (gameId) {
    case "rabbit":
      return (
        <GameLoader>
          <RabbitGame />
        </GameLoader>
      );
    case "associations":
      return (
        <GameLoader>
          <AssociationsGame />
        </GameLoader>
      );
    case "frogs":
      return (
        <GameLoader>
          <FrogGame />
        </GameLoader>
      );

    case "fish":
      return (
        <GameLoader>
          <FishGame />
        </GameLoader>
      );

    case "smaller-bigger":
      return (
        <GameLoader>
          <BiggerSmallerGame />
        </GameLoader>
      );
    case "seasons":
      return (
        <GameLoader>
          <SeasonsGame />
        </GameLoader>
      );
    case "artist-mistakes":
      return (
        <GameLoader>
          <ArtistMistakesGame />
        </GameLoader>
      );
    case "find-in-room":
      return (
        <GameLoader>
          <FindInRoomGame />
        </GameLoader>
      );
    case "animals-in-forest":
      return (
        <GameLoader>
          <AnimalsInForestGame />
        </GameLoader>
      );
    case "animals-of-hot-countries":
      return (
        <GameLoader>
          <AnimalsHotCountriesGame />
        </GameLoader>
      );
    case "guess-action":
      return (
        <GameLoader>
          <GuessActionGame />
        </GameLoader>
      );
    case "animals-at-farm":
      return (
        <GameLoader>
          <AnimalsAtFarm />
        </GameLoader>
      );
    case "find-in-the-park":
      return (
        <GameLoader>
          <FindInParkGame />
        </GameLoader>
      );
    case "dinosaur":
      return (
        <GameLoader>
          <DinosaurGame />
        </GameLoader>
      );
    case "home-appliances":
      return (
        <GameLoader>
          <HomeAppliancesGame />
        </GameLoader>
      );
    case "animals-sounds":
      return (
        <GameLoader>
          <AnimalsSoundsGame />
        </GameLoader>
      );
    case "countryside-sounds":
      return (
        <GameLoader>
          <CountrysideGame />
        </GameLoader>
      );
    case "street-sounds":
      return (
        <GameLoader>
          <StreerSoundsGame />
        </GameLoader>
      );
    case "transport-sounds":
      return (
        <GameLoader>
          <TransportSoundsGame />
        </GameLoader>
      );
    case "nature-sounds":
      return (
        <GameLoader>
          <NatureSoundsGame />
        </GameLoader>
      );
    case "emotions-sounds":
      return (
        <GameLoader>
          <EmotionsSoundsGame />
        </GameLoader>
      );
    case "birds-sounds":
      return (
        <GameLoader>
          <BirdsSoundsGame />
        </GameLoader>
      );
    case "musical-instruments-sounds":
      return (
        <GameLoader>
          <MusicalInstrumentsSoundsGames />
        </GameLoader>
      );
    case "insects-sounds":
      return (
        <GameLoader>
          <InsectsSoundsGame />
        </GameLoader>
      );
    case "math-figure-one":
      return (
        <GameLoader>
          <MathFigureOneGame />
        </GameLoader>
      );
    case "math-figure-two":
      return (
        <GameLoader>
          <MathFigureTwoGame />
        </GameLoader>
      );
    case "math-figure-three":
      return (
        <GameLoader>
          <MathFigureThreeGame />
        </GameLoader>
      );
    case "math-figure-four":
      return (
        <GameLoader>
          <MathFigureFourGame />
        </GameLoader>
      );
    case "math-figure-five":
      return (
        <GameLoader>
          <MathFigureFiveGame />
        </GameLoader>
      );
    case "math-figure-six":
      return (
        <GameLoader>
          <MathFigureSixGame />
        </GameLoader>
      );
    case "math-figure-seven":
      return (
        <GameLoader>
          <MathFigureSevenGame />
        </GameLoader>
      );
    case "math-figure-eight":
      return (
        <GameLoader>
          <MathFigureEightGame />
        </GameLoader>
      );
    case "math-figure-nine":
      return (
        <GameLoader>
          <MathFigureNineGame />
        </GameLoader>
      );
    case "math-figure-ten":
      return (
        <GameLoader>
          <MathFigureTenGame />
        </GameLoader>
      );
    case "angry-goat":
      return (
        <GameLoader>
          <AngryGoatGame />
        </GameLoader>
      );
    case "magic-lamps":
      return (
        <GameLoader>
          <MaigcLampsGame />
        </GameLoader>
      );
    case "help-bumblebee-eat":
      return (
        <GameLoader>
          <BumbleBeeGame />
        </GameLoader>
      );
    case "what-in-snakes-hole":
      return (
        <GameLoader>
          <WhatIsInSnakesHoleGame />
        </GameLoader>
      );
    case "dating-with-zhuzha":
      return (
        <GameLoader>
          <DatingWithZhuzhaMain />
        </GameLoader>
      );
    case "acorn-with-surprise":
      return (
        <GameLoader>
          <AcornWithSurpriseGame />
        </GameLoader>
      );
    case "picking-fruits":
      return (
        <GameLoader>
          <PickingFruitsGame />
        </GameLoader>
      );
    case "what-owl-found-in-forest":
      return (
        <GameLoader>
          <WhatOwlFoundInForestGame />
        </GameLoader>
      );
    case "studying-circle":
      return (
        <GameLoader>
          <StudyingCircleGame />
        </GameLoader>
      );
    case "studying-square":
      return (
        <GameLoader>
          <StudyingSquareGame />
        </GameLoader>
      );
    case "studying-triangle":
      return (
        <GameLoader>
          <StudyingTriangleGame />
        </GameLoader>
      );
    case "studying-rectangle":
      return (
        <GameLoader>
          <StudyingRectangleGame />
        </GameLoader>
      );

    case "studying-rhomb":
      return (
        <GameLoader>
          <StudyingRhombGame />
        </GameLoader>
      );
    // case "studying-oval":
    //   return <StudyingOvalGame />;
    default:
      return <NotFound />;
  }
};

export default GameRoutes;
