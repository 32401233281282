import React, { useState, useEffect, useRef } from "react";
import useDynamicHeight from "../../../hooks/useDynamicHeight";
import EndGameOptions from "../../end-game-options/end-game-options";
import { gsap } from "gsap";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import DraggableItem from "./DraggableItem";
import DropTarget from "./DropTarget";
import styles from "./studying-rectangle.module.css";
import rectangle from "./images/rectangle.png";
import railStation from "./images/wallperTrail.png";
import loko from "./images/loko.png";
import lokoTwo from "./images/lokoTwo.png";
import circleCarriage from "./images/circleCarriage.png";
import ovalCarriage from "./images/ovalCarriage.png";
import triangleCarriage from "./images/triangleCarriage.png";
import squareCarriage from "./images/squareCarriage.png";
import rectangleCarriage from "./images/rectangleCarriage.png";
import blueCarriage from "./images/blueCarriage.png";
import greenCarriage from "./images/greenCarriage.png";
import redCarriage from "./images/redCarriage.png";
import orangeCarriage from "./images/orangeCarriage.png";
import ball from "./images/ball.png";
import carpet from "./images/carpet.png";
import envelope from "./images/envelope.png";
import shelf from "./images/shelf.png";
import spinning from "./images/spinning.png";
import sun from "./images/sun.png";
import wallperCity from "./images/wallperCity.png";
import houseOne from "./images/houseOne.png";
import houseTwo from "./images/houseTwo.png";
import houseThree from "./images/houseThree.png";
import path from "./images/path.svg";
import catDog from "./images/catDog.png";
import dogCow from "./images/dogCow.png";
import cowGiraf from "./images/cowGiraf.png";
import girafGiraf from "./images/girafGiraf.png";
import hedgehogGiraf from "./images/hedgehogGiraf.png";
import wolfHedgehog from "./images/wolfHedgehog.png";
import wolfHip from "./images/wolfHip.png";
import hipCat from "./images/hipCat.png";

import backgroundSound from "./sounds/backgroundSound.mp3";
import correctAnswerSound from "./sounds/correctAnswer.mp3";
import incorrectAnswerSound from "./sounds/no.mp3";
import drawSound from "./sounds/draw.mp3";

import taskZeroSound from "./sounds/taskZero.mp3";
import taskTwoSound from "./sounds/taskTwo.mp3";
import taskThreeSound from "./sounds/taskThree.mp3";
import taskFourSound from "./sounds/taskFour.mp3";
import taskFiveSound from "./sounds/taskFive.mp3";

import praiseSound from "./sounds/praise.mp3";

const colors = [
  "#FF0000",
  "#FF7F00",
  "#FFFF00",
  "#00FF00",
  "#0000FF",
  "#4B0082",
  "#8B00FF",
];

const triggerFireworks = () => {
  const fireworksContainer = document.querySelector(
    `.${styles.fireworksContainer}`
  );
  if (fireworksContainer) {
    for (let i = 0; i < 10; i++) {
      setTimeout(() => createFirework(fireworksContainer), i * 500);
    }
  } else {
    console.error("Fireworks container not found");
  }
};

const createFirework = (container) => {
  const firework = document.createElement("div");
  firework.className = styles.firework;
  firework.style.left = `${Math.random() * 100}%`;
  firework.style.top = `${Math.random() * 100}%`;
  container.appendChild(firework);

  const particles = [];
  for (let i = 0; i < 30; i++) {
    const particle = document.createElement("div");
    particle.className = styles.particle;
    particle.style.background =
      colors[Math.floor(Math.random() * colors.length)];
    firework.appendChild(particle);
    particles.push(particle);
  }

  const tl = gsap.timeline({
    onComplete: () => {
      firework.remove();
    },
  });

  particles.forEach((particle) => {
    const angle = Math.random() * Math.PI * 2;
    const distance = Math.random() * 200 + 50;
    tl.fromTo(
      particle,
      { x: 0, y: 0, opacity: 1 },
      {
        x: Math.cos(angle) * distance,
        y: Math.sin(angle) * distance,
        opacity: 0,
        duration: 2,
        ease: "power2.out",
      },
      0
    );
  });
};

const StudyingRectangleGame = () => {
  useDynamicHeight();
  const [isGameCompleted, setIsGameCompleted] = useState(false);
  const [currentTaskIndex, setCurrentTaskIndex] = useState(0);
  const [imageOpacity, setImageOpacity] = useState(1);
  const [buttonText, setButtonText] = useState("Послушать");
  const [buttonDisabled, setButtonDisabled] = useState(false);

  const [isInteractionBlocked, setIsInteractionBlocked] = useState(true);
  const [coloredWagons, setColoredWagons] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [foundItemsCount, setFoundItemsCount] = useState(0);
  const [droppedHouses, setDroppedHouses] = useState([]);
  const [currentDominoIndex, setCurrentDominoIndex] = useState(0);
  const [selectedDominoes, setSelectedDominoes] = useState([]);

  const audioRef = useRef(null);
  const taskZeroSoundRef = useRef(new Audio(taskZeroSound));
  const taskTwoSoundRef = useRef(new Audio(taskTwoSound));
  const taskThreeSoundRef = useRef(new Audio(taskThreeSound));
  const taskFourSoundRef = useRef(new Audio(taskFourSound));
  const taskFiveSoundRef = useRef(new Audio(taskFiveSound));

  const backgroundSoundRef = useRef(new Audio(backgroundSound));
  const drawSoundRef = useRef(new Audio(drawSound));
  const praiseSoundRef = useRef(new Audio(praiseSound));
  const correctSoundRef = useRef(new Audio(correctAnswerSound));
  const incorrectSoundRef = useRef(new Audio(incorrectAnswerSound));

  useEffect(() => {
    document.body.style.backgroundColor = "rgba(252, 229, 170, 1)";
  }, [currentTaskIndex]);

  const tasks = [
    {
      question: "Это прямоугольник",
      images: [
        { src: rectangle, alt: "прямоугольник", class: styles.rectangle },
      ],
      audioRef: taskZeroSoundRef,
    },
    {
      question: "Раскрась прямоугольные вагоны, нажимая на них",
      images: [
        {
          src: circleCarriage,
          alt: "вагон",
          class: styles.circleCarriageOne,
          id: "wagon51",
        },
        {
          src: circleCarriage,
          alt: "вагон",
          class: styles.circleCarriageTwo,
          id: "wagon52",
        },
        {
          src: ovalCarriage,
          alt: "вагон",
          class: styles.ovalCarriageOne,
          id: "wagon53",
        },
        {
          src: ovalCarriage,
          alt: "вагон",
          class: styles.ovalCarriageTwo,
          id: "wagon54",
        },
        {
          src: ovalCarriage,
          alt: "вагон",
          class: styles.ovalCarriageThree,
          id: "wagon55",
        },
        {
          src: ovalCarriage,
          alt: "вагон",
          class: styles.ovalCarriageFour,
          id: "wagon56",
        },
        {
          src: squareCarriage,
          alt: "вагон",
          class: styles.squareCarriageOne,
          id: "wagon57",
        },
        {
          src: squareCarriage,
          alt: "вагон",
          class: styles.squareCarriageTwo,
          id: "wagon58",
        },
        {
          src: rectangleCarriage,
          alt: "вагон",
          class: styles.rectangleCarriageOne,
          id: "wagon1",
        },
        {
          src: rectangleCarriage,
          alt: "вагон",
          class: styles.rectangleCarriageTwo,
          id: "wagon2",
        },
        {
          src: rectangleCarriage,
          alt: "вагон",
          class: styles.rectangleCarriageThree,
          id: "wagon3",
        },
        {
          src: rectangleCarriage,
          alt: "вагон",
          class: styles.rectangleCarriageFour,
          id: "wagon4",
        },
      ],
      audioRef: taskTwoSoundRef,
    },
    {
      question: "Найди предметы похожие на прямоугольник",
      images: [
        {
          src: ball,
          alt: "мяч",
          class: styles.ball,
          isRectangle: false,
        },
        {
          src: carpet,
          alt: "ковер",
          class: styles.carpet,
          isRectangle: true,
        },
        {
          src: envelope,
          alt: "конверт",
          class: styles.envelope,
          isRectangle: true,
        },
        {
          src: shelf,
          alt: "полка",
          class: styles.shelf,
          isRectangle: true,
        },
        {
          src: spinning,
          alt: "вертится",
          class: styles.spinning,
          isRectangle: false,
        },
        {
          src: sun,
          alt: "солнце",
          class: styles.sun,
          isRectangle: false,
        },
      ],
      audioRef: taskThreeSoundRef,
    },
    {
      question: "Найди места для домиков и расставь их",
      images: [
        { src: houseOne, alt: "дом", class: styles.houseOne, id: "house1" },
        { src: houseTwo, alt: "дом", class: styles.houseTwo, id: "house2" },
        { src: houseThree, alt: "дом", class: styles.houseThree, id: "house3" },
      ],
      audioRef: taskFourSoundRef,
    },

    {
      question:
        "Собери прямоугольник по принципу домино. Ставь картинку к картинке!",
      images: [
        { src: catDog, alt: "домино", class: styles.catDog, id: "catDog" },
        { src: dogCow, alt: "домино", class: styles.dogCow, id: "dogCow" },
        {
          src: cowGiraf,
          alt: "домино",
          class: styles.cowGiraf,
          id: "cowGiraf",
        },

        {
          src: girafGiraf,
          alt: "домино",
          class: styles.girafGiraf,
          id: "girafGiraf",
        },

        {
          src: hedgehogGiraf,
          alt: "домино",
          class: styles.hedgehogGiraf,
          id: "hedgehogGiraf",
        },

        {
          src: wolfHedgehog,
          alt: "домино",
          class: styles.wolfHedgehog,
          id: "wolfHedgehog",
        },

        { src: wolfHip, alt: "домино", class: styles.wolfHip, id: "wolfHip" },
        { src: hipCat, alt: "домино", class: styles.hipCat, id: "hipCat" },
      ],
      audioRef: taskFiveSoundRef,
    },
  ];

  const currentTask = tasks[currentTaskIndex];

  const playSound = () => {
    const taskAudioRef = currentTask.audioRef;

    if (taskAudioRef && taskAudioRef.current) {
      setIsInteractionBlocked(true);
      setButtonDisabled(true);

      taskAudioRef.current.play().catch((error) => {
        console.error("Playback failed:", error);
        setButtonDisabled(false);
        setIsInteractionBlocked(false);
      });

      taskAudioRef.current.onended = () => {
        setIsInteractionBlocked(false);
        setButtonDisabled(false);
        setButtonText("Далее");
      };
    }
  };

  useEffect(() => {
    if (currentTaskIndex === 4) {
      setTimeout(animateDomino, 500);
    }
  }, [currentTaskIndex]);

  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.onended = () => {
        setButtonDisabled(false);
        setButtonText("Далее");
      };
    }
  }, [currentTaskIndex]);

  useEffect(() => {
    // Задача 1: проверяем, раскрашены ли все вагоны
    if (currentTaskIndex === 1) {
      const allWagonsColored = Object.keys(coloredWagons).length === 4;
      setButtonDisabled(!allWagonsColored && buttonText === "Далее");
    }
  }, [coloredWagons, currentTaskIndex, buttonText]);

  useEffect(() => {
    // Задача 2: проверяем, выбраны ли все три предмета, похожие на прямоугольник
    if (currentTaskIndex === 2) {
      const allItemsSelected = selectedItems.length >= 3;
      setButtonDisabled(!allItemsSelected && buttonText === "Далее");
    }
  }, [selectedItems, currentTaskIndex, buttonText]);

  useEffect(() => {
    // Задача 3: проверяем, выбраны ли все дома
    if (currentTaskIndex === 3) {
      const allHousesSelected =
        droppedHouses.length >= currentTask.images.length;
      setButtonDisabled(!allHousesSelected && buttonText === "Далее");
    }
  }, [droppedHouses, currentTaskIndex, buttonText, currentTask.images.length]);

  const handleNextTask = () => {
    if (buttonText === "Послушать") {
      setIsInteractionBlocked(true);
      playSound();
    } else if (buttonText === "Далее" && !buttonDisabled) {
      setImageOpacity(0);
      setTimeout(() => {
        if (currentTaskIndex + 1 < tasks.length) {
          setCurrentTaskIndex((prevIndex) => prevIndex + 1);
          setButtonText("Послушать");
          setButtonDisabled(false);
          setIsInteractionBlocked(true);
          setTimeout(() => {
            backgroundSoundRef.current.play();
          }, 10);
        }
        setImageOpacity(1);
      }, 500);
    }
  };

  const handleWagonClick = (id) => {
    const colorMapping = {
      wagon1: blueCarriage,
      wagon2: greenCarriage,
      wagon3: redCarriage,
      wagon4: orangeCarriage,
    };

    setColoredWagons((prev) => {
      const newColoredWagons = { ...prev };

      if (colorMapping[id]) {
        // Если вагон правильный
        if (!newColoredWagons[id]) {
          // Если вагон еще не был раскрашен
          newColoredWagons[id] = colorMapping[id];
          drawSoundRef.current.play();
        } else {
          incorrectSoundRef.current.play();
        }
      } else {
        // Если вагон неправильный
        incorrectSoundRef.current.play();
      }

      // Проверяем, раскрашены ли все вагоны
      const allWagonsColored = Object.keys(newColoredWagons).length === 4;
      setButtonDisabled(!allWagonsColored);
      setButtonText(allWagonsColored ? "Далее" : "Далее");

      return newColoredWagons;
    });
  };

  const handleItemClick = (index, isRectangle) => {
    if (isRectangle && !selectedItems.includes(index)) {
      const newSelectedItems = [...selectedItems, index];
      setSelectedItems(newSelectedItems);
      setFoundItemsCount(newSelectedItems.length);
      correctSoundRef.current.play();
      if (newSelectedItems.length === 3) {
        setButtonDisabled(false);
        setButtonText("Далее");
      }
    } else {
      incorrectSoundRef.current.play();
    }
  };

  const handleDrop = (item) => {
    const coordinates = {
      house1: { top: 40, left: 2 },
      house2: { top: 40, left: 26 },
      house3: { top: 42, left: 53 },
    };

    const newHousePosition = {
      ...item,
      top: coordinates[item.id].top,
      left: coordinates[item.id].left,
    };

    setDroppedHouses((prev) => {
      const updatedHouses = [...prev, newHousePosition];

      const allHousesDropped = updatedHouses.length >= 3;
      if (allHousesDropped) {
        correctSoundRef.current.play();
        setButtonDisabled(false);
        setButtonText("Далее");
      } else {
        setButtonDisabled(true);
        setButtonText("Далее");
      }

      return updatedHouses;
    });
  };

  const dominoOrder = [
    "catDog",
    "dogCow",
    "cowGiraf",
    "girafGiraf",
    "hedgehogGiraf",
    "wolfHedgehog",
    "wolfHip",
    "hipCat",
  ];

  const dominoPositions = [
    { top: "0%", left: "12%" },
    { top: "0%", left: "37%" },
    { top: "0%", left: "61%" },
    { top: "6%", left: "86%" }, // 53
    { top: "62%", left: "61%" },
    { top: "62%", left: "37%" },
    { top: "62%", left: "13%" },
    { top: "6%", left: "0%" },
  ];
  const animateDomino = () => {
    const firstDomino = tasks[4].images.find(
      (img) => img.id === dominoOrder[0]
    );
    const newDomino = {
      ...firstDomino,
      top: dominoPositions[0].top,
      left: dominoPositions[0].left,
    };

    gsap.fromTo(
      newDomino,
      { top: "0%", left: "0%" },
      {
        top: dominoPositions[0].top,
        left: dominoPositions[0].left,
        duration: 1,
        onComplete: () => {
          setSelectedDominoes((prevDominoes) => [...prevDominoes, newDomino]);
          setCurrentDominoIndex((prevIndex) => prevIndex + 1);
        },
      }
    );
  };

  const handleDominoDrop = (item) => {
    if (item.id === dominoOrder[currentDominoIndex]) {
      correctSoundRef.current.play();
      const newDomino = {
        ...item,
        top: dominoPositions[currentDominoIndex].top,
        left: dominoPositions[currentDominoIndex].left,
      };
      setSelectedDominoes((prevDominoes) => [...prevDominoes, newDomino]);
      setCurrentDominoIndex((prevIndex) => prevIndex + 1);

      if (currentDominoIndex + 1 >= dominoOrder.length) {
        setButtonDisabled(false);
        setButtonText("Далее");
        setTimeout(() => {
          praiseSoundRef.current.play();

          setTimeout(() => {
            triggerFireworks();
            setTimeout(() => {
              setIsGameCompleted(true);
            }, 3000);
          }, 2000);
        }, 500);
      }
    } else {
      incorrectSoundRef.current.play();
    }
  };

  useEffect(() => {
    return () => {
      if (taskZeroSoundRef.current) {
        taskZeroSoundRef.current.pause();
        taskZeroSoundRef.current.currentTime = 0;
      }

      if (taskTwoSoundRef.current) {
        taskTwoSoundRef.current.pause();
        taskTwoSoundRef.current.currentTime = 0;
      }

      if (taskThreeSoundRef.current) {
        taskThreeSoundRef.current.pause();
        taskThreeSoundRef.current.currentTime = 0;
      }

      if (taskFourSoundRef.current) {
        taskFourSoundRef.current.pause();
        taskFourSoundRef.current.currentTime = 0;
      }

      if (taskFiveSoundRef.current) {
        taskFiveSoundRef.current.pause();
        taskFiveSoundRef.current.currentTime = 0;
      }

      if (praiseSoundRef.current) {
        praiseSoundRef.current.pause();
        praiseSoundRef.current.currentTime = 0;
      }

      if (backgroundSoundRef.current) {
        backgroundSoundRef.current.pause();
        backgroundSoundRef.current.currentTime = 0;
      }

      gsap.globalTimeline.clear();
    };
  }, [currentTaskIndex]);

  return (
    <DndProvider backend={HTML5Backend}>
      {isGameCompleted && <EndGameOptions />}
      <div className={`container ${styles.someOtherClass}`}>
        <div
          className={styles.backgroundContainer}
          style={{
            pointerEvents: isInteractionBlocked ? "none" : "auto",
          }}
        >
          <h3 className={styles.header}>{currentTask.question}</h3>
          <div
            style={{
              opacity: imageOpacity,
              transition: "opacity 0.5s ease-in-out",
            }}
            className={styles.taskContainer}
          >
            {currentTaskIndex === 0 &&
              currentTask.images.map((img, index) => (
                <img
                  key={index}
                  src={img.src}
                  alt={img.alt}
                  className={img.class}
                />
              ))}
            {currentTaskIndex === 1 && (
              <div className={styles.railStationContainer}>
                <img
                  src={railStation}
                  alt="вокзал"
                  className={styles.railStation}
                />
                <img src={loko} alt="локомотив" className={styles.loko} />
                <img src={lokoTwo} alt="локомотив" className={styles.lokoTwo} />
                <div className={styles.wagonsContainer}>
                  {currentTask.images.map((img) => (
                    <img
                      key={img.id}
                      src={
                        coloredWagons[img.id] ? coloredWagons[img.id] : img.src
                      }
                      alt={img.alt}
                      className={img.class}
                      onClick={() => img.id && handleWagonClick(img.id)}
                    />
                  ))}
                </div>
              </div>
            )}
            {currentTaskIndex === 2 && (
              <div className={styles.secondTaskContainer}>
                <h4 className={styles.subHeader}>
                  Найдено: {foundItemsCount}/3
                </h4>
                <div className={styles.row}>
                  {currentTask.images.slice(0, 3).map((image, index) => (
                    <div
                      key={index}
                      className={`${styles.itemContainer} ${
                        selectedItems.includes(index) ? styles.selected : ""
                      }`}
                    >
                      <img
                        src={image.src}
                        alt={image.alt}
                        className={image.class}
                        onClick={() =>
                          handleItemClick(index, image.isRectangle)
                        }
                      />
                    </div>
                  ))}
                </div>
                <div className={styles.row}>
                  {currentTask.images.slice(3).map((image, index) => (
                    <div
                      key={index + 3}
                      className={`${styles.itemContainer} ${
                        selectedItems.includes(index + 3) ? styles.selected : ""
                      }`}
                    >
                      <img
                        src={image.src}
                        alt={image.alt}
                        className={image.class}
                        onClick={() =>
                          handleItemClick(index + 3, image.isRectangle)
                        }
                      />
                    </div>
                  ))}
                </div>
              </div>
            )}
            {currentTaskIndex === 3 && (
              <div className={styles.cityContainer}>
                <img
                  src={wallperCity}
                  alt="фон города"
                  className={styles.wallperCity}
                />
                <div className={styles.housesContainer}>
                  {currentTask.images
                    .filter(
                      (image) =>
                        !droppedHouses.some((house) => house.id === image.id)
                    )
                    .map((image, index) => (
                      <DraggableItem key={index} item={image} type="HOUSE" />
                    ))}
                </div>
                <div className={styles.dropZoneContainer}>
                  <DropTarget
                    accept="HOUSE"
                    onDrop={handleDrop}
                    className={styles.dropZone}
                  >
                    {droppedHouses.map((house, index) => (
                      <img
                        key={index}
                        src={house.src}
                        alt={house.alt}
                        className={styles.droppedHouse}
                        style={{
                          top: `${house.top}%`,
                          left: `${house.left}%`,
                        }}
                      />
                    ))}
                  </DropTarget>
                </div>
              </div>
            )}
            {currentTaskIndex === 4 && (
              <div className={styles.dominoContainer}>
                <div className={styles.pathContainer}>
                  <DropTarget
                    onDrop={handleDominoDrop}
                    accept={["DOMINO"]}
                    className={styles.dropZone}
                  >
                    <img src={path} alt="path" className={styles.path} />
                    <div className={styles.selectedDominoes}>
                      {selectedDominoes.map((domino, index) => (
                        <div
                          key={index}
                          className={`${styles.dominoWrapper} ${
                            domino.id === "girafGiraf" || domino.id === "hipCat"
                              ? styles.largeDomino
                              : ""
                          }`}
                          style={{
                            top: domino.top,
                            left: domino.left,
                            position: "absolute",
                          }}
                        >
                          <img
                            src={domino.src}
                            alt={domino.alt}
                            className={styles[domino.id] || styles.domino}
                            style={{
                              width: "100%",
                              height: "auto",
                            }}
                          />
                        </div>
                      ))}
                    </div>
                  </DropTarget>
                </div>
                <div className={styles.dominoesContainer}>
                  <div className={styles.row}>
                    {currentTask.images
                      .filter(
                        (image) =>
                          !selectedDominoes.some(
                            (domino) => domino.id === image.id
                          )
                      )
                      .filter((_, index) => index % 2 === 0)
                      .map((image, index) => (
                        <DraggableItem key={index} item={image} type="DOMINO" />
                      ))}
                  </div>
                  <div className={styles.row}>
                    {currentTask.images
                      .filter(
                        (image) =>
                          !selectedDominoes.some(
                            (domino) => domino.id === image.id
                          )
                      )
                      .filter((_, index) => index % 2 !== 0)
                      .map((image, index) => (
                        <DraggableItem key={index} item={image} type="DOMINO" />
                      ))}
                  </div>
                </div>
              </div>
            )}
            <div className={styles.fireworksContainer}></div>
          </div>
        </div>
        {currentTaskIndex < tasks.length - 1 ? (
          <button
            className={styles.button}
            onClick={handleNextTask}
            disabled={buttonDisabled}
          >
            {buttonText}
          </button>
        ) : (
          buttonText === "Послушать" && (
            <button
              className={styles.button}
              onClick={() => {
                playSound();
                setButtonText("");
              }}
              disabled={buttonDisabled}
            >
              {buttonText}
            </button>
          )
        )}
      </div>
      <audio ref={audioRef} />
      <audio ref={backgroundSoundRef} src={backgroundSound} loop />
    </DndProvider>
  );
};
export default StudyingRectangleGame;
