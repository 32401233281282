// Helper function to determine current environment
const isDev = process.env.NODE_ENV === "development";

const getBackendUrl = () => {
  if (isDev) {
    return process.env.REACT_APP_BACKEND_URL || "http://localhost:8082/api";
  } else {
    return window.env.REACT_APP_BACKEND_URL || "http://localhost:8082/api";
  }
};

export const config = {
  baseUrl: getBackendUrl(),
  headers: {
    "Content-Type": "application/json",
  },
};

const getRedirectUrl = () => {
  if (isDev) {
    return process.env.REACT_APP_REDIRECT_URL || "http://localhost:3000";
  } else {
    return window.env.REACT_APP_REDIRECT_URL || "https://edland.ru/";
  }
};

export const REDIRECT_URL = getRedirectUrl();
