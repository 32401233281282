import React, { useState, useEffect, useRef } from "react";
import useDynamicHeight from "../../../hooks/useDynamicHeight";
import EndGameOptions from "../../end-game-options/end-game-options";
import Confetti from "react-confetti";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import DraggableItem from "./DraggableItem";
import DropTarget from "./DropTarget";
import styles from "./math-seven.module.css";

import figureSeven from "./images/figureSeven.png";
import petal from "./images/petal.png";
import leftPetal from "./images/leftPetal.png";
import rightPetal from "./images/rightPetal.png";
import brich from "./images/brich.png";
import wallperBrich from "./images/wallperBrich.png";
import puzzleOne from "./images/1.png";
import puzzleTwo from "./images/2.png";
import puzzleThree from "./images/3.png";
import puzzleFour from "./images/4.png";

import startInstructionSound from "./sounds/startInstruction.mp3";
import backgroundSound from "./sounds/backgroundSound.mp3";
import taskZeroSound from "./sounds/taskZero.mp3";
import taskTwoSound from "./sounds/taskTwo.mp3";
import taskThreeSound from "./sounds/taskThree.mp3";
import taskFourSound from "./sounds/taskFour.mp3";
import puzzleSound from "./sounds/puzzle.mp3";
import leaveSound from "./sounds/leave.mp3";
import finalSound from "./sounds/finalSound.mp3";
import praiseSound from "./sounds/praise.mp3";

const MathFigureSevenGame = () => {
  useDynamicHeight();
  const [isGameCompleted, setIsGameCompleted] = useState(false);
  const [currentTaskIndex, setCurrentTaskIndex] = useState(0);
  const [imageOpacity, setImageOpacity] = useState(1);
  const [buttonText, setButtonText] = useState("Послушать");
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [isButtonVisible, setIsButtonVisible] = useState(true);
  const [isInteractionBlocked, setIsInteractionBlocked] = useState(true);
  const [showConfetti, setShowConfetti] = useState(false);
  const [leavesOnTree, setLeavesOnTree] = useState({
    leftLeaf1: false,
    leftLeaf2: false,
    leftLeaf3: false,
    rightLeaf1: false,
    rightLeaf2: false,
    rightLeaf3: false,
    rightLeaf4: false,
  });
  const [puzzleSolved, setPuzzleSolved] = useState(false);
  const [puzzles, setPuzzels] = useState([
    { id: "puzzle1", angle: 90, correctAngle: 0, fixed: false, src: puzzleOne },
    {
      id: "puzzle2",
      angle: 180,
      correctAngle: 0,
      fixed: false,
      src: puzzleTwo,
    },
    {
      id: "puzzle3",
      angle: 90,
      correctAngle: 0,
      fixed: false,
      src: puzzleThree,
    },
    {
      id: "puzzle4",
      angle: 180,
      correctAngle: 0,
      fixed: false,
      src: puzzleFour,
    },
  ]);

  const audioRef = useRef(null);
  const startInstructionAudioRef = useRef(new Audio(startInstructionSound));
  const backgroundAudioRef = useRef(new Audio(backgroundSound));
  const puzzleSoundRef = useRef(new Audio(puzzleSound));
  const finalSoundRef = useRef(new Audio(finalSound));
  const praiseSoundRef = useRef(new Audio(praiseSound));

  const tasks = [
    {
      question: "Это цифра семь",
      images: [
        { src: figureSeven, alt: "Цифра cемь", class: styles.figureSeven },
      ],
      audioSrc: taskZeroSound,
    },
    {
      question: "А это семь листочков",
      images: [
        {
          src: figureSeven,
          alt: "Цифра семь",
          class: styles.figureSevenWithPetals,
        },
        { src: petal, alt: "листок", class: styles.petal },
        { src: petal, alt: "листок", class: styles.petal },
        { src: petal, alt: "листок", class: styles.petal },
        { src: petal, alt: "листок", class: styles.petal },
        { src: petal, alt: "листок", class: styles.petal },
        { src: petal, alt: "листок", class: styles.petal },
        { src: petal, alt: "листок", class: styles.petal },
      ],
      audioSrc: taskTwoSound,
    },
    {
      question: "Прикрепи 7 листочков к березе",
      images: [
        { src: brich, alt: "береза", class: styles.brich, id: "tree" },
        {
          src: leftPetal,
          alt: "левый листок",
          class: styles.leftPetal,
          id: "leftLeaf1",
        },
        {
          src: leftPetal,
          alt: "левый листок",
          class: styles.leftPetal,
          id: "leftLeaf2",
        },
        {
          src: leftPetal,
          alt: "левый листок",
          class: styles.leftPetal,
          id: "leftLeaf3",
        },

        {
          src: rightPetal,
          alt: "правый листок",
          class: styles.rightPetal,
          id: "rightLeaf1",
        },
        {
          src: rightPetal,
          alt: "правый листок",
          class: styles.rightPetal,
          id: "rightLeaf2",
        },
        {
          src: rightPetal,
          alt: "правый листок",
          class: styles.rightPetal,
          id: "rightLeaf3",
        },
        {
          src: rightPetal,
          alt: "правый листок",
          class: styles.rightPetal,
          id: "rightLeaf4",
        },
      ],
      audioSrc: taskThreeSound,
    },
    {
      question: "Поверни пазлы так, чтобы получилась цифра семь",
      images: [
        { src: puzzleOne, alt: "пазл1", class: styles.puzzleOne },
        { src: puzzleTwo, alt: "пазл2", class: styles.puzzleTwo },
        { src: puzzleThree, alt: "пазл3", class: styles.puzzleThree },
        { src: puzzleFour, alt: "пазл4", class: styles.puzzleFour },
      ],
      audioSrc: taskFourSound,
    },
  ];

  const currentTask = tasks[currentTaskIndex];

  const playSound = () => {
    if (currentTask.audioSrc) {
      setIsInteractionBlocked(true);
      setButtonDisabled(true);

      const taskAudio = new Audio(currentTask.audioSrc);
      taskAudio.play().catch((error) => {
        console.error("Playback failed:", error);
        setButtonDisabled(false);
        setIsInteractionBlocked(false);
      });

      taskAudio.onended = () => {
        setButtonDisabled(false);
        setIsInteractionBlocked(false);

        if (currentTaskIndex === tasks.length - 1) {
          setButtonText("");
        } else {
          setButtonText("Далее");
        }
      };
    }
  };

  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.onended = () => {
        if (currentTaskIndex !== 2) {
          setButtonDisabled(false);
          setButtonText("Далее");
        }
      };
    }
  }, [currentTaskIndex]);

  const handleNextTask = () => {
    if (buttonText === "Послушать") {
      setIsInteractionBlocked(true); // Блокируем взаимодействие сразу после нажатия
      playSound(); // Воспроизводим звук для текущей задачи
    } else if (buttonText === "Далее" && !buttonDisabled) {
      setImageOpacity(0);
      setTimeout(() => {
        if (currentTaskIndex + 1 < tasks.length) {
          setCurrentTaskIndex(currentTaskIndex + 1);
          setButtonText("Послушать");
          setButtonDisabled(false);
          setIsInteractionBlocked(true); // Снова блокируем после перехода к следующему заданию
        }
        setImageOpacity(1);
      }, 500);
    }
  };

  const handleDrop = (itemId, targetId) => {
    if (targetId === "tree") {
      setLeavesOnTree((prev) => ({
        ...prev,
        [itemId]: true,
      }));

      const leafSoundAudio = new Audio(leaveSound);
      leafSoundAudio.play();
    }
  };
  useEffect(() => {
    if (currentTaskIndex === 2) {
      const allLeavesAttached = Object.values(leavesOnTree).every(
        (attached) => attached
      );
      setButtonDisabled(!allLeavesAttached && buttonText === "Далее");
    }
  }, [leavesOnTree, currentTaskIndex, buttonText]);

  const handleRotate = (puzzleId) => {
    setPuzzels((prevPuzzels) => {
      const updatedPuzzles = prevPuzzels.map((puzzle) => {
        if (puzzle.id === puzzleId && !puzzle.fixed) {
          const newAngle = (puzzle.angle + 90) % 360;
          const isCorrect = newAngle === puzzle.correctAngle;
          if (isCorrect) {
            puzzleSoundRef.current.play();
          }
          return { ...puzzle, angle: newAngle, fixed: isCorrect };
        }
        return puzzle;
      });

      const allCorrect = updatedPuzzles.every((puzzle) => puzzle.fixed);
      if (allCorrect && !puzzleSolved) {
        setPuzzleSolved(true);
        finalSoundRef.current.play();
        setShowConfetti(true);
        setTimeout(() => {
          setIsGameCompleted(true);
        }, 5000);
        finalSoundRef.current.onended = () => {
          praiseSoundRef.current.play();
        };
      }

      return updatedPuzzles;
    });
  };
  const handleStartGame = () => {
    setButtonDisabled(true);
    setIsInteractionBlocked(true);
    const instructionAudio = startInstructionAudioRef.current;
    instructionAudio.play();

    instructionAudio.onended = () => {
      if (audioRef.current) {
        audioRef.current.play();
      }
      if (backgroundAudioRef.current) {
        backgroundAudioRef.current.play();
      }
      setButtonDisabled(false);
    };

    setIsButtonVisible(false);
  };

  useEffect(() => {
    document.body.style.backgroundColor = "rgba(252, 229, 170, 1)";
    return () => {
      if (startInstructionAudioRef.current) {
        startInstructionAudioRef.current.pause();
        startInstructionAudioRef.current.currentTime = 0;
      }

      if (backgroundAudioRef.current) {
        backgroundAudioRef.current.pause();
        backgroundAudioRef.current.currentTime = 0;
      }

      document.body.style.backgroundColor = "";
    };
  }, []);
  return (
    <DndProvider backend={HTML5Backend}>
      {isGameCompleted && <EndGameOptions />}
      {showConfetti && (
        <Confetti
          numberOfPieces={500}
          angle={180}
          spread={120}
          origin={{ x: 1, y: 0.5 }}
        />
      )}
      <div className={`container ${styles.someOtherClass}`}>
        <div
          className={styles.backgroundContainer}
          style={{
            pointerEvents: isInteractionBlocked ? "none" : "auto",
          }}
        >
          <h3 className={styles.header}>{currentTask.question}</h3>
          <div
            style={{
              opacity: imageOpacity,
              transition: "opacity 0.5s ease-in-out",
            }}
            className={styles.taskContainer}
          >
            {currentTaskIndex === 0 &&
              currentTask.images.map((img, index) => (
                <img
                  key={index}
                  src={img.src}
                  alt={img.alt}
                  className={img.class}
                />
              ))}
            {currentTaskIndex === 1 && (
              <div className={styles.figureAndEclairsContainer}>
                <img
                  src={figureSeven}
                  alt="Цифра семь"
                  className={styles.figureSevenWithPetals}
                />
                <div className={styles.fourFlowers}>
                  <div className={styles.flowerRow}>
                    {currentTask.images
                      .filter(
                        (img, index) => img.alt === "листок" && index <= 3
                      )
                      .map((img, index) => (
                        <img
                          key={index}
                          src={img.src}
                          alt={img.alt}
                          className={styles.flower}
                        />
                      ))}
                  </div>
                  <div className={styles.flowerRow}>
                    {currentTask.images
                      .filter(
                        (img, index) => img.alt === "листок" && index <= 4
                      )
                      .map((img, index) => (
                        <img
                          key={index}
                          src={img.src}
                          alt={img.alt}
                          className={styles.flower}
                        />
                      ))}
                  </div>
                </div>
              </div>
            )}
            {currentTaskIndex === 2 && (
              <div className={styles.leavesContainer}>
                <img
                  src={wallperBrich}
                  alt="фон березки"
                  className={styles.wallperBrich}
                />
                <div>
                  <DropTarget
                    targetId="tree"
                    onDrop={handleDrop}
                    className={styles.treeDropTarget}
                  >
                    <img
                      src={brich}
                      alt="береза"
                      className={styles.brich}
                      id="tree"
                    />
                  </DropTarget>
                </div>
                <div className={styles.leftLeaves}>
                  {currentTask.images
                    .filter(
                      (img) =>
                        img.alt === "левый листок" && !leavesOnTree[img.id]
                    )
                    .map((img) => (
                      <DraggableItem
                        key={img.id}
                        id={img.id}
                        src={img.src}
                        alt={img.alt}
                        className={`${styles.leaf} ${styles[img.id]}`}
                      />
                    ))}
                </div>
                <div className={styles.rightLeaves}>
                  {currentTask.images
                    .filter(
                      (img) =>
                        img.alt === "правый листок" && !leavesOnTree[img.id]
                    )
                    .map((img) => (
                      <DraggableItem
                        key={img.id}
                        id={img.id}
                        src={img.src}
                        alt={img.alt}
                        className={`${styles.leaf} ${styles[img.id]}`}
                      />
                    ))}
                </div>
                <div className={styles.treeLeaves}>
                  {Object.keys(leavesOnTree)
                    .filter((key) => leavesOnTree[key])
                    .map((key) => (
                      <img
                        key={key}
                        src={key.includes("left") ? leftPetal : rightPetal}
                        alt="листок"
                        className={styles[`onTree-${key}`]}
                      />
                    ))}
                </div>
              </div>
            )}
            {currentTaskIndex === 3 && (
              <div className={styles.cubesContainerMain}>
                <div className={styles.cubesContainer}>
                  {puzzles.map((puzzle) => (
                    <img
                      key={puzzle.id}
                      src={puzzle.src}
                      alt={puzzle.id}
                      className={styles[puzzle.id]}
                      style={{ transform: `rotate(${puzzle.angle}deg)` }}
                      onClick={() => handleRotate(puzzle.id)}
                    />
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>
        {currentTaskIndex < tasks.length - 1 ? (
          <button
            className={styles.button}
            onClick={handleNextTask}
            disabled={buttonDisabled}
          >
            {buttonText}
          </button>
        ) : (
          buttonText === "Послушать" && (
            <button
              className={styles.button}
              onClick={() => {
                playSound();
                setButtonText("");
              }}
              disabled={buttonDisabled}
            >
              {buttonText}
            </button>
          )
        )}
      </div>
      <audio ref={audioRef} />
      <audio ref={backgroundAudioRef} src={backgroundSound} loop />
      {isButtonVisible && (
        <div className={styles.darkOverlay}>
          <button className={styles.buttonStartGame} onClick={handleStartGame}>
            Начать игру
          </button>
        </div>
      )}
    </DndProvider>
  );
};

export default MathFigureSevenGame;
