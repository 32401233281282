import React, { useState, useEffect, useRef } from "react";
import useDynamicHeight from "../../../hooks/useDynamicHeight";
import EndGameOptions from "../../end-game-options/end-game-options";
import { gsap } from "gsap";
import styles from "./artist-mistakes.module.css";
import wallperImage from "./images/wallper.svg";
import hedgehogIncorrect from "./images/hedgehogIncorrect.png";
import hedgehog from "./images/hedgehog.svg";
import squirellIncorrect from "./images/squirellIncor.png";
import squirell from "./images/squirell.png";
import foxIncorrect from "./images/foxIncorrect.svg";
import fox from "./images/fox.svg";
import bear from "./images/bear.svg";
import bearIncorrect from "./images/bearIncorrect.png";
import incorrectWolf from "./images/wolfIncorrect.svg";
import wolf from "./images/wolf.svg";
import boarIncorrect from "./images/boarIncorrect.svg";
import boar from "./images/boar.svg";
import deerIncorrect from "./images/deerIncorrect.svg";
import deer from "./images/deer.svg";
import rabbitIncorrect from "./images/rabbitIncorrect.svg";
import rabbit from "./images/rabbit.svg";
import birdIncorrect from "./images/birdIncorrect.svg";
import bird from "./images/bird.svg";
import elkIncorrect from "./images/elkIncorrect.svg";
import elk from "./images/elk.svg";

import startInstructionSound from "./sounds/startInstruction.mp3";
import backgroundSound from "./sounds/backgroundSound.mp3";
import rightAnswerSound from "./sounds/rightAnswer.mp3";
import finalSound from "./sounds/finalSound.mp3";

const colors = [
  "#FF0000",
  "#FF7F00",
  "#FFFF00",
  "#00FF00",
  "#0000FF",
  "#4B0082",
  "#8B00FF",
];

const triggerFireworks = () => {
  const fireworksContainer = document.querySelector(
    `.${styles.fireworksContainer}`
  );
  if (fireworksContainer) {
    for (let i = 0; i < 10; i++) {
      setTimeout(() => createFirework(fireworksContainer), i * 500);
    }
  } else {
    console.error("Fireworks container not found");
  }
};

const createFirework = (container) => {
  const firework = document.createElement("div");
  firework.className = styles.firework;
  firework.style.left = `${Math.random() * 100}%`;
  firework.style.top = `${Math.random() * 100}%`;
  container.appendChild(firework);

  const particles = [];
  for (let i = 0; i < 30; i++) {
    const particle = document.createElement("div");
    particle.className = styles.particle;
    particle.style.background =
      colors[Math.floor(Math.random() * colors.length)];
    firework.appendChild(particle);
    particles.push(particle);
  }

  const tl = gsap.timeline({
    onComplete: () => {
      firework.remove();
    },
  });

  particles.forEach((particle) => {
    const angle = Math.random() * Math.PI * 2;
    const distance = Math.random() * 200 + 50;
    tl.fromTo(
      particle,
      { x: 0, y: 0, opacity: 1 },
      {
        x: Math.cos(angle) * distance,
        y: Math.sin(angle) * distance,
        opacity: 0,
        duration: 2,
        ease: "power2.out",
      },
      0
    );
  });
};

const ArtistMistakesGame = () => {
  useDynamicHeight();

  const [isGameCompleted, setIsGameCompleted] = useState(false);

  const [buttonText, setButtonText] = useState("Начать игру");
  const [isButtonVisible, setIsButtonVisible] = useState(true);
  const audioRef = useRef(null);
  const startInstructionAudioRef = useRef(new Audio(startInstructionSound));
  const backgroundAudioRef = useRef(new Audio(backgroundSound));
  const [showAnswer, setShowAnswer] = useState(false);
  const [currentTaskIndex, setCurrentTaskIndex] = useState(0);
  const [imageOpacity, setImageOpacity] = useState(1);

  const tasks = [
    {
      question: "Что здесь неверно?",
      incorrectImage: hedgehogIncorrect,
      correctImage: hedgehog,
      imageClass: "hedgehogImage",
    },
    {
      question: "Что здесь неверно?",
      incorrectImage: squirellIncorrect,
      correctImage: squirell,
      imageClass: "squirellImage",
    },
    {
      question: "Что здесь неверно?",
      incorrectImage: foxIncorrect,
      correctImage: fox,
      imageClass: "foxImage",
    },
    {
      question: "Что здесь неверно?",
      incorrectImage: bearIncorrect,
      correctImage: bear,
      imageClass: "bearImage",
    },
    {
      question: "Что здесь неверно?",
      incorrectImage: incorrectWolf,
      correctImage: wolf,
      imageClass: "wolfImage",
    },
    {
      question: "Что здесь неверно?",
      incorrectImage: boarIncorrect,
      correctImage: boar,
      imageClass: "boarImage",
    },
    {
      question: "Что здесь неверно?",
      incorrectImage: deerIncorrect,
      correctImage: deer,
      imageClass: "deerImage",
    },
    {
      question: "Что здесь неверно?",
      incorrectImage: rabbitIncorrect,
      correctImage: rabbit,
      imageClass: "rabbit",
    },
    {
      question: "Что здесь неверно?",
      incorrectImage: birdIncorrect,
      correctImage: bird,
      imageClass: "bird",
    },
    {
      question: "Что здесь неверно?",
      incorrectImage: elkIncorrect,
      correctImage: elk,
      imageClass: "elk",
    },
  ];

  const currentTask = tasks[currentTaskIndex];

  const handleShowAnswer = () => {
    const rightAnswerAudio = new Audio(rightAnswerSound);
    rightAnswerAudio.play();

    setImageOpacity(0);
    setTimeout(() => {
      setShowAnswer(true);
      setImageOpacity(1);
    }, 500); // Полусекундная задержка для анимации исчезновения
  };

  const handleNextTask = () => {
    if (currentTaskIndex + 1 < tasks.length) {
      // Анимация при переходе к следующему заданию
      setImageOpacity(0);
      setTimeout(() => {
        setShowAnswer(false);
        setCurrentTaskIndex((prevIndex) => prevIndex + 1);
        setImageOpacity(1);
      }, 500); // Полусекундная задержка для анимации исчезновения
    } else {
      triggerFireworks();
      setTimeout(() => {
        setIsGameCompleted(true);
      }, 5000);
      const finalAudio = new Audio(finalSound);
      finalAudio.play();
    }
  };

  const handleStartGame = () => {
    startInstructionAudioRef.current.play();

    startInstructionAudioRef.current.onended = () => {
      if (backgroundAudioRef.current) {
        backgroundAudioRef.current.loop = true;
        backgroundAudioRef.current.play();
      }
      if (audioRef.current) {
        audioRef.current.play();
      }
    };
    setIsButtonVisible(false);
  };

  useEffect(() => {
    return () => {
      startInstructionAudioRef.current.pause();
      startInstructionAudioRef.current.currentTime = 0;
      backgroundAudioRef.current.pause();
      backgroundAudioRef.current.currentTime = 0;

      gsap.globalTimeline.clear();
    };
  }, []);

  return (
    <>
      {isGameCompleted && <EndGameOptions />}
      <div className={`container ${styles.someOtherClass}`}>
        <div className={styles.backgroundContainer}>
          <h3 className={styles.header}>
            {showAnswer ? "Правильный ответ:" : currentTask.question}
          </h3>
          <h4 className={styles.headerCounter}>
            Вопрос {currentTaskIndex + 1} из {tasks.length}
          </h4>
          <img className={styles.wallperImage} src={wallperImage} alt="" />
          <div className={styles.positionContainer}>
            <img
              src={
                showAnswer
                  ? tasks[currentTaskIndex].correctImage
                  : tasks[currentTaskIndex].incorrectImage
              }
              alt={showAnswer ? "Правильный ответ" : "Что здесь неверно?"}
              style={{
                opacity: imageOpacity,
                transition: "opacity 0.5s ease-in-out",
              }}
              className={`${styles.taskImage} ${
                styles[currentTask.imageClass]
              }`}
            />
          </div>
          <div className={styles.fireworksContainer}></div>
        </div>
        <button
          className={styles.button}
          onClick={showAnswer ? handleNextTask : handleShowAnswer}
        >
          {showAnswer
            ? currentTaskIndex + 1 < tasks.length
              ? "Перейти к следующему заданию"
              : "Завершить игру"
            : "Узнать правильный ответ!"}
        </button>
        <audio ref={audioRef} />
        {isButtonVisible && (
          <div className={styles.darkOverlay}>
            <button
              className={styles.buttonStartGame}
              onClick={handleStartGame}
            >
              {buttonText}
            </button>
          </div>
        )}
      </div>
    </>
  );
};

export default ArtistMistakesGame;
