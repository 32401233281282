import React, { useState, useEffect, useRef } from "react";
import useDynamicHeight from "../../../hooks/useDynamicHeight";
import EndGameOptions from "../../end-game-options/end-game-options";
import styles from "./animals-sounds.module.css";
import { gsap } from "gsap";
import dynamic from "./images/dynamic.svg";
import wolf from "./images/wolf.png";
import dolphin from "./images/dolphin.png";
import snake from "./images/snake.png";
import whale from "./images/whale.png";
import goat from "./images/goat.png";
import cow from "./images/cow.png";
import cat from "./images/cat.png";
import horse from "./images/horse.png";
import frog from "./images/frog.svg";
import bear from "./images/bear.png";
import mouse from "./images/mouse.png";
import monkey from "./images/monkey.png";
import sheep from "./images/sheep.png";
import donkey from "./images/donkey.png";
import pig from "./images/pig.png";
import elephant from "./images/elephant.png";
import dog from "./images/dog.png";
import seal from "./images/seal.png";

import startInstructionSound from "./sounds/startInstruction.mp3";
import rightAnswerSound from "./sounds/rightAnswer.mp3";
import finalSound from "./sounds/finalSound.mp3";
import incorrectAnswerSound from "./sounds/no.mp3";
import bearSound from "./sounds/bear.mp3";
import cowSound from "./sounds/cow.mp3";
import dogSound from "./sounds/dog.mp3";
import dolphinSound from "./sounds/dolphin.mp3";
import donkeySound from "./sounds/donkey.mp3";
import elephantSound from "./sounds/elephant.mp3";
import frogSound from "./sounds/frog.mp3";
import goatSound from "./sounds/goat.mp3";
import horseSound from "./sounds/horse.mp3";
import monkeySound from "./sounds/monkey.mp3";
import mouseSound from "./sounds/mouse.mp3";
import pigSound from "./sounds/pig.mp3";
import sealSound from "./sounds/seal.mp3";
import sheepSound from "./sounds/sheep.mp3";
import snakeSound from "./sounds/snake.mp3";
import whaleSound from "./sounds/whale.mp3";
import wolfSound from "./sounds/wolf.mp3";
import catSound from "./sounds/cat.mp3";

const colors = [
  "#FF0000",
  "#FF7F00",
  "#FFFF00",
  "#00FF00",
  "#0000FF",
  "#4B0082",
  "#8B00FF",
];

const triggerFireworks = () => {
  const fireworksContainer = document.querySelector(
    `.${styles.fireworksContainer}`
  );
  if (fireworksContainer) {
    for (let i = 0; i < 10; i++) {
      setTimeout(() => createFirework(fireworksContainer), i * 500);
    }
  } else {
    console.error("Fireworks container not found");
  }
};

const createFirework = (container) => {
  const firework = document.createElement("div");
  firework.className = styles.firework;
  firework.style.left = `${Math.random() * 100}%`;
  firework.style.top = `${Math.random() * 100}%`;
  container.appendChild(firework);

  const particles = [];
  for (let i = 0; i < 30; i++) {
    const particle = document.createElement("div");
    particle.className = styles.particle;
    particle.style.background =
      colors[Math.floor(Math.random() * colors.length)];
    firework.appendChild(particle);
    particles.push(particle);
  }

  const tl = gsap.timeline({
    onComplete: () => {
      firework.remove();
    },
  });

  particles.forEach((particle) => {
    const angle = Math.random() * Math.PI * 2;
    const distance = Math.random() * 200 + 50;
    tl.fromTo(
      particle,
      { x: 0, y: 0, opacity: 1 },
      {
        x: Math.cos(angle) * distance,
        y: Math.sin(angle) * distance,
        opacity: 0,
        duration: 2,
        ease: "power2.out",
      },
      0
    );
  });
};

const AnimalsSoundsGame = () => {
  useDynamicHeight();
  const [isInteractionBlocked, setIsInteractionBlocked] = useState(false);
  const [isGameCompleted, setIsGameCompleted] = useState(false);
  const [currentTaskIndex, setCurrentTaskIndex] = useState(0);
  const [isAnswerCorrect, setIsAnswerCorrect] = useState(null);
  const [selectedAnswer, setSelectedAnswer] = useState(null);
  const [buttonText, setButtonText] = useState("Начать игру");
  const [isButtonVisible, setIsButtonVisible] = useState(true);
  const audioRef = useRef(null);
  const startInstructionRef = useRef(new Audio(startInstructionSound));

  const tasks = [
    {
      question: (
        <>
          Послушай звук.
          <br /> Какое из животных издаёт его?
        </>
      ),
      images: [
        { src: cow, alt: "cow" },
        { src: pig, alt: "pig" },
        { src: dog, alt: "dog" },
        { src: cat, alt: "cat" },
      ],
      correctAnswer: "cow",
    },
    {
      question: (
        <>
          Послушай звук.
          <br /> Какое из животных издаёт его?
        </>
      ),
      images: [
        { src: sheep, alt: "sheep" },
        { src: goat, alt: "goat" },
        { src: pig, alt: "pig" },
        { src: horse, alt: "horse" },
      ],
      correctAnswer: "pig",
    },
    {
      question: (
        <>
          Послушай звук.
          <br /> Какое из животных издаёт его?
        </>
      ),
      images: [
        { src: cat, alt: "cat" },
        { src: dog, alt: "dog" },
        { src: mouse, alt: "mouse" },
        { src: snake, alt: "snake" },
      ],
      correctAnswer: "dog",
    },
    {
      question: (
        <>
          Послушай звук.
          <br /> Какое из животных издаёт его?
        </>
      ),
      images: [
        { src: frog, alt: "frog" },
        { src: goat, alt: "goat" },
        { src: cat, alt: "cat" },
        { src: dog, alt: "dog" },
      ],
      correctAnswer: "cat",
    },
    {
      question: (
        <>
          Послушай звук.
          <br /> Какое из животных издаёт его?
        </>
      ),
      images: [
        { src: sheep, alt: "sheep" },
        { src: goat, alt: "goat" },
        { src: donkey, alt: "donkey" },
        { src: horse, alt: "horse" },
      ],
      correctAnswer: "sheep",
    },
    {
      question: (
        <>
          Послушай звук.
          <br /> Какое из животных издаёт его?
        </>
      ),
      images: [
        { src: goat, alt: "goat" },
        { src: sheep, alt: "sheep" },
        { src: bear, alt: "bear" },
        { src: cow, alt: "cow" },
      ],
      correctAnswer: "goat",
    },
    {
      question: (
        <>
          Послушай звук.
          <br /> Какое из животных издаёт его?
        </>
      ),
      images: [
        { src: donkey, alt: "donkey" },
        { src: bear, alt: "bear" },
        { src: whale, alt: "whale" },
        { src: horse, alt: "horse" },
      ],
      correctAnswer: "horse",
    },
    {
      question: (
        <>
          Послушай звук.
          <br /> Какое из животных издаёт его?
        </>
      ),
      images: [
        { src: donkey, alt: "donkey" },
        { src: dog, alt: "dog" },
        { src: horse, alt: "horse" },
        { src: seal, alt: "seal" },
      ],
      correctAnswer: "donkey",
    },
    {
      question: (
        <>
          Послушай звук.
          <br /> Какое из животных издаёт его?
        </>
      ),
      images: [
        { src: snake, alt: "snake" },
        { src: frog, alt: "frog" },
        { src: mouse, alt: "mouse" },
        { src: goat, alt: "goat" },
      ],
      correctAnswer: "frog",
    },
    {
      question: (
        <>
          Послушай звук.
          <br /> Какое из животных издаёт его?
        </>
      ),
      images: [
        { src: elephant, alt: "elephant" },
        { src: monkey, alt: "monkey" },
        { src: snake, alt: "snake" },
        { src: mouse, alt: "mouse" },
      ],
      correctAnswer: "snake",
    },
    {
      question: (
        <>
          Послушай звук.
          <br /> Какое из животных издаёт его?
        </>
      ),
      images: [
        { src: bear, alt: "bear" },
        { src: wolf, alt: "wolf" },
        { src: goat, alt: "goat" },
        { src: sheep, alt: "sheep" },
      ],
      correctAnswer: "bear",
    },
    {
      question: (
        <>
          Послушай звук.
          <br /> Какое из животных издаёт его?
        </>
      ),
      images: [
        { src: dolphin, alt: "dolphin" },
        { src: snake, alt: "snake" },
        { src: whale, alt: "whale" },
        { src: seal, alt: "seal" },
      ],
      correctAnswer: "whale",
    },
    {
      question: (
        <>
          Послушай звук.
          <br /> Какое из животных издаёт его?
        </>
      ),
      images: [
        { src: whale, alt: "whale" },
        { src: dolphin, alt: "dolphin" },
        { src: snake, alt: "snake" },
        { src: frog, alt: "frog" },
      ],
      correctAnswer: "dolphin",
    },
    {
      question: (
        <>
          Послушай звук.
          <br /> Какое из животных издаёт его?
        </>
      ),
      images: [
        { src: monkey, alt: "monkey" },
        { src: snake, alt: "snake" },
        { src: whale, alt: "whale" },
        { src: seal, alt: "seal" },
      ],
      correctAnswer: "seal",
    },
    {
      question: (
        <>
          Послушай звук.
          <br /> Какое из животных издаёт его?
        </>
      ),
      images: [
        { src: wolf, alt: "wolf" },
        { src: bear, alt: "bear" },
        { src: pig, alt: "pig" },
        { src: cow, alt: "cow" },
      ],
      correctAnswer: "wolf",
    },
    {
      question: (
        <>
          Послушай звук.
          <br /> Какое из животных издаёт его?
        </>
      ),
      images: [
        { src: snake, alt: "snake" },
        { src: mouse, alt: "mouse" },
        { src: frog, alt: "frog" },
        { src: bear, alt: "bear" },
      ],
      correctAnswer: "mouse",
    },
    {
      question: (
        <>
          Послушай звук.
          <br /> Какое из животных издаёт его?
        </>
      ),
      images: [
        { src: monkey, alt: "monkey" },
        { src: pig, alt: "pig" },
        { src: frog, alt: "frog" },
        { src: elephant, alt: "elephant" },
      ],
      correctAnswer: "elephant",
    },
    {
      question: (
        <>
          Послушай звук.
          <br /> Какое из животных издаёт его?
        </>
      ),
      images: [
        { src: monkey, alt: "monkey" },
        { src: elephant, alt: "elephant" },
        { src: cow, alt: "cow" },
        { src: donkey, alt: "donkey" },
      ],
      correctAnswer: "monkey",
    },
  ];

  const currentTask = tasks[currentTaskIndex];

  const handleImageClick = (answer) => {
    if (isAnswerCorrect) return;

    setSelectedAnswer(answer);
    const isCorrect = answer === currentTask.correctAnswer;
    setIsAnswerCorrect(isCorrect);

    const audio = new Audio(
      isCorrect ? rightAnswerSound : incorrectAnswerSound
    );
    audio.play();

    if (isCorrect) {
      setTimeout(() => {
        setIsAnswerCorrect(null);
        setSelectedAnswer(null);

        if (currentTaskIndex + 1 < tasks.length) {
          setCurrentTaskIndex((prevIndex) => prevIndex + 1);
        } else {
          triggerFireworks();
          setTimeout(() => {
            setIsGameCompleted(true);
          }, 5000);
          const finalAudio = new Audio(finalSound);
          finalAudio.play();
        }
      }, 1000);
    } else {
      setTimeout(() => {
        setSelectedAnswer(null);
      }, 1000);
    }
  };
  const handleStartGame = () => {
    setIsInteractionBlocked(true);
    if (startInstructionRef.current) {
      startInstructionRef.current.play();

      startInstructionRef.current.onended = () => {
        setIsInteractionBlocked(false);
      };
    }
    setIsButtonVisible(false);
  };

  const handlePlaySound = () => {
    if (audioRef.current) {
      const soundMap = {
        cow: cowSound,
        pig: pigSound,
        dog: dogSound,
        cat: catSound,
        sheep: sheepSound,
        goat: goatSound,
        donkey: donkeySound,
        horse: horseSound,
        frog: frogSound,
        snake: snakeSound,
        bear: bearSound,
        whale: whaleSound,
        dolphin: dolphinSound,
        seal: sealSound,
        wolf: wolfSound,
        mouse: mouseSound,
        elephant: elephantSound,
        monkey: monkeySound,
      };

      const currentSound = soundMap[currentTask.correctAnswer];
      if (currentSound) {
        const sound = new Audio(currentSound);
        sound.play();
      }
    }
  };

  useEffect(() => {
    return () => {
      startInstructionRef.current.pause();
      startInstructionRef.current.currentTime = 0;
    };
  }, []);

  return (
    <>
      {isGameCompleted && <EndGameOptions />}
      <div className={`container ${styles.someOtherClass}`}>
        <h3 className={styles.header}>{currentTask.question}</h3>
        <h4 className={styles.headerCounter}>
          {" "}
          Вопрос {currentTaskIndex + 1} из {tasks.length}
        </h4>
        <div
          className={styles.backgroundContainer}
          style={{
            pointerEvents: isInteractionBlocked ? "none" : "auto",
          }}
        >
          <div className={styles.imagesContainer}>
            {currentTask.images.map((image) => (
              <div className={styles.imageWrapper}>
                <img
                  key={image.alt}
                  src={image.src}
                  alt={image.alt}
                  className={`${styles.taskImage} ${
                    styles[`${image.alt}Image`]
                  }`}
                  onClick={() => handleImageClick(image.alt)}
                  style={{
                    filter:
                      selectedAnswer === image.alt && isAnswerCorrect
                        ? "drop-shadow(0 10px 50px rgba(50, 205, 50, 0.5))"
                        : selectedAnswer === image.alt &&
                          selectedAnswer !== null
                        ? "none"
                        : "",
                  }}
                />
              </div>
            ))}
            <button className={styles.centralButton} onClick={handlePlaySound}>
              <img src={dynamic} alt="Dynamic" />
              прослушать
            </button>
          </div>
        </div>
        <audio ref={audioRef} />
        {isButtonVisible && (
          <div className={styles.darkOverlay}>
            <button
              className={styles.buttonStartGame}
              onClick={handleStartGame}
            >
              {buttonText}
            </button>
          </div>
        )}
        <div className={styles.fireworksContainer}></div>
      </div>
    </>
  );
};

export default AnimalsSoundsGame;
