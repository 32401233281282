import React, { useState } from "react";
import DatingWithZhuzhaFirstPart from "./dating-with-zhuzha-part-one";
import DatingWithZhuzhaSecondPart from "./dating-with-zhuzha-part-two";

const DatingWithZhuzhaMain = () => {
  const [scene, setScene] = useState("DatingWithZhuzhaFirstPart"); // здесь поменять на первую часть

  const handleSceneChange = () => {
    setScene("DatingWithZhuzhaSecondPart");
  };

  return (
    <div>
      {scene === "DatingWithZhuzhaFirstPart" && (
        <DatingWithZhuzhaFirstPart onEnterCafe={handleSceneChange} />
      )}
      {scene === "DatingWithZhuzhaSecondPart" && <DatingWithZhuzhaSecondPart />}
    </div>
  );
};

export default DatingWithZhuzhaMain;
