import { useEffect, useState } from "react";
import { getUserInfo } from "../api/user-info";
import { REDIRECT_URL } from "../api/api";

const AuthCheck = ({ children, onAuthCheckComplete }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const checkUserInfo = async () => {
      try {
        const userInfo = await getUserInfo();
        if (userInfo) {
          setIsAuthenticated(true);
          if (userInfo.id) {
            localStorage.setItem("userId", userInfo.id);
          }
        } else {
          throw new Error("No user info");
        }
      } catch (error) {
        setIsAuthenticated(false);
        window.location.href = REDIRECT_URL;
      } finally {
        setIsLoading(false);
        onAuthCheckComplete();
      }
    };

    checkUserInfo();
  }, [onAuthCheckComplete]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return isAuthenticated ? children : null;
};

export default AuthCheck;
