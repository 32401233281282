import React, { useState, useEffect, useRef } from "react";
import useDynamicHeight from "../../../hooks/useDynamicHeight";
import EndGameOptions from "../../end-game-options/end-game-options";
import { gsap } from "gsap";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import DraggableItem from "./DraggableItem";
import DropTarget from "./DropTarget";
import styles from "./math-eight.module.css";

import figureEight from "./images/8 1.png";
import bone from "./images/bone.png";
import dogWallper from "./images/image 2 (1).png";
import dog from "./images/dog.png";
import bowl from "./images/bowl.png";
import bowlOneBone from "./images/bowlOneBone.png";
import bowlTwoBones from "./images/bowlTwoBones.png";
import bowlThreeBones from "./images/bowlThreeBones.png";
import bowlFourBones from "./images/bowlFourBones.png";
import bowlFiveBones from "./images/bowlFiveBones.png";
import bowlSixBones from "./images/bowlSixBones.png";
import bowlSevenBones from "./images/bowlSevenBones.png";
import bowlEightBones from "./images/bowlEightBones.png";
import bubbleOne from "./images/Ellipse 2.svg";
import bubbleTwo from "./images/Ellipse 3.svg";
import bubbleThree from "./images/Ellipse 4.svg";
import bubbleFour from "./images/Ellipse 5.svg";
import bubbleFive from "./images/Ellipse 6.svg";
import bubbleSix from "./images/Ellipse 7.svg";
import bubbleSeven from "./images/Ellipse 8.svg";
import bubbleEight from "./images/Ellipse 9.svg";
import bubbleNine from "./images/Ellipse 10.svg";

import startInstructionSound from "./sounds/startInstruction.mp3";
import backgroundSound from "./sounds/backgroundSound.mp3";
import taskZeroSound from "./sounds/taskZero.mp3";
import taskTwoSound from "./sounds/taskTwo.mp3";
import taskThreeSound from "./sounds/taskThree.mp3";
import taskFourSound from "./sounds/taskFour.mp3";
import bubbleSound from "./sounds/bubble.mp3";
import boneSound from "./sounds/bone.mp3";
import finalSound from "./sounds/finalSound.mp3";
import praiseSound from "./sounds/praise.mp3";

const colors = [
  "#FF0000",
  "#FF7F00",
  "#FFFF00",
  "#00FF00",
  "#0000FF",
  "#4B0082",
  "#8B00FF",
];

const triggerFireworks = () => {
  const fireworksContainer = document.querySelector(
    `.${styles.fireworksContainer}`
  );
  if (fireworksContainer) {
    for (let i = 0; i < 10; i++) {
      setTimeout(() => createFirework(fireworksContainer), i * 500);
    }
  } else {
    console.error("Fireworks container not found");
  }
};

const createFirework = (container) => {
  const firework = document.createElement("div");
  firework.className = styles.firework;
  firework.style.left = `${Math.random() * 100}%`;
  firework.style.top = `${Math.random() * 100}%`;
  container.appendChild(firework);

  const particles = [];
  for (let i = 0; i < 30; i++) {
    const particle = document.createElement("div");
    particle.className = styles.particle;
    particle.style.background =
      colors[Math.floor(Math.random() * colors.length)];
    firework.appendChild(particle);
    particles.push(particle);
  }

  const tl = gsap.timeline({
    onComplete: () => {
      firework.remove();
    },
  });

  particles.forEach((particle) => {
    const angle = Math.random() * Math.PI * 2;
    const distance = Math.random() * 200 + 50;
    tl.fromTo(
      particle,
      { x: 0, y: 0, opacity: 1 },
      {
        x: Math.cos(angle) * distance,
        y: Math.sin(angle) * distance,
        opacity: 0,
        duration: 2,
        ease: "power2.out",
      },
      0
    );
  });
};

const MathFigureEightGame = () => {
  useDynamicHeight();
  const [isGameCompleted, setIsGameCompleted] = useState(false);
  const [currentTaskIndex, setCurrentTaskIndex] = useState(0);
  const [imageOpacity, setImageOpacity] = useState(1);
  const [buttonText, setButtonText] = useState("Послушать");
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [isButtonVisible, setIsButtonVisible] = useState(true);
  const [isInteractionBlocked, setIsInteractionBlocked] = useState(true);

  const [bonesInBowl, setBonesInBowl] = useState(0);
  const [bonesOnField, setBonesOnField] = useState({
    bone1: false,
    bone2: false,
    bone3: false,
    bone4: false,
    bone5: false,
    bone6: false,
    bone7: false,
    bone8: false,
  });

  const bowlImages = [
    bowl,
    bowlOneBone,
    bowlTwoBones,
    bowlThreeBones,
    bowlFourBones,
    bowlFiveBones,
    bowlSixBones,
    bowlSevenBones,
    bowlEightBones,
  ];
  const [visibleBubbles, setVisibleBubbles] = useState({
    bubbleOne: true,
    bubbleTwo: true,
    bubbleThree: true,
    bubbleFour: true,
    bubbleFive: true,
    bubbleSix: true,
    bubbleSeven: true,
    bubbleEight: true,
    bubbleNine: true,
    bubbleTen: true,
  });
  const [poppedCount, setPoppedCount] = useState(0);

  const audioRef = useRef(null);
  const startInstructionAudioRef = useRef(new Audio(startInstructionSound));
  const backgroundAudioRef = useRef(new Audio(backgroundSound));
  const bubbleSoundRef = useRef(new Audio(bubbleSound));
  const finalSoundRef = useRef(new Audio(finalSound));
  const praiseSoundRef = useRef(new Audio(praiseSound));

  const tasks = [
    {
      question: "Это цифра восемь",
      images: [
        { src: figureEight, alt: "Цифра восемь", class: styles.figureEight },
      ],
      audioSrc: taskZeroSound,
    },
    {
      question: "А это восемь косточек",
      images: [
        {
          src: figureEight,
          alt: "Цифра восемь",
          class: styles.figureEight,
        },
        { src: bone, alt: "кость", class: styles.bone },
        { src: bone, alt: "кость", class: styles.bone },
        { src: bone, alt: "кость", class: styles.bone },
        { src: bone, alt: "кость", class: styles.bone },
        { src: bone, alt: "кость", class: styles.bone },
        { src: bone, alt: "кость", class: styles.bone },
        { src: bone, alt: "кость", class: styles.bone },
        { src: bone, alt: "кость", class: styles.bone },
      ],
      audioSrc: taskTwoSound,
    },
    {
      question: "Положи восемь косточек в миску",
      images: [],
      audioSrc: taskThreeSound,
    },
    {
      question: "Лопни восемь мыльных пузырей!",
      images: [
        {
          src: bubbleOne,
          alt: "Мыльный пузырь",
          class: styles.bubble,
          isBubble: true,
          id: "bubbleOne",
        },
        {
          src: bubbleTwo,
          alt: "Мыльный пузырь",
          class: styles.bubble,
          isBubble: true,
          id: "bubbleTwo",
        },
        {
          src: bubbleThree,
          alt: "Мыльный пузырь",
          class: styles.bubble,
          isBubble: true,
          id: "bubbleThree",
        },
        {
          src: bubbleFour,
          alt: "Мыльный пузырь",
          class: styles.bubble,
          isBubble: true,
          id: "bubbleFour",
        },
        {
          src: bubbleFive,
          alt: "Мыльный пузырь",
          class: styles.bubble,
          isBubble: true,
          id: "bubbleFive",
        },
        {
          src: bubbleSix,
          alt: "Мыльный пузырь",
          class: styles.bubble,
          isBubble: true,
          id: "bubbleSix",
        },
        {
          src: bubbleSeven,
          alt: "Мыльный пузырь",
          class: styles.bubble,
          isBubble: true,
          id: "bubbleSeven",
        },
        {
          src: bubbleEight,
          alt: "Мыльный пузырь",
          class: styles.bubble,
          isBubble: true,
          id: "bubbleEight",
        },
        {
          src: bubbleNine,
          alt: "Мыльный пузырь",
          class: styles.bubble,
          isBubble: true,
          id: "bubbleNine",
        },
      ],
      audioSrc: taskFourSound,
    },
  ];

  const currentTask = tasks[currentTaskIndex];

  const playSound = () => {
    if (currentTask.audioSrc) {
      setIsInteractionBlocked(true);
      setButtonDisabled(true);

      const taskAudio = new Audio(currentTask.audioSrc);
      taskAudio.play().catch((error) => {
        console.error("Playback failed:", error);
        setButtonDisabled(false);
        setIsInteractionBlocked(false);
      });

      taskAudio.onended = () => {
        setButtonDisabled(false);
        setIsInteractionBlocked(false);

        if (currentTaskIndex === tasks.length - 1) {
          setButtonText("");
        } else {
          setButtonText("Далее");
        }
      };
    }
  };

  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.onended = () => {
        if (currentTaskIndex !== 2) {
          setButtonDisabled(false);
          setButtonText("Далее");
        }
      };
    }
  }, [currentTaskIndex]);

  const handleNextTask = () => {
    if (buttonText === "Послушать") {
      setIsInteractionBlocked(true); // Блокируем взаимодействие сразу после нажатия
      playSound(); // Воспроизводим звук для текущей задачи
    } else if (buttonText === "Далее" && !buttonDisabled) {
      setImageOpacity(0);
      setTimeout(() => {
        if (currentTaskIndex + 1 < tasks.length) {
          setCurrentTaskIndex(currentTaskIndex + 1);
          setButtonText("Послушать");
          setButtonDisabled(false);
          setIsInteractionBlocked(true); // Снова блокируем после перехода к следующему заданию
        }
        setImageOpacity(1);
      }, 500);
    }
  };

  const handleDrop = (itemId) => {
    if (bonesInBowl < 8 && !bonesOnField[itemId]) {
      setBonesOnField((prev) => ({
        ...prev,
        [itemId]: true,
      }));
      setBonesInBowl((prev) => prev + 1);

      const boneSoundAudio = new Audio(boneSound);
      boneSoundAudio.play();
    }
  };

  useEffect(() => {
    if (currentTaskIndex === 2) {
      const allBonesCollected = bonesInBowl === 8;
      setButtonDisabled(!allBonesCollected && buttonText === "Далее");
    }
  }, [bonesInBowl, currentTaskIndex, buttonText]);

  const handleBubbleClick = (bubbleId) => {
    if (poppedCount < 8 && visibleBubbles[bubbleId] !== "popped") {
      setVisibleBubbles((prevState) => ({
        ...prevState,
        [bubbleId]: "popped",
      }));
      bubbleSoundRef.current.play();

      setTimeout(() => {
        setVisibleBubbles((prevState) => ({
          ...prevState,
          [bubbleId]: false,
        }));
        setPoppedCount((prevCount) => {
          const newCount = prevCount + 1;
          if (newCount >= 8) {
            triggerFireworks();
            finalSoundRef.current.play();

            finalSoundRef.current.onended = () => {
              triggerFireworks();
              setTimeout(() => {
                setIsGameCompleted(true);
              }, 5000);
              praiseSoundRef.current.play();
            };
          }
          return newCount;
        });
      }, 500);
    }
  };

  const handleStartGame = () => {
    setButtonDisabled(true);
    setIsInteractionBlocked(true);
    const instructionAudio = startInstructionAudioRef.current;
    instructionAudio.play();

    instructionAudio.onended = () => {
      if (audioRef.current) {
        audioRef.current.play();
      }
      if (backgroundAudioRef.current) {
        backgroundAudioRef.current.play();
      }
      setButtonDisabled(false);
    };

    setIsButtonVisible(false);
  };

  useEffect(() => {
    if (currentTaskIndex === 3) {
      document.body.style.backgroundColor = "#038dbe";
    } else {
      document.body.style.backgroundColor = "rgba(252, 229, 170, 1)";
    }

    return () => {
      if (startInstructionAudioRef.current) {
        startInstructionAudioRef.current.pause();
        startInstructionAudioRef.current.currentTime = 0;
      }

      if (backgroundAudioRef.current) {
        backgroundAudioRef.current.pause();
        backgroundAudioRef.current.currentTime = 0;
      }

      document.body.style.backgroundColor = "";
    };
  }, [currentTaskIndex]);

  return (
    <DndProvider backend={HTML5Backend}>
      {isGameCompleted && <EndGameOptions />}
      <div className={`container ${styles.someOtherClass}`}>
        <div
          className={styles.backgroundContainer}
          style={{
            pointerEvents: isInteractionBlocked ? "none" : "auto",
          }}
        >
          <h3 className={styles.header}>{currentTask.question}</h3>
          <div
            style={{
              opacity: imageOpacity,
              transition: "opacity 0.5s ease-in-out",
            }}
            className={styles.taskContainer}
          >
            {currentTaskIndex === 0 &&
              currentTask.images.map((img, index) => (
                <img
                  key={index}
                  src={img.src}
                  alt={img.alt}
                  className={img.class}
                />
              ))}
            {currentTaskIndex === 1 && (
              <div className={styles.figureAndEclairsContainer}>
                <img
                  src={figureEight}
                  alt="Цифра восемь"
                  className={styles.figureEight}
                />
                <div className={styles.fourFlowers}>
                  <div className={styles.flowerRow}>
                    {currentTask.images
                      .filter((img, index) => img.alt === "кость" && index <= 4)
                      .map((img, index) => (
                        <img
                          key={index}
                          src={img.src}
                          alt={img.alt}
                          className={styles.flower}
                        />
                      ))}
                  </div>
                  <div className={styles.flowerRow}>
                    {currentTask.images
                      .filter((img, index) => img.alt === "кость" && index >= 5)
                      .map((img, index) => (
                        <img
                          key={index}
                          src={img.src}
                          alt={img.alt}
                          className={styles.flower}
                        />
                      ))}
                  </div>
                </div>
              </div>
            )}

            {currentTaskIndex === 2 && (
              <div className={styles.dogContainer}>
                <img src={dogWallper} alt="Фон" className={styles.dogWallper} />
                <img src={dog} alt="Собака" className={styles.dog} />
                <DropTarget onDrop={handleDrop}>
                  <img
                    src={bowlImages[bonesInBowl]}
                    alt="Миска"
                    className={styles.bowl}
                  />
                </DropTarget>
                <div className={styles.bonesContainer}>
                  {Object.keys(bonesOnField)
                    .filter((boneId) => !bonesOnField[boneId])
                    .map((boneId) => (
                      <DraggableItem
                        key={boneId}
                        id={boneId}
                        src={bone}
                        alt="кость"
                        className={styles.bone}
                      />
                    ))}
                </div>
              </div>
            )}
            {currentTaskIndex === 3 && (
              <div className={styles.bubblesContainer}>
                {currentTask.images.map((img, index) => {
                  const isPopped = visibleBubbles[img.id] === "popped";
                  const isVisible = visibleBubbles[img.id] !== false;
                  return (
                    <img
                      key={index}
                      src={img.src}
                      alt={img.alt}
                      className={`${img.class} ${
                        img.isBubble ? styles.bubble : ""
                      } ${isPopped ? styles.pop : ""}`}
                      onClick={() => img.isBubble && handleBubbleClick(img.id)}
                      style={{
                        display: isVisible ? "block" : "none",
                        top: `${Math.random() * 50 + 25}%`, // Random value between 25% and 75%
                        left: `${Math.random() * 50 + 25}%`, // Random value between 25% and 75%
                      }}
                    />
                  );
                })}
              </div>
            )}
          </div>
        </div>
        {currentTaskIndex < tasks.length - 1 ? (
          <button
            className={styles.button}
            onClick={handleNextTask}
            disabled={buttonDisabled}
          >
            {buttonText}
          </button>
        ) : (
          buttonText === "Послушать" && (
            <button
              className={styles.button}
              onClick={() => {
                playSound();
                setButtonText("");
              }}
              disabled={buttonDisabled}
            >
              {buttonText}
            </button>
          )
        )}
        <div className={styles.fireworksContainer}></div>
      </div>
      <audio ref={audioRef} />
      <audio ref={backgroundAudioRef} src={backgroundSound} loop />
      {isButtonVisible && (
        <div className={styles.darkOverlay}>
          <button className={styles.buttonStartGame} onClick={handleStartGame}>
            Начать игру
          </button>
        </div>
      )}
    </DndProvider>
  );
};

export default MathFigureEightGame;
