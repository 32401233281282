import React from "react";
import { useDrop } from "react-dnd";

const DropTarget = ({ targetId, onDrop, children, className, isBearInBox }) => {
  const [, drop] = useDrop(() => ({
    accept: "BEAR",
    drop: (item) => {
      if (!isBearInBox) {
        onDrop(item.id, targetId);
      }
    },
    canDrop: () => !isBearInBox, // Блокируем возможность сброса, если флаг установлен
    collect: (monitor) => ({
      isBearInBox: monitor.canDrop(),
    }),
  }));

  return (
    <div ref={drop} className={className}>
      {children}
    </div>
  );
};

export default DropTarget;
