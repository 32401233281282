import React, { useState, useEffect, useRef } from "react";
import useDynamicHeight from "../../../hooks/useDynamicHeight";
import EndGameOptions from "../../end-game-options/end-game-options";
import styles from "./street-sounds.module.css";
import Confetti from "react-confetti";
import dynamic from "./images/dynamic.svg";

import bottle from "./images/bottle.png";
import bell from "./images/bell.png";
import peopleTalking from "./images/peopleTalking.png";
import carSignal from "./images/carSignal.png";
import ball from "./images/ball.png";
import playground from "./images/playground.png";
import purchases from "./images/purchases.png";
import whistle from "./images/whistle.png";
import siren from "./images/siren.png";
import firework from "./images/firework.png";
import go from "./images/go.png";

import startInstructionSound from "./sounds/startInstruction.mp3";
import rightAnswerSound from "./sounds/rightAnswer.mp3";
import finalSound from "./sounds/finalSound.mp3";
import incorrectAnswerSound from "./sounds/no.mp3";

import bottleSound from "./sounds/bottle.mp3";
import bellSound from "./sounds/bell.mp3";
import peopleTalkingSound from "./sounds/peopleTalking.mp3";
import carSignalSound from "./sounds/carSignal.mp3";
import ballSound from "./sounds/ball.mp3";
import playgroundSound from "./sounds/playground.mp3";
import purchasesSound from "./sounds/purchases.mp3";
import whistleSound from "./sounds/whistle.mp3";
import sirenSound from "./sounds/siren.mp3";
import fireworkSound from "./sounds/firework.mp3";
import goSound from "./sounds/go.mp3";

const StreerSoundsGame = () => {
  useDynamicHeight();
  const [isInteractionBlocked, setIsInteractionBlocked] = useState(false);
  const [isGameCompleted, setIsGameCompleted] = useState(false);
  const [currentTaskIndex, setCurrentTaskIndex] = useState(0);
  const [isAnswerCorrect, setIsAnswerCorrect] = useState(null);
  const [selectedAnswer, setSelectedAnswer] = useState(null);
  const [buttonText, setButtonText] = useState("Начать игру");
  const [isButtonVisible, setIsButtonVisible] = useState(true);
  const [showConfetti, setShowConfetti] = useState(false);
  const [audioPlayed, setAudioPlayed] = useState(false);
  const audioRef = useRef(null);
  const startInstructionRef = useRef(new Audio(startInstructionSound));

  const tasks = [
    {
      question: (
        <>
          Послушай звук.
          <br /> На что он похож?
        </>
      ),
      images: [
        { src: siren, alt: "siren" },
        { src: carSignal, alt: "carSignal" },
        { src: purchases, alt: "purchases" },
        { src: ball, alt: "ball" },
      ],
      correctAnswer: "carSignal",
    },
    {
      question: (
        <>
          Послушай звук.
          <br /> На что он похож?
        </>
      ),
      images: [
        { src: go, alt: "go" },
        { src: ball, alt: "ball" },
        { src: whistle, alt: "whistle" },
        { src: peopleTalking, alt: "peopleTalking" },
      ],
      correctAnswer: "go",
    },
    {
      question: (
        <>
          Послушай звук.
          <br /> На что он похож?
        </>
      ),
      images: [
        { src: bell, alt: "bell" },
        { src: peopleTalking, alt: "peopleTalking" },
        { src: carSignal, alt: "carSignal" },
        { src: playground, alt: "playground" },
      ],
      correctAnswer: "playground",
    },
    {
      question: (
        <>
          Послушай звук.
          <br /> На что он похож?
        </>
      ),
      images: [
        { src: siren, alt: "siren" },
        { src: carSignal, alt: "carSignal" },
        { src: bell, alt: "bell" },
        { src: peopleTalking, alt: "peopleTalking" },
      ],
      correctAnswer: "siren",
    },
    {
      question: (
        <>
          Послушай звук.
          <br /> На что он похож?
        </>
      ),
      images: [
        { src: bottle, alt: "bottle" },
        { src: peopleTalking, alt: "peopleTalking" },
        { src: playground, alt: "playground" },
        { src: whistle, alt: "whistle" },
      ],
      correctAnswer: "peopleTalking",
    },
    {
      question: (
        <>
          Послушай звук.
          <br /> На что он похож?
        </>
      ),
      images: [
        { src: bottle, alt: "bottle" },
        { src: bell, alt: "bell" },
        { src: firework, alt: "firework" },
        { src: purchases, alt: "purchases" },
      ],
      correctAnswer: "firework",
    },
    {
      question: (
        <>
          Послушай звук.
          <br /> На что он похож?
        </>
      ),
      images: [
        { src: ball, alt: "ball" },
        { src: go, alt: "go" },
        { src: whistle, alt: "whistle" },
        { src: bottle, alt: "bottle" },
      ],
      correctAnswer: "bottle",
    },
    {
      question: (
        <>
          Послушай звук.
          <br /> На что он похож?
        </>
      ),
      images: [
        { src: purchases, alt: "purchases" },
        { src: carSignal, alt: "carSignal" },
        { src: siren, alt: "siren" },
        { src: bell, alt: "bell" },
      ],
      correctAnswer: "purchases",
    },
    {
      question: (
        <>
          Послушай звук.
          <br /> На что он похож?
        </>
      ),
      images: [
        { src: whistle, alt: "whistle" },
        { src: ball, alt: "ball" },
        { src: bottle, alt: "bottle" },
        { src: go, alt: "go" },
      ],
      correctAnswer: "ball",
    },
    {
      question: (
        <>
          Послушай звук.
          <br /> На что он похож?
        </>
      ),
      images: [
        { src: carSignal, alt: "carSignal" },
        { src: firework, alt: "firework" },
        { src: bottle, alt: "bottle" },
        { src: whistle, alt: "whistle" },
      ],
      correctAnswer: "whistle",
    },
    {
      question: (
        <>
          Послушай звук.
          <br /> На что он похож?
        </>
      ),
      images: [
        { src: bell, alt: "bell" },
        { src: firework, alt: "firework" },
        { src: ball, alt: "ball" },
        { src: go, alt: "go" },
      ],
      correctAnswer: "bell",
    },
  ];

  const currentTask = tasks[currentTaskIndex];

  const handleImageClick = (answer) => {
    if (isAnswerCorrect) return;

    setSelectedAnswer(answer);
    const isCorrect = answer === currentTask.correctAnswer;
    setIsAnswerCorrect(isCorrect);

    const audio = new Audio(
      isCorrect ? rightAnswerSound : incorrectAnswerSound
    );
    audio.play();

    if (isCorrect) {
      setTimeout(() => {
        setIsAnswerCorrect(null);
        setSelectedAnswer(null);

        if (currentTaskIndex + 1 < tasks.length) {
          setCurrentTaskIndex((prevIndex) => prevIndex + 1);
        } else {
          setShowConfetti(true);
          setTimeout(() => {
            setIsGameCompleted(true);
          }, 5000);
          const finalAudio = new Audio(finalSound);
          finalAudio.play();
        }
      }, 1000);
    } else {
      setTimeout(() => {
        setSelectedAnswer(null);
      }, 1000);
    }
  };

  const handlePlaySound = () => {
    const soundMap = {
      siren: sirenSound,
      carSignal: carSignalSound,
      purchases: purchasesSound,
      ball: ballSound,
      go: goSound,
      whistle: whistleSound,
      peopleTalking: peopleTalkingSound,
      playground: playgroundSound,
      bottle: bottleSound,
      firework: fireworkSound,
      bell: bellSound,
    };

    const currentSound = soundMap[currentTask.correctAnswer];
    if (currentSound) {
      const sound = new Audio(currentSound);
      sound.play();
      setAudioPlayed(true); // Устанавливаем состояние, чтобы звук был воспроизведен
    }
  };

  const handleStartGame = () => {
    setIsInteractionBlocked(true);
    if (startInstructionRef.current) {
      startInstructionRef.current.play();

      startInstructionRef.current.onended = () => {
        setIsInteractionBlocked(false);
      };
    }
    setIsButtonVisible(false);
  };

  useEffect(() => {
    return () => {
      startInstructionRef.current.pause();
      startInstructionRef.current.currentTime = 0;
    };
  }, []);

  return (
    <>
      {isGameCompleted && <EndGameOptions />}
      <div className={`container ${styles.someOtherClass}`}>
        {showConfetti && (
          <Confetti
            numberOfPieces={500}
            angle={180}
            spread={120}
            origin={{ x: 1, y: 0.5 }}
          />
        )}
        <h3 className={styles.header}>{currentTask.question}</h3>
        <h4 className={styles.headerCounter}>
          {" "}
          Вопрос {currentTaskIndex + 1} из {tasks.length}
        </h4>
        <div
          className={styles.backgroundContainer}
          style={{
            pointerEvents: isInteractionBlocked ? "none" : "auto",
          }}
        >
          <div className={styles.imagesContainer}>
            {currentTask.images.map((image) => (
              <div className={styles.imageWrapper}>
                <img
                  key={image.alt}
                  src={image.src}
                  alt={image.alt}
                  className={`${styles.taskImage} ${
                    styles[`${image.alt}Image`]
                  }`}
                  onClick={() => handleImageClick(image.alt)}
                  style={{
                    filter:
                      selectedAnswer === image.alt && isAnswerCorrect
                        ? "drop-shadow(0 10px 50px rgba(50, 205, 50, 0.5))"
                        : selectedAnswer === image.alt &&
                          selectedAnswer !== null
                        ? "none"
                        : "",
                  }}
                />
              </div>
            ))}
            <button className={styles.centralButton} onClick={handlePlaySound}>
              <img src={dynamic} alt="Dynamic" />
              прослушать
            </button>
          </div>
        </div>
        <audio ref={audioRef} />
        {isButtonVisible && (
          <div className={styles.darkOverlay}>
            <button
              className={styles.buttonStartGame}
              onClick={handleStartGame}
            >
              {buttonText}
            </button>
          </div>
        )}
      </div>
    </>
  );
};

export default StreerSoundsGame;
