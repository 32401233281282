import React, { useState, useEffect, useRef } from "react";
import useDynamicHeight from "../../../hooks/useDynamicHeight";
import EndGameOptions from "../../end-game-options/end-game-options";
import Confetti from "react-confetti";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import DraggableItem from "./DraggableItem";
import DropTarget from "./DropTarget";
import styles from "./math-three.module.css";
import figureThree from "./images/3-1.png";
import plate from "./images/plate.png";
import eclairs from "./images/eclairs.png";
import boyWithPlate from "./images/boyWithPlate.png";

import startInstructionSound from "./sounds/startInstruction.mp3";
import backgroundSound from "./sounds/backgroundSound.mp3";
import taskZeroSound from "./sounds/taskZero.mp3";
import taskTwoSound from "./sounds/taskTwo.mp3";
import taskThreeSound from "./sounds/taskThree.mp3";
import taskFourSound from "./sounds/taskFour.mp3";
import ateEclairSound from "./sounds/ateEclair.mp3";
import eclairOnPlateSound from "./sounds/eclairOnPlateSound.mp3";
import praiseSound from "./sounds/praise.mp3";
import finalSound from "./sounds/finalSound.mp3";

const MathFigureThreeGame = () => {
  useDynamicHeight();
  const [isGameCompleted, setIsGameCompleted] = useState(false);
  const [currentTaskIndex, setCurrentTaskIndex] = useState(0);
  const [imageOpacity, setImageOpacity] = useState(1);
  const [buttonText, setButtonText] = useState("Послушать");
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [isButtonVisible, setIsButtonVisible] = useState(true);
  const [isInteractionBlocked, setIsInteractionBlocked] = useState(true);
  const [showConfetti, setShowConfetti] = useState(false);
  const [eclairsTaskTwo, setEclairsTaskTwo] = useState([
    { id: "eclair1" },
    { id: "eclair2" },
    { id: "eclair3" },
    { id: "eclair4" },
    { id: "eclair5" },
    { id: "eclair6" },
    { id: "eclair7" },
  ]);
  const [eclairsOnPlate, setEclairsOnPlate] = useState([]);
  const [eclairsToEat, setEclairsToEat] = useState([
    { id: "eclair1" },
    { id: "eclair2" },
    { id: "eclair3" },
  ]);

  const audioRef = useRef(null);
  const startInstructionAudioRef = useRef(new Audio(startInstructionSound));
  const backgroundAudioRef = useRef(new Audio(backgroundSound));

  const tasks = [
    {
      question: "Это цифра три",
      images: [
        { src: figureThree, alt: "Цифра три", class: styles.figureThree },
      ],
      audioSrc: taskZeroSound,
    },
    {
      question: "А это три эклера",
      images: [
        {
          src: figureThree,
          alt: "Цифра один",
          class: styles.figureThreeWithEclairs,
        },
        { src: eclairs, alt: "Эклер", class: styles.eclairs },
        { src: eclairs, alt: "Эклер", class: styles.eclairs },
        { src: eclairs, alt: "Эклер", class: styles.eclairs },
      ],
      audioSrc: taskTwoSound,
    },
    {
      question: "Положи три эклера на тарелку",
      images: [
        { src: eclairs, alt: "Эклер", id: "eclair1" },
        { src: eclairs, alt: "Эклер", id: "eclair2" },
        { src: eclairs, alt: "Эклер", id: "eclair3" },
        { src: eclairs, alt: "Эклер", id: "eclair4" },
        { src: eclairs, alt: "Эклер", id: "eclair5" },
        { src: eclairs, alt: "Эклер", id: "eclair6" },
        { src: eclairs, alt: "Эклер", id: "eclair7" },
      ],
      audioSrc: taskThreeSound,
    },
    {
      question: "Накорми мальчика тремя эклерами",
      images: [
        {
          src: boyWithPlate,
          alt: "мальчик с тарелкой",
          class: styles.boyWithPlate,
        },
        {
          src: eclairs,
          alt: "Эклер",
          class: styles.eclairsLastTask,
          id: "eclair1",
        },
        {
          src: eclairs,
          alt: "Эклер",
          class: styles.eclairsLastTask,
          id: "eclair2",
        },
        {
          src: eclairs,
          alt: "Эклер",
          class: styles.eclairsLastTask,
          id: "eclair3",
        },
      ],
      audioSrc: taskFourSound,
    },
  ];

  const currentTask = tasks[currentTaskIndex];

  const playSound = () => {
    if (currentTask.audioSrc) {
      setIsInteractionBlocked(true);
      setButtonDisabled(true);

      const taskAudio = new Audio(currentTask.audioSrc);
      taskAudio.play().catch((error) => {
        console.error("Playback failed:", error);
        setButtonDisabled(false);
        setIsInteractionBlocked(false);
      });

      taskAudio.onended = () => {
        setButtonDisabled(false);
        setIsInteractionBlocked(false);

        if (currentTaskIndex === tasks.length - 1) {
          setButtonText("");
        } else {
          setButtonText("Далее");
        }
      };
    }
  };

  useEffect(() => {
    if (currentTaskIndex === 2) {
      if (eclairsOnPlate.length === 3) {
        setButtonDisabled(false);
        setButtonText("Далее");
      } else if (buttonText === "Далее") {
        setButtonDisabled(true);
      }
    }
  }, [eclairsOnPlate, currentTaskIndex, buttonText]);

  const handleNextTask = () => {
    if (buttonText === "Послушать") {
      setIsInteractionBlocked(true); // Блокируем взаимодействие сразу после нажатия
      playSound(); // Воспроизводим звук для текущей задачи
    } else if (buttonText === "Далее" && !buttonDisabled) {
      setImageOpacity(0);
      setTimeout(() => {
        if (currentTaskIndex + 1 < tasks.length) {
          setCurrentTaskIndex(currentTaskIndex + 1);
          setButtonText("Послушать");
          setButtonDisabled(false);
          setIsInteractionBlocked(true); // Снова блокируем после перехода к следующему заданию
        }
        setImageOpacity(1);
      }, 500);
    }
  };

  const handleDrop = (itemId, targetId) => {
    if (targetId === "plate" && eclairsOnPlate.length < 3) {
      const coordinates = [
        { top: "20%", left: "17%" }, // Coordinates for the first éclair
        { top: "20%", left: "41%" }, // Coordinates for the second éclair
        { top: "20%", left: "64%" }, // Coordinates for the third éclair
      ];

      setEclairsOnPlate((prev) => {
        if (prev.length < 3) {
          const eclairSound = new Audio(eclairOnPlateSound);
          eclairSound.play();
          return [...prev, { id: itemId, ...coordinates[prev.length] }];
        }
        return prev;
      });

      setEclairsTaskTwo((prev) =>
        prev.filter((eclair) => eclair.id !== itemId)
      );

      if (eclairsOnPlate.length + 1 === 3) {
        setButtonDisabled(false);
        setButtonText("Далее");
      }
    }
  };

  const handleDropForMouth = (itemId, targetId) => {
    if (targetId === "mouth") {
      setEclairsToEat((prev) => {
        const updated = prev.filter((eclair) => eclair.id !== itemId);

        const ateEclairAudio = new Audio(ateEclairSound);
        ateEclairAudio.play();

        if (updated.length === 0) {
          setButtonDisabled(false);
          setButtonText("Далее");

          const finalAudio = new Audio(finalSound);
          finalAudio.play();

          finalAudio.onended = () => {
            const praiseAudio = new Audio(praiseSound);
            praiseAudio.play();
          };

          setShowConfetti(true);
          setTimeout(() => setShowConfetti(false), 10000);
          setTimeout(() => {
            setIsGameCompleted(true);
          }, 5000);
        } else {
          const eclairSound = new Audio(eclairOnPlateSound);
          eclairSound.play();
        }

        return updated;
      });
    }
  };

  const handleStartGame = () => {
    setButtonDisabled(true);
    setIsInteractionBlocked(true);
    const instructionAudio = startInstructionAudioRef.current;
    instructionAudio.play();

    instructionAudio.onended = () => {
      if (audioRef.current) {
        audioRef.current.play();
      }
      if (backgroundAudioRef.current) {
        backgroundAudioRef.current.play();
      }
      setButtonDisabled(false);
    };

    setIsButtonVisible(false);
  };

  useEffect(() => {
    document.body.style.backgroundColor = "rgba(252, 229, 170, 1)";
    return () => {
      if (startInstructionAudioRef.current) {
        startInstructionAudioRef.current.pause();
        startInstructionAudioRef.current.currentTime = 0;
      }

      if (backgroundAudioRef.current) {
        backgroundAudioRef.current.pause();
        backgroundAudioRef.current.currentTime = 0;
      }

      document.body.style.backgroundColor = "";
    };
  }, []);

  return (
    <DndProvider backend={HTML5Backend}>
      {isGameCompleted && <EndGameOptions />}
      <div className={`container ${styles.someOtherClass}`}>
        <div
          className={styles.backgroundContainer}
          style={{
            pointerEvents: isInteractionBlocked ? "none" : "auto",
          }}
        >
          {showConfetti && (
            <Confetti
              numberOfPieces={500}
              angle={180}
              spread={55}
              origin={{ x: 1, y: 0.5 }}
            />
          )}
          <h3 className={styles.header}>{currentTask.question}</h3>
          <div
            style={{
              opacity: imageOpacity,
              transition: "opacity 0.5s ease-in-out",
            }}
            className={styles.taskContainer}
          >
            {currentTaskIndex === 0 &&
              currentTask.images.map((img, index) => (
                <img
                  key={index}
                  src={img.src}
                  alt={img.alt}
                  className={img.class}
                />
              ))}
            {currentTaskIndex === 1 && (
              <div className={styles.figureAndEclairsContainer}>
                <img
                  src={figureThree}
                  alt="Цифра три"
                  className={styles.figureThree}
                />
                <div className={styles.threeEclairs}>
                  {currentTask.images
                    .filter((img) => img.alt === "Эклер")
                    .map((img, index) => (
                      <img
                        key={index}
                        src={img.src}
                        alt={img.alt}
                        className={img.class}
                      />
                    ))}
                </div>
              </div>
            )}
            {currentTaskIndex === 2 && (
              <div className={styles.eclairsAndPlateContainer}>
                <div className={styles.eclairsContainer}>
                  {eclairsTaskTwo.map((eclair) => (
                    <DraggableItem
                      key={eclair.id}
                      id={eclair.id}
                      src={eclairs}
                      alt="Эклер"
                      className={styles.eclairsTaskTwo}
                      isDisabled={eclairsOnPlate.length >= 2}
                    />
                  ))}
                </div>
                <div className={styles.plateWrapper}>
                  <img src={plate} alt="Тарелка" className={styles.plate} />
                  <DropTarget
                    targetId="plate"
                    onDrop={handleDrop}
                    className={styles.dropTarget}
                    canDrop={eclairsOnPlate.length < 2}
                  >
                    {eclairsOnPlate.map((eclairId, index) => (
                      <img
                        key={index}
                        src={eclairs}
                        alt="Эклер на тарелке"
                        className={styles.eclairOnPlate}
                        style={{ top: eclairId.top, left: eclairId.left }}
                      />
                    ))}
                  </DropTarget>
                </div>
              </div>
            )}
            {currentTaskIndex === 3 && (
              <div className={styles.boyWithEclairsContainer}>
                <img
                  src={boyWithPlate}
                  alt="Мальчик с тарелкой"
                  className={styles.boyWithPlate}
                />
                <div className={styles.eclairsContainerLastTask}>
                  {eclairsToEat.map((eclair, index) => (
                    <DraggableItem
                      key={eclair.id}
                      id={eclair.id}
                      src={eclairs}
                      alt="Эклер"
                      className={styles.eclairsLastTask}
                    />
                  ))}
                </div>
                <DropTarget
                  targetId="mouth"
                  onDrop={handleDropForMouth}
                  className={styles.mouthDropTarget}
                  canDrop={true}
                ></DropTarget>
              </div>
            )}
          </div>
        </div>
        {currentTaskIndex < tasks.length - 1 ? (
          <button
            className={styles.button}
            onClick={handleNextTask}
            disabled={buttonDisabled}
          >
            {buttonText}
          </button>
        ) : (
          buttonText === "Послушать" && (
            <button
              className={styles.button}
              onClick={() => {
                playSound();
                setButtonText("");
              }}
              disabled={buttonDisabled}
            >
              {buttonText}
            </button>
          )
        )}
      </div>
      <audio ref={audioRef} />
      <audio ref={backgroundAudioRef} src={backgroundSound} loop />
      {isButtonVisible && (
        <div className={styles.darkOverlay}>
          <button className={styles.buttonStartGame} onClick={handleStartGame}>
            Начать игру
          </button>
        </div>
      )}
    </DndProvider>
  );
};

export default MathFigureThreeGame;
