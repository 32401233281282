import { useState } from "react";
import styles from "./games-menu.module.css";
import { NavLink } from "react-router-dom";

function GamesMenu({ gamesData, activeCategory, setActiveCategory }) {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState({
    speechDevelopment: false,
    pronunciation: false,
    speechHearing: false,
    memoryAttentionLogic: false,
    math: false,
    world: false,
  });
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const isSubcategoryActive = (subcategoryCount) => {
    return subcategoryCount > 0
      ? styles.subcategoryGames
      : styles.nonActiveLink;
  };

  const isCategoryActive = (categoryName) => {
    return activeCategory.category === categoryName &&
      activeCategory.subcategory === null
      ? styles.activeLink
      : "";
  };

  const handleCategoryOrSubcategoryClick = (
    event,
    category,
    subcategory = null
  ) => {
    event.stopPropagation();
    setActiveCategory({ category, subcategory });

    // Если подкатегория не задана, переключаем состояние выпадающего списка
    if (subcategory === null) {
      setDropdownOpen((prevState) => ({
        ...prevState,
        [category]: !prevState[category],
      }));
    }
  };

  const handleSubcategoryClick = (event, category, subcategory) => {
    event.stopPropagation(); // Это предотвратит всплытие события
    setActiveCategory({ category, subcategory });
  };
  const countGames = (gamesData) => {
    return gamesData.reduce((acc, { category, subcategory }) => {
      acc[category] = acc[category] || { total: 0, subcategories: {} };
      acc[category].total++;
      acc[category].subcategories[subcategory] =
        (acc[category].subcategories[subcategory] || 0) + 1;
      return acc;
    }, {});
  };

  const categoryOrder = [
    "Звукопроизношение",
    "Развитие речи",
    "Слуховое восприятие",
    "Память, внимание и логика",
    "Математика",
    "Окружающий мир",
  ];

  const gamesCount = countGames(gamesData);

  const renderSubcategories = (categoryName, subcategories) => {
    return (
      <ul className={styles.dropdown}>
        {Object.keys(subcategories).map((subcategory) => (
          <li
            key={subcategory}
            onClick={(event) =>
              handleSubcategoryClick(event, categoryName, subcategory)
            }
            className={
              isSubcategoryActive(subcategories[subcategory])
                ? styles.subcategoryGames
                : styles.nonActiveLink
            }
          >
            {subcategory}{" "}
            <span className={styles.quantityGamesText}>
              ({subcategories[subcategory]})
            </span>
          </li>
        ))}
      </ul>
    );
  };

  const renderCategories = () => {
    return categoryOrder.map((categoryName) => (
      <li
        key={categoryName}
        onClick={(event) =>
          handleCategoryOrSubcategoryClick(event, categoryName)
        }
      >
        <NavLink className={isCategoryActive(categoryName)}>
          {categoryName}{" "}
          <span className={styles.quantityGamesText}>
            ({gamesCount[categoryName]?.total || 0})
          </span>
        </NavLink>
        {dropdownOpen[categoryName] &&
          renderSubcategories(
            categoryName,
            gamesCount[categoryName]?.subcategories || {}
          )}
      </li>
    ));
  };

  return (
    <div className={`${styles.header}`}>
      <nav>
        <ul className={styles.links}>{renderCategories()}</ul>
        <button className={styles.hamburger} onClick={toggleMenu}>
          <span></span>
          <span></span>
          <span></span>
        </button>
      </nav>
    </div>
  );
}

export default GamesMenu;
