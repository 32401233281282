import React, { useState, useEffect } from "react";
import { useDrag, DragPreviewImage } from "react-dnd";

// Функция для определения, является ли браузер Chrome
const isChrome =
  /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);

const DraggableItem = ({ id, src, alt, className }) => {
  const [dragCounter, setDragCounter] = useState(0); // Используется только для Chrome

  // console.log(id);

  const [{ isDragging }, drag, preview] = useDrag(
    () => ({
      type: "ITEM",
      item: { id: id },
      collect: (monitor) => ({
        isDragging: !!monitor.isDragging(),
      }),
      end: () => {
        // Обновляем счётчик при завершении перетаскивания, но только в Chrome
        if (isChrome) {
          setDragCounter((prevCounter) => prevCounter + 1);
        }
      },
    }),
    [id]
  );

  useEffect(() => {
    if (isChrome && isDragging) {
      // Обновляем счётчик при начале перетаскивания, но только для Chrome
      setDragCounter((prevCounter) => prevCounter + 1);
    }
  }, [isDragging]);

  // Для Chrome добавляем DragPreviewImage с ключом, который обновляется
  // Для других браузеров DragPreviewImage не используется
  return (
    <>
      {isChrome && (
        <DragPreviewImage connect={preview} src={src} key={dragCounter} />
      )}
      <img
        ref={drag}
        src={src}
        alt={alt}
        style={{
          opacity: isDragging ? 0.5 : 1,
          background: "transparent",
          border: "none",
        }}
        className={className}
      />
    </>
  );
};

export default DraggableItem;
