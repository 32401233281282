import React, { useState, useEffect, useRef } from "react";
import useDynamicHeight from "../../../hooks/useDynamicHeight";
import EndGameOptions from "../../end-game-options/end-game-options";
import Confetti from "react-confetti";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import DraggableItem from "./DraggableItem";
import DropTarget from "./DropTarget";
import styles from "./studying-rhomb.module.css";
import rhomb from "./images/rhomb.png";
import circleOne from "./images/circleOne.svg";
import circleTwo from "./images/circleTwo.svg";
import circleThree from "./images/circleThree.svg";
import rhombTwo from "./images/rhombTwo.svg";
import rhombThree from "./images/rhombThree.svg";
import squareOne from "./images/squareOne.svg";
import squareTwo from "./images/squareTwo.svg";
import triangleOne from "./images/triangleOne.svg";
import triangleTwo from "./images/triangleTwo.svg";
import triagnleThree from "./images/triangleThree.svg";
import btn from "./images/btn.png";
import ball from "./images/ball.png";
import cheese from "./images/cheese.png";
import fish from "./images/fish.png";
import flightSnake from "./images/flightSnake.png";
import spinning from "./images/spinning.png";
import waterlemon from "./images/waterlemon.png";
import triangleBlueOne from "./images/triangleBlueOne.svg";
import triangleBlueTwo from "./images/triangleBlueTwo.svg";
import triangleGreenOne from "./images/triangleGreenOne.svg";
import triangleGreenTwo from "./images/triangleGreenTwo.svg";
import rhombLastTask from "./images/rhombLastTask.png";
import circle from "./images/circle.png";
import cross from "./images/cross.png";
import wallperLastTask from "./images/wallperLastTask.svg";

import backgroundSound from "./sounds/backgroundSound.mp3";
import incorrectAnswerSound from "./sounds/no.mp3";
import correctAnswerSound from "./sounds/correctAnswer.mp3";
import correctAnswerSoundTwo from "./sounds/correctAnswerTwo.mp3";

import taskZeroSound from "./sounds/taskZero.mp3";
import taskTwoSound from "./sounds/taskTwo.mp3";
import taskThreeSound from "./sounds/taskThree.mp3";
import taskFourSound from "./sounds/taskFour.mp3";
import taskFiveSound from "./sounds/taskFive.mp3";

import praiseSound from "./sounds/praise.mp3";

const StudyingRhombGame = () => {
  useDynamicHeight();
  const [isGameCompleted, setIsGameCompleted] = useState(false);
  const [currentTaskIndex, setCurrentTaskIndex] = useState(0);
  const [imageOpacity, setImageOpacity] = useState(1);
  const [buttonText, setButtonText] = useState("Послушать");
  const [isInteractionBlocked, setIsInteractionBlocked] = useState(true);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [selectedRhombs, setSelectedRhombs] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [foundRhombCount, setFoundRhombCount] = useState(0);
  const [trianglePositions, setTrianglePositions] = useState({});
  const [droppedTriangles, setDroppedTriangles] = useState([]);
  const [droppedFigures, setDroppedFigures] = useState({
    cross: null,
    rhombLastTaskOne: null,
    rhombLastTaskTwo: null,
    circle: null,
  });
  const [showConfetti, setShowConfetti] = useState(false);

  const audioRef = useRef(null);
  const backgroundAudioRef = useRef(new Audio(backgroundSound));
  const taskZeroSoundRef = useRef(new Audio(taskZeroSound));
  const taskTwoSoundRef = useRef(new Audio(taskTwoSound));
  const taskThreeSoundRef = useRef(new Audio(taskThreeSound));
  const taskFourSoundRef = useRef(new Audio(taskFourSound));
  const taskFiveSoundRef = useRef(new Audio(taskFiveSound));
  const praiseSoundRef = useRef(new Audio(praiseSound));
  const correctSoundRef = useRef(new Audio(correctAnswerSound));
  const correctSoundTwoRef = useRef(new Audio(correctAnswerSoundTwo));
  const incorrectSoundRef = useRef(new Audio(incorrectAnswerSound));

  const tasks = [
    {
      question: "Это ромб",
      images: [{ src: rhomb, alt: "ромб", class: styles.rhomb }],
      audioRef: taskZeroSoundRef,
    },
    {
      question: "Найди ромбы среди фигур",
      images: [
        {
          src: rhomb,
          alt: "ромб",
          class: styles.rhombSecondTask,
          isRhomb: true,
        },
        {
          src: rhombTwo,
          alt: "ромб",
          class: styles.rhombTwo,
          isRhomb: true,
        },
        {
          src: circleOne,
          alt: "круг",
          class: styles.circleOne,
          isRhomb: false,
        },
        {
          src: circleTwo,
          alt: "круг",
          class: styles.circleTwo,
          isRhomb: false,
        },
        {
          src: circleThree,
          alt: "круг",
          class: styles.circleThree,
          isRhomb: false,
        },
        {
          src: squareOne,
          alt: "квадрат",
          class: styles.squareOne,
          isRhomb: false,
        },
        {
          src: squareTwo,
          alt: "квадрат",
          class: styles.squareTwo,
          isRhomb: false,
        },
        {
          src: triangleOne,
          alt: "треугольник",
          class: styles.triangleOne,
          isRhomb: false,
        },
        {
          src: triangleTwo,
          alt: "треугольник",
          class: styles.triangleTwo,
          isRhomb: false,
        },
        {
          src: triagnleThree,
          alt: "треугольник",
          class: styles.triangleThree,
          isRhomb: false,
        },

        {
          src: rhombThree,
          alt: "ромб",
          class: styles.rhombThree,
          isRhomb: true,
        },
      ],
      audioRef: taskTwoSoundRef,
    },
    {
      question: "Найди предметы похожие на ромб",
      images: [
        {
          src: rhomb,
          alt: "ромб",
          class: styles.largeRhomb,
        },
        {
          src: ball,
          alt: "мяч",
          class: styles.ball,
          isRhomb: false,
        },
        {
          src: cheese,
          alt: "сыр",
          class: styles.cheese,
          isRhomb: false,
        },
        {
          src: btn,
          alt: "кнопка",
          class: styles.btn,
          isRhomb: false,
        },
        {
          src: fish,
          alt: "рыба",
          class: styles.fish,
          isRhomb: true,
        },
        {
          src: flightSnake,
          alt: "змей",
          class: styles.flightSnake,
          isRhomb: true,
        },
        {
          src: spinning,
          alt: "юла",
          class: styles.spinning,
          isRhomb: true,
        },
        {
          src: waterlemon,
          alt: "арбуз",
          class: styles.waterlemon,
          isRhomb: false,
        },
      ],
      audioRef: taskThreeSoundRef,
    },
    {
      question: "Собери из треугольников ромбы",
      images: [
        {
          src: triangleGreenTwo,
          alt: "зеленый треугольник",
          class: styles.triangleGreenTwo,
          id: "triangleGreenTwo",
        },
        {
          src: triangleBlueTwo,
          alt: "синий треугольник",
          class: styles.triangleBlueTwo,
          id: "triangleBlueTwo",
        },
        {
          src: triangleBlueOne,
          alt: "синий треугольник",
          class: styles.triangleBlueOne,
          id: "triangleBlueOne",
        },
        {
          src: triangleGreenOne,
          alt: "зеленый треугольник",
          class: styles.triangleGreenOne,
          id: "triangleGreenOne",
        },
      ],
      audioRef: taskFourSoundRef,
    },
    {
      question: "Закончи судоку. Расставь фигуры в правильные места",
      images: [
        {
          src: circle,
          alt: "круг",
          class: styles.circle,
          id: "circle",
        },
        {
          src: rhombLastTask,
          alt: "ромб",
          class: styles.rhombLastTask,
          id: "rhombLastTask",
        },
        {
          src: cross,
          alt: "крестик",
          class: styles.cross,
          id: "cross",
        },
      ],
      audioRef: taskFiveSoundRef,
    },
  ];

  const currentTask = tasks[currentTaskIndex];

  const playSound = () => {
    const taskAudioRef = currentTask.audioRef;

    if (taskAudioRef && taskAudioRef.current) {
      setIsInteractionBlocked(true);
      setButtonDisabled(true);

      taskAudioRef.current.play().catch((error) => {
        console.error("Playback failed:", error);
        setButtonDisabled(false);
        setIsInteractionBlocked(false);
      });

      taskAudioRef.current.onended = () => {
        setIsInteractionBlocked(false);
        setButtonDisabled(false);
        setButtonText("Далее");
      };
    }
  };

  useEffect(() => {
    if (currentTaskIndex === 1) {
      const allRhombsSelected = selectedRhombs.length === 3;
      setButtonDisabled(!allRhombsSelected && buttonText === "Далее");
    }
  }, [selectedRhombs, currentTaskIndex, buttonText]);

  useEffect(() => {
    if (currentTaskIndex === 2) {
      const allItemsSelected = selectedItems.length === 3;
      setButtonDisabled(!allItemsSelected && buttonText === "Далее");
    }
  }, [selectedItems, currentTaskIndex, buttonText]);

  useEffect(() => {
    if (currentTaskIndex === 3) {
      const allTrianglesDropped = droppedTriangles.length >= 2;
      setButtonDisabled(!allTrianglesDropped && buttonText === "Далее");
    }
  }, [droppedTriangles, currentTaskIndex, buttonText]);

  const handleNextTask = () => {
    if (buttonText === "Послушать") {
      setIsInteractionBlocked(true);
      playSound();
    } else if (buttonText === "Далее" && !buttonDisabled) {
      setImageOpacity(0);
      setTimeout(() => {
        if (currentTaskIndex + 1 < tasks.length) {
          setCurrentTaskIndex(currentTaskIndex + 1);
          setButtonText("Послушать");
          setButtonDisabled(false);
          setIsInteractionBlocked(true);
          if (backgroundAudioRef.current) {
            setTimeout(() => {
              backgroundAudioRef.current.play();
            }, 10);
          }
        }
        setImageOpacity(1);
      }, 500);
    }
  };

  const handleCircleClick = (index, isRhomb) => {
    if (isRhomb && !selectedRhombs.includes(index)) {
      correctSoundRef.current.play();

      const newSelectedRhombs = [...selectedRhombs, index];
      setSelectedRhombs(newSelectedRhombs);

      if (newSelectedRhombs.length === 3) {
        setButtonDisabled(false);
        setButtonText("Далее");
      }
    } else {
      incorrectSoundRef.current.play();
    }
  };

  const handleItemClick = (index, isRhomb) => {
    if (isRhomb && !selectedItems.includes(index)) {
      correctSoundRef.current.play();

      const newSelectedItems = [...selectedItems, index];
      setSelectedItems(newSelectedItems);
      setFoundRhombCount(newSelectedItems.length);

      if (newSelectedItems.length === 3) {
        setButtonDisabled(false);
        setButtonText("Далее");
      }
    } else {
      incorrectSoundRef.current.play();
    }
  };

  const handleTriangleDrop = (item, dropZone) => {
    const positions = {
      triangleBlueOne: { top: "-36%", left: "25%" },
      triangleGreenOne: { top: "-36%", left: "25%" },
    };

    const correctDropZones = {
      triangleBlueOne: "blueDropZone",
      triangleGreenOne: "greenDropZone",
    };

    if (correctDropZones[item.id] === dropZone) {
      correctSoundTwoRef.current.play();
      setTrianglePositions((prevPositions) => ({
        ...prevPositions,
        [item.id]: positions[item.id],
      }));
      setDroppedTriangles((prevDropped) => {
        const updatedDropped = [...prevDropped, item.id];
        if (updatedDropped.length >= 2) {
          setButtonDisabled(false);
          setButtonText("Далее");
        }
        return updatedDropped;
      });
    } else {
      incorrectSoundRef.current.play();
    }
  };

  const handleFigureDropLastTask = (item, dropZone) => {
    const validDropZones = {
      cross: ["dropTargetCross"],
      rhombLastTask: ["dropTargetRhomb", "dropTargetRhombTwo"],
      circle: [],
    };

    if (validDropZones[item.id] && validDropZones[item.id].includes(dropZone)) {
      correctSoundTwoRef.current.play();
      setDroppedFigures((prevFigures) => {
        let updatedFigures = { ...prevFigures };

        if (item.id === "rhombLastTask") {
          if (dropZone === "dropTargetRhomb") {
            updatedFigures.rhombLastTaskOne = dropZone;
          } else if (dropZone === "dropTargetRhombTwo") {
            updatedFigures.rhombLastTaskTwo = dropZone;
          }
        } else {
          updatedFigures[item.id] = dropZone;
        }
        const allFiguresPlaced =
          updatedFigures.rhombLastTaskOne &&
          updatedFigures.rhombLastTaskTwo &&
          updatedFigures.cross;

        if (allFiguresPlaced) {
          praiseSoundRef.current.play();
          setShowConfetti(true);
          setTimeout(() => {
            setIsGameCompleted(true);
          }, 5000);
        }

        return updatedFigures;
      });
    } else {
      incorrectSoundRef.current.play();
    }
  };

  useEffect(() => {
    document.body.style.backgroundColor = "rgba(252, 229, 170, 1)";

    return () => {
      if (taskZeroSoundRef.current) {
        taskZeroSoundRef.current.pause();
        taskZeroSoundRef.current.currentTime = 0;
      }

      if (taskTwoSoundRef.current) {
        taskTwoSoundRef.current.pause();
        taskTwoSoundRef.current.currentTime = 0;
      }

      if (taskThreeSoundRef.current) {
        taskThreeSoundRef.current.pause();
        taskThreeSoundRef.current.currentTime = 0;
      }

      if (taskFourSoundRef.current) {
        taskFourSoundRef.current.pause();
        taskFourSoundRef.current.currentTime = 0;
      }

      if (taskFiveSoundRef.current) {
        taskFiveSoundRef.current.pause();
        taskFiveSoundRef.current.currentTime = 0;
      }

      if (praiseSoundRef.current) {
        praiseSoundRef.current.pause();
        praiseSoundRef.current.currentTime = 0;
      }

      if (backgroundAudioRef.current) {
        backgroundAudioRef.current.pause();
        backgroundAudioRef.current.currentTime = 0;
      }

      document.body.style.backgroundColor = "";
    };
  }, [currentTaskIndex]);

  return (
    <DndProvider backend={HTML5Backend}>
      {showConfetti && (
        <Confetti
          numberOfPieces={500}
          angle={90}
          spread={55}
          origin={{ x: 0, y: 0.5 }}
        />
      )}

      {isGameCompleted && <EndGameOptions />}
      <div className={`container ${styles.someOtherClass}`}>
        <div
          className={styles.backgroundContainer}
          style={{
            pointerEvents: isInteractionBlocked ? "none" : "auto",
          }}
        >
          <h3 className={styles.header}>{currentTask.question}</h3>
          <div
            style={{
              opacity: imageOpacity,
              transition: "opacity 0.5s ease-in-out",
            }}
            className={styles.taskContainer}
          >
            {currentTaskIndex === 0 &&
              currentTask.images.map((img, index) => (
                <img
                  key={index}
                  src={img.src}
                  alt={img.alt}
                  className={img.class}
                />
              ))}
            {currentTaskIndex === 1 && (
              <div className={styles.figures}>
                {currentTask.images.map((image, index) => (
                  <img
                    key={index}
                    src={image.src}
                    alt={image.alt}
                    className={`${image.class} ${
                      selectedRhombs.includes(index)
                        ? styles.selectedCircle
                        : ""
                    }`}
                    onClick={() => handleCircleClick(index, image.isRhomb)}
                  />
                ))}
              </div>
            )}

            {currentTaskIndex === 2 && (
              <div className={styles.triangleTaskContainerTwo}>
                <h4 className={styles.subHeader}>
                  Найдено: {foundRhombCount}/3
                </h4>
                <div className={styles.triangleAndItems}>
                  <div className={styles.itemsLeft}>
                    {currentTask.images.slice(1, 5).map((image, index) => (
                      <div
                        key={index}
                        className={`${styles.itemContainer} ${
                          selectedItems.includes(index + 1)
                            ? styles.selectedCircle
                            : ""
                        }`}
                      >
                        <img
                          src={image.src}
                          alt={image.alt}
                          className={image.class}
                          onClick={() =>
                            handleItemClick(index + 1, image.isRhomb)
                          }
                        />
                      </div>
                    ))}
                  </div>
                  <div className={styles.centerTriangle}>
                    <img
                      src={currentTask.images[0].src}
                      alt={currentTask.images[0].alt}
                      className={styles.largeRhomb}
                    />
                  </div>
                  <div className={styles.itemsRight}>
                    {currentTask.images.slice(5).map((image, index) => (
                      <div
                        key={index + 5}
                        className={`${styles.itemContainer} ${
                          selectedItems.includes(index + 5)
                            ? styles.selectedCircle
                            : ""
                        }`}
                      >
                        <img
                          src={image.src}
                          alt={image.alt}
                          className={image.class}
                          onClick={() =>
                            handleItemClick(index + 5, image.isRhomb)
                          }
                        />
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            )}

            {currentTaskIndex === 3 && (
              <div className={styles.triangleTaskContainer}>
                <div className={styles.triangleContainer}>
                  <DropTarget
                    onDrop={(item) => handleTriangleDrop(item, "greenDropZone")}
                    accept={["triangle"]}
                    className={styles.dropTarget}
                  >
                    <img
                      src={triangleGreenTwo}
                      alt="зеленый треугольник"
                      className={styles.triangleGreenTwo}
                      style={{
                        position: "static",
                      }}
                    />
                    {droppedTriangles.includes("triangleGreenOne") && (
                      <img
                        src={triangleGreenOne}
                        alt="зеленый треугольник"
                        className={`${styles.triangleGreenOne} ${styles.dropTriangleGreen}`}
                        style={{
                          position: "absolute",
                          top: trianglePositions["triangleGreenOne"]?.top,
                          left: trianglePositions["triangleGreenOne"]?.left,
                        }}
                      />
                    )}
                  </DropTarget>
                  <DropTarget
                    onDrop={(item) => handleTriangleDrop(item, "blueDropZone")}
                    accept={["triangle"]}
                    className={styles.dropTarget}
                  >
                    <img
                      src={triangleBlueTwo}
                      alt="синий треугольник"
                      className={styles.triangleBlueTwo}
                      style={{
                        position: "static",
                      }}
                    />
                    {droppedTriangles.includes("triangleBlueOne") && (
                      <img
                        src={triangleBlueOne}
                        alt="синий треугольник"
                        className={`${styles.triangleBlueOne} ${styles.dropTriangleBlue}`}
                        style={{
                          position: "absolute",
                          top: trianglePositions["triangleBlueOne"]?.top,
                          left: trianglePositions["triangleBlueOne"]?.left,
                        }}
                      />
                    )}
                  </DropTarget>
                </div>
                <div className={styles.trianglesContainer}>
                  {currentTask.images
                    .slice(2)
                    .map((image) =>
                      !droppedTriangles.includes(image.id) ? (
                        <DraggableItem
                          key={image.id}
                          item={image}
                          styles={image.class}
                          type="triangle"
                          isDropped={droppedTriangles.includes(image.id)}
                        />
                      ) : null
                    )}
                </div>
              </div>
            )}
            {currentTaskIndex === 4 && (
              <div className={styles.lastTaskContainer}>
                <img
                  src={wallperLastTask}
                  alt="фон"
                  className={styles.wallperLastTask}
                />
                <div className={styles.figuresContainer}>
                  {currentTask.images.map((image) => (
                    <DraggableItem
                      key={image.id}
                      item={image}
                      styles={image.class}
                      type="figure"
                      isDropped={droppedTriangles.includes(image.id)}
                    />
                  ))}
                </div>
                <DropTarget
                  onDrop={(item) =>
                    handleFigureDropLastTask(item, "dropTargetRhombTwo")
                  }
                  accept={["figure"]}
                  className={styles.dropTargetRhombTwo}
                />
                <DropTarget
                  onDrop={(item) =>
                    handleFigureDropLastTask(item, "dropTargetCross")
                  }
                  accept={["figure"]}
                  className={styles.dropTargetCross}
                />
                <DropTarget
                  onDrop={(item) =>
                    handleFigureDropLastTask(item, "dropTargetRhomb")
                  }
                  accept={["figure"]}
                  className={styles.dropTargetRhomb}
                />
                {droppedFigures.rhombLastTaskOne === "dropTargetRhomb" && (
                  <img
                    src={rhombLastTask}
                    alt="ромб"
                    className={`${styles.rhombLastTask} ${styles.droppedRhomb}`}
                  />
                )}
                {droppedFigures.rhombLastTaskTwo === "dropTargetRhombTwo" && (
                  <img
                    src={rhombLastTask}
                    alt="ромб"
                    className={`${styles.rhombLastTask} ${styles.droppedRhombTwo}`}
                  />
                )}
                {droppedFigures.cross === "dropTargetCross" && (
                  <img
                    src={cross}
                    alt="крестик"
                    className={`${styles.cross} ${styles.droppedCross}`}
                  />
                )}
              </div>
            )}
          </div>
        </div>
        {currentTaskIndex < tasks.length - 1 ? (
          <button
            className={styles.button}
            onClick={handleNextTask}
            disabled={buttonDisabled}
          >
            {buttonText}
          </button>
        ) : (
          buttonText === "Послушать" && (
            <button
              className={styles.button}
              onClick={() => {
                playSound();
                setButtonText("");
              }}
              disabled={buttonDisabled}
            >
              {buttonText}
            </button>
          )
        )}
      </div>
      <audio ref={audioRef} />
      <audio ref={backgroundAudioRef} src={backgroundSound} loop />
    </DndProvider>
  );
};
export default StudyingRhombGame;
