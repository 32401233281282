import { Navigate } from "react-router-dom";

function SubscriptionProtectedRoute({ element, paymentInfo }) {
  if (paymentInfo === null) {
    return <div>Loading...</div>;
  }

  const isSubscriptionActive =
    paymentInfo && new Date(paymentInfo.expiresAt) > new Date();

  if (!isSubscriptionActive) {
    return <Navigate to="/subscription" replace />;
  }

  return element;
}

export default SubscriptionProtectedRoute;
