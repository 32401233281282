import React, { useState, useEffect, useRef } from "react";
import useDynamicHeight from "../../../hooks/useDynamicHeight";
import EndGameOptions from "../../end-game-options/end-game-options";
import { gsap } from "gsap";
import Snowfall from "react-snowfall";
import Confetti from "react-confetti";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import DraggableItem from "./DraggableItem";
import DropTarget from "./DropTarget";
import styles from "./studying-circle.module.css";

import circle from "./images/circle.png";
import circleTwo from "./images/circleTwo.svg";
import circleThree from "./images/circleThree.svg";
import rectangle from "./images/rectangle.svg";
import rectangleTwo from "./images/rectangleTwo.svg";
import squareOne from "./images/squareOne.svg";
import squareTwo from "./images/squareTwo.svg";
import squareThree from "./images/squareThree.svg";
import rhombus from "./images/rhombus.svg";
import ball from "./images/ball.png";
import apple from "./images/apple.png";
import clock from "./images/clock.png";
import egg from "./images/egg.png";
import tree from "./images/tree.svg";
import tv from "./images/tv.png";
import window from "./images/window.png";
import greenBusa from "./images/greenBusa.png";
import redBusa from "./images/redBusa.png";
import redSquareBusa from "./images/redSquareBusa.png";
import yellowBusa from "./images/yellowBusa.png";
import yellowSquareBusa from "./images/yellowSquareBusa.png";
import beadThread from "./images/beadThread.svg";
import wallper from "./images/wallper.png";
import snowBallOne from "./images/snowballOne.svg";
import snowBallTwo from "./images/snowballTwo.svg";
import snowBallThree from "./images/snowballThree.svg";
import snowman from "./images/snowman.svg";

import backgroundSound from "./sounds/backgroundSound.mp3";
import incorrectAnswerSound from "./sounds/no.mp3";

import taskZeroSound from "./sounds/taskZero.mp3";
import taskTwoSound from "./sounds/taskTwo.mp3";
import taskThreeSound from "./sounds/taskThree.mp3";
import taskFourSound from "./sounds/taskFour.mp3";
import taskFiveSound from "./sounds/taskFive.mp3";

import rightCircleSound from "./sounds/rightCircle.mp3";
import busaOneSound from "./sounds/busaOne.mp3";
import busaTwoSound from "./sounds/busaTwo.mp3";
import busaThreeSound from "./sounds/busaThree.mp3";
import busaFourSound from "./sounds/busaFour.mp3";
import busesSound from "./sounds/buses.mp3";

import praiseSound from "./sounds/praise.mp3";
import finalSound from "./sounds/finalSound.mp3";

const StudyingCircleGame = () => {
  useDynamicHeight();
  const [isGameCompleted, setIsGameCompleted] = useState(false);
  const [currentTaskIndex, setCurrentTaskIndex] = useState(0);
  const [imageOpacity, setImageOpacity] = useState(1);
  const [buttonText, setButtonText] = useState("Послушать");
  const [isInteractionBlocked, setIsInteractionBlocked] = useState(true);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [selectedCircles, setSelectedCircles] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [foundCirclesCount, setFoundCirclesCount] = useState(0);

  const beadOrder = [
    "redBusa",
    "yellowBusa",
    "redBusa",
    "yellowBusa",
    "redBusa",
    "yellowBusa",
    "redBusa",
    "yellowBusa",
    "redBusa",
    "yellowBusa",
    "redBusa",
  ];
  const [currentBeadIndex, setCurrentBeadIndex] = useState(0);
  const [selectedBeads, setSelectedBeads] = useState([]);
  const [selectedSnowballs, setSelectedSnowballs] = useState([]);
  const [currentSnowballIndex, setCurrentSnowballIndex] = useState(0);
  const [snowballsVisibility, setSnowballsVisibility] = useState({
    snowBallTwo: true,
    snowBallThree: true,
  });
  const [isSnowmanComplete, setIsSnowmanComplete] = useState(false);
  const [showConfetti, setShowConfetti] = useState(false);

  const audioRef = useRef(null);
  const backgroundAudioRef = useRef(new Audio(backgroundSound));
  const taskZeroSoundSoundRef = useRef(new Audio(taskZeroSound));
  const taskTwoSoundSoundRef = useRef(new Audio(taskTwoSound));
  const taskThreeSoundSoundRef = useRef(new Audio(taskThreeSound));
  const taskFourSoundSoundRef = useRef(new Audio(taskFourSound));
  const taskFiveSoundSoundRef = useRef(new Audio(taskFiveSound));

  const praiseSoundRef = useRef(new Audio(praiseSound));
  const finalSoundRef = useRef(new Audio(finalSound));

  const beadSounds = [
    busaOneSound,
    busaTwoSound,
    busaThreeSound,
    busaFourSound,
  ];

  useEffect(() => {
    if (currentTaskIndex === 3) {
      document.body.style.backgroundColor = "#AAD5FC";
    } else {
      document.body.style.backgroundColor = "rgba(252, 229, 170, 1)";
    }
  }, [currentTaskIndex]);

  // useEffect(() => {
  //   if (audioRef.current) {
  //     audioRef.current.onended = () => {
  //       setButtonDisabled(false);
  //       setButtonText("Далее");
  //     };
  //   }
  // }, [currentTaskIndex]);

  useEffect(() => {
    // задача 1
    if (currentTaskIndex === 1) {
      const allCirclesSelected = selectedCircles.length >= 3;
      setButtonDisabled(!allCirclesSelected && buttonText === "Далее");
    }
  }, [selectedCircles, currentTaskIndex, buttonText]);

  useEffect(() => {
    // задача 2
    if (currentTaskIndex === 2) {
      const allItemsSelected = selectedItems.length >= 3;
      setButtonDisabled(!allItemsSelected && buttonText === "Далее");
    }
  }, [selectedItems, currentTaskIndex, buttonText]);

  useEffect(() => {
    if (currentTaskIndex === 3) {
      setButtonText("Далее");
      playSound();
    }
  }, [currentTaskIndex]);

  const tasks = [
    {
      question: "Это круг",
      images: [{ src: circle, alt: "круг", class: styles.circle }],
      audioRef: taskZeroSoundSoundRef,
    },
    {
      question: "Найди круги среди фигур",
      images: [
        {
          src: circle,
          alt: "круг",
          class: styles.circleSecondTask,
          isCircle: true,
        },
        {
          src: circleTwo,
          alt: "круг",
          class: styles.circleTwo,
          isCircle: true,
        },
        {
          src: circleThree,
          alt: "круг",
          class: styles.circleThree,
          isCircle: true,
        },
        {
          src: rectangle,
          alt: "прямоугольник",
          class: styles.rectangle,
          isCircle: false,
        },
        {
          src: rectangleTwo,
          alt: "прямоугольник",
          class: styles.rectangleTwo,
          isCircle: false,
        },
        {
          src: squareOne,
          alt: "квадрат",
          class: styles.squareOne,
          isCircle: false,
        },
        {
          src: squareTwo,
          alt: "квадрат",
          class: styles.squareTwo,
          isCircle: false,
        },
        {
          src: squareThree,
          alt: "квадрат",
          class: styles.squareThree,
          isCircle: false,
        },
        { src: rhombus, alt: "ромб", class: styles.rhombus, isCircle: false },
      ],
      audioRef: taskTwoSoundSoundRef,
    },
    {
      question: "Найди предметы похожие на круг",
      images: [
        {
          src: ball,
          alt: "мяч",
          class: styles.ball,
          isCircle: true,
        },

        {
          src: egg,
          alt: "яйцо",
          class: styles.egg,
          isCircle: false,
        },
        {
          src: apple,
          alt: "яблоко",
          class: styles.apple,
          isCircle: true,
        },
        {
          src: tree,
          alt: "елка",
          class: styles.tree,
          isCircle: false,
        },
        {
          src: tv,
          alt: "телевизор",
          class: styles.tv,
          isCircle: false,
        },
        {
          src: clock,
          alt: "часы",
          class: styles.clock,
          isCircle: true,
        },
        {
          src: window,
          alt: "окно",
          class: styles.window,
          isCircle: false,
        },
      ],
      audioRef: taskThreeSoundSoundRef,
    },
    {
      question:
        "Продолжи нанизывать бусинки на нитку, соблюдая форму и цветовой порядок",
      images: [
        { src: beadThread, alt: "нитка для бусинок", class: styles.beadThread },
        {
          src: greenBusa,
          alt: "зелёная бусинка",
          id: "greenBusa",
          class: styles.bead,
          type: "BEADS",
        },
        {
          src: redBusa,
          alt: "красная бусинка",
          id: "redBusa",
          class: styles.bead,
          type: "BEADS",
        },
        {
          src: redSquareBusa,
          alt: "красная квадратная бусинка",
          id: "redSquareBusa",
          class: styles.bead,
          type: "BEADS",
        },
        {
          src: yellowBusa,
          alt: "жёлтая бусинка",
          id: "yellowBusa",
          class: styles.bead,
          type: "BEADS",
        },
        {
          src: yellowSquareBusa,
          alt: "жёлтая квадратная бусинка",
          id: "yellowSquareBusa",
          class: styles.bead,
          type: "BEADS",
        },
      ],
      audioRef: taskFourSoundSoundRef,
    },
    {
      question: "Слепи снеговика из трех снежных шаров в правильном порядке",
      images: [
        {
          src: snowBallOne,
          alt: "снежный шар",
          id: "snowBallOne",
          class: styles.snowBall1,
          type: "SNOW",
        },
        {
          src: snowBallTwo,
          alt: "снежный шар",
          id: "snowBallTwo",
          class: styles.snowBall2,
          type: "SNOW",
        },
        {
          src: snowBallThree,
          alt: "снежный шар",
          id: "snowBallThree",
          class: styles.snowBall3,
          type: "SNOW",
        },
      ],
      audioRef: taskFiveSoundSoundRef,
    },
  ];

  const currentTask = tasks[currentTaskIndex];

  const playSound = () => {
    const taskAudioRef = currentTask.audioRef;

    if (taskAudioRef && taskAudioRef.current) {
      setIsInteractionBlocked(true);
      setButtonDisabled(true);

      taskAudioRef.current.play().catch((error) => {
        console.error("Playback failed:", error);
        setButtonDisabled(false);
        setIsInteractionBlocked(false);
      });

      taskAudioRef.current.onended = () => {
        setIsInteractionBlocked(false);

        if (currentTaskIndex === 3) {
          animateBeads();
        } else {
          setButtonDisabled(false);
          setButtonText("Далее");
        }

        if (currentTaskIndex === tasks.length - 1) {
          setButtonText("");
        }
      };
    }
  };

  const handleNextTask = () => {
    if (buttonText === "Послушать") {
      setIsInteractionBlocked(true);
      playSound();
    } else if (buttonText === "Далее" && !buttonDisabled) {
      setImageOpacity(0);
      setTimeout(() => {
        if (currentTaskIndex + 1 < tasks.length) {
          setCurrentTaskIndex(currentTaskIndex + 1);
          setButtonText("Послушать");
          setButtonDisabled(false);
          setIsInteractionBlocked(true);
          if (backgroundAudioRef.current) {
            setTimeout(() => {
              backgroundAudioRef.current.play();
            }, 10);
          }
        }
        setImageOpacity(1);
      }, 500);
    }
  };

  const handleCircleClick = (index, isCircle) => {
    const sound = new Audio(isCircle ? rightCircleSound : incorrectAnswerSound);
    sound.play();

    if (isCircle && !selectedCircles.includes(index)) {
      const newSelectedCircles = [...selectedCircles, index];
      setSelectedCircles(newSelectedCircles);
      if (newSelectedCircles.length === 3) {
        setButtonDisabled(false);
        setButtonText("Далее");
      }
    }
  };

  const handleItemClick = (index, isCircle) => {
    const sound = new Audio(isCircle ? rightCircleSound : incorrectAnswerSound);
    sound.play();
    if (isCircle && !selectedItems.includes(index)) {
      const newSelectedItems = [...selectedItems, index];
      setSelectedItems(newSelectedItems);
      setFoundCirclesCount(newSelectedItems.length);
      if (newSelectedItems.length === 3) {
        setButtonDisabled(false);
        setButtonText("Далее");
      }
    }
  };
  const beadPositions = [
    { top: "-8%", left: "24%" },
    { top: "24%", left: "20%" },
    { top: "59%", left: "22%" },
    { top: "72%", left: "30%" },
    { top: "75%", left: "39%" },
    { top: "74%", left: "48%" },
    { top: "70%", left: "57%" },
    { top: "62%", left: "66%" },
    { top: "27%", left: "67%" },
    { top: "-5%", left: "64%" },
    { top: "-15%", left: "56%" },
  ];

  const handleDrop = (item) => {
    if (item.id === beadOrder[currentBeadIndex]) {
      const newBead = {
        ...item,
        top: beadPositions[currentBeadIndex].top,
        left: beadPositions[currentBeadIndex].left,
      };
      setSelectedBeads((prevBeads) => [...prevBeads, newBead]);
      setCurrentBeadIndex((prevIndex) => prevIndex + 1);
      const sound = new Audio(busesSound);
      sound.play();
      if (currentBeadIndex + 1 > 10) {
        setButtonDisabled(false);
        setButtonText("Далее");
      }
    } else {
      const sound = new Audio(incorrectAnswerSound);
      sound.play();
    }
  };
  const animateBeads = () => {
    const initialBeads = ["redBusa", "yellowBusa", "redBusa", "yellowBusa"];
    const newBeads = initialBeads.map((id, index) => {
      const bead = tasks[3].images.find((img) => img.id === id);
      return {
        ...bead,
        top: beadPositions[index].top,
        left: beadPositions[index].left,
      };
    });

    newBeads.forEach((bead, index) => {
      gsap.fromTo(
        bead,
        { top: "0%", left: "0%" },
        {
          top: beadPositions[index].top,
          left: beadPositions[index].left,
          duration: 1,
          delay: index * 0.5,
          onStart: () => {
            setTimeout(() => {
              const sound = new Audio(beadSounds[index]);
              sound.play();
            }, 200);
          },
          onComplete: () => {
            setSelectedBeads((prevBeads) => [...prevBeads, bead]);
            setCurrentBeadIndex((prevIndex) => prevIndex + 1);
          },
        }
      );
    });
  };

  const snowballPositions = [
    { top: "79%", left: "50%" },
    { top: "37%", left: "41%" },
    { top: "21%", left: "43%" },
  ];
  const handleSnowballDrop = (item) => {
    const targetIndex = currentSnowballIndex;
    const snowballOrder = ["snowBallTwo", "snowBallThree"];

    if (item.id === snowballOrder[targetIndex]) {
      const newSnowball = {
        ...item,
        top: snowballPositions[targetIndex + 1].top,
        left: snowballPositions[targetIndex + 1].left,
      };
      setSelectedSnowballs((prevSnowballs) => [...prevSnowballs, newSnowball]);
      setCurrentSnowballIndex((prevIndex) => prevIndex + 1);
      setSnowballsVisibility((prevVisibility) => ({
        ...prevVisibility,
        [item.id]: false,
      }));

      const sound = new Audio(rightCircleSound);
      sound.play();

      if (targetIndex === 1) {
        setTimeout(() => {
          animateSnowman();
          finalSoundRef.current.play();

          setTimeout(() => {
            setShowConfetti(true);
            setTimeout(() => {
              setIsGameCompleted(true);
            }, 5000);
            praiseSoundRef.current.play();
          }, 500);
        }, 500);
      }
    } else {
      const sound = new Audio(incorrectAnswerSound);
      sound.play();
    }
  };

  const animateSnowman = () => {
    const tl = gsap.timeline();

    tl.to(".snowBall", {
      duration: 1,
      opacity: 0,
      scale: 0.5,
      y: -50,
      ease: "power1.inOut",
      onComplete: () => {
        setIsSnowmanComplete(true);
      },
    }).fromTo(
      ".snowman",
      { opacity: 0, scale: 0.5 },
      { opacity: 1, scale: 1, duration: 1, ease: "power1.inOut" }
    );
  };

  useEffect(() => {
    if (currentTaskIndex === 3) {
      document.body.style.backgroundColor = "#AAD5FC";
    } else {
      document.body.style.backgroundColor = "rgba(252, 229, 170, 1)";
    }

    return () => {
      if (taskZeroSoundSoundRef.current) {
        taskZeroSoundSoundRef.current.pause();
        taskZeroSoundSoundRef.current.currentTime = 0;
      }

      if (taskTwoSoundSoundRef.current) {
        taskTwoSoundSoundRef.current.pause();
        taskTwoSoundSoundRef.current.currentTime = 0;
      }

      if (taskThreeSoundSoundRef.current) {
        taskThreeSoundSoundRef.current.pause();
        taskThreeSoundSoundRef.current.currentTime = 0;
      }

      if (taskFourSoundSoundRef.current) {
        taskFourSoundSoundRef.current.pause();
        taskFourSoundSoundRef.current.currentTime = 0;
      }

      if (taskFiveSoundSoundRef.current) {
        taskFiveSoundSoundRef.current.pause();
        taskFiveSoundSoundRef.current.currentTime = 0;
      }

      if (praiseSoundRef.current) {
        praiseSoundRef.current.pause();
        praiseSoundRef.current.currentTime = 0;
      }

      if (finalSoundRef.current) {
        finalSoundRef.current.pause();
        finalSoundRef.current.currentTime = 0;
      }

      if (backgroundAudioRef.current) {
        backgroundAudioRef.current.pause();
        backgroundAudioRef.current.currentTime = 0;
      }

      document.body.style.backgroundColor = "";

      gsap.globalTimeline.clear();
    };
  }, [currentTaskIndex]);

  return (
    <DndProvider backend={HTML5Backend}>
      {showConfetti && (
        <Confetti
          numberOfPieces={500}
          angle={180}
          spread={120}
          origin={{ x: 1, y: 0.5 }}
        />
      )}

      {isGameCompleted && <EndGameOptions />}
      <div className={`container ${styles.someOtherClass}`}>
        <div
          className={styles.backgroundContainer}
          style={{
            pointerEvents: isInteractionBlocked ? "none" : "auto",
          }}
        >
          <h3 className={styles.header}>{currentTask.question}</h3>
          <div
            style={{
              opacity: imageOpacity,
              transition: "opacity 0.5s ease-in-out",
            }}
            className={styles.taskContainer}
          >
            {currentTaskIndex === 0 &&
              currentTask.images.map((img, index) => (
                <img
                  key={index}
                  src={img.src}
                  alt={img.alt}
                  className={img.class}
                />
              ))}
            {currentTaskIndex === 1 && (
              <div className={styles.figures}>
                {currentTask.images.map((image, index) => (
                  <img
                    key={index}
                    src={image.src}
                    alt={image.alt}
                    className={`${image.class} ${
                      selectedCircles.includes(index)
                        ? styles.selectedCircle
                        : ""
                    }`}
                    onClick={() => handleCircleClick(index, image.isCircle)}
                  />
                ))}
              </div>
            )}

            {currentTaskIndex === 2 && (
              <div className={styles.secondTaskContainer}>
                <h4 className={styles.subHeader}>
                  Найдено: {foundCirclesCount}/3
                </h4>
                <div className={styles.row}>
                  {currentTask.images.slice(0, 4).map((image, index) => (
                    <div
                      key={index}
                      className={`${styles.itemContainer} ${
                        selectedItems.includes(index) ? styles.hidden : ""
                      }`}
                    >
                      <img
                        src={image.src}
                        alt={image.alt}
                        className={image.class}
                        onClick={() => handleItemClick(index, image.isCircle)}
                      />
                    </div>
                  ))}
                </div>
                <div className={styles.row}>
                  {currentTask.images.slice(4).map((image, index) => (
                    <div
                      key={index + 4}
                      className={`${styles.itemContainer} ${
                        selectedItems.includes(index + 4) ? styles.hidden : ""
                      }`}
                    >
                      <img
                        src={image.src}
                        alt={image.alt}
                        className={image.class}
                        onClick={() =>
                          handleItemClick(index + 4, image.isCircle)
                        }
                      />
                    </div>
                  ))}
                </div>
              </div>
            )}
            {currentTaskIndex === 3 && (
              <div className={styles.beadTaskContainer}>
                <DropTarget onDrop={handleDrop} accept={["BEADS"]}>
                  <div className={styles.threadContainer}>
                    <img
                      src={currentTask.images[0].src}
                      alt={currentTask.images[0].alt}
                      className={currentTask.images[0].class}
                    />
                    <div className={styles.selectedBeads}>
                      {selectedBeads.map((bead, index) => (
                        <img
                          key={index}
                          src={bead.src}
                          alt={bead.alt}
                          className={styles.bead}
                          style={{ top: bead.top, left: bead.left }}
                        />
                      ))}
                    </div>
                  </div>
                </DropTarget>
                <div className={styles.beadsContainer}>
                  {currentTask.images.slice(1).map((img, index) => (
                    <DraggableItem
                      key={index}
                      item={img}
                      index={index}
                      type="BEADS"
                    />
                  ))}
                </div>
              </div>
            )}
            {currentTaskIndex === 4 && (
              <div className={styles.snowmanTaskContainer}>
                <img src={wallper} alt="фон" className={styles.wallper} />
                <DropTarget onDrop={handleSnowballDrop} accept={["SNOW"]}>
                  <div className={styles.snowBallsContainer}>
                    {isSnowmanComplete ? (
                      <>
                        <img
                          src={snowman}
                          alt="Снеговик"
                          className={styles.snowman}
                        />
                      </>
                    ) : (
                      <>
                        <img
                          src={currentTask.images[0].src}
                          alt={currentTask.images[0].alt}
                          className={`${styles.snowBall} ${styles.snowBall1}`}
                        />
                        <div className={styles.selectedSnowballs}>
                          {selectedSnowballs.map((snowball, index) => (
                            <img
                              key={index}
                              src={snowball.src}
                              alt={snowball.alt}
                              className={`${styles.snowBall} ${
                                styles[`snowBall${index + 2}`]
                              }`}
                              style={{ top: snowball.top, left: snowball.left }}
                            />
                          ))}
                        </div>
                      </>
                    )}
                  </div>
                </DropTarget>
                <div className={styles.draggableSnowballsContainer}>
                  {currentTask.images
                    .slice(1)
                    .map(
                      (img, index) =>
                        snowballsVisibility[img.id] && (
                          <DraggableItem key={index} item={img} type="SNOW" />
                        )
                    )}
                </div>
                <Snowfall snowflakeCount={500} />
              </div>
            )}
          </div>
        </div>
        {currentTaskIndex < tasks.length - 1 ? (
          <button
            className={styles.button}
            onClick={handleNextTask}
            disabled={buttonDisabled}
          >
            {buttonText}
          </button>
        ) : (
          buttonText === "Послушать" && (
            <button
              className={styles.button}
              onClick={() => {
                playSound();
                setButtonText("");
              }}
              disabled={buttonDisabled}
            >
              {buttonText}
            </button>
          )
        )}
      </div>
      <audio ref={audioRef} />
      <audio ref={backgroundAudioRef} src={backgroundSound} loop />
    </DndProvider>
  );
};
export default StudyingCircleGame;
