import React from "react";
import styles from "./success-change-modal.module.css";

function SuccessChangePasswordModal({ coloseSuccessPasswordModal }) {
  const handleClick = () => {
    coloseSuccessPasswordModal();
  };
  return (
    <div className={styles.container}>
      <h3 className={styles.header}>Ваш пароль успешно изменен</h3>
      <span className={styles.text}>
        Пожалуйста, сохраните новый пароль в надежном месте.
      </span>
      <button type="button" className={styles.btnLogin} onClick={handleClick}>
        Понятно!
      </button>
    </div>
  );
}

export default SuccessChangePasswordModal;
