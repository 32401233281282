import React, { useState, useEffect, useRef } from "react";
import useDynamicHeight from "../../../hooks/useDynamicHeight";
import EndGameOptions from "../../end-game-options/end-game-options";
import Confetti from "react-confetti";
import styles from "./find-in-park.module.css";
import wallperImage from "./images/wallper.png";
import squirrel from "./images/squirrel.png";
import girlBycicle from "./images/girlBycicle.png";
import grandpa from "./images/grandpa.png";
import manGo from "./images/manGo.png";
import rabbit from "./images/rabbit.png";
import babyGo from "./images/babyGo.png";
import boyCry from "./images/boyCry.png";
import momSong from "./images/momSong.png";
import bear from "./images/bear.png";
import fatherCook from "./images/fatherCook.png";
import star from "./images/star.svg";
import repeatTask from "./images/repeatTask.png";

import startInstructionSound from "./sounds/startInstruction.mp3";
import bonusStarSound from "./sounds/bonusStar.mp3";
import finalSound from "./sounds/finalSound.mp3";
import incorrectAnswerSound from "./sounds/no.mp3";

import babySpeakerSound from "./sounds/babySpeaker.mp3";
import babyActionSound from "./sounds/babyAction.mp3";
import bearSpeakerSound from "./sounds/bearSpeaker.mp3";
import bearActionSound from "./sounds/bearAction.mp3";
import boySpeakerSound from "./sounds/boySpeaker.mp3";
import boyActionSound from "./sounds/boyAction.mp3";
import fatherSpeakerSound from "./sounds/fatherSpeaker.mp3";
import fatherActionSound from "./sounds/fatherAction.mp3";
import girlSpeakerSound from "./sounds/girlSpeaker.mp3";
import girlActionSound from "./sounds/girlAction.mp3";
import grandpaSpeakerSound from "./sounds/grandpaSpeaker.mp3";
import grandpaActionSound from "./sounds/grandpaAction.mp3";
import motherSpeakerSound from "./sounds/motherSpeaker.mp3";
import motherActionSound from "./sounds/motherAction.mp3";
import rabbitSpeakerSound from "./sounds/rabbitSpeaker.mp3";
import rabbitActionSound from "./sounds/rabbitAction.mp3";
import squirellActionSound from "./sounds/squirellAction.mp3";
import squirellSpeakerSound from "./sounds/squirellSpeaker.mp3";
import uncleSpeakerSound from "./sounds/uncleSpeaker.mp3";
import uncleActionSound from "./sounds/uncleAction.mp3";

const FindInParkGame = () => {
  useDynamicHeight();
  const [isInteractionBlocked, setIsInteractionBlocked] = useState(false);
  const [isGameCompleted, setIsGameCompleted] = useState(false);
  const [isBackgroundLoaded, setIsBackgroundLoaded] = useState(false);
  const [buttonText, setButtonText] = useState("Начать игру");
  const [isButtonVisible, setIsButtonVisible] = useState(true);
  const [showConfetti, setShowConfetti] = useState(false);
  const audioRef = useRef(null);
  const startInstructionAudioRef = useRef(new Audio(startInstructionSound));
  const [currentTaskIndex, setCurrentTaskIndex] = useState(0);
  const [isAnswerCorrect, setIsAnswerCorrect] = useState(null);
  const [selectedAnswer, setSelectedAnswer] = useState(null);
  const [stars, setStars] = useState([]);

  const tasks = [
    {
      question: "Найдите медведя",
      images: [
        { src: squirrel, alt: "squirrel" },
        { src: girlBycicle, alt: "girlBycicle" },
        { src: grandpa, alt: "grandpa" },
        { src: manGo, alt: "manGo" },
        { src: rabbit, alt: "rabbit" },
        { src: babyGo, alt: "babyGo" },
        { src: boyCry, alt: "boyCry" },
        { src: momSong, alt: "momSong" },
        { src: bear, alt: "bear" },
        { src: fatherCook, alt: "fatherCook" },
      ],
      correctAnswer: "bear",
      instructionSound: bearSpeakerSound,
    },
    {
      question: "Найдите зайца",
      images: [
        { src: squirrel, alt: "squirrel" },
        { src: girlBycicle, alt: "girlBycicle" },
        { src: grandpa, alt: "grandpa" },
        { src: manGo, alt: "manGo" },
        { src: rabbit, alt: "rabbit" },
        { src: babyGo, alt: "babyGo" },
        { src: boyCry, alt: "boyCry" },
        { src: momSong, alt: "momSong" },
        { src: bear, alt: "bear" },
        { src: fatherCook, alt: "fatherCook" },
      ],
      correctAnswer: "boyCry",
      instructionSound: boySpeakerSound,
    },
    {
      question: "Найдите зайца",
      images: [
        { src: squirrel, alt: "squirrel" },
        { src: girlBycicle, alt: "girlBycicle" },
        { src: grandpa, alt: "grandpa" },
        { src: manGo, alt: "manGo" },
        { src: rabbit, alt: "rabbit" },
        { src: babyGo, alt: "babyGo" },
        { src: boyCry, alt: "boyCry" },
        { src: momSong, alt: "momSong" },
        { src: bear, alt: "bear" },
        { src: fatherCook, alt: "fatherCook" },
      ],
      correctAnswer: "fatherCook",
      instructionSound: fatherSpeakerSound,
    },
    {
      question: "Найдите зайца",
      images: [
        { src: squirrel, alt: "squirrel" },
        { src: girlBycicle, alt: "girlBycicle" },
        { src: grandpa, alt: "grandpa" },
        { src: manGo, alt: "manGo" },
        { src: rabbit, alt: "rabbit" },
        { src: babyGo, alt: "babyGo" },
        { src: boyCry, alt: "boyCry" },
        { src: momSong, alt: "momSong" },
        { src: bear, alt: "bear" },
        { src: fatherCook, alt: "fatherCook" },
      ],
      correctAnswer: "momSong",
      instructionSound: motherSpeakerSound,
    },
    {
      question: "Найдите зайца",
      images: [
        { src: squirrel, alt: "squirrel" },
        { src: girlBycicle, alt: "girlBycicle" },
        { src: grandpa, alt: "grandpa" },
        { src: manGo, alt: "manGo" },
        { src: rabbit, alt: "rabbit" },
        { src: babyGo, alt: "babyGo" },
        { src: boyCry, alt: "boyCry" },
        { src: momSong, alt: "momSong" },
        { src: bear, alt: "bear" },
        { src: fatherCook, alt: "fatherCook" },
      ],
      correctAnswer: "squirrel",
      instructionSound: squirellSpeakerSound,
    },
    {
      question: "Найдите зайца",
      images: [
        { src: squirrel, alt: "squirrel" },
        { src: girlBycicle, alt: "girlBycicle" },
        { src: grandpa, alt: "grandpa" },
        { src: manGo, alt: "manGo" },
        { src: rabbit, alt: "rabbit" },
        { src: babyGo, alt: "babyGo" },
        { src: boyCry, alt: "boyCry" },
        { src: momSong, alt: "momSong" },
        { src: bear, alt: "bear" },
        { src: fatherCook, alt: "fatherCook" },
      ],
      correctAnswer: "manGo",
      instructionSound: uncleSpeakerSound,
    },
    {
      question: "Найдите зайца",
      images: [
        { src: squirrel, alt: "squirrel" },
        { src: girlBycicle, alt: "girlBycicle" },
        { src: grandpa, alt: "grandpa" },
        { src: manGo, alt: "manGo" },
        { src: rabbit, alt: "rabbit" },
        { src: babyGo, alt: "babyGo" },
        { src: boyCry, alt: "boyCry" },
        { src: momSong, alt: "momSong" },
        { src: bear, alt: "bear" },
        { src: fatherCook, alt: "fatherCook" },
      ],
      correctAnswer: "babyGo",
      instructionSound: babySpeakerSound,
    },
    {
      question: "Найдите зайца",
      images: [
        { src: squirrel, alt: "squirrel" },
        { src: girlBycicle, alt: "girlBycicle" },
        { src: grandpa, alt: "grandpa" },
        { src: manGo, alt: "manGo" },
        { src: rabbit, alt: "rabbit" },
        { src: babyGo, alt: "babyGo" },
        { src: boyCry, alt: "boyCry" },
        { src: momSong, alt: "momSong" },
        { src: bear, alt: "bear" },
        { src: fatherCook, alt: "fatherCook" },
      ],
      correctAnswer: "girlBycicle",
      instructionSound: girlSpeakerSound,
    },
    {
      question: "Найдите зайца",
      images: [
        { src: squirrel, alt: "squirrel" },
        { src: girlBycicle, alt: "girlBycicle" },
        { src: grandpa, alt: "grandpa" },
        { src: manGo, alt: "manGo" },
        { src: rabbit, alt: "rabbit" },
        { src: babyGo, alt: "babyGo" },
        { src: boyCry, alt: "boyCry" },
        { src: momSong, alt: "momSong" },
        { src: bear, alt: "bear" },
        { src: fatherCook, alt: "fatherCook" },
      ],
      correctAnswer: "rabbit",
      instructionSound: rabbitSpeakerSound,
    },
    {
      question: "Найдите зайца",
      images: [
        { src: squirrel, alt: "squirrel" },
        { src: girlBycicle, alt: "girlBycicle" },
        { src: grandpa, alt: "grandpa" },
        { src: manGo, alt: "manGo" },
        { src: rabbit, alt: "rabbit" },
        { src: babyGo, alt: "babyGo" },
        { src: boyCry, alt: "boyCry" },
        { src: momSong, alt: "momSong" },
        { src: bear, alt: "bear" },
        { src: fatherCook, alt: "fatherCook" },
      ],
      correctAnswer: "grandpa",
      instructionSound: grandpaSpeakerSound,
    },
  ];

  const currentTask = tasks[currentTaskIndex];

  const handleImageClick = (answer) => {
    if (isAnswerCorrect) return;

    setSelectedAnswer(answer);
    const isCorrect = answer === currentTask.correctAnswer;
    setIsAnswerCorrect(isCorrect);

    if (isCorrect) {
      const correctActionSound = new Audio(
        getActionSound(currentTask.correctAnswer)
      );
      correctActionSound.play();
      correctActionSound.onended = () => {
        const bonusSound = new Audio(bonusStarSound);
        bonusSound.play();

        if (stars.length === 0) {
          setStars([...stars, { x: 95, y: 1, image: star }]);
        } else {
          const newStar = {
            x: stars[stars.length - 1].x - 4,
            y: stars[stars.length - 1].y + 0,
            image: star,
          };
          setStars((prevStars) => [...prevStars, newStar]);
        }

        const updatedImages = currentTask.images.filter(
          (image) => image.alt !== currentTask.correctAnswer
        );

        setTimeout(() => {
          setIsAnswerCorrect(null);
          setSelectedAnswer(null);
          const nextIndex = (currentTaskIndex + 1) % tasks.length;

          if (nextIndex === 0) {
            const finalAudio = new Audio(finalSound);
            setShowConfetti(true);
            setTimeout(() => {
              setIsGameCompleted(true);
            }, 5000);
            finalAudio.play();
            finalAudio.onended = () => {};
          } else {
            setCurrentTaskIndex(nextIndex);

            setTimeout(() => {
              const taskAudio = new Audio(tasks[nextIndex].instructionSound);
              taskAudio.play();
            }, 1000);
          }
        }, 1000);
      };
    } else {
      const incorrectAudio = new Audio(incorrectAnswerSound);
      incorrectAudio.play();
    }
  };

  const getActionSound = (correctAnswer) => {
    switch (correctAnswer) {
      case "bear":
        return bearActionSound;
      case "rabbit":
        return rabbitActionSound;
      case "boyCry":
        return boyActionSound;
      case "fatherCook":
        return fatherActionSound;
      case "momSong":
        return motherActionSound;
      case "squirrel":
        return squirellActionSound;
      case "manGo":
        return uncleActionSound;
      case "babyGo":
        return babyActionSound;
      case "girlBycicle":
        return girlActionSound;
      case "grandpa":
        return grandpaActionSound;
      default:
        return "";
    }
  };

  const handleNextTask = () => {
    if (audioRef.current) {
      startInstructionAudioRef.current.play();

      startInstructionAudioRef.current.onended = () => {
        setTimeout(() => {
          const taskAudio = new Audio(tasks[0].instructionSound);
          taskAudio.play();
          taskAudio.onended = () => {
            setCurrentTaskIndex(0); // Переход к первому заданию после инструкции
          };
        }, 200); // Небольшая пауза перед воспроизведением motherGoSound
      };
    }
    setIsButtonVisible(false);
  };

  const handleRepeatTask = () => {
    const currentInstructionSound = new Audio(
      tasks[currentTaskIndex].instructionSound || startInstructionSound
    );
    currentInstructionSound.play();
  };

  useEffect(() => {
    return () => {
      startInstructionAudioRef.current.pause();
      startInstructionAudioRef.current.currentTime = 0;
    };
  }, []);

  return (
    <>
      {isGameCompleted && <EndGameOptions />}
      <div className={`container ${styles.someOtherClass}`}>
        {showConfetti && (
          <Confetti
            numberOfPieces={1000}
            angle={180}
            spread={120}
            origin={{ x: 1, y: 0.5 }}
          />
        )}
        <div
          className={styles.backgroundContainer}
          style={{
            pointerEvents: isInteractionBlocked ? "none" : "auto",
          }}
        >
          <img className={styles.wallperImage} src={wallperImage} alt="" />
          <div className={styles.imagesContainer}>
            {currentTask.images.map((image) => (
              <img
                key={image.alt}
                src={image.src}
                alt={image.alt}
                className={`${styles.taskImage} ${styles[`${image.alt}Image`]}`}
                onClick={() => handleImageClick(image.alt)}
                style={{
                  filter:
                    selectedAnswer === image.alt && isAnswerCorrect
                      ? "drop-shadow(0 10px 50px rgba(50, 205, 50, 0.5)) brightness(150%) saturate(200%)"
                      : selectedAnswer === image.alt && selectedAnswer !== null
                      ? "none"
                      : "",
                }}
              />
            ))}
            {stars.map((star, index) => (
              <img
                key={index}
                alt="star"
                src={star.image}
                style={{
                  position: "absolute",
                  width: "4%",
                  left: `${star.x}%`,
                  top: `${star.y}%`,
                }}
              />
            ))}
          </div>

          <button className={styles.repeatButton} onClick={handleRepeatTask}>
            <img src={repeatTask} alt="Повторить инструкцию" />
            <span className={styles.tooltip}>
              Нажмите для повторного прослушивания инструкции
            </span>
          </button>
        </div>
        <audio ref={audioRef} />
        {isButtonVisible && (
          <div className={styles.darkOverlay}>
            <button className={styles.button} onClick={handleNextTask}>
              {buttonText}
            </button>
          </div>
        )}
      </div>
    </>
  );
};

export default FindInParkGame;
