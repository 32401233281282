import React, { useState, useEffect, useRef } from "react";
import useDynamicHeight from "../../../hooks/useDynamicHeight";
import EndGameOptions from "../../end-game-options/end-game-options";
import Confetti from "react-confetti";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import styles from "./find-in-room.module.css";
import wallperImage from "./images/wallper.png";
import alarm from "./images/alarm.svg";
import shoes from "./images/shoes.svg";
import cubes from "./images/cubes.svg";
import car from "./images/car.svg";
import ball from "./images/ball.svg";
import scooter from "./images/scooter.svg";
import aircraft from "./images/aircraft.svg";
import DraggableItem from "../../DnD/drag";
import DropTarget from "../../DnD/drop";

import backgroundSound from "./sounds/backgroundSound.mp3";
import startInstructionSound from "./sounds/startInstruction.mp3";
import finalSound from "./sounds/finalSound.mp3";
import incorrectAnswerSound from "./sounds/no.mp3";
import aircraftSound from "./sounds/aircraft.mp3";
import alarmSound from "./sounds/alarm.mp3";
import ballSound from "./sounds/ball.mp3";
import carSound from "./sounds/car.mp3";
import cubesSound from "./sounds/cubes.mp3";
import scooterSound from "./sounds/scooter.mp3";
import shoesSound from "./sounds/shoes.mp3";

const images = [
  {
    id: "буцы",
    src: shoes,
    alt: "буцы",
    className: styles.shoesImage,
    dropStyle: {
      left: "15%",
      top: "77%",
      width: "10%",
    },
    shadowStyle: {
      left: "14",
      top: "75",
    },
    targetId: "буцы",
  },
  {
    id: "машинка",
    src: car,
    alt: "машинка",
    className: styles.carImage,
    dropStyle: {
      left: "59%",
      top: "75%",
      width: "12%",
    },
    shadowStyle: {
      left: "59",
      top: "75",
    },
    targetId: "машинка",
  },
  {
    id: "кубики",
    src: cubes,
    alt: "кубики",
    className: styles.cubesImage,
    dropStyle: {
      left: "71%",
      top: "79%",
      width: "7%",
    },
    shadowStyle: {
      left: "70",
      top: "75",
    },
    targetId: "кубики",
  },
  {
    id: "мяч",
    src: ball,
    alt: "мяч",
    className: styles.ballImage,
    dropStyle: {
      left: "49%",
      top: "73%",
      width: "9%",
    },
    shadowStyle: {
      left: "49",
      top: "73",
    },
    targetId: "мяч",
  },
  {
    id: "будильник",
    src: alarm,
    alt: "будильник",
    className: styles.alarmImage,
    dropStyle: {
      left: "76%",
      top: "13%",
      width: "8%",
    },
    shadowStyle: {
      left: "75",
      top: "13",
    },
    targetId: "будильник",
  },
  {
    id: "самолет",
    src: aircraft,
    alt: "самолет",
    className: styles.aircraftImage,
    dropStyle: {
      left: "56%",
      top: "14%",
      width: "12%",
    },
    shadowStyle: {
      left: "57",
      top: "13",
    },
    targetId: "самолет",
  },
  {
    id: "самокат",
    src: scooter,
    alt: "самокат",
    className: styles.scooterImage,
    dropStyle: {
      left: "30.5%",
      top: "50%",
      width: "15%",
    },
    shadowStyle: {
      left: "33",
      top: "66",
    },
    targetId: "самокат",
  },
];

const FindInRoomGame = () => {
  useDynamicHeight();
  const [isInteractionBlocked, setIsInteractionBlocked] = useState(false);
  const [isGameCompleted, setIsGameCompleted] = useState(false);
  const [showConfetti, setShowConfetti] = useState(false);
  const [buttonText, setButtonText] = useState("Начать игру");
  const [isButtonVisible, setIsButtonVisible] = useState(true);
  const audioRef = useRef(null);
  const startInstructionAudioRef = useRef(new Audio(startInstructionSound));
  const backgroundAudioRef = useRef(new Audio(backgroundSound));
  const [itemPlaced, setItemPlaced] = useState(
    images.reduce(
      (acc, image) => ({
        ...acc,
        [image.alt]: false,
      }),
      {}
    )
  );

  const handleDrop = (itemId, targetId) => {
    if (itemId === targetId) {
      setItemPlaced((prev) => {
        const updatedItemPlaced = {
          ...prev,
          [itemId]: true,
        };

        // Воспроизведение звука при успешном размещении предмета
        let sound;
        switch (itemId) {
          case "буцы":
            sound = new Audio(shoesSound);
            break;
          case "машинка":
            sound = new Audio(carSound);
            break;
          case "кубики":
            sound = new Audio(cubesSound);
            break;
          case "мяч":
            sound = new Audio(ballSound);
            break;
          case "будильник":
            sound = new Audio(alarmSound);
            break;
          case "самолет":
            sound = new Audio(aircraftSound);
            break;
          case "самокат":
            sound = new Audio(scooterSound);
            break;
          default:
            break;
        }

        if (sound) {
          sound.play();
          sound.onended = () => {
            const allPlaced = Object.values(updatedItemPlaced).every(Boolean);
            if (allPlaced) {
              setShowConfetti(true);
              setTimeout(() => {
                setIsGameCompleted(true);
              }, 5000);

              const finalAudio = new Audio(finalSound);
              finalAudio.play();
            }
          };
        }
        return updatedItemPlaced;
      });
    } else {
      const incorrectSound = new Audio(incorrectAnswerSound);
      incorrectSound.play();
    }
  };

  const handleNextTask = () => {
    setIsInteractionBlocked(true);

    if (startInstructionAudioRef.current) {
      startInstructionAudioRef.current.play();

      startInstructionAudioRef.current.onended = () => {
        setIsInteractionBlocked(false);
        if (backgroundAudioRef.current) {
          backgroundAudioRef.current.loop = true;
          backgroundAudioRef.current.play();
        }
      };
    }

    setIsButtonVisible(false);
  };

  useEffect(() => {
    document.body.style.backgroundColor = "rgba(29,44,46,255)";
    return () => {
      // Остановить и сбросить все аудио
      startInstructionAudioRef.current.pause();
      startInstructionAudioRef.current.currentTime = 0;
      backgroundAudioRef.current.pause();
      backgroundAudioRef.current.currentTime = 0;

      document.body.style.backgroundColor = "";
    };
  }, []);

  return (
    <>
      <DndProvider backend={HTML5Backend}>
        {showConfetti && (
          <Confetti
            numberOfPieces={500}
            angle={180}
            spread={120}
            origin={{ x: 1, y: 0.5 }}
          />
        )}
        {isGameCompleted && <EndGameOptions />}
        <div className={`container ${styles.someOtherClass}`}>
          <div
            className={styles.backgroundContainer}
            style={{
              pointerEvents: isInteractionBlocked ? "none" : "auto",
            }}
          >
            <div className={styles.dropContainer}>
              <img className={styles.wallperImage} src={wallperImage} alt="" />
              {images.map((image, index) => (
                <DropTarget
                  key={index}
                  id={image.id}
                  targetId={image.targetId}
                  onDrop={(itemId, targetId) => handleDrop(itemId, targetId)}
                  left={image.shadowStyle.left}
                  top={image.shadowStyle.top}
                />
              ))}
              {/* Динамически рендерим сброшенные предметы */}
              {images.map(
                (image, index) =>
                  itemPlaced[image.id] && (
                    <img
                      key={index}
                      src={image.src}
                      alt={image.alt}
                      className={image.className}
                      style={{
                        position: "absolute",
                        ...image.dropStyle,
                      }}
                    />
                  )
              )}
            </div>

            <div className={styles.gallery}>
              {images.map((image, index) => {
                // Если itemPlaced[image.alt] === true, предмет был сброшен и не должен отображаться
                if (!itemPlaced[image.alt]) {
                  return (
                    <div key={index} className={styles.frameImage}>
                      <DraggableItem
                        id={image.id}
                        src={image.src}
                        alt={image.alt}
                        className={image.className}
                      />
                    </div>
                  );
                }
                return null; // Если предмет сброшен, он не рендерится
              })}
            </div>
          </div>
          <audio ref={audioRef} />
          {isButtonVisible && (
            <div className={styles.darkOverlay}>
              <button className={styles.button} onClick={handleNextTask}>
                {buttonText}
              </button>
            </div>
          )}
        </div>
      </DndProvider>
    </>
  );
};

export default FindInRoomGame;
