import React, { useState, useEffect, useRef } from "react";

import EndGameOptions from "../../end-game-options/end-game-options";
import { gsap } from "gsap";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import DraggableItem from "./DraggableItem";
import DropTarget from "./DropTarget";
import styles from "./picking-fruits.module.css";

import trees from "./images/trees.png";
import boxFront from "./images/boxFront.png";
import boxBack from "./images/boxBack.png";
import as from "./images/as.png";
import isYz from "./images/isYz.png";
import is from "./images/is.png";
import os from "./images/os.png";
import saSy from "./images/saSy.png";
import sa from "./images/sa.png";
import so from "./images/so.png";
import su from "./images/su.png";
import suOs from "./images/suOs.png";
import sy from "./images/sy.png";
import sySo from "./images/sySo.png";
import usSy from "./images/usSy.png";
import us from "./images/us.png";
import esas from "./images/esas.png";

import startInstructionSound from "./sounds/startInstruction.mp3";
import backgroundSound from "./sounds/backgroundSound.mp3";
import basketSound from "./sounds/basket.mp3";
import fruitsSound from "./sounds/fruit.mp3";
import finalSound from "./sounds/finalSound.mp3";
import praiseSound from "./sounds/praise.mp3";

const colors = [
  "#FF0000",
  "#FF7F00",
  "#FFFF00",
  "#00FF00",
  "#0000FF",
  "#4B0082",
  "#8B00FF",
];

const triggerFireworks = () => {
  const fireworksContainer = document.querySelector(
    `.${styles.fireworksContainer}`
  );
  if (fireworksContainer) {
    for (let i = 0; i < 10; i++) {
      setTimeout(() => createFirework(fireworksContainer), i * 500);
    }
  } else {
    console.error("Fireworks container not found");
  }
};

const createFirework = (container) => {
  const firework = document.createElement("div");
  firework.className = styles.firework;
  firework.style.left = `${Math.random() * 100}%`;
  firework.style.top = `${Math.random() * 100}%`;
  container.appendChild(firework);

  const particles = [];
  for (let i = 0; i < 30; i++) {
    const particle = document.createElement("div");
    particle.className = styles.particle;
    particle.style.background =
      colors[Math.floor(Math.random() * colors.length)];
    firework.appendChild(particle);
    particles.push(particle);
  }

  const tl = gsap.timeline({
    onComplete: () => {
      firework.remove();
    },
  });

  particles.forEach((particle) => {
    const angle = Math.random() * Math.PI * 2;
    const distance = Math.random() * 200 + 50;
    tl.fromTo(
      particle,
      { x: 0, y: 0, opacity: 1 },
      {
        x: Math.cos(angle) * distance,
        y: Math.sin(angle) * distance,
        opacity: 0,
        duration: 2,
        ease: "power2.out",
      },
      0
    );
  });
};

const initialFruits = [
  { id: "as", src: as, top: "10%", left: "20%", inBowl: false },
  { id: "isYz", src: isYz, top: "15%", left: "25%", inBowl: false },
  { id: "is", src: is, top: "20%", left: "30%", inBowl: false },
  { id: "os", src: os, top: "25%", left: "35%", inBowl: false },
  { id: "saSy", src: saSy, top: "30%", left: "40%", inBowl: false },
  { id: "sa", src: sa, top: "35%", left: "45%", inBowl: false },
  { id: "so", src: so, top: "40%", left: "50%", inBowl: false },
  { id: "su", src: su, top: "45%", left: "55%", inBowl: false },
  { id: "suOs", src: suOs, top: "50%", left: "60%", inBowl: false },
  { id: "sy", src: sy, top: "55%", left: "65%", inBowl: false },
  { id: "sySo", src: sySo, top: "60%", left: "70%", inBowl: false },
  { id: "usSy", src: usSy, top: "65%", left: "75%", inBowl: false },
  { id: "us", src: us, top: "70%", left: "80%", inBowl: false },
  { id: "esas", src: esas, top: "70%", left: "80%", inBowl: false },
];

const getBowlPosition = (index) => {
  const basePositions = [
    { top: 132, left: 42 },
    { top: 132, left: 48 },
    { top: 132, left: 55 },
  ];

  if (index < basePositions.length) {
    return basePositions[index];
  }

  const row = Math.floor(index / 3);
  const col = index % 3;
  return { top: 132 - row * 5, left: 41 + col * 7 };
};

const isProblemFruit = (id) => ["as", "is", "os", "us"].includes(id);

const PickingFruitsGame = () => {
  const [isGameCompleted, setIsGameCompleted] = useState(false);
  const [currentTaskIndex, setCurrentTaskIndex] = useState(0);
  const [imageOpacity, setImageOpacity] = useState(1);
  const [isInteractionBlocked, setIsInteractionBlocked] = useState(false);
  const [buttonText, setButtonText] = useState("Начать игру");
  const [isButtonVisible, setIsButtonVisible] = useState(true);
  const [fruits, setFruits] = useState(initialFruits);
  const [fruitsInBowl, setFruitsInBowl] = useState([]);

  const audioRef = useRef(null);
  const startInstructionAudioRef = useRef(new Audio(startInstructionSound));
  const backgroundAudioRef = useRef(new Audio(backgroundSound));
  const basketSoundRef = useRef(new Audio(basketSound));
  const fruitsSoundRef = useRef(new Audio(fruitsSound));
  const finalSoundRef = useRef(new Audio(finalSound));
  const praiseSoundRef = useRef(new Audio(praiseSound));

  useEffect(() => {
    document.body.style.backgroundColor = "rgba(252, 229, 170, 1)";
    return () => {
      document.body.style.backgroundColor = "";
    };
  }, []);

  const handleDrop = (itemId) => {
    fruitsSoundRef.current.play();
    setFruits((prevFruits) =>
      prevFruits.map((fruit) =>
        fruit.id === itemId ? { ...fruit, inBowl: true } : fruit
      )
    );
    setFruitsInBowl((prev) => [...prev, itemId]);

    basketSoundRef.current.play();
  };

  useEffect(() => {
    if (fruitsInBowl.length === initialFruits.length) {
      finalSoundRef.current.play();

      finalSoundRef.current.onended = () => {
        triggerFireworks();
        setTimeout(() => {
          setIsGameCompleted(true);
        }, 5000);
        praiseSoundRef.current.play();
      };
    }
  }, [fruitsInBowl]);

  const handleNextTask = () => {
    setIsInteractionBlocked(true); // Блокируем взаимодействие

    if (startInstructionAudioRef.current) {
      startInstructionAudioRef.current.play();

      startInstructionAudioRef.current.onended = () => {
        setIsInteractionBlocked(false); // Разблокируем взаимодействие после окончания инструкции
        if (backgroundAudioRef.current) {
          backgroundAudioRef.current.loop = true; // Включаем повтор для фоновой музыки
          backgroundAudioRef.current.volume = 0.1;
          backgroundAudioRef.current.play(); // Запускаем фоновую музыку
        }
      };
    }

    setIsButtonVisible(false);
  };

  useEffect(() => {
    return () => {
      // Остановить и сбросить все аудио
      startInstructionAudioRef.current.pause();
      startInstructionAudioRef.current.currentTime = 0;
      backgroundAudioRef.current.pause();
      backgroundAudioRef.current.currentTime = 0;

      praiseSoundRef.current.pause();
      praiseSoundRef.current.currentTime = 0;

      // Остановить все анимации GSAP
      gsap.globalTimeline.clear();
    };
  }, []);

  return (
    <DndProvider backend={HTML5Backend}>
      {isGameCompleted && <EndGameOptions />}
      <div className={styles.container}>
        <div
          className={styles.backgroundContainer}
          style={{
            pointerEvents: isInteractionBlocked ? "none" : "auto",
          }}
        >
          <div
            style={{
              opacity: imageOpacity,
              transition: "opacity 0.5s ease-in-out",
            }}
            className={styles.taskContainer}
          >
            <div className={styles.dogContainer}>
              <img src={trees} alt="деревья" className={styles.trees} />

              {fruits.map(
                (fruit) =>
                  !fruit.inBowl && (
                    <DraggableItem
                      key={fruit.id}
                      id={fruit.id}
                      src={fruit.src}
                      top={fruit.top}
                      left={fruit.left}
                      className={styles[fruit.id]}
                    />
                  )
              )}

              <img
                src={boxBack}
                alt="Корзина задняя часть"
                className={styles.boxBack}
              />
              <DropTarget onDrop={handleDrop} className={styles.dropTarget} />
              {fruitsInBowl.map((fruitId, index) => {
                const fruit = fruits.find((f) => f.id === fruitId);
                const position = getBowlPosition(index);
                const isProblem = isProblemFruit(fruit.id);
                const hiddenClass =
                  isProblem && index < 4 ? styles.hiddenFruit : "";
                const smallClass = isProblem ? styles.smallFruit : "";
                return (
                  <img
                    key={fruit.id}
                    src={fruit.src}
                    alt={fruit.id}
                    className={`${styles.fruitInBowl} ${hiddenClass} ${smallClass}`}
                    style={{
                      top: `${position.top}%`,
                      left: `${position.left}%`,
                      width: isProblem ? "5%" : "10%", // изменяем размер для проблемных фруктов
                      zIndex: isProblem && index < 4 ? -1 : 1,
                    }}
                  />
                );
              })}
              <img
                src={boxFront}
                alt="Корзина передняя часть"
                className={styles.boxFront}
              />
            </div>
          </div>
        </div>
      </div>
      <div className={styles.fireworksContainer}></div>
      <audio ref={audioRef} />
      {isButtonVisible && (
        <div className={styles.darkOverlay}>
          <button className={styles.button} onClick={handleNextTask}>
            {buttonText}
          </button>
        </div>
      )}
    </DndProvider>
  );
};

export default PickingFruitsGame;
