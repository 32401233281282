import React, { useState, useEffect, useRef } from "react";
import EndGameOptions from "../../end-game-options/end-game-options";
import Confetti from "react-confetti";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import DraggableItem from "./DraggableItem";
import DropTarget from "./DropTarget";
import styles from "./math-one.module.css";
import figureOne from "./images/1.png";
import baloonThree from "./images/baloonThree.png";
import baloonFive from "./images/baloonFive.png";
import boxOpen from "./images/boxOpen.png";
import bearInsideBox from "./images/bearInsideBox.png";
import bearBig from "./images/bearBig.png";
import bearSmall from "./images/bearSmall.png";
import baloonNine from "./images/baloonNine.png";
import baloonEight from "./images/baloonEight.png";
import baloonSeven from "./images/baloonSeven.png";
import baloonOneSecond from "./images/baloonOneSecond.png";
import baloonOne from "./images/baloonOne.png";

import startInstructionSound from "./sounds/startInstruction.mp3";
import backgroundSound from "./sounds/backgroundSound.mp3";
import incorrectAnswerSound from "./sounds/no.mp3";
import taskZeroSound from "./sounds/taskZero.mp3";
import taskTwoSound from "./sounds/taskTwo.mp3";
import taskThreeSound from "./sounds/taskThree.mp3";
import taskFourSound from "./sounds/taskFour.mp3";
import baloonPoppedSound from "./sounds/baloonPopped.mp3";
import bearInBoxSound from "./sounds/bearInBox.mp3";
import praiseSound from "./sounds/praise.mp3";
import finalSound from "./sounds/finalSound.mp3";

const MathFigureOneGame = () => {
  const [isGameCompleted, setIsGameCompleted] = useState(false);
  const [currentTaskIndex, setCurrentTaskIndex] = useState(0);
  const [imageOpacity, setImageOpacity] = useState(1);
  const [buttonText, setButtonText] = useState("Послушать");
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [isButtonVisible, setIsButtonVisible] = useState(true);
  const [visibleBalloons, setVisibleBalloons] = useState({
    baloonOne: true,
    baloonOneSecond: true,
    baloonThree: true,
    baloonFive: true,
    baloonSeven: true,
    baloonEight: true,
    baloonNine: true,
  });
  const [bearsInBox, setBearsInBox] = useState({
    bear1: false,
    bear2: false,
    bear3: false,
    bear4: false,
    bear5: false,
  });
  const [isBearInBox, setIsBearInBox] = useState(false);
  const [showConfetti, setShowConfetti] = useState(false);
  const [isInteractionBlocked, setIsInteractionBlocked] = useState(true);

  const audioRef = useRef(null);
  const startInstructionAudioRef = useRef(new Audio(startInstructionSound));
  const backgroundAudioRef = useRef(new Audio(backgroundSound));

  const tasks = [
    {
      question: "Это цифра один",
      images: [{ src: figureOne, alt: "Цифра один", class: styles.figureOne }],
      audioSrc: taskZeroSound,
    },
    {
      question: "А это один медвежонок",
      images: [
        { src: figureOne, alt: "Цифра один", class: styles.figureOneWithBear },
        { src: bearBig, alt: "Медвежонок", class: styles.bearImage },
      ],
      audioSrc: taskTwoSound,
    },
    {
      question: "Лопни шарики с цифрой один!",
      images: [
        {
          src: baloonOne,
          alt: "Воздушный шарик",
          class: styles.baloon,
          isBalloon: true,
          id: "baloonOne",
        },
        {
          src: baloonFive,
          alt: "Воздушный шарик",
          class: styles.baloon,
          isBalloon: true,
          id: "baloonFive",
        },
        {
          src: baloonThree,
          alt: "Воздушный шарик",
          class: styles.baloon,
          isBalloon: true,
          id: "baloonThree",
        },
        {
          src: baloonNine,
          alt: "Воздушный шарик",
          class: styles.baloon,
          isBalloon: true,
          id: "baloonNine",
        },
        {
          src: baloonOneSecond,
          alt: "Воздушный шарик",
          class: styles.baloon,
          isBalloon: true,
          id: "baloonOneSecond",
        },
        {
          src: baloonSeven,
          alt: "Воздушный шарик",
          class: styles.baloon,
          isBalloon: true,
          id: "baloonSeven",
        },
        {
          src: baloonEight,
          alt: "Воздушный шарик",
          class: styles.baloon,
          isBalloon: true,
          id: "baloonEight",
        },
      ],
      audioSrc: taskThreeSound,
    },
    {
      question: "Положи одного медвежонка в коробку",
      images: [
        { src: figureOne, alt: "Цифра один", class: styles.figureOneLastTask },
        {
          src: boxOpen,
          alt: "Открытая коробка",
          class: styles.boxOpen,
          id: "boxOpen",
        },
      ],
      audioSrc: taskFourSound,
    },
  ];

  const currentTask = tasks[currentTaskIndex];

  const playSound = () => {
    if (currentTask.audioSrc) {
      setIsInteractionBlocked(true);
      setButtonDisabled(true);

      const taskAudio = new Audio(currentTask.audioSrc);
      taskAudio.play().catch((error) => {
        console.error("Playback failed:", error);
        setButtonDisabled(false);
        setIsInteractionBlocked(false);
      });

      taskAudio.onended = () => {
        setButtonDisabled(false);
        setIsInteractionBlocked(false);

        if (currentTaskIndex === tasks.length - 1) {
          setButtonText("");
        } else {
          setButtonText("Далее");
        }
      };
    }
  };

  useEffect(() => {
    if (currentTaskIndex === 2) {
      // Проверяем состояние только для задачи с шариками
      const allRequiredBalloonsPopped =
        !visibleBalloons.baloonOne && !visibleBalloons.baloonOneSecond;

      if (allRequiredBalloonsPopped && buttonText === "Далее") {
        setButtonDisabled(false); // Разблокируем кнопку "Далее", если все требуемые шарики лопнули
      } else if (buttonText === "Послушать") {
        setButtonDisabled(false); // Кнопка "Слушать" всегда доступна
      } else {
        setButtonDisabled(true); // Блокируем кнопку "Далее", если не все шарики лопнули
      }
    }
  }, [visibleBalloons, currentTaskIndex, buttonText]);

  const handleNextTask = () => {
    if (buttonText === "Послушать") {
      setIsInteractionBlocked(true); // Блокируем взаимодействие сразу после нажатия
      playSound(); // Воспроизводим звук для текущей задачи
    } else if (buttonText === "Далее" && !buttonDisabled) {
      setImageOpacity(0);
      setTimeout(() => {
        if (currentTaskIndex + 1 < tasks.length) {
          setCurrentTaskIndex(currentTaskIndex + 1);
          setButtonText("Послушать");
          setButtonDisabled(false);
          setIsInteractionBlocked(true); // Снова блокируем после перехода к следующему заданию
        }
        setImageOpacity(1);
      }, 500);
    }
  };
  const handleBalloonClick = (balloonId) => {
    if (balloonId === "baloonOne" || balloonId === "baloonOneSecond") {
      // Play the balloon popped sound for correct balloons
      const popSound = new Audio(baloonPoppedSound);
      popSound.play();

      setVisibleBalloons((prevState) => ({
        ...prevState,
        [balloonId]: "popped", // Set the status to "popped" for animation
      }));
      setTimeout(() => {
        setVisibleBalloons((prevState) => ({
          ...prevState,
          [balloonId]: false, // Remove the balloon after animation
        }));
      }, 500); // Duration of the popping animation
    } else {
      // Play the incorrect answer sound for other balloons
      const incorrectSound = new Audio(incorrectAnswerSound);
      incorrectSound.play();
    }
  };

  useEffect(() => {
    if (currentTaskIndex === 2) {
      // Проверяем состояние только для задачи с шариками
      const allRequiredBalloonsPopped =
        !visibleBalloons.baloonOne && !visibleBalloons.baloonOneSecond;
      setButtonDisabled(!allRequiredBalloonsPopped);
    }
  }, [visibleBalloons.baloonOne, visibleBalloons.baloonOneSecond]);

  const handleDrop = (itemId, targetId) => {
    if (targetId === "boxTarget" && !isBearInBox) {
      setBearsInBox((prev) => ({
        ...prev,
        [itemId]: true,
      }));
      setIsBearInBox(true);
      setShowConfetti(true);

      // Play bear in box sound
      const bearSound = new Audio(bearInBoxSound);
      bearSound.play();

      // When bear sound ends, play the final sound, then the praise sound
      bearSound.onended = () => {
        const finalSoundAudio = new Audio(finalSound);
        finalSoundAudio.play();

        finalSoundAudio.onended = () => {
          const praiseSoundAudio = new Audio(praiseSound);
          praiseSoundAudio.play();
        };
      };

      setTimeout(() => setShowConfetti(false), 10000);
      setTimeout(() => {
        setIsGameCompleted(true);
      }, 5000);
    }
  };

  const handleStartGame = () => {
    setButtonDisabled(true);
    setIsInteractionBlocked(true);
    const instructionAudio = startInstructionAudioRef.current;
    instructionAudio.play();

    instructionAudio.onended = () => {
      if (audioRef.current) {
        audioRef.current.play();
      }
      if (backgroundAudioRef.current) {
        backgroundAudioRef.current.play();
      }
      setButtonDisabled(false);
    };

    setIsButtonVisible(false);
  };

  useEffect(() => {
    document.body.style.backgroundColor = "rgba(252, 229, 170, 1)";
    return () => {
      if (startInstructionAudioRef.current) {
        startInstructionAudioRef.current.pause();
        startInstructionAudioRef.current.currentTime = 0;
      }

      if (backgroundAudioRef.current) {
        backgroundAudioRef.current.pause();
        backgroundAudioRef.current.currentTime = 0;
      }

      document.body.style.backgroundColor = "";
    };
  }, []);

  return (
    <DndProvider backend={HTML5Backend}>
      {isGameCompleted && <EndGameOptions />}
      <div className={styles.container}>
        <div
          className={styles.backgroundContainer}
          style={{
            pointerEvents: isInteractionBlocked ? "none" : "auto",
          }}
        >
          {showConfetti && (
            <Confetti
              numberOfPieces={500}
              angle={180}
              spread={120}
              origin={{ x: 1, y: 0.5 }}
            />
          )}
          <h3 className={styles.header}>{currentTask.question}</h3>
          <div
            style={{
              opacity: imageOpacity,
              transition: "opacity 0.5s ease-in-out",
            }}
            className={styles.bearAndOneContainer}
          >
            {currentTask.images.map((img, index) => {
              const isPopped = visibleBalloons[img.id] === "popped";
              const isVisible = visibleBalloons[img.id] !== false;
              if (img.id === "boxOpen") {
                return (
                  <DropTarget
                    key={index}
                    targetId="boxTarget"
                    onDrop={handleDrop}
                    isBearInBox={isBearInBox}
                    className={styles.boxOpenContainer}
                  >
                    <img
                      src={isBearInBox ? bearInsideBox : img.src}
                      alt={img.alt}
                      className={img.class}
                    />
                  </DropTarget>
                );
              }
              return (
                <img
                  key={index}
                  src={img.src}
                  alt={img.alt}
                  className={`${img.class} ${
                    img.isBalloon ? styles.balloon : ""
                  } ${isPopped ? styles.pop : ""}`}
                  onClick={() => img.isBalloon && handleBalloonClick(img.id)}
                  style={{ display: isVisible ? "block" : "none" }}
                />
              );
            })}

            {currentTaskIndex === tasks.length - 1 && (
              <div className={styles.bearContainer}>
                <div className={styles.bearRow}>
                  {Object.keys(bearsInBox)
                    .slice(0, 2)
                    .map(
                      (bearId) =>
                        !bearsInBox[bearId] && (
                          <DraggableItem
                            key={bearId}
                            id={bearId}
                            src={bearSmall}
                            alt="Медвежонок"
                            className={`${styles.bearSmall} draggable`}
                            isBearInBox={isBearInBox}
                          />
                        )
                    )}
                </div>
                <div className={styles.bearRow}>
                  {Object.keys(bearsInBox)
                    .slice(2)
                    .map(
                      (bearId) =>
                        !bearsInBox[bearId] && (
                          <DraggableItem
                            key={bearId}
                            id={bearId}
                            src={bearSmall}
                            alt="Медвежонок"
                            className={`${styles.bearSmall} draggable`}
                            isBearInBox={isBearInBox}
                          />
                        )
                    )}
                </div>
              </div>
            )}
          </div>
        </div>

        {currentTaskIndex < tasks.length - 1 ? (
          <button
            className={styles.button}
            onClick={handleNextTask}
            disabled={buttonDisabled}
          >
            {buttonText}
          </button>
        ) : (
          buttonText === "Послушать" && (
            <button
              className={styles.button}
              onClick={() => {
                playSound();
                setButtonText("");
              }}
              disabled={buttonDisabled}
            >
              {buttonText}
            </button>
          )
        )}
      </div>

      <audio ref={audioRef} />
      <audio ref={backgroundAudioRef} src={backgroundSound} loop />
      {isButtonVisible && (
        <div className={styles.darkOverlay}>
          <button className={styles.buttonStartGame} onClick={handleStartGame}>
            Начать игру
          </button>
        </div>
      )}
    </DndProvider>
  );
};

export default MathFigureOneGame;
