import { useEffect } from "react";

const useDynamicHeight = () => {
  useEffect(() => {
    const adjustContainerHeight = () => {
      const header = document.querySelector("header");
      const container = document.querySelector(".container");
      const headerHeight = header.offsetHeight;
      const windowHeight = window.innerHeight;

      const headerHeightInVh = (headerHeight / windowHeight) * 100;

      container.style.height = `calc(100vh - ${headerHeightInVh}vh)`;
    };

    window.addEventListener("resize", adjustContainerHeight);
    adjustContainerHeight();

    return () => window.removeEventListener("resize", adjustContainerHeight);
  }, []);
};

export default useDynamicHeight;
