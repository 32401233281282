import React, { useState, useEffect, useRef } from "react";
import useDynamicHeight from "../../../hooks/useDynamicHeight";
import EndGameOptions from "../../end-game-options/end-game-options";
import { gsap } from "gsap";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import DraggableItem from "./DraggableItem";
import DropTarget from "./DropTarget";
import styles from "./math-four.module.css";

import figureFour from "./images/figureFour.png";
import box from "./images/box.png";
import flower from "./images/flower.png";
import oneFlowerInBox from "./images/oneFlowerInBox.png";
import twoFlowerInBox from "./images/twoFlowerInBox.png";
import threeFlowerInBox from "./images/threeFlowerInBox.png";
import fourFlowerInBox from "./images/fourFlowerInBox.png";
import cubeOne from "./images/cube-1.png";
import cubeTwo from "./images/cube-2.png";
import cubeThree from "./images/cube-3.png";
import cubeFour from "./images/cube-4.png";

import startInstructionSound from "./sounds/startInstruction.mp3";
import backgroundSound from "./sounds/backgroundSound.mp3";
import taskZeroSound from "./sounds/taskZero.mp3";
import taskTwoSound from "./sounds/taskTwo.mp3";
import taskThreeSound from "./sounds/taskThree.mp3";
import taskFourSound from "./sounds/taskFour.mp3";
import cubeSound from "./sounds/cube.mp3";
import inBoxSound from "./sounds/inBox.mp3";
import finalSound from "./sounds/finalSound.mp3";
import praiseSound from "./sounds/praise.mp3";

const colors = [
  "#FF0000",
  "#FF7F00",
  "#FFFF00",
  "#00FF00",
  "#0000FF",
  "#4B0082",
  "#8B00FF",
];

const triggerFireworks = () => {
  const fireworksContainer = document.querySelector(
    `.${styles.fireworksContainer}`
  );
  if (fireworksContainer) {
    for (let i = 0; i < 10; i++) {
      setTimeout(() => createFirework(fireworksContainer), i * 500);
    }
  } else {
    console.error("Fireworks container not found");
  }
};

const createFirework = (container) => {
  const firework = document.createElement("div");
  firework.className = styles.firework;
  firework.style.left = `${Math.random() * 100}%`;
  firework.style.top = `${Math.random() * 100}%`;
  container.appendChild(firework);

  const particles = [];
  for (let i = 0; i < 30; i++) {
    const particle = document.createElement("div");
    particle.className = styles.particle;
    particle.style.background =
      colors[Math.floor(Math.random() * colors.length)];
    firework.appendChild(particle);
    particles.push(particle);
  }

  const tl = gsap.timeline({
    onComplete: () => {
      firework.remove();
    },
  });

  particles.forEach((particle) => {
    const angle = Math.random() * Math.PI * 2;
    const distance = Math.random() * 200 + 50;
    tl.fromTo(
      particle,
      { x: 0, y: 0, opacity: 1 },
      {
        x: Math.cos(angle) * distance,
        y: Math.sin(angle) * distance,
        opacity: 0,
        duration: 2,
        ease: "power2.out",
      },
      0
    );
  });
};

const MathFigureFourGame = () => {
  useDynamicHeight();
  const [isGameCompleted, setIsGameCompleted] = useState(false);
  const [currentTaskIndex, setCurrentTaskIndex] = useState(0);
  const [imageOpacity, setImageOpacity] = useState(1);
  const [buttonText, setButtonText] = useState("Послушать");
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [isButtonVisible, setIsButtonVisible] = useState(true);
  const [isInteractionBlocked, setIsInteractionBlocked] = useState(true);
  const [flowersInBox, setFlowersInBox] = useState({
    flower1: false,
    flower2: false,
    flower3: false,
    flower4: false,
    flower5: false,
    flower6: false,
    flower7: false,
    flower8: false,
    flower9: false,
    flower10: false,
  });
  const [isFlowerInBox, setIsFlowerInBox] = useState(false);
  const [flowersCount, setFlowersCount] = useState(0);
  const basketImages = {
    0: box,
    1: oneFlowerInBox,
    2: twoFlowerInBox,
    3: threeFlowerInBox,
    4: fourFlowerInBox,
  };
  const [cubes, setCubes] = useState([
    { id: "cube1", angle: 90, correctAngle: 0, fixed: false, src: cubeOne },
    { id: "cube2", angle: 180, correctAngle: 0, fixed: false, src: cubeTwo },
    { id: "cube3", angle: 90, correctAngle: 0, fixed: false, src: cubeThree },
    { id: "cube4", angle: 180, correctAngle: 0, fixed: false, src: cubeFour },
  ]);
  const [allCubesCorrect, setAllCubesCorrect] = useState(false);

  const audioRef = useRef(null);
  const startInstructionAudioRef = useRef(new Audio(startInstructionSound));
  const backgroundAudioRef = useRef(new Audio(backgroundSound));
  const cubeSoundRef = useRef(new Audio(cubeSound));
  const finalSoundRef = useRef(new Audio(finalSound));
  const praiseSoundRef = useRef(new Audio(praiseSound));

  const tasks = [
    {
      question: "Это цифра четыре",
      images: [
        { src: figureFour, alt: "Цифра четыре", class: styles.figureFour },
      ],
      audioSrc: taskZeroSound,
    },
    {
      question: "А это четыре цветочка",
      images: [
        {
          src: figureFour,
          alt: "Цифра четыре",
          class: styles.figureFourWithFlowers,
        },
        { src: flower, alt: "цветок", class: styles.flower },
        { src: flower, alt: "цветок", class: styles.flower },
        { src: flower, alt: "цветок", class: styles.flower },
        { src: flower, alt: "цветок", class: styles.flower },
      ],
      audioSrc: taskTwoSound,
    },
    {
      question: "Положи четыре цветочка в корзину",
      images: [
        { src: flower, alt: "цветок", id: "flower1" },
        { src: flower, alt: "цветок", id: "flower2" },
        { src: flower, alt: "цветок", id: "flower3" },
        { src: flower, alt: "цветок", id: "flower4" },
        {
          src: box,
          alt: "корзина",
          class: styles.box,
          id: "box",
        },
      ],
      audioSrc: taskThreeSound,
    },
    {
      question: "Поверни кубики так, чтобы получилась цифра четыре",
      images: [
        { src: cubeOne, alt: "кубик1", class: styles.cubeOne },
        { src: cubeTwo, alt: "кубик2", class: styles.cubeTwo },
        { src: cubeThree, alt: "кубик3", class: styles.cubeThree },
        { src: cubeFour, alt: "кубик4", class: styles.cubeFour },
      ],
      audioSrc: taskFourSound,
    },
  ];

  const currentTask = tasks[currentTaskIndex];

  const playSound = () => {
    if (currentTask.audioSrc) {
      setIsInteractionBlocked(true);
      setButtonDisabled(true);

      const taskAudio = new Audio(currentTask.audioSrc);
      taskAudio.play().catch((error) => {
        console.error("Playback failed:", error);
        setButtonDisabled(false);
        setIsInteractionBlocked(false);
      });

      taskAudio.onended = () => {
        setButtonDisabled(false);
        setIsInteractionBlocked(false);

        if (currentTaskIndex === tasks.length - 1) {
          setButtonText("");
        } else {
          setButtonText("Далее");
        }
      };
    }
  };

  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.onended = () => {
        if (currentTaskIndex !== 2) {
          setButtonDisabled(false);
          setButtonText("Далее");
        }
      };
    }
  }, [currentTaskIndex]);

  const handleNextTask = () => {
    if (buttonText === "Послушать") {
      setIsInteractionBlocked(true); // Блокируем взаимодействие сразу после нажатия
      playSound(); // Воспроизводим звук для текущей задачи
    } else if (buttonText === "Далее" && !buttonDisabled) {
      setImageOpacity(0);
      setTimeout(() => {
        if (currentTaskIndex + 1 < tasks.length) {
          setCurrentTaskIndex(currentTaskIndex + 1);
          setButtonText("Послушать");
          setButtonDisabled(false);
          setIsInteractionBlocked(true); // Снова блокируем после перехода к следующему заданию
        }
        setImageOpacity(1);
      }, 500);
    }
  };

  const handleDrop = (itemId, targetId) => {
    if (targetId === "box" && flowersCount < 4) {
      setFlowersInBox((prev) => ({
        ...prev,
        [itemId]: true,
      }));
      setFlowersCount((prev) => Math.min(prev + 1, 4));
      setIsFlowerInBox(true);

      const flowerSound = new Audio(inBoxSound);
      flowerSound.play();
    }
  };

  useEffect(() => {
    if (currentTaskIndex === 2) {
      const allFlowersCollected = flowersCount === 4;
      setButtonDisabled(!allFlowersCollected && buttonText === "Далее");
    }
  }, [flowersCount, currentTaskIndex, buttonText]);

  const handleRotate = (cubeId) => {
    setCubes((prevCubes) => {
      const updatedCubes = prevCubes.map((cube) => {
        if (cube.id === cubeId && !cube.fixed) {
          const newAngle = (cube.angle + 90) % 360;
          const isCorrect = newAngle === cube.correctAngle;
          if (isCorrect) {
            cubeSoundRef.current.play(); // Проигрываем звук куба
          }
          return { ...cube, angle: newAngle, fixed: isCorrect };
        }
        return cube;
      });

      // Проверка, все ли кубики в правильном положении
      const allCorrect = updatedCubes.every((cube) => cube.fixed);
      if (allCorrect && !allCubesCorrect) {
        setAllCubesCorrect(true); // Устанавливаем флаг, что все кубики правильно размещены
        finalSoundRef.current.play(); // Проигрываем финальный звук

        finalSoundRef.current.onended = () => {
          praiseSoundRef.current.play(); // Проигрываем звук похвалы после финального звука
          triggerFireworks();
          setTimeout(() => {
            setIsGameCompleted(true);
          }, 5000);
        };
      }

      return updatedCubes;
    });
  };

  const handleStartGame = () => {
    setButtonDisabled(true);
    setIsInteractionBlocked(true);
    const instructionAudio = startInstructionAudioRef.current;
    instructionAudio.play();

    instructionAudio.onended = () => {
      if (audioRef.current) {
        audioRef.current.play();
      }
      if (backgroundAudioRef.current) {
        backgroundAudioRef.current.play();
      }
      setButtonDisabled(false);
    };

    setIsButtonVisible(false);
  };

  useEffect(() => {
    document.body.style.backgroundColor = "rgba(252, 229, 170, 1)";
    return () => {
      if (startInstructionAudioRef.current) {
        startInstructionAudioRef.current.pause();
        startInstructionAudioRef.current.currentTime = 0;
      }

      if (backgroundAudioRef.current) {
        backgroundAudioRef.current.pause();
        backgroundAudioRef.current.currentTime = 0;
      }

      document.body.style.backgroundColor = "";
    };
  }, []);

  return (
    <DndProvider backend={HTML5Backend}>
      {isGameCompleted && <EndGameOptions />}
      <div className={`container ${styles.someOtherClass}`}>
        <div
          className={styles.backgroundContainer}
          style={{
            pointerEvents: isInteractionBlocked ? "none" : "auto",
          }}
        >
          <h3 className={styles.header}>{currentTask.question}</h3>
          <div
            style={{
              opacity: imageOpacity,
              transition: "opacity 0.5s ease-in-out",
            }}
            className={styles.taskContainer}
          >
            {currentTaskIndex === 0 &&
              currentTask.images.map((img, index) => (
                <img
                  key={index}
                  src={img.src}
                  alt={img.alt}
                  className={img.class}
                />
              ))}
            {currentTaskIndex === 1 && (
              <div className={styles.figureAndEclairsContainer}>
                <img
                  src={figureFour}
                  alt="Цифра четыре"
                  className={styles.figureFour}
                />
                <div className={styles.fourFlowers}>
                  <div className={styles.flowerRow}>
                    {currentTask.images
                      .filter(
                        (img, index) => img.alt === "цветок" && index <= 2
                      )
                      .map((img, index) => (
                        <img
                          key={index}
                          src={img.src}
                          alt={img.alt}
                          className={styles.flower}
                        />
                      ))}
                  </div>
                  <div className={styles.flowerRow}>
                    {currentTask.images
                      .filter(
                        (img, index) => img.alt === "цветок" && index >= 3
                      )
                      .map((img, index) => (
                        <img
                          key={index}
                          src={img.src}
                          alt={img.alt}
                          className={styles.flower}
                        />
                      ))}
                  </div>
                </div>
              </div>
            )}

            {currentTaskIndex === 2 && (
              <div className={styles.flowersContainer}>
                <div className={styles.flowerRow}>
                  {Object.keys(flowersInBox)
                    .slice(0, 5)
                    .map(
                      (flowerId) =>
                        !flowersInBox[flowerId] && (
                          <DraggableItem
                            key={flowerId}
                            id={flowerId}
                            src={flower}
                            alt="цветок"
                            className={`${styles.flowerSmall} draggable`}
                            isFlowerInBox={isFlowerInBox}
                            canDrag={flowersCount < 4}
                          />
                        )
                    )}
                </div>
                <div className={styles.flowerRow}>
                  {Object.keys(flowersInBox)
                    .slice(5)
                    .map(
                      (flowerId) =>
                        !flowersInBox[flowerId] && (
                          <DraggableItem
                            key={flowerId}
                            id={flowerId}
                            src={flower}
                            alt="цветок"
                            className={`${styles.flowerSmall} draggable`}
                            isFlowerInBox={isFlowerInBox}
                            canDrag={flowersCount < 4}
                          />
                        )
                    )}
                </div>
                <DropTarget
                  targetId="box"
                  onDrop={handleDrop}
                  className={styles.boxDropTarget}
                >
                  <img
                    src={basketImages[flowersCount]}
                    alt="корзина"
                    className={styles.box}
                  />
                </DropTarget>
              </div>
            )}
            {currentTaskIndex === 3 && (
              <div className={styles.cubesContainerMain}>
                <div className={styles.cubesContainer}>
                  {cubes.map((cube) => (
                    <img
                      key={cube.id}
                      src={cube.src}
                      alt={cube.id}
                      className={styles[cube.id]}
                      style={{ transform: `rotate(${cube.angle}deg)` }}
                      onClick={() => handleRotate(cube.id)}
                    />
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>
        {currentTaskIndex < tasks.length - 1 ? (
          <button
            className={styles.button}
            onClick={handleNextTask}
            disabled={buttonDisabled}
          >
            {buttonText}
          </button>
        ) : (
          buttonText === "Послушать" && (
            <button
              className={styles.button}
              onClick={() => {
                playSound();
                setButtonText("");
              }}
              disabled={buttonDisabled}
            >
              {buttonText}
            </button>
          )
        )}
        <div className={styles.fireworksContainer}></div>
      </div>
      <audio ref={audioRef} />
      <audio ref={backgroundAudioRef} src={backgroundSound} loop />
      {isButtonVisible && (
        <div className={styles.darkOverlay}>
          <button className={styles.buttonStartGame} onClick={handleStartGame}>
            Начать игру
          </button>
        </div>
      )}
    </DndProvider>
  );
};

export default MathFigureFourGame;
