import React, { useState, useEffect, useRef } from "react";
import useDynamicHeight from "../../../hooks/useDynamicHeight";
import EndGameOptions from "../../end-game-options/end-game-options";
import styles from "./home-appliances.module.css";
import Confetti from "react-confetti";
import dynamic from "./images/dynamic.svg";
import switchLight from "./images/switchLight.png";
import girlTooth from "./images/girlTooth.png";
import shower from "./images/shower.png";
import bell from "./images/bell.png";
import pot from "./images/pot.png";
import keys from "./images/keys.png";
import computer from "./images/computer.png";
import waterTap from "./images/waterTap.png";
import cupSpoon from "./images/cupSpoon.png";
import bag from "./images/bag.png";
import remoteController from "./images/remoteController.png";
import vacuumCleaner from "./images/vacuumCleaner.png";
import washMachine from "./images/washMachine.png";
import tv from "./images/tv.png";
import phone from "./images/phone.png";
import kettle from "./images/kettle.png";
import alarm from "./images/alarm.png";

import startInstructionSound from "./sounds/startInstruction.mp3";
import rightAnswerSound from "./sounds/rightAnswer.mp3";
import finalSound from "./sounds/finalSound.mp3";
import incorrectAnswerSound from "./sounds/no.mp3";
import switchLightSound from "./sounds/switchLight.mp3";
import girlToothSound from "./sounds/girlTooth.mp3";
import showerSound from "./sounds/shower.mp3";
import bellSound from "./sounds/bell.mp3";
import potSound from "./sounds/pot.mp3";
import keysSound from "./sounds/keys.mp3";
import computerSound from "./sounds/computer.mp3";
import waterTapSound from "./sounds/water.mp3";
import cupSpoonSound from "./sounds/cupSpoon.mp3";
import bagSound from "./sounds/bag.mp3";
import remoteControllerSound from "./sounds/remoteController.mp3";
import vacuumCleanerSound from "./sounds/vacuumCleaner.mp3";
import washMachineSound from "./sounds/washMachine.mp3";
import tvSound from "./sounds/tv.mp3";
import phoneSound from "./sounds/phone.mp3";
import kettleSound from "./sounds/kettle.mp3";
import alarmSound from "./sounds/alarm.mp3";

const HomeAppliancesGame = () => {
  useDynamicHeight();
  const [isInteractionBlocked, setIsInteractionBlocked] = useState(false);
  const [isGameCompleted, setIsGameCompleted] = useState(false);
  const [showConfetti, setShowConfetti] = useState(false);
  const [currentTaskIndex, setCurrentTaskIndex] = useState(0);
  const [isAnswerCorrect, setIsAnswerCorrect] = useState(null);
  const [selectedAnswer, setSelectedAnswer] = useState(null);
  const [buttonText, setButtonText] = useState("Начать игру");
  const [isButtonVisible, setIsButtonVisible] = useState(true);
  const audioRef = useRef(null);
  const startInstructionRef = useRef(new Audio(startInstructionSound));

  const tasks = [
    {
      question: (
        <>
          Послушай звук.
          <br /> Какой из предметов издает его?
        </>
      ),
      images: [
        { src: vacuumCleaner, alt: "vacuumCleaner" },
        { src: pot, alt: "pot" },
        { src: washMachine, alt: "washMachine" },
        { src: phone, alt: "phone" },
      ],
      correctAnswer: "vacuumCleaner",
    },
    {
      question: (
        <>
          Послушай звук.
          <br /> Какой из предметов издает его?
        </>
      ),
      images: [
        { src: tv, alt: "tv" },
        { src: bag, alt: "bag" },
        { src: bell, alt: "bell" },
        { src: keys, alt: "keys" },
      ],
      correctAnswer: "bell",
    },
    {
      question: (
        <>
          Послушай звук.
          <br /> На что он похож больше всего?
        </>
      ),
      images: [
        { src: girlTooth, alt: "girlTooth" },
        { src: computer, alt: "computer" },
        { src: waterTap, alt: "waterTap" },
        { src: washMachine, alt: "washMachine" },
      ],
      correctAnswer: "washMachine",
    },
    {
      question: (
        <>
          Послушай звук.
          <br /> Какой из предметов издает его?
        </>
      ),
      images: [
        { src: bag, alt: "bag" },
        { src: phone, alt: "phone" },
        { src: shower, alt: "shower" },
        { src: pot, alt: "pot" },
      ],
      correctAnswer: "bag",
    },
    {
      question: (
        <>
          Послушай звук.
          <br /> Какой из предметов издает его?
        </>
      ),
      images: [
        { src: pot, alt: "pot" },
        { src: kettle, alt: "kettle" },
        { src: cupSpoon, alt: "cupSpoon" },
        { src: bell, alt: "bell" },
      ],
      correctAnswer: "kettle",
    },
    {
      question: (
        <>
          Послушай звук.
          <br /> Какой из предметов издает его?
        </>
      ),
      images: [
        { src: remoteController, alt: "remoteController" },
        { src: vacuumCleaner, alt: "vacuumCleaner" },
        { src: phone, alt: "phone" },
        { src: tv, alt: "tv" },
      ],
      correctAnswer: "tv",
    },
    {
      question: (
        <>
          Послушай звук.
          <br /> Какой из предметов издает его?
        </>
      ),
      images: [
        { src: alarm, alt: "alarm" },
        { src: remoteController, alt: "remoteController" },
        { src: tv, alt: "tv" },
        { src: phone, alt: "phone" },
      ],
      correctAnswer: "remoteController",
    },
    {
      question: (
        <>
          Послушай звук.
          <br /> На что он похож больше всего?
        </>
      ),
      images: [
        { src: girlTooth, alt: "girlTooth" },
        { src: shower, alt: "shower" },
        { src: waterTap, alt: "waterTap" },
        { src: pot, alt: "pot" },
      ],
      correctAnswer: "girlTooth",
    },
    {
      question: (
        <>
          Послушай звук.
          <br /> Какой из предметов издает его?
        </>
      ),
      images: [
        { src: cupSpoon, alt: "cupSpoon" },
        { src: pot, alt: "pot" },
        { src: shower, alt: "shower" },
        { src: kettle, alt: "kettle" },
      ],
      correctAnswer: "pot",
    },
    {
      question: (
        <>
          Послушай звук.
          <br /> Какой из предметов издает его?
        </>
      ),
      images: [
        { src: alarm, alt: "alarm" },
        { src: remoteController, alt: "remoteController" },
        { src: computer, alt: "computer" },
        { src: phone, alt: "phone" },
      ],
      correctAnswer: "phone",
    },
    {
      question: (
        <>
          Послушай звук.
          <br /> Какой из предметов издает его?
        </>
      ),
      images: [
        { src: keys, alt: "keys" },
        { src: bell, alt: "bell" },
        { src: vacuumCleaner, alt: "vacuumCleaner" },
        { src: switchLight, alt: "switchLight" },
      ],
      correctAnswer: "keys",
    },
    {
      question: (
        <>
          Послушай звук.
          <br /> Какой из предметов издает его?
        </>
      ),
      images: [
        { src: kettle, alt: "kettle" },
        { src: pot, alt: "pot" },
        { src: cupSpoon, alt: "cupSpoon" },
        { src: bag, alt: "bag" },
      ],
      correctAnswer: "cupSpoon",
    },
    {
      question: (
        <>
          Послушай звук.
          <br /> Какой из предметов издает его?
        </>
      ),
      images: [
        { src: pot, alt: "pot" },
        { src: kettle, alt: "kettle" },
        { src: shower, alt: "shower" },
        { src: waterTap, alt: "waterTap" },
      ],
      correctAnswer: "waterTap",
    },

    {
      question: (
        <>
          Послушай звук.
          <br /> Какой из предметов издает его?
        </>
      ),
      images: [
        { src: tv, alt: "tv" },
        { src: computer, alt: "computer" },
        { src: remoteController, alt: "remoteController" },
        { src: alarm, alt: "alarm" },
      ],
      correctAnswer: "computer",
    },
    {
      question: (
        <>
          Послушай звук.
          <br /> Какой из предметов издает его?
        </>
      ),
      images: [
        { src: alarm, alt: "alarm" },
        { src: phone, alt: "phone" },
        { src: vacuumCleaner, alt: "vacuumCleaner" },
        { src: washMachine, alt: "washMachine" },
      ],
      correctAnswer: "alarm",
    },
    {
      question: (
        <>
          Послушай звук.
          <br /> Какой из предметов издает его?
        </>
      ),
      images: [
        { src: kettle, alt: "kettle" },
        { src: waterTap, alt: "waterTap" },
        { src: shower, alt: "shower" },
        { src: bell, alt: "bell" },
      ],
      correctAnswer: "shower",
    },
  ];

  const currentTask = tasks[currentTaskIndex];

  const handleImageClick = (answer) => {
    if (isAnswerCorrect) return;

    setSelectedAnswer(answer);
    const isCorrect = answer === currentTask.correctAnswer;
    setIsAnswerCorrect(isCorrect);

    const audio = new Audio(
      isCorrect ? rightAnswerSound : incorrectAnswerSound
    );
    audio.play();

    if (isCorrect) {
      setTimeout(() => {
        setIsAnswerCorrect(null);
        setSelectedAnswer(null);

        if (currentTaskIndex + 1 < tasks.length) {
          setCurrentTaskIndex((prevIndex) => prevIndex + 1);
        } else {
          setShowConfetti(true);
          setTimeout(() => {
            setIsGameCompleted(true);
          }, 5000);
          const finalAudio = new Audio(finalSound);
          finalAudio.play();
        }
      }, 1000);
    } else {
      setTimeout(() => {
        setSelectedAnswer(null);
      }, 1000);
    }
  };

  const handleStartGame = () => {
    setIsInteractionBlocked(true);
    if (startInstructionRef.current) {
      startInstructionRef.current.play();

      startInstructionRef.current.onended = () => {
        setIsInteractionBlocked(false);
      };
    }
    setIsButtonVisible(false);
  };
  const handlePlaySound = () => {
    const soundMap = {
      vacuumCleaner: vacuumCleanerSound,
      pot: potSound,
      washMachine: washMachineSound,
      phone: phoneSound,
      tv: tvSound,
      bag: bagSound,
      bell: bellSound,
      keys: keysSound,
      girlTooth: girlToothSound,
      computer: computerSound,
      waterTap: waterTapSound,
      cupSpoon: cupSpoonSound,
      remoteController: remoteControllerSound,
      kettle: kettleSound,
      alarm: alarmSound,
      switchLight: switchLightSound,
      shower: showerSound,
    };

    const currentSound = soundMap[currentTask.correctAnswer];
    if (currentSound) {
      const sound = new Audio(currentSound);
      sound.play();
    }
  };

  useEffect(() => {
    return () => {
      startInstructionRef.current.pause();
      startInstructionRef.current.currentTime = 0;
    };
  }, []);

  return (
    <>
      {isGameCompleted && <EndGameOptions />}
      <div className={`container ${styles.someOtherClass}`}>
        {showConfetti && (
          <Confetti
            numberOfPieces={500}
            angle={180}
            spread={120}
            origin={{ x: 1, y: 0.5 }}
          />
        )}
        <h3 className={styles.header}>{currentTask.question}</h3>
        <h4 className={styles.headerCounter}>
          {" "}
          Вопрос {currentTaskIndex + 1} из {tasks.length}
        </h4>
        <div
          className={styles.backgroundContainer}
          style={{
            pointerEvents: isInteractionBlocked ? "none" : "auto",
          }}
        >
          <div className={styles.imagesContainer}>
            {currentTask.images.map((image) => (
              <div className={styles.imageWrapper}>
                <img
                  key={image.alt}
                  src={image.src}
                  alt={image.alt}
                  className={`${styles.taskImage} ${
                    styles[`${image.alt}Image`]
                  }`}
                  onClick={() => handleImageClick(image.alt)}
                  style={{
                    filter:
                      selectedAnswer === image.alt && isAnswerCorrect
                        ? "drop-shadow(0 10px 50px rgba(50, 205, 50, 0.5))"
                        : selectedAnswer === image.alt &&
                          selectedAnswer !== null
                        ? "none"
                        : "",
                  }}
                />
              </div>
            ))}
            <button className={styles.centralButton} onClick={handlePlaySound}>
              <img src={dynamic} alt="Dynamic" />
              прослушать
            </button>
          </div>
        </div>
        <audio ref={audioRef} />
        {isButtonVisible && (
          <div className={styles.darkOverlay}>
            <button
              className={styles.buttonStartGame}
              onClick={handleStartGame}
            >
              {buttonText}
            </button>
          </div>
        )}
      </div>
    </>
  );
};

export default HomeAppliancesGame;
