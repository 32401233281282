import React, { useState, useEffect, useRef } from "react";
import useDynamicHeight from "../../../hooks/useDynamicHeight";
import EndGameOptions from "../../end-game-options/end-game-options";
import styles from "./musical-instruments-sounds.module.css";
import { gsap } from "gsap";

import dynamic from "./images/dynamic.svg";

import harp from "./images/harp.png";
import balalaika from "./images/balalaika.png";
import dramMachine from "./images/dramMachine.png";
import tambourine from "./images/tambourine.png";
import harmonic from "./images/harmonic.png";
import guitar from "./images/guitar.png";
import flute from "./images/flute.png";
import bell from "./images/bell.png";
import xylophone from "./images/xylophone.png";
import maracas from "./images/maracas.png";
import piano from "./images/piano.png";
import violin from "./images/violin.png";
import triangle from "./images/triangle.png";

import startInstructionSound from "./sounds/startInstruction.mp3";
import rightAnswerSound from "./sounds/rightAnswer.mp3";
import finalSound from "./sounds/finalSound.mp3";
import incorrectAnswerSound from "./sounds/no.mp3";

import harpSound from "./sounds/harp.mp3";
import balalaikaSound from "./sounds/balalaika.mp3";
import dramMachineSound from "./sounds/dramMachine.mp3";
import tambourineSound from "./sounds/tambourine.mp3";
import harmonicSound from "./sounds/harmonic.mp3";
import guitarSound from "./sounds/guitar.mp3";
import fluteSound from "./sounds/flute.mp3";
import bellSound from "./sounds/bell.mp3";
import xylophoneSound from "./sounds/xylophone.mp3";
import maracasSound from "./sounds/maracas.mp3";
import pianoSound from "./sounds/piano.mp3";
import violinSound from "./sounds/violin.mp3";
import triangleSound from "./sounds/triangle.mp3";

const colors = [
  "#FF0000",
  "#FF7F00",
  "#FFFF00",
  "#00FF00",
  "#0000FF",
  "#4B0082",
  "#8B00FF",
];

const triggerFireworks = () => {
  const fireworksContainer = document.querySelector(
    `.${styles.fireworksContainer}`
  );
  if (fireworksContainer) {
    for (let i = 0; i < 10; i++) {
      setTimeout(() => createFirework(fireworksContainer), i * 500);
    }
  } else {
    console.error("Fireworks container not found");
  }
};

const createFirework = (container) => {
  const firework = document.createElement("div");
  firework.className = styles.firework;
  firework.style.left = `${Math.random() * 100}%`;
  firework.style.top = `${Math.random() * 100}%`;
  container.appendChild(firework);

  const particles = [];
  for (let i = 0; i < 30; i++) {
    const particle = document.createElement("div");
    particle.className = styles.particle;
    particle.style.background =
      colors[Math.floor(Math.random() * colors.length)];
    firework.appendChild(particle);
    particles.push(particle);
  }

  const tl = gsap.timeline({
    onComplete: () => {
      firework.remove();
    },
  });

  particles.forEach((particle) => {
    const angle = Math.random() * Math.PI * 2;
    const distance = Math.random() * 200 + 50;
    tl.fromTo(
      particle,
      { x: 0, y: 0, opacity: 1 },
      {
        x: Math.cos(angle) * distance,
        y: Math.sin(angle) * distance,
        opacity: 0,
        duration: 2,
        ease: "power2.out",
      },
      0
    );
  });
};

const MusicalInstrumentsSoundsGame = () => {
  useDynamicHeight();
  const [isInteractionBlocked, setIsInteractionBlocked] = useState(false);
  const [isGameCompleted, setIsGameCompleted] = useState(false);
  const [currentTaskIndex, setCurrentTaskIndex] = useState(0);
  const [isAnswerCorrect, setIsAnswerCorrect] = useState(null);
  const [selectedAnswer, setSelectedAnswer] = useState(null);
  const [audioPlayed, setAudioPlayed] = useState(false);
  const [buttonText, setButtonText] = useState("Начать игру");
  const [isButtonVisible, setIsButtonVisible] = useState(true);
  const audioRef = useRef(null);
  const startInstructionRef = useRef(new Audio(startInstructionSound));

  const tasks = [
    {
      question: (
        <>
          Послушай звук.
          <br /> Какой инструмент издает его?
        </>
      ),
      images: [
        { src: piano, alt: "piano" },
        { src: harp, alt: "harp" },
        { src: flute, alt: "flute" },
        { src: guitar, alt: "guitar" },
      ],
      correctAnswer: "guitar",
    },
    {
      question: (
        <>
          Послушай звук.
          <br /> Какой инструмент издает его?
        </>
      ),
      images: [
        { src: dramMachine, alt: "dramMachine" },
        { src: maracas, alt: "maracas" },
        { src: xylophone, alt: "xylophone" },
        { src: harmonic, alt: "harmonic" },
      ],
      correctAnswer: "dramMachine",
    },
    {
      question: (
        <>
          Послушай звук.
          <br /> Какой инструмент издает его?
        </>
      ),
      images: [
        { src: violin, alt: "violin" },
        { src: flute, alt: "flute" },
        { src: guitar, alt: "guitar" },
        { src: harp, alt: "harp" },
      ],
      correctAnswer: "harp",
    },
    {
      question: (
        <>
          Послушай звук.
          <br /> Какой инструмент издает его?
        </>
      ),
      images: [
        { src: balalaika, alt: "balalaika" },
        { src: maracas, alt: "maracas" },
        { src: flute, alt: "flute" },
        { src: tambourine, alt: "tambourine" },
      ],
      correctAnswer: "flute",
    },
    {
      question: (
        <>
          Послушай звук.
          <br /> Какой инструмент издает его?
        </>
      ),
      images: [
        { src: tambourine, alt: "tambourine" },
        { src: maracas, alt: "maracas" },
        { src: piano, alt: "piano" },
        { src: balalaika, alt: "balalaika" },
      ],
      correctAnswer: "maracas",
    },
    {
      question: (
        <>
          Послушай звук.
          <br /> Какой инструмент издает его?
        </>
      ),
      images: [
        { src: dramMachine, alt: "dramMachine" },
        { src: harmonic, alt: "harmonic" },
        { src: violin, alt: "violin" },
        { src: tambourine, alt: "tambourine" },
      ],
      correctAnswer: "tambourine",
    },
    {
      question: (
        <>
          Послушай звук.
          <br /> Какой инструмент издает его?
        </>
      ),
      images: [
        { src: violin, alt: "violin" },
        { src: balalaika, alt: "balalaika" },
        { src: xylophone, alt: "xylophone" },
        { src: flute, alt: "flute" },
      ],
      correctAnswer: "violin",
    },
    {
      question: (
        <>
          Послушай звук.
          <br /> Какой инструмент издает его?
        </>
      ),
      images: [
        { src: balalaika, alt: "balalaika" },
        { src: dramMachine, alt: "dramMachine" },
        { src: guitar, alt: "guitar" },
        { src: harp, alt: "harp" },
      ],
      correctAnswer: "balalaika",
    },
    {
      question: (
        <>
          Послушай звук.
          <br /> Какой инструмент издает его?
        </>
      ),
      images: [
        { src: xylophone, alt: "xylophone" },
        { src: piano, alt: "piano" },
        { src: harp, alt: "harp" },
        { src: violin, alt: "violin" },
      ],
      correctAnswer: "piano",
    },
    {
      question: (
        <>
          Послушай звук.
          <br /> Какой инструмент издает его?
        </>
      ),
      images: [
        { src: maracas, alt: "maracas" },
        { src: guitar, alt: "guitar" },
        { src: harmonic, alt: "harmonic" },
        { src: tambourine, alt: "tambourine" },
      ],
      correctAnswer: "harmonic",
    },

    {
      question: (
        <>
          Послушай звук.
          <br /> Какой инструмент издает его?
        </>
      ),
      images: [
        { src: triangle, alt: "triangle" },
        { src: bell, alt: "bell" },
        { src: harp, alt: "harp" },
        { src: xylophone, alt: "xylophone" },
      ],
      correctAnswer: "xylophone",
    },
    {
      question: (
        <>
          Послушай звук.
          <br /> Какой инструмент издает его?
        </>
      ),
      images: [
        { src: triangle, alt: "triangle" },
        { src: violin, alt: "violin" },
        { src: bell, alt: "bell" },
        { src: piano, alt: "piano" },
      ],
      correctAnswer: "triangle",
    },
    {
      question: (
        <>
          Послушай звук.
          <br /> Какой инструмент издает его?
        </>
      ),
      images: [
        { src: bell, alt: "bell" },
        { src: guitar, alt: "guitar" },
        { src: triangle, alt: "triangle" },
        { src: tambourine, alt: "tambourine" },
      ],
      correctAnswer: "bell",
    },
  ];

  const currentTask = tasks[currentTaskIndex];

  const handleImageClick = (answer) => {
    if (isAnswerCorrect) return;

    setSelectedAnswer(answer);
    const isCorrect = answer === currentTask.correctAnswer;
    setIsAnswerCorrect(isCorrect);

    const audio = new Audio(
      isCorrect ? rightAnswerSound : incorrectAnswerSound
    );
    audio.play();

    if (isCorrect) {
      setTimeout(() => {
        setIsAnswerCorrect(null);
        setSelectedAnswer(null);

        if (currentTaskIndex + 1 < tasks.length) {
          setCurrentTaskIndex((prevIndex) => prevIndex + 1);
        } else {
          triggerFireworks();
          setTimeout(() => {
            setIsGameCompleted(true);
          }, 5000);
          const finalAudio = new Audio(finalSound);
          finalAudio.play();
        }
      }, 1000);
    } else {
      setTimeout(() => {
        setSelectedAnswer(null);
      }, 1000);
    }
  };

  const handleStartGame = () => {
    setIsInteractionBlocked(true);
    if (startInstructionRef.current) {
      startInstructionRef.current.play();

      startInstructionRef.current.onended = () => {
        setIsInteractionBlocked(false);
      };
    }
    setIsButtonVisible(false);
  };

  const handlePlaySound = () => {
    const soundMap = {
      harp: harpSound,
      balalaika: balalaikaSound,
      dramMachine: dramMachineSound,
      tambourine: tambourineSound,
      harmonic: harmonicSound,
      guitar: guitarSound,
      flute: fluteSound,
      bell: bellSound,
      xylophone: xylophoneSound,
      maracas: maracasSound,
      piano: pianoSound,
      violin: violinSound,
      triangle: triangleSound,
    };

    const currentSound = soundMap[currentTask.correctAnswer];
    if (currentSound) {
      const sound = new Audio(currentSound);
      sound.play();
      setAudioPlayed(true);
    }
  };

  useEffect(() => {
    return () => {
      startInstructionRef.current.pause();
      startInstructionRef.current.currentTime = 0;
    };
  }, []);

  return (
    <>
      {isGameCompleted && <EndGameOptions />}
      <div className={`container ${styles.someOtherClass}`}>
        <h3 className={styles.header}>{currentTask.question}</h3>
        <h4 className={styles.headerCounter}>
          {" "}
          Вопрос {currentTaskIndex + 1} из {tasks.length}
        </h4>
        <div
          className={styles.backgroundContainer}
          style={{
            pointerEvents: isInteractionBlocked ? "none" : "auto",
          }}
        >
          <div className={styles.imagesContainer}>
            {currentTask.images.map((image) => (
              <div className={styles.imageWrapper}>
                <img
                  key={image.alt}
                  src={image.src}
                  alt={image.alt}
                  className={`${styles.taskImage} ${
                    styles[`${image.alt}Image`]
                  }`}
                  onClick={() => handleImageClick(image.alt)}
                  style={{
                    filter:
                      selectedAnswer === image.alt && isAnswerCorrect
                        ? "drop-shadow(0 10px 50px rgba(50, 205, 50, 0.5))"
                        : selectedAnswer === image.alt &&
                          selectedAnswer !== null
                        ? "none"
                        : "",
                  }}
                />
              </div>
            ))}
            <button className={styles.centralButton} onClick={handlePlaySound}>
              <img src={dynamic} alt="Dynamic" />
              прослушать
            </button>
          </div>
        </div>
        <audio ref={audioRef} />
        {isButtonVisible && (
          <div className={styles.darkOverlay}>
            <button
              className={styles.buttonStartGame}
              onClick={handleStartGame}
            >
              {buttonText}
            </button>
          </div>
        )}
        <div className={styles.fireworksContainer}></div>
      </div>
    </>
  );
};

export default MusicalInstrumentsSoundsGame;
