import React, { useState, useRef, useEffect } from "react";
import useDynamicHeight from "../../../hooks/useDynamicHeight";
import { gsap } from "gsap";

import styles from "./dating-with-zhuzha-part-one.module.css";

import wallper from "./images/wallper.png";
import zhuzha from "./images/zhuzha.png";
import flowerOne from "./images/flowerOne.png";
import flowerTwo from "./images/flowerTwo.png";
import flowerThree from "./images/flowerThree.png";
import cafe from "./images/cafe.png";
import azh from "./images/azh.svg";
import azhAzh from "./images/azhAzh.svg";
import zha from "./images/zha.svg";
import zhi from "./images/zhi.svg";
import zhiZha from "./images/zhiZha.svg";
import zho from "./images/zho.svg";
import zhuZhu from "./images/zhuZhu.svg";
import zhuIzh from "./images/zhuIzh.svg";
import izh from "./images/izh.svg";
import izhZhi from "./images/izhZhi.svg";
import ozhZha from "./images/ozhZha.svg";
import ozhUzh from "./images/ozhUzh.svg";
import ezh from "./images/ezh.svg";
import zhu from "./images/zhu.svg";

import startInstructionSound from "./sounds/startInstruction.mp3";
import backgroundSound from "./sounds/backgroundSound.mp3";
import beeSound from "./sounds/bee.mp3";

const flowerData = [
  {
    id: 1,
    flowerSrc: flowerOne,
    soundSrc: zha,
    flowerPosition: { top: "64%", left: "4%" },
    soundPosition: { top: "66%", left: "5.1%" },
  },
  {
    id: 2,
    flowerSrc: flowerTwo,
    soundSrc: zhu,
    flowerPosition: { top: "41%", left: "6%" },
    soundPosition: { top: "43%", left: "7.1%" },
  },
  {
    id: 3,
    flowerSrc: flowerThree,
    soundSrc: zhi,
    flowerPosition: { top: "33%", left: "17%" },
    soundPosition: { top: "36%", left: "17.9%" },
  },
  {
    id: 4,
    flowerSrc: flowerThree,
    soundSrc: zhuZhu,
    flowerPosition: { top: "49%", left: "20%" },
    soundPosition: { top: "52%", left: "21%" },
  },
  {
    id: 5,
    flowerSrc: flowerTwo,
    soundSrc: zho,
    flowerPosition: { top: "43%", left: "33%" },
    soundPosition: { top: "45%", left: "34.1%" },
  },
  {
    id: 6,
    flowerSrc: flowerOne,
    soundSrc: zhiZha,
    flowerPosition: { top: "55%", left: "40%" },
    soundPosition: { top: "57%", left: "41.1%" },
  },
  {
    id: 7,
    flowerSrc: flowerThree,
    soundSrc: azh,
    flowerPosition: { top: "40%", left: "50%" },
    soundPosition: { top: "43%", left: "51%" },
  },
  {
    id: 8,
    flowerSrc: flowerTwo,
    soundSrc: ozhUzh,
    flowerPosition: { top: "77%", left: "60%" },
    soundPosition: { top: "79%", left: "61.1%" },
  },
  {
    id: 9,
    flowerSrc: flowerOne,
    soundSrc: azhAzh,
    flowerPosition: { top: "52%", left: "59%" },
    soundPosition: { top: "54%", left: "60.1%" },
  },
  {
    id: 10,
    flowerSrc: flowerThree,
    soundSrc: zhuIzh,
    flowerPosition: { top: "73%", left: "47%" },
    soundPosition: { top: "76%", left: "48.1%" },
  },
  {
    id: 11,
    flowerSrc: flowerTwo,
    soundSrc: ozhZha,
    flowerPosition: { top: "77%", left: "25%" },
    soundPosition: { top: "79%", left: "26.1%" },
  },
  {
    id: 12,
    flowerSrc: flowerOne,
    soundSrc: ezh,
    flowerPosition: { top: "76%", left: "36%" },
    soundPosition: { top: "78%", left: "37.1%" },
  },
  {
    id: 13,
    flowerSrc: flowerTwo,
    soundSrc: izh,
    flowerPosition: { top: "66%", left: "17%" },
    soundPosition: { top: "68%", left: "18.1%" },
  },
  {
    id: 14,
    flowerSrc: flowerOne,
    soundSrc: izhZhi,
    flowerPosition: { top: "62%", left: "52%" },
    soundPosition: { top: "64%", left: "53.1%" },
  },
];

const DatingWithZhuzhaFirstPart = ({ onEnterCafe }) => {
  useDynamicHeight();
  const [isInteractionBlocked, setIsInteractionBlocked] = useState(false);
  const [buttonText, setButtonText] = useState("Начать игру");
  const [isButtonVisible, setIsButtonVisible] = useState(true);
  const [isAnimating, setIsAnimating] = useState(false);
  const [sounds, setSounds] = useState(flowerData.map((item) => item.soundSrc));
  const zhuzhaRef = useRef(null);
  const audioRef = useRef(null);
  const startInstructionAudioRef = useRef(new Audio(startInstructionSound));
  const backgroundAudioRef = useRef(new Audio(backgroundSound));

  const handleSoundClick = (soundSrc) => {
    const beeSoundRef = new Audio(beeSound);
    const soundElement = document.querySelector(`[data-id='${soundSrc}']`);
    if (soundElement) {
      beeSoundRef.play();
      soundElement.classList.add(styles.disappearing);
      setTimeout(() => {
        setSounds((prevSounds) => {
          const newSounds = prevSounds.filter((sound) => sound !== soundSrc);

          if (newSounds.length === 0) {
            handleLastFlowerClick();
          }
          return newSounds;
        });
      }, 1000);
    }
  };

  const handleLastFlowerClick = () => {
    setIsAnimating(true);

    gsap.to(zhuzhaRef.current, { autoAlpha: 1, duration: 1 });

    gsap.to(zhuzhaRef.current, {
      x: 100,
      y: 0,
      duration: 1,
      onComplete: () => {
        gsap.to(zhuzhaRef.current, {
          autoAlpha: 0,
          duration: 1,
          onComplete: () => onEnterCafe(),
        });
      },
    });
  };

  const handleNextTask = () => {
    setIsInteractionBlocked(true);

    if (startInstructionAudioRef.current) {
      startInstructionAudioRef.current.play();

      startInstructionAudioRef.current.onended = () => {
        setIsInteractionBlocked(false);
        if (backgroundAudioRef.current) {
          backgroundAudioRef.current.loop = true;
          backgroundAudioRef.current.play();
        }
      };
    }

    setIsButtonVisible(false);
  };

  useEffect(() => {
    return () => {
      startInstructionAudioRef.current.pause();
      startInstructionAudioRef.current.currentTime = 0;
      backgroundAudioRef.current.pause();
      backgroundAudioRef.current.currentTime = 0;

      gsap.globalTimeline.clear();
    };
  }, []);

  return (
    <>
      <div className={`container ${styles.someOtherClass}`}>
        <div
          className={styles.backgroundContainer}
          style={{
            pointerEvents: isInteractionBlocked ? "none" : "auto",
          }}
        >
          <div className={styles.dropContainer}>
            <img className={styles.wallper} src={wallper} alt="фон" />
            <img className={styles.cafe} src={cafe} alt="кафе" />
            <img
              ref={zhuzhaRef}
              src={zhuzha}
              alt="Zhuzha"
              className={styles.zhuzha}
              style={{ position: "absolute", autoAlpha: 0 }}
            />
            {flowerData.map((flower) => (
              <div key={flower.id}>
                <img
                  className={styles.flower}
                  src={flower.flowerSrc}
                  alt={`Flower ${flower.id}`}
                  style={{
                    top: flower.flowerPosition.top,
                    left: flower.flowerPosition.left,
                    position: "absolute",
                    width: "7%",
                  }}
                />
                {sounds.includes(flower.soundSrc) && (
                  <img
                    className={`${styles.sound} ${
                      sounds.includes(flower.soundSrc)
                        ? ""
                        : styles.disappearing
                    }`}
                    src={flower.soundSrc}
                    alt={`Sound ${flower.id}`}
                    data-id={flower.soundSrc}
                    style={{
                      top: flower.soundPosition.top,
                      left: flower.soundPosition.left,
                      position: "absolute",
                      width: "5%",
                    }}
                    onClick={() => handleSoundClick(flower.soundSrc)}
                  />
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
      <audio ref={audioRef} />
      {isButtonVisible && (
        <div className={styles.darkOverlay}>
          <button className={styles.button} onClick={handleNextTask}>
            {buttonText}
          </button>
        </div>
      )}
    </>
  );
};

export default DatingWithZhuzhaFirstPart;
