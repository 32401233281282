import { useDrop } from "react-dnd";

const DropTarget = ({ children, targetId, onDrop, className }) => {
  const [, drop] = useDrop(() => ({
    accept: "fish",
    drop: (item) => onDrop(item.id, targetId),
  }));

  return (
    <div ref={drop} className={className}>
      {children}
    </div>
  );
};

export default DropTarget;
