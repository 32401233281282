import React, { useState, useEffect, useRef } from "react";
import useDynamicHeight from "../../../hooks/useDynamicHeight";
import EndGameOptions from "../../end-game-options/end-game-options";
import { gsap } from "gsap";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import DraggableItem from "./DraggableItem";
import DropTarget from "./DropTarget";
import styles from "./math-six.module.css";

import figureSix from "./images/figureSix.png";
import smallPyramid from "./images/smallPyramid.png";
import pyramideBase from "./images/pyramideBase.png";
import firstRing from "./images/firstRing.png";
import secondRing from "./images/secondRing.png";
import thirdRing from "./images/thirdRing.png";
import fourthRing from "./images/fourthRing.png";
import fifthRing from "./images/fifthRing.png";
import sixthRing from "./images/sixthRing.png";
import pyramideOneRing from "./images/1.png";
import pyramideTwoRings from "./images/2.png";
import pyramideThreeRings from "./images/3.png";
import pyramideFourRings from "./images/4.png";
import pyramideFiveRings from "./images/5.png";
import pyramideSixRings from "./images/6.png";
import railStation from "./images/railStation.png";
import locomotive from "./images/locomotive.png";
import wagonGrey from "./images/wagonGrey.png";
import wagonColor from "./images/wagonColor.png";

import startInstructionSound from "./sounds/startInstruction.mp3";
import backgroundSound from "./sounds/backgroundSound.mp3";
import taskZeroSound from "./sounds/taskZero.mp3";
import taskTwoSound from "./sounds/taskTwo.mp3";
import taskThreeSound from "./sounds/taskThree.mp3";
import taskFourSound from "./sounds/taskFour.mp3";
import ringRightSound from "./sounds/ringRight.mp3";
import ringIncorrectSound from "./sounds/incorrectRing.mp3";
import wagonSound from "./sounds/wagon.mp3";
import finalSound from "./sounds/finalSound.mp3";
import praiseSound from "./sounds/praise.mp3";

const colors = [
  "#FF0000",
  "#FF7F00",
  "#FFFF00",
  "#00FF00",
  "#0000FF",
  "#4B0082",
  "#8B00FF",
];

const triggerFireworks = () => {
  const fireworksContainer = document.querySelector(
    `.${styles.fireworksContainer}`
  );
  if (fireworksContainer) {
    for (let i = 0; i < 10; i++) {
      setTimeout(() => createFirework(fireworksContainer), i * 500);
    }
  } else {
    console.error("Fireworks container not found");
  }
};

const createFirework = (container) => {
  const firework = document.createElement("div");
  firework.className = styles.firework;
  firework.style.left = `${Math.random() * 100}%`;
  firework.style.top = `${Math.random() * 100}%`;
  container.appendChild(firework);

  const particles = [];
  for (let i = 0; i < 30; i++) {
    const particle = document.createElement("div");
    particle.className = styles.particle;
    particle.style.background =
      colors[Math.floor(Math.random() * colors.length)];
    firework.appendChild(particle);
    particles.push(particle);
  }

  const tl = gsap.timeline({
    onComplete: () => {
      firework.remove();
    },
  });

  particles.forEach((particle) => {
    const angle = Math.random() * Math.PI * 2;
    const distance = Math.random() * 200 + 50;
    tl.fromTo(
      particle,
      { x: 0, y: 0, opacity: 1 },
      {
        x: Math.cos(angle) * distance,
        y: Math.sin(angle) * distance,
        opacity: 0,
        duration: 2,
        ease: "power2.out",
      },
      0
    );
  });
};

const MathFigureSixGame = () => {
  useDynamicHeight();
  const [isGameCompleted, setIsGameCompleted] = useState(false);
  const [currentTaskIndex, setCurrentTaskIndex] = useState(0);
  const [imageOpacity, setImageOpacity] = useState(1);
  const [buttonText, setButtonText] = useState("Послушать");
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [isButtonVisible, setIsButtonVisible] = useState(true);
  const [isInteractionBlocked, setIsInteractionBlocked] = useState(true);
  const [ringsOnBase, setRingsOnBase] = useState({
    ring1: false,
    ring2: false,
    ring3: false,
    ring4: false,
    ring5: false,
    ring6: false,
  });
  const [isRingOnBase, setIsRingOnBase] = useState(false);
  const [ringsCount, setRingsCount] = useState(0);
  const basketImages = {
    0: pyramideBase,
    1: pyramideOneRing,
    2: pyramideTwoRings,
    3: pyramideThreeRings,
    4: pyramideFourRings,
    5: pyramideFiveRings,
    6: pyramideSixRings,
  };
  const [allWagonsColored, setAllWagonsColored] = useState(false);
  const [coloredWagons, setColoredWagons] = useState([
    false,
    false,
    false,
    false,
    false,
    false,
  ]);

  const audioRef = useRef(null);
  const startInstructionAudioRef = useRef(new Audio(startInstructionSound));
  const backgroundAudioRef = useRef(new Audio(backgroundSound));
  const wagonSoundRef = useRef(new Audio(wagonSound));
  const finalSoundRef = useRef(new Audio(finalSound));
  const praiseSoundRef = useRef(new Audio(praiseSound));

  const tasks = [
    {
      question: "Это цифра шесть",
      images: [{ src: figureSix, alt: "Цифра шесть", class: styles.figureSix }],
      audioSrc: taskZeroSound,
    },
    {
      question: "А это шесть пирамидок",
      images: [
        {
          src: figureSix,
          alt: "Цифра шесть",
          class: styles.figureFourWithFlowers,
        },
        { src: smallPyramid, alt: "пирамидка", class: styles.smallPyramid },
        { src: smallPyramid, alt: "пирамидка", class: styles.smallPyramid },
        { src: smallPyramid, alt: "пирамидка", class: styles.smallPyramid },
        { src: smallPyramid, alt: "пирамидка", class: styles.smallPyramid },
        { src: smallPyramid, alt: "пирамидка", class: styles.smallPyramid },
        { src: smallPyramid, alt: "пирамидка", class: styles.smallPyramid },
      ],
      audioSrc: taskTwoSound,
    },
    {
      question: "Собери пирамидку",
      images: [
        { src: firstRing, alt: "кольцо", id: "ring1" },
        { src: secondRing, alt: "кольцо", id: "ring2" },
        { src: thirdRing, alt: "кольцо", id: "ring3" },
        { src: fourthRing, alt: "кольцо", id: "ring4" },
        { src: fifthRing, alt: "кольцо", id: "ring5" },
        { src: sixthRing, alt: "кольцо", id: "ring6" },
        {
          src: pyramideBase,
          alt: "основа",
          class: styles.ringBase,
          id: "base",
        },
      ],
      audioSrc: taskThreeSound,
    },
    {
      question: "Раскрась шесть вагончиков, нажимая на них",
      images: [
        { src: railStation, alt: "вокзал", class: styles.railStation },
        { src: locomotive, alt: "локомотив", class: styles.locomotive },
        { src: wagonGrey, alt: "вагон", class: styles.wagon, id: "wagon1" },
        { src: wagonGrey, alt: "вагон", class: styles.wagon, id: "wagon2" },
        { src: wagonGrey, alt: "вагон", class: styles.wagon, id: "wagon3" },
        { src: wagonGrey, alt: "вагон", class: styles.wagon, id: "wagon4" },
        { src: wagonGrey, alt: "вагон", class: styles.wagon, id: "wagon5" },
        { src: wagonGrey, alt: "вагон", class: styles.wagon, id: "wagon6" },
      ],
      audioSrc: taskFourSound,
    },
  ];

  const currentTask = tasks[currentTaskIndex];

  const playSound = () => {
    if (currentTask.audioSrc) {
      setIsInteractionBlocked(true);
      setButtonDisabled(true);

      const taskAudio = new Audio(currentTask.audioSrc);
      taskAudio.play().catch((error) => {
        console.error("Playback failed:", error);
        setButtonDisabled(false);
        setIsInteractionBlocked(false);
      });

      taskAudio.onended = () => {
        setButtonDisabled(false);
        setIsInteractionBlocked(false);

        if (currentTaskIndex === tasks.length - 1) {
          setButtonText("");
        } else {
          setButtonText("Далее");
        }
      };
    }
  };
  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.onended = () => {
        if (currentTaskIndex !== 2) {
          setButtonDisabled(false);
          setButtonText("Далее");
        }
      };
    }
  }, [currentTaskIndex]);

  const handleNextTask = () => {
    if (buttonText === "Послушать") {
      setIsInteractionBlocked(true); // Блокируем взаимодействие сразу после нажатия
      playSound(); // Воспроизводим звук для текущей задачи
    } else if (buttonText === "Далее" && !buttonDisabled) {
      setImageOpacity(0);
      setTimeout(() => {
        if (currentTaskIndex + 1 < tasks.length) {
          setCurrentTaskIndex(currentTaskIndex + 1);
          setButtonText("Послушать");
          setButtonDisabled(false);
          setIsInteractionBlocked(true); // Снова блокируем после перехода к следующему заданию
        }
        setImageOpacity(1);
      }, 500);
    }
  };

  const handleDrop = (itemId, targetId) => {
    const correctOrder = ["ring1", "ring2", "ring3", "ring4", "ring5", "ring6"];

    if (targetId === "base") {
      if (correctOrder[ringsCount] === itemId) {
        const ringRightAudio = new Audio(ringRightSound);
        ringRightAudio.play();

        setRingsOnBase((prev) => ({
          ...prev,
          [itemId]: true,
        }));
        setRingsCount((prevCount) => prevCount + 1);
      } else {
        const ringIncorrectAudio = new Audio(ringIncorrectSound);
        ringIncorrectAudio.play();
      }
    }
  };
  useEffect(() => {
    if (currentTaskIndex === 2) {
      const allRingsCollected = ringsCount === 6;
      setButtonDisabled(!allRingsCollected && buttonText === "Далее");
    }
  }, [ringsCount, currentTaskIndex, buttonText]);

  const handleWagonClick = (index) => {
    setColoredWagons((prev) => {
      const newColoredWagons = [...prev];
      if (!newColoredWagons[index]) {
        newColoredWagons[index] = true;
        wagonSoundRef.current.play();

        if (newColoredWagons.every((colored) => colored) && !allWagonsColored) {
          setAllWagonsColored(true);

          finalSoundRef.current.play();

          finalSoundRef.current.onended = () => {
            triggerFireworks();
            setTimeout(() => {
              setIsGameCompleted(true);
            }, 5000);
            praiseSoundRef.current.play();
          };
        }
      }
      return newColoredWagons;
    });
  };

  const handleStartGame = () => {
    setButtonDisabled(true);
    setIsInteractionBlocked(true);
    const instructionAudio = startInstructionAudioRef.current;
    instructionAudio.play();

    instructionAudio.onended = () => {
      if (audioRef.current) {
        audioRef.current.play();
      }
      if (backgroundAudioRef.current) {
        backgroundAudioRef.current.play();
      }
      setButtonDisabled(false);
    };

    setIsButtonVisible(false);
  };

  useEffect(() => {
    document.body.style.backgroundColor = "rgba(252, 229, 170, 1)";
    return () => {
      if (startInstructionAudioRef.current) {
        startInstructionAudioRef.current.pause();
        startInstructionAudioRef.current.currentTime = 0;
      }

      if (backgroundAudioRef.current) {
        backgroundAudioRef.current.pause();
        backgroundAudioRef.current.currentTime = 0;
      }

      document.body.style.backgroundColor = "";
    };
  }, []);

  return (
    <DndProvider backend={HTML5Backend}>
      {isGameCompleted && <EndGameOptions />}
      <div className={`container ${styles.someOtherClass}`}>
        <div
          className={styles.backgroundContainer}
          style={{
            pointerEvents: isInteractionBlocked ? "none" : "auto",
          }}
        >
          <h3 className={styles.header}>{currentTask.question}</h3>
          <div
            style={{
              opacity: imageOpacity,
              transition: "opacity 0.5s ease-in-out",
            }}
            className={styles.taskContainer}
          >
            {currentTaskIndex === 0 &&
              currentTask.images.map((img, index) => (
                <img
                  key={index}
                  src={img.src}
                  alt={img.alt}
                  className={img.class}
                />
              ))}
            {currentTaskIndex === 1 && (
              <div className={styles.figureAndEclairsContainer}>
                <img
                  src={figureSix}
                  alt="Цифра шесть"
                  className={styles.figureSixWithPyramides}
                />
                <div className={styles.sixFlowers}>
                  <div className={styles.pyramideRow}>
                    {currentTask.images
                      .filter(
                        (img, index) => img.alt === "пирамидка" && index <= 3
                      )
                      .map((img, index) => (
                        <img
                          key={index}
                          src={img.src}
                          alt={img.alt}
                          className={styles.smallPyramid}
                        />
                      ))}
                  </div>
                  <div className={styles.pyramideRow}>
                    {currentTask.images
                      .filter(
                        (img, index) => img.alt === "пирамидка" && index <= 3
                      )
                      .map((img, index) => (
                        <img
                          key={index}
                          src={img.src}
                          alt={img.alt}
                          className={styles.smallPyramid}
                        />
                      ))}
                  </div>
                </div>
              </div>
            )}

            {currentTaskIndex === 2 && (
              <div className={styles.pyramidContainer}>
                <div className={styles.ringsContainer}>
                  {currentTask.images
                    .filter(
                      (img) => img.alt === "кольцо" && !ringsOnBase[img.id]
                    )
                    .map((img) => (
                      <DraggableItem
                        key={img.id}
                        id={img.id}
                        src={img.src}
                        alt={img.alt}
                        className={`${styles.ring} ${styles[img.id]}`}
                        isDisabled={ringsOnBase[img.id]}
                      />
                    ))}
                </div>
                <div className={styles.baseContainer}>
                  <DropTarget
                    targetId="base"
                    onDrop={handleDrop}
                    className={styles.baseDropTarget}
                  >
                    <img
                      src={basketImages[ringsCount]}
                      alt="Основа пирамиды"
                      className={styles.ringBase}
                    />
                  </DropTarget>
                </div>
              </div>
            )}
            {currentTaskIndex === 3 && (
              <div className={styles.railStationContainer}>
                <img
                  src={railStation}
                  alt="вокзал"
                  className={styles.railStation}
                />
                <img
                  src={locomotive}
                  alt="локомотив"
                  className={styles.locomotive}
                />
                <div className={styles.wagonsContainer}>
                  {currentTask.images
                    .filter((img) => img.alt === "вагон")
                    .map((img, index) => (
                      <img
                        key={img.id}
                        src={coloredWagons[index] ? wagonColor : img.src}
                        alt={img.alt}
                        className={img.class}
                        onClick={() => handleWagonClick(index)}
                      />
                    ))}
                </div>
              </div>
            )}
          </div>
        </div>
        {currentTaskIndex < tasks.length - 1 ? (
          <button
            className={styles.button}
            onClick={handleNextTask}
            disabled={buttonDisabled}
          >
            {buttonText}
          </button>
        ) : (
          buttonText === "Послушать" && (
            <button
              className={styles.button}
              onClick={() => {
                playSound();
                setButtonText("");
              }}
              disabled={buttonDisabled}
            >
              {buttonText}
            </button>
          )
        )}
        <div className={styles.fireworksContainer}></div>
      </div>
      <audio ref={audioRef} />
      <audio ref={backgroundAudioRef} src={backgroundSound} loop />
      {isButtonVisible && (
        <div className={styles.darkOverlay}>
          <button className={styles.buttonStartGame} onClick={handleStartGame}>
            Начать игру
          </button>
        </div>
      )}
    </DndProvider>
  );
};

export default MathFigureSixGame;
