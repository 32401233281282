import React, { useState, useEffect, useRef } from "react";
import Confetti from "react-confetti";
import useDynamicHeight from "../../../hooks/useDynamicHeight";
import EndGameOptions from "../../end-game-options/end-game-options";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import styles from "./animals-in-forest.module.css";
import DraggableItem from "../../DnD/drag";
import DropTarget from "../../DnD/drop";

import wallperImage from "./images/wallper.png";
import squirell from "./images/squirell.png";
import hedgehog from "./images/hedgehog.png";
import rabbit from "./images/rabbit.svg";
import boar from "./images/boar.png";
import fox from "./images/fox.svg";
import bear from "./images/bear.svg";
import owl from "./images/owl.png";
import wolf from "./images/wolf.svg";
import squirellShadow from "./images/squirellShadow.svg";
import hedgehogShadow from "./images/hedgehodShadow.svg";
import rabbitShadow from "./images/rabbitShadow.svg";
import boarShadow from "./images/boarShadow.svg";
import foxShadow from "./images/foxShadow.svg";
import bearShadow from "./images/bearShadow.svg";
import owlShadow from "./images/owlShadow.svg";
import wolfShadow from "./images/wolfShadow.svg";

import backgroundSound from "./sounds/backgroundSound.mp3";
import startInstructionSound from "./sounds/startInstructionSound.mp3";
import finalSound from "./sounds/finalSound.mp3";
import incorrectAnswerSound from "./sounds/no.mp3";
import bearSound from "./sounds/bearSound.mp3";
import boarSound from "./sounds/boarSound.mp3";
import foxSound from "./sounds/foxSound.mp3";
import hedgehogSound from "./sounds/hedgehogSound.mp3";
import owlSound from "./sounds/owlSound.mp3";
import rabbitSound from "./sounds/rabbitSound.mp3";
import squirellSound from "./sounds/squirellSound.mp3";
import wolfSound from "./sounds/wolfSound.mp3";

const images = [
  {
    id: "белка",
    src: squirell,
    alt: "белка",
    className: styles.squirell,
    dropStyle: {
      left: "83%",
      top: "33%",
      width: "11%",
    },
    shadowStyle: {
      left: "85",
      top: "38",
    },
    targetId: "белка",
  },
  {
    id: "ёж",
    src: hedgehog,
    alt: "ёж",
    className: styles.hedgehog,
    dropStyle: {
      left: "32%",
      top: "81%",
      width: "11%",
    },
    shadowStyle: {
      left: "34",
      top: "83",
    },
    targetId: "ёж",
  },
  {
    id: "заяц",
    src: rabbit,
    alt: "заяц",
    className: styles.rabbit,
    dropStyle: {
      left: "21.8%",
      top: "69%",
      width: "7.5%",
    },
    shadowStyle: {
      left: "21",
      top: "79",
    },
    targetId: "заяц",
  },
  {
    id: "кабан",
    src: boar,
    alt: "кабан",
    className: styles.boar,
    dropStyle: {
      left: "38%",
      top: "57%",
      width: "14%",
    },
    shadowStyle: {
      left: "40",
      top: "60",
    },
    targetId: "кабан",
  },
  {
    id: "лиса",
    src: fox,
    alt: "лиса",
    className: styles.fox,
    dropStyle: {
      left: "55%",
      top: "72%",
      width: "15%",
    },
    shadowStyle: {
      left: "55",
      top: "77",
    },
    targetId: "лиса",
  },
  {
    id: "медведь",
    src: bear,
    alt: "медведь",
    className: styles.bear,
    dropStyle: {
      left: "79%",
      top: "67%",
      width: "20%",
    },
    shadowStyle: {
      left: "84",
      top: "78",
    },
    targetId: "медведь",
  },
  {
    id: "сова",
    src: owl,
    alt: "сова",
    className: styles.owl,
    dropStyle: {
      left: "42%",
      top: "26%",
      width: "10%",
    },
    shadowStyle: {
      left: "41",
      top: "26",
    },
    targetId: "сова",
  },
  {
    id: "волк",
    src: wolf,
    alt: "волк",
    className: styles.wolf,
    dropStyle: {
      left: "3%",
      top: "61%",
      width: "13%",
    },
    shadowStyle: {
      left: "4",
      top: "66",
    },
    targetId: "волк",
  },
];

const AnimalsInForestGame = () => {
  useDynamicHeight();
  const [isInteractionBlocked, setIsInteractionBlocked] = useState(false);
  const [isGameCompleted, setIsGameCompleted] = useState(false);
  const [showConfetti, setShowConfetti] = useState(false);
  const [buttonText, setButtonText] = useState("Начать игру");
  const [isButtonVisible, setIsButtonVisible] = useState(true);
  const audioRef = useRef(null);
  const startInstructionAudioRef = useRef(new Audio(startInstructionSound));
  const backgroundAudioRef = useRef(new Audio(backgroundSound));
  const [itemPlaced, setItemPlaced] = useState(
    images.reduce(
      (acc, image) => ({
        ...acc,
        [image.alt]: false,
      }),
      {}
    )
  );

  const handleDrop = (itemId, targetId) => {
    if (itemId === targetId) {
      setItemPlaced((prev) => {
        const updatedItemPlaced = {
          ...prev,
          [itemId]: true,
        };

        let sound;
        switch (itemId) {
          case "белка":
            sound = new Audio(squirellSound);
            break;
          case "ёж":
            sound = new Audio(hedgehogSound);
            break;
          case "заяц":
            sound = new Audio(rabbitSound);
            break;
          case "кабан":
            sound = new Audio(boarSound);
            break;
          case "лиса":
            sound = new Audio(foxSound);
            break;
          case "медведь":
            sound = new Audio(bearSound);
            break;
          case "сова":
            sound = new Audio(owlSound);
            break;
          case "волк":
            sound = new Audio(wolfSound);
            break;
          default:
            break;
        }
        if (sound) {
          sound.play();
          sound.onended = () => {
            const allPlaced = Object.values(updatedItemPlaced).every(Boolean);
            if (allPlaced) {
              const finalAudio = new Audio(finalSound);
              finalAudio.play();
              setShowConfetti(true);
              setTimeout(() => {
                setIsGameCompleted(true);
              }, 5000);
            }
          };
        }
        return updatedItemPlaced;
      });
    } else {
      const incorrectSound = new Audio(incorrectAnswerSound);
      incorrectSound.play();
    }
  };

  const handleNextTask = () => {
    setIsInteractionBlocked(true);

    if (startInstructionAudioRef.current) {
      startInstructionAudioRef.current.play();

      startInstructionAudioRef.current.onended = () => {
        setIsInteractionBlocked(false);
        if (backgroundAudioRef.current) {
          backgroundAudioRef.current.loop = true;
          backgroundAudioRef.current.play();
        }
      };
    }

    setIsButtonVisible(false);
  };

  useEffect(() => {
    return () => {
      startInstructionAudioRef.current.pause();
      startInstructionAudioRef.current.currentTime = 0;
      backgroundAudioRef.current.pause();
      backgroundAudioRef.current.currentTime = 0;
    };
  }, []);

  return (
    <>
      <DndProvider backend={HTML5Backend}>
        {showConfetti && (
          <Confetti
            numberOfPieces={500}
            angle={180}
            spread={120}
            origin={{ x: 1, y: 0.5 }}
          />
        )}
        {isGameCompleted && <EndGameOptions />}
        <div className={`container ${styles.someOtherClass}`}>
          <div
            className={styles.backgroundContainer}
            style={{
              pointerEvents: isInteractionBlocked ? "none" : "auto",
            }}
          >
            <div className={styles.dropContainer}>
              <img className={styles.wallperImage} src={wallperImage} alt="" />
              <img
                className={styles.squirellShadow}
                src={squirellShadow}
                alt=""
              />
              <img
                className={styles.hedgehogShadow}
                src={hedgehogShadow}
                alt=""
              />
              {!itemPlaced["заяц"] && (
                <img
                  className={styles.rabbitShadow}
                  src={rabbitShadow}
                  alt=""
                />
              )}
              <img className={styles.boarShadow} src={boarShadow} alt="" />
              {!itemPlaced["лиса"] && (
                <img className={styles.foxShadow} src={foxShadow} alt="" />
              )}
              {!itemPlaced["медведь"] && (
                <img className={styles.bearShadow} src={bearShadow} alt="" />
              )}
              <img className={styles.owlShadow} src={owlShadow} alt="" />
              <img className={styles.wolfShadow} src={wolfShadow} alt="" />

              {images.map((image, index) => (
                <DropTarget
                  key={index}
                  id={image.id}
                  targetId={image.targetId}
                  onDrop={(itemId, targetId) => handleDrop(itemId, targetId)}
                  left={image.shadowStyle.left}
                  top={image.shadowStyle.top}
                />
              ))}

              {images.map(
                (image, index) =>
                  itemPlaced[image.id] && (
                    <img
                      key={index}
                      src={image.src}
                      alt={image.alt}
                      className={image.className}
                      style={{
                        position: "absolute",
                        ...image.dropStyle,
                      }}
                    />
                  )
              )}
            </div>

            <div className={styles.gallery}>
              {images.map((image, index) => {
                if (!itemPlaced[image.alt]) {
                  return (
                    <div key={index} className={styles.frameImage}>
                      <DraggableItem
                        id={image.id}
                        src={image.src}
                        alt={image.alt}
                        className={image.className}
                      />
                    </div>
                  );
                }
                return null;
              })}
            </div>
          </div>
          <audio ref={audioRef} />
          {isButtonVisible && (
            <div className={styles.darkOverlay}>
              <button className={styles.button} onClick={handleNextTask}>
                {buttonText}
              </button>
            </div>
          )}
        </div>
      </DndProvider>
    </>
  );
};

export default AnimalsInForestGame;
