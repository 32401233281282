import React, { useState, useEffect, useRef } from "react";
import useDynamicHeight from "../../../hooks/useDynamicHeight";
import EndGameOptions from "../../end-game-options/end-game-options";
import Confetti from "react-confetti";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import DraggableItem from "./DraggableItem";
import DropTarget from "./DropTarget";
import styles from "./math-nine.module.css";
import figureNine from "./images/figureNine.png";
import candy from "./images/candy.png";
import shelf from "./images/shelf.png";
import puzzleOne from "./images/puzzleOne.png";
import puzzleTwo from "./images/puzzleTwo.png";
import puzzleThree from "./images/puzzleThree.png";
import puzzleFour from "./images/puzzleFour.png";

import startInstructionSound from "./sounds/startInstruction.mp3";
import backgroundSound from "./sounds/backgroundSound.mp3";
import taskZeroSound from "./sounds/taskZero.mp3";
import taskTwoSound from "./sounds/taskTwo.mp3";
import taskThreeSound from "./sounds/taskThree.mp3";
import taskFourSound from "./sounds/taskFour.mp3";
import puzzleSound from "./sounds/puzzle.mp3";
import candySound from "./sounds/candy.mp3";
import finalSound from "./sounds/finalSound.mp3";
import praiseSound from "./sounds/praise.mp3";

const MathFigureNineGame = () => {
  useDynamicHeight();
  const [isGameCompleted, setIsGameCompleted] = useState(false);
  const [currentTaskIndex, setCurrentTaskIndex] = useState(0);
  const [imageOpacity, setImageOpacity] = useState(1);
  const [buttonText, setButtonText] = useState("Послушать");
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [isButtonVisible, setIsButtonVisible] = useState(true);
  const [isInteractionBlocked, setIsInteractionBlocked] = useState(true);
  const [showConfetti, setShowConfetti] = useState(false);
  const [candiesOnShelf, setCandiesOnShelf] = useState({
    candy1: false,
    candy2: false,
    candy3: false,
    candy4: false,
    candy5: false,
    candy6: false,
    candy7: false,
    candy8: false,
    candy9: false,
  });
  const [puzzleSolved, setPuzzleSolved] = useState(false);
  const [puzzles, setPuzzels] = useState([
    { id: "puzzle1", angle: 90, correctAngle: 0, fixed: false, src: puzzleOne },
    {
      id: "puzzle2",
      angle: 180,
      correctAngle: 0,
      fixed: false,
      src: puzzleTwo,
    },
    {
      id: "puzzle3",
      angle: 90,
      correctAngle: 0,
      fixed: false,
      src: puzzleThree,
    },
    {
      id: "puzzle4",
      angle: 180,
      correctAngle: 0,
      fixed: false,
      src: puzzleFour,
    },
  ]);

  const audioRef = useRef(null);
  const startInstructionAudioRef = useRef(new Audio(startInstructionSound));
  const backgroundAudioRef = useRef(new Audio(backgroundSound));
  const puzzleSoundRef = useRef(new Audio(puzzleSound));
  const finalSoundRef = useRef(new Audio(finalSound));
  const praiseSoundRef = useRef(new Audio(praiseSound));

  const tasks = [
    {
      question: "Это цифра девять",
      images: [
        { src: figureNine, alt: "Цифра девять", class: styles.figureNine },
      ],
      audioSrc: taskZeroSound,
    },
    {
      question: "А это девять конфет",
      images: [
        {
          src: figureNine,
          alt: "Цифра девять",
          class: styles.figureSevenWithPetals,
        },
        { src: candy, alt: "конфета", class: styles.candy },
        { src: candy, alt: "конфета", class: styles.candy },
        { src: candy, alt: "конфета", class: styles.candy },
        { src: candy, alt: "конфета", class: styles.candy },
        { src: candy, alt: "конфета", class: styles.candy },
        { src: candy, alt: "конфета", class: styles.candy },
        { src: candy, alt: "конфета", class: styles.candy },
        { src: candy, alt: "конфета", class: styles.candy },
        { src: candy, alt: "конфета", class: styles.candy },
      ],
      audioSrc: taskTwoSound,
    },
    {
      question: "Положи девять конфет в коробку",
      images: [
        { id: "candy1", src: candy, alt: "конфета", class: styles.candy },
        { id: "candy2", src: candy, alt: "конфета", class: styles.candy },
        { id: "candy3", src: candy, alt: "конфета", class: styles.candy },
        { id: "candy4", src: candy, alt: "конфета", class: styles.candy },
        { id: "candy5", src: candy, alt: "конфета", class: styles.candy },
        { id: "candy6", src: candy, alt: "конфета", class: styles.candy },
        { id: "candy7", src: candy, alt: "конфета", class: styles.candy },
        { id: "candy8", src: candy, alt: "конфета", class: styles.candy },
        { id: "candy9", src: candy, alt: "конфета", class: styles.candy },
      ],
      audioSrc: taskThreeSound,
    },
    {
      question: "Поверни пазлы так, чтобы получилась цифра девять",
      images: [
        { src: puzzleOne, alt: "пазл1", class: styles.puzzle1 },
        { src: puzzleTwo, alt: "пазл2", class: styles.puzzle2 },
        { src: puzzleThree, alt: "пазл3", class: styles.puzzle3 },
        { src: puzzleFour, alt: "пазл4", class: styles.puzzle4 },
      ],
      audioSrc: taskFourSound,
    },
  ];

  const currentTask = tasks[currentTaskIndex];

  const playSound = () => {
    if (currentTask.audioSrc) {
      setIsInteractionBlocked(true);
      setButtonDisabled(true);

      const taskAudio = new Audio(currentTask.audioSrc);
      taskAudio.play().catch((error) => {
        console.error("Playback failed:", error);
        setButtonDisabled(false);
        setIsInteractionBlocked(false);
      });

      taskAudio.onended = () => {
        setButtonDisabled(false);
        setIsInteractionBlocked(false);

        if (currentTaskIndex === tasks.length - 1) {
          setButtonText("");
        } else {
          setButtonText("Далее");
        }
      };
    }
  };

  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.onended = () => {
        if (currentTaskIndex !== 2) {
          setButtonDisabled(false);
          setButtonText("Далее");
        }
      };
    }
  }, [currentTaskIndex]);

  const handleNextTask = () => {
    if (buttonText === "Послушать") {
      setIsInteractionBlocked(true); // Блокируем взаимодействие сразу после нажатия
      playSound(); // Воспроизводим звук для текущей задачи
    } else if (buttonText === "Далее" && !buttonDisabled) {
      setImageOpacity(0);
      setTimeout(() => {
        if (currentTaskIndex + 1 < tasks.length) {
          setCurrentTaskIndex(currentTaskIndex + 1);
          setButtonText("Послушать");
          setButtonDisabled(false);
          setIsInteractionBlocked(true); // Снова блокируем после перехода к следующему заданию
        }
        setImageOpacity(1);
      }, 500);
    }
  };

  const handleDrop = (candyId, targetId) => {
    if (targetId === "shelf") {
      setCandiesOnShelf((prev) => ({
        ...prev,
        [candyId]: true,
      }));
      const candySoundAudio = new Audio(candySound);
      candySoundAudio.play();
    }
  };

  useEffect(() => {
    if (currentTaskIndex === 2) {
      const allCandiesOnShelf = Object.values(candiesOnShelf).every(
        (onShelf) => onShelf
      );
      setButtonDisabled(!allCandiesOnShelf && buttonText === "Далее");
      if (allCandiesOnShelf) {
        setButtonText("Далее");
      }
    }
  }, [candiesOnShelf, currentTaskIndex, buttonText]);

  const handleRotate = (puzzleId) => {
    setPuzzels((prevPuzzles) => {
      const updatedPuzzles = prevPuzzles.map((puzzle) => {
        if (puzzle.id === puzzleId && !puzzle.fixed) {
          const newAngle = (puzzle.angle + 90) % 360;
          const isCorrect = newAngle === puzzle.correctAngle;

          if (isCorrect) {
            puzzleSoundRef.current.play();
          }

          return { ...puzzle, angle: newAngle, fixed: isCorrect };
        }
        return puzzle;
      });

      const allCorrect = updatedPuzzles.every((puzzle) => puzzle.fixed);
      if (allCorrect && !puzzleSolved) {
        setPuzzleSolved(true);
        finalSoundRef.current.play();
        setShowConfetti(true);
        setTimeout(() => {
          setIsGameCompleted(true);
        }, 5000);
        finalSoundRef.current.onended = () => {
          praiseSoundRef.current.play();
        };
      }

      return updatedPuzzles;
    });
  };

  const handleStartGame = () => {
    setButtonDisabled(true);
    setIsInteractionBlocked(true);
    const instructionAudio = startInstructionAudioRef.current;
    instructionAudio.play();

    instructionAudio.onended = () => {
      if (audioRef.current) {
        audioRef.current.play();
      }
      if (backgroundAudioRef.current) {
        backgroundAudioRef.current.play();
      }
      setButtonDisabled(false);
    };

    setIsButtonVisible(false);
  };

  useEffect(() => {
    document.body.style.backgroundColor = "rgba(252, 229, 170, 1)";
    return () => {
      if (startInstructionAudioRef.current) {
        startInstructionAudioRef.current.pause();
        startInstructionAudioRef.current.currentTime = 0;
      }

      if (backgroundAudioRef.current) {
        backgroundAudioRef.current.pause();
        backgroundAudioRef.current.currentTime = 0;
      }

      document.body.style.backgroundColor = "";
    };
  }, []);

  return (
    <DndProvider backend={HTML5Backend}>
      {isGameCompleted && <EndGameOptions />}
      {showConfetti && (
        <Confetti
          numberOfPieces={500}
          angle={180}
          spread={120}
          origin={{ x: 1, y: 0.5 }}
        />
      )}
      <div className={`container ${styles.someOtherClass}`}>
        <div
          className={styles.backgroundContainer}
          style={{
            pointerEvents: isInteractionBlocked ? "none" : "auto",
          }}
        >
          <h3 className={styles.header}>{currentTask.question}</h3>
          <div
            style={{
              opacity: imageOpacity,
              transition: "opacity 0.5s ease-in-out",
            }}
            className={styles.taskContainer}
          >
            {currentTaskIndex === 0 &&
              currentTask.images.map((img, index) => (
                <img
                  key={index}
                  src={img.src}
                  alt={img.alt}
                  className={img.class}
                />
              ))}
            {currentTaskIndex === 1 && (
              <div className={styles.figureAndEclairsContainer}>
                <img
                  src={figureNine}
                  alt="Цифра девять"
                  className={styles.figureNine}
                />
                <div className={styles.fourFlowers}>
                  <div className={styles.flowerRow}>
                    {currentTask.images
                      .filter(
                        (img, index) => img.alt === "конфета" && index <= 3
                      )
                      .map((img, index) => (
                        <img
                          key={index}
                          src={img.src}
                          alt={img.alt}
                          className={styles.flower}
                        />
                      ))}
                  </div>
                  <div className={styles.flowerRow}>
                    {currentTask.images
                      .filter(
                        (img, index) =>
                          img.alt === "конфета" && index > 2 && index <= 5
                      )
                      .map((img, index) => (
                        <img
                          key={index}
                          src={img.src}
                          alt={img.alt}
                          className={styles.flower}
                        />
                      ))}
                  </div>
                  <div className={styles.flowerRow}>
                    {currentTask.images
                      .filter(
                        (img, index) =>
                          img.alt === "конфета" && index > 5 && index <= 8
                      )
                      .map((img, index) => (
                        <img
                          key={index}
                          src={img.src}
                          alt={img.alt}
                          className={styles.flower}
                        />
                      ))}
                  </div>
                </div>
              </div>
            )}
            {currentTaskIndex === 2 && (
              <div className={styles.shelfContainer}>
                <DropTarget
                  onDrop={handleDrop}
                  targetId="shelf"
                  className={styles.shelfPosition}
                >
                  <img
                    src={shelf}
                    alt="Шкаф"
                    className={styles.shelf}
                    id={shelf}
                  />
                  <div className={styles.candiesOnShelf}>
                    {Object.keys(candiesOnShelf).map(
                      (candyId) =>
                        candiesOnShelf[candyId] && (
                          <div
                            key={candyId}
                            className={`${styles.candyOnShelf} ${styles[candyId]}`}
                          >
                            <img
                              src={candy}
                              alt="конфета"
                              className={styles.candyImage}
                            />
                          </div>
                        )
                    )}
                  </div>
                </DropTarget>
                <div className={styles.candiesContainer}>
                  {Object.keys(candiesOnShelf)
                    .filter((candyId) => !candiesOnShelf[candyId])
                    .map((candyId) => (
                      <DraggableItem
                        key={candyId}
                        id={candyId}
                        src={candy}
                        alt="конфета"
                        className={styles.candy}
                      />
                    ))}
                </div>
              </div>
            )}
            {currentTaskIndex === 3 && (
              <div className={styles.cubesContainerMain}>
                <div className={styles.cubesContainer}>
                  {puzzles.map((puzzle) => (
                    <img
                      key={puzzle.id}
                      src={puzzle.src}
                      alt={puzzle.id}
                      className={styles[puzzle.id]}
                      style={{ transform: `rotate(${puzzle.angle}deg)` }}
                      onClick={() => handleRotate(puzzle.id)}
                    />
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>
        {currentTaskIndex < tasks.length - 1 ? (
          <button
            className={styles.button}
            onClick={handleNextTask}
            disabled={buttonDisabled}
          >
            {buttonText}
          </button>
        ) : (
          buttonText === "Послушать" && (
            <button
              className={styles.button}
              onClick={() => {
                playSound();
                setButtonText("");
              }}
              disabled={buttonDisabled}
            >
              {buttonText}
            </button>
          )
        )}
      </div>
      <audio ref={audioRef} />
      <audio ref={backgroundAudioRef} src={backgroundSound} loop />
      {isButtonVisible && (
        <div className={styles.darkOverlay}>
          <button className={styles.buttonStartGame} onClick={handleStartGame}>
            Начать игру
          </button>
        </div>
      )}
    </DndProvider>
  );
};

export default MathFigureNineGame;
