import React, { useState, useEffect, useRef } from "react";
import useDynamicHeight from "../../../hooks/useDynamicHeight";
import EndGameOptions from "../../end-game-options/end-game-options";
import styles from "./countryside.module.css";
import { gsap } from "gsap";
import dynamic from "./images/dynamic.svg";
import gate from "./images/gate.png";
import fire from "./images/fire.png";
import forest from "./images/forest.png";
import hammer from "./images/hammer.png";
import doorOpen from "./images/doorOpen.png";
import saw from "./images/saw.png";
import doorKnock from "./images/doorKnock.png";
import axe from "./images/axe.png";
import hose from "./images/hose.png";
import drill from "./images/drill.png";

import startInstructionSound from "./sounds/startInstruction.mp3";
import rightAnswerSound from "./sounds/rightAnswerSound.mp3";
import finalSound from "./sounds/finalSound.mp3";
import incorrectAnswerSound from "./sounds/no.mp3";

import gateSound from "./sounds/gate.mp3";
import fireSound from "./sounds/fire.mp3";
import forestSound from "./sounds/forest.mp3";
import hammerSound from "./sounds/hammer.mp3";
import doorOpenSound from "./sounds/doorOpen.mp3";
import sawSound from "./sounds/saw.mp3";
import doorKnockSound from "./sounds/doorKnock.mp3";
import axeSound from "./sounds/axe.mp3";
import hoseSound from "./sounds/hose.mp3";
import drillSound from "./sounds/drill.mp3";

const colors = [
  "#FF0000",
  "#FF7F00",
  "#FFFF00",
  "#00FF00",
  "#0000FF",
  "#4B0082",
  "#8B00FF",
];

const triggerFireworks = () => {
  const fireworksContainer = document.querySelector(
    `.${styles.fireworksContainer}`
  );
  if (fireworksContainer) {
    for (let i = 0; i < 10; i++) {
      setTimeout(() => createFirework(fireworksContainer), i * 500);
    }
  } else {
    console.error("Fireworks container not found");
  }
};

const createFirework = (container) => {
  const firework = document.createElement("div");
  firework.className = styles.firework;
  firework.style.left = `${Math.random() * 100}%`;
  firework.style.top = `${Math.random() * 100}%`;
  container.appendChild(firework);

  const particles = [];
  for (let i = 0; i < 30; i++) {
    const particle = document.createElement("div");
    particle.className = styles.particle;
    particle.style.background =
      colors[Math.floor(Math.random() * colors.length)];
    firework.appendChild(particle);
    particles.push(particle);
  }

  const tl = gsap.timeline({
    onComplete: () => {
      firework.remove();
    },
  });

  particles.forEach((particle) => {
    const angle = Math.random() * Math.PI * 2;
    const distance = Math.random() * 200 + 50;
    tl.fromTo(
      particle,
      { x: 0, y: 0, opacity: 1 },
      {
        x: Math.cos(angle) * distance,
        y: Math.sin(angle) * distance,
        opacity: 0,
        duration: 2,
        ease: "power2.out",
      },
      0
    );
  });
};

const CountrysideGame = () => {
  useDynamicHeight();
  const [isInteractionBlocked, setIsInteractionBlocked] = useState(false);
  const [isGameCompleted, setIsGameCompleted] = useState(false);
  const [currentTaskIndex, setCurrentTaskIndex] = useState(0);
  const [isAnswerCorrect, setIsAnswerCorrect] = useState(null);
  const [selectedAnswer, setSelectedAnswer] = useState(null);
  const [audioPlayed, setAudioPlayed] = useState(false);
  const [buttonText, setButtonText] = useState("Начать игру");
  const [isButtonVisible, setIsButtonVisible] = useState(true);
  const audioRef = useRef(null);
  const startInstructionRef = useRef(new Audio(startInstructionSound));

  const tasks = [
    {
      question: (
        <>
          Послушай звук.
          <br /> На что он похож?
        </>
      ),
      images: [
        { src: axe, alt: "axe" },
        { src: fire, alt: "fire" },
        { src: hammer, alt: "hammer" },
        { src: forest, alt: "forest" },
      ],
      correctAnswer: "hammer",
    },
    {
      question: (
        <>
          Послушай звук.
          <br /> На что он похож?
        </>
      ),
      images: [
        { src: axe, alt: "axe" },
        { src: hammer, alt: "hammer" },
        { src: saw, alt: "saw" },
        { src: gate, alt: "gate" },
      ],
      correctAnswer: "axe",
    },
    {
      question: (
        <>
          Послушай звук.
          <br /> На что он похож?
        </>
      ),
      images: [
        { src: forest, alt: "forest" },
        { src: fire, alt: "fire" },
        { src: gate, alt: "gate" },
        { src: doorKnock, alt: "doorKnock" },
      ],
      correctAnswer: "fire",
    },
    {
      question: (
        <>
          Послушай звук.
          <br /> На что он похож?
        </>
      ),
      images: [
        { src: doorKnock, alt: "doorKnock" },
        { src: saw, alt: "saw" },
        { src: gate, alt: "gate" },
        { src: doorOpen, alt: "doorOpen" },
      ],
      correctAnswer: "doorOpen",
    },
    {
      question: (
        <>
          Послушай звук.
          <br /> На что он похож?
        </>
      ),
      images: [
        { src: saw, alt: "saw" },
        { src: drill, alt: "drill" },
        { src: axe, alt: "axe" },
        { src: hammer, alt: "hammer" },
      ],
      correctAnswer: "saw",
    },
    {
      question: (
        <>
          Послушай звук.
          <br /> На что он похож?
        </>
      ),
      images: [
        { src: hose, alt: "hose" },
        { src: gate, alt: "gate" },
        { src: doorOpen, alt: "doorOpen" },
        { src: forest, alt: "forest" },
      ],
      correctAnswer: "gate",
    },
    {
      question: (
        <>
          Послушай звук.
          <br /> На что он похож?
        </>
      ),
      images: [
        { src: drill, alt: "drill" },
        { src: hammer, alt: "hammer" },
        { src: fire, alt: "fire" },
        { src: doorKnock, alt: "doorKnock" },
      ],
      correctAnswer: "doorKnock",
    },
    {
      question: (
        <>
          Послушай звук.
          <br /> На что он похож?
        </>
      ),
      images: [
        { src: forest, alt: "forest" },
        { src: hammer, alt: "hammer" },
        { src: drill, alt: "drill" },
        { src: fire, alt: "fire" },
      ],
      correctAnswer: "drill",
    },
    {
      question: (
        <>
          Послушай звук.
          <br /> На что он похож?
        </>
      ),
      images: [
        { src: forest, alt: "forest" },
        { src: doorOpen, alt: "doorOpen" },
        { src: fire, alt: "fire" },
        { src: gate, alt: "gate" },
      ],
      correctAnswer: "forest",
    },
    {
      question: (
        <>
          Послушай звук.
          <br /> На что он похож?
        </>
      ),
      images: [
        { src: hammer, alt: "hammer" },
        { src: hose, alt: "hose" },
        { src: fire, alt: "fire" },
        { src: axe, alt: "axe" },
      ],
      correctAnswer: "hose",
    },
  ];

  const currentTask = tasks[currentTaskIndex];

  const handleImageClick = (answer) => {
    if (isAnswerCorrect) return;

    setSelectedAnswer(answer);
    const isCorrect = answer === currentTask.correctAnswer;
    setIsAnswerCorrect(isCorrect);

    const audio = new Audio(
      isCorrect ? rightAnswerSound : incorrectAnswerSound
    );
    audio.play();

    if (isCorrect) {
      setTimeout(() => {
        setIsAnswerCorrect(null);
        setSelectedAnswer(null);

        if (currentTaskIndex + 1 < tasks.length) {
          setCurrentTaskIndex((prevIndex) => prevIndex + 1);
        } else {
          triggerFireworks();
          setTimeout(() => {
            setIsGameCompleted(true);
          }, 5000);
          const finalAudio = new Audio(finalSound);
          finalAudio.play();
        }
      }, 1000);
    } else {
      setTimeout(() => {
        setSelectedAnswer(null);
      }, 1000);
    }
  };

  const handleStartGame = () => {
    setIsInteractionBlocked(true);
    if (startInstructionRef.current) {
      startInstructionRef.current.play();

      startInstructionRef.current.onended = () => {
        setIsInteractionBlocked(false);
      };
    }
    setIsButtonVisible(false);
  };

  const handlePlaySound = () => {
    const soundMap = {
      axe: axeSound,
      fire: fireSound,
      hammer: hammerSound,
      forest: forestSound,
      doorKnock: doorKnockSound,
      doorOpen: doorOpenSound,
      saw: sawSound,
      drill: drillSound,
      hose: hoseSound,
      gate: gateSound,
    };

    const currentSound = soundMap[currentTask.correctAnswer];
    if (currentSound) {
      const sound = new Audio(currentSound);
      sound.play();
      setAudioPlayed(true);
    }
  };

  useEffect(() => {
    return () => {
      startInstructionRef.current.pause();
      startInstructionRef.current.currentTime = 0;
    };
  }, []);

  return (
    <>
      {isGameCompleted && <EndGameOptions />}
      <div className={`container ${styles.someOtherClass}`}>
        <h3 className={styles.header}>{currentTask.question}</h3>
        <h4 className={styles.headerCounter}>
          {" "}
          Вопрос {currentTaskIndex + 1} из {tasks.length}
        </h4>
        <div
          className={styles.backgroundContainer}
          style={{
            pointerEvents: isInteractionBlocked ? "none" : "auto",
          }}
        >
          <div className={styles.imagesContainer}>
            {currentTask.images.map((image) => (
              <div className={styles.imageWrapper}>
                <img
                  key={image.alt}
                  src={image.src}
                  alt={image.alt}
                  className={`${styles.taskImage} ${
                    styles[`${image.alt}Image`]
                  }`}
                  onClick={() => handleImageClick(image.alt)}
                  style={{
                    filter:
                      selectedAnswer === image.alt && isAnswerCorrect
                        ? "drop-shadow(0 10px 50px rgba(50, 205, 50, 0.5))"
                        : selectedAnswer === image.alt &&
                          selectedAnswer !== null
                        ? "none"
                        : "",
                  }}
                />
              </div>
            ))}
            <button className={styles.centralButton} onClick={handlePlaySound}>
              <img src={dynamic} alt="Dynamic" />
              прослушать
            </button>
          </div>
        </div>
        <audio ref={audioRef} />
        {isButtonVisible && (
          <div className={styles.darkOverlay}>
            <button
              className={styles.buttonStartGame}
              onClick={handleStartGame}
            >
              {buttonText}
            </button>
          </div>
        )}
        <div className={styles.fireworksContainer}></div>
      </div>
    </>
  );
};

export default CountrysideGame;
