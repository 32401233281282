import React, { useState, useEffect, useRef } from "react";
import useDynamicHeight from "../../../hooks/useDynamicHeight";
import EndGameOptions from "../../end-game-options/end-game-options";
import styles from "./emotions-sounds.module.css";
import { gsap } from "gsap";
import dynamic from "./images/dynamic.svg";

import pear from "./images/pear.png";
import cough from "./images/cough.png";
import sing from "./images/sing.png";
import cry from "./images/cry.png";
import spit from "./images/spit.png";
import kiss from "./images/kiss.png";
import arguing from "./images/arguing.png";
import adultRofl from "./images/adultRofl.png";
import rofl from "./images/rofl.png";
import surprise from "./images/surprise.png";
import clap from "./images/clap.png";
import snore from "./images/snore.png";
import slurp from "./images/slurp.png";
import sneeze from "./images/sneeze.png";

import startInstructionSound from "./sounds/startInstruction.mp3";
import rightAnswerSound from "./sounds/rightAnswer.mp3";
import finalSound from "./sounds/finalSound.mp3";
import incorrectAnswerSound from "./sounds/no.mp3";

import pearSound from "./sounds/pear.mp3";
import coughSound from "./sounds/cough.mp3";
import singSound from "./sounds/sing.mp3";
import crySound from "./sounds/cry.mp3";
import spitSound from "./sounds/spit.mp3";
import kissSound from "./sounds/kiss.mp3";
import arguingSound from "./sounds/arguing.mp3";
import adultRoflSound from "./sounds/adultRofl.mp3";
import roflSound from "./sounds/rofl.mp3";
import surpriseSound from "./sounds/surprise.mp3";
import clapSound from "./sounds/clap.mp3";
import snoreSound from "./sounds/snore.mp3";
import slurpSound from "./sounds/slurp.mp3";
import sneezeSound from "./sounds/sneeze.mp3";

const colors = [
  "#FF0000",
  "#FF7F00",
  "#FFFF00",
  "#00FF00",
  "#0000FF",
  "#4B0082",
  "#8B00FF",
];

const triggerFireworks = () => {
  const fireworksContainer = document.querySelector(
    `.${styles.fireworksContainer}`
  );
  if (fireworksContainer) {
    for (let i = 0; i < 10; i++) {
      setTimeout(() => createFirework(fireworksContainer), i * 500);
    }
  } else {
    console.error("Fireworks container not found");
  }
};

const createFirework = (container) => {
  const firework = document.createElement("div");
  firework.className = styles.firework;
  firework.style.left = `${Math.random() * 100}%`;
  firework.style.top = `${Math.random() * 100}%`;
  container.appendChild(firework);

  const particles = [];
  for (let i = 0; i < 30; i++) {
    const particle = document.createElement("div");
    particle.className = styles.particle;
    particle.style.background =
      colors[Math.floor(Math.random() * colors.length)];
    firework.appendChild(particle);
    particles.push(particle);
  }

  const tl = gsap.timeline({
    onComplete: () => {
      firework.remove();
    },
  });

  particles.forEach((particle) => {
    const angle = Math.random() * Math.PI * 2;
    const distance = Math.random() * 200 + 50;
    tl.fromTo(
      particle,
      { x: 0, y: 0, opacity: 1 },
      {
        x: Math.cos(angle) * distance,
        y: Math.sin(angle) * distance,
        opacity: 0,
        duration: 2,
        ease: "power2.out",
      },
      0
    );
  });
};

const EmotionsSoundsGame = () => {
  useDynamicHeight();
  const [isInteractionBlocked, setIsInteractionBlocked] = useState(false);
  const [isGameCompleted, setIsGameCompleted] = useState(false);
  const [currentTaskIndex, setCurrentTaskIndex] = useState(0);
  const [isAnswerCorrect, setIsAnswerCorrect] = useState(null);
  const [selectedAnswer, setSelectedAnswer] = useState(null);
  const [audioPlayed, setAudioPlayed] = useState(false);
  const [buttonText, setButtonText] = useState("Начать игру");
  const [isButtonVisible, setIsButtonVisible] = useState(true);
  const audioRef = useRef(null);
  const startInstructionRef = useRef(new Audio(startInstructionSound));

  const tasks = [
    {
      question: (
        <>
          Послушай звук.
          <br /> На что он похож?
        </>
      ),
      images: [
        { src: sing, alt: "sing" },
        { src: pear, alt: "pear" },
        { src: adultRofl, alt: "adultRofl" },
        { src: snore, alt: "snore" },
      ],
      correctAnswer: "adultRofl",
    },
    {
      question: (
        <>
          Послушай звук.
          <br /> На что он похож?
        </>
      ),
      images: [
        { src: cry, alt: "cry" },
        { src: cough, alt: "cough" },
        { src: clap, alt: "clap" },
        { src: slurp, alt: "slurp" },
      ],
      correctAnswer: "cry",
    },
    {
      question: (
        <>
          Послушай звук.
          <br /> На что он похож?
        </>
      ),
      images: [
        { src: surprise, alt: "surprise" },
        { src: rofl, alt: "rofl" },
        { src: arguing, alt: "arguing" },
        { src: cry, alt: "cry" },
      ],
      correctAnswer: "arguing",
    },
    {
      question: (
        <>
          Послушай звук.
          <br /> На что он похож?
        </>
      ),
      images: [
        { src: sneeze, alt: "sneeze" },
        { src: slurp, alt: "slurp" },
        { src: cough, alt: "cough" },
        { src: surprise, alt: "surprise" },
      ],
      correctAnswer: "surprise",
    },
    {
      question: (
        <>
          Послушай звук.
          <br /> На что он похож?
        </>
      ),
      images: [
        { src: pear, alt: "pear" },
        { src: slurp, alt: "slurp" },
        { src: snore, alt: "snore" },
        { src: kiss, alt: "kiss" },
      ],
      correctAnswer: "pear",
    },
    {
      question: (
        <>
          Послушай звук.
          <br /> На что он похож?
        </>
      ),
      images: [
        { src: sneeze, alt: "sneeze" },
        { src: snore, alt: "snore" },
        { src: slurp, alt: "slurp" },
        { src: surprise, alt: "surprise" },
      ],
      correctAnswer: "slurp",
    },
    {
      question: (
        <>
          Послушай звук.
          <br /> На что он похож?
        </>
      ),
      images: [
        { src: arguing, alt: "arguing" },
        { src: spit, alt: "spit" },
        { src: cough, alt: "cough" },
        { src: sneeze, alt: "sneeze" },
      ],
      correctAnswer: "spit",
    },
    {
      question: (
        <>
          Послушай звук.
          <br /> На что он похож?
        </>
      ),
      images: [
        { src: snore, alt: "snore" },
        { src: sing, alt: "sing" },
        { src: cry, alt: "cry" },
        { src: clap, alt: "clap" },
      ],
      correctAnswer: "clap",
    },
    {
      question: (
        <>
          Послушай звук.
          <br /> На что он похож?
        </>
      ),
      images: [
        { src: kiss, alt: "kiss" },
        { src: surprise, alt: "surprise" },
        { src: rofl, alt: "rofl" },
        { src: adultRofl, alt: "adultRofl" },
      ],
      correctAnswer: "kiss",
    },
    {
      question: (
        <>
          Послушай звук.
          <br /> На что он похож?
        </>
      ),
      images: [
        { src: cough, alt: "cough" },
        { src: sing, alt: "sing" },
        { src: clap, alt: "clap" },
        { src: slurp, alt: "slurp" },
      ],
      correctAnswer: "sing",
    },
    {
      question: (
        <>
          Послушай звук.
          <br /> На что он похож?
        </>
      ),
      images: [
        { src: snore, alt: "snore" },
        { src: pear, alt: "pear" },
        { src: rofl, alt: "rofl" },
        { src: arguing, alt: "arguing" },
      ],
      correctAnswer: "rofl",
    },
    {
      question: (
        <>
          Послушай звук.
          <br /> На что он похож?
        </>
      ),
      images: [
        { src: cough, alt: "cough" },
        { src: sneeze, alt: "sneeze" },
        { src: cry, alt: "cry" },
        { src: snore, alt: "snore" },
      ],
      correctAnswer: "cough",
    },

    {
      question: (
        <>
          Послушай звук.
          <br /> На что он похож?
        </>
      ),
      images: [
        { src: sneeze, alt: "sneeze" },
        { src: sing, alt: "sing" },
        { src: surprise, alt: "surprise" },
        { src: clap, alt: "clap" },
      ],
      correctAnswer: "sneeze",
    },
    {
      question: (
        <>
          Послушай звук.
          <br /> На что он похож?
        </>
      ),
      images: [
        { src: slurp, alt: "slurp" },
        { src: cough, alt: "cough" },
        { src: snore, alt: "snore" },
        { src: rofl, alt: "rofl" },
      ],
      correctAnswer: "snore",
    },
  ];

  const currentTask = tasks[currentTaskIndex];

  const handleImageClick = (answer) => {
    if (isAnswerCorrect) return;

    setSelectedAnswer(answer);
    const isCorrect = answer === currentTask.correctAnswer;
    setIsAnswerCorrect(isCorrect);

    const audio = new Audio(
      isCorrect ? rightAnswerSound : incorrectAnswerSound
    );
    audio.play();

    if (isCorrect) {
      setTimeout(() => {
        setIsAnswerCorrect(null);
        setSelectedAnswer(null);

        if (currentTaskIndex + 1 < tasks.length) {
          setCurrentTaskIndex((prevIndex) => prevIndex + 1);
        } else {
          triggerFireworks();
          setTimeout(() => {
            setIsGameCompleted(true);
          }, 5000);
          const finalAudio = new Audio(finalSound);
          finalAudio.play();
        }
      }, 1000);
    } else {
      setTimeout(() => {
        setSelectedAnswer(null);
      }, 1000);
    }
  };

  const handleStartGame = () => {
    setIsInteractionBlocked(true);
    if (startInstructionRef.current) {
      startInstructionRef.current.play();

      startInstructionRef.current.onended = () => {
        setIsInteractionBlocked(false);
      };
    }
    setIsButtonVisible(false);
  };

  const handlePlaySound = () => {
    const soundMap = {
      pear: pearSound,
      cough: coughSound,
      sing: singSound,
      cry: crySound,
      spit: spitSound,
      kiss: kissSound,
      arguing: arguingSound,
      adultRofl: adultRoflSound,
      rofl: roflSound,
      surprise: surpriseSound,
      clap: clapSound,
      snore: snoreSound,
      slurp: slurpSound,
      sneeze: sneezeSound,
    };

    const currentSound = soundMap[currentTask.correctAnswer];
    if (currentSound) {
      const sound = new Audio(currentSound);
      sound.play();
      setAudioPlayed(true);
    }
  };

  useEffect(() => {
    return () => {
      startInstructionRef.current.pause();
      startInstructionRef.current.currentTime = 0;
    };
  }, []);

  return (
    <>
      {isGameCompleted && <EndGameOptions />}
      <div className={`container ${styles.someOtherClass}`}>
        <h3 className={styles.header}>{currentTask.question}</h3>
        <h4 className={styles.headerCounter}>
          {" "}
          Вопрос {currentTaskIndex + 1} из {tasks.length}
        </h4>
        <div
          className={styles.backgroundContainer}
          style={{
            pointerEvents: isInteractionBlocked ? "none" : "auto",
          }}
        >
          <div className={styles.imagesContainer}>
            {currentTask.images.map((image) => (
              <div className={styles.imageWrapper}>
                <img
                  key={image.alt}
                  src={image.src}
                  alt={image.alt}
                  className={`${styles.taskImage} ${
                    styles[`${image.alt}Image`]
                  }`}
                  onClick={() => handleImageClick(image.alt)}
                  style={{
                    filter:
                      selectedAnswer === image.alt && isAnswerCorrect
                        ? "drop-shadow(0 10px 50px rgba(50, 205, 50, 0.5))"
                        : selectedAnswer === image.alt &&
                          selectedAnswer !== null
                        ? "none"
                        : "",
                  }}
                />
              </div>
            ))}
            <button className={styles.centralButton} onClick={handlePlaySound}>
              <img src={dynamic} alt="Dynamic" />
              прослушать
            </button>
          </div>
        </div>
        <audio ref={audioRef} />
        {isButtonVisible && (
          <div className={styles.darkOverlay}>
            <button
              className={styles.buttonStartGame}
              onClick={handleStartGame}
            >
              {buttonText}
            </button>
          </div>
        )}
        <div className={styles.fireworksContainer}></div>
      </div>
    </>
  );
};

export default EmotionsSoundsGame;
