import { useState } from "react";
import styles from "./profile.module.css";
import TextField from "@mui/material/TextField";
import { useEffect } from "react";
import { putUserChangePassword } from "../api/change-password";
import { getUserInfo } from "../api/user-info";

function Profile({ openPasswordSuccessModal, openPasswordNotSuccessModal }) {
  const [userame, setUsername] = useState("Крекер");
  const [email, setEmail] = useState("kreker1488@gmail.com");
  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [isFormValid, setIsFormValid] = useState(false);
  const [userInfo, setUserInfo] = useState(null);

  useEffect(() => {
    getUserInfo()
      .then((data) => {
        setUserInfo(data); // Обновление состояния userInfo данными пользователя
        localStorage.setItem("userId", JSON.stringify(data.id));
      })
      .catch((error) => {
        console.error("Ошибка при получении информации о пользователе:", error);
      });
  }, []);

  console.log(userInfo);

  useEffect(() => {
    setIsFormValid(userame && email && password && newPassword);
  }, [userame, email, password, newPassword]);

  const handleCurrentlyPassword = (event) => {
    setPassword(event.target.value);
  };

  const handleNewPassword = (event) => {
    setNewPassword(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const userData = {
      oldPassword: password,
      newPassword: newPassword,
    };
    putUserChangePassword(userData)
      .then((data) => {
        openPasswordSuccessModal();
        setPassword("");
        setNewPassword("");
      })
      .catch((error) => {
        console.error("There was a problem with the fetch operation:", error);
        openPasswordNotSuccessModal();
        setPassword("");
        setNewPassword("");
      });
  };

  return (
    <div className={styles.profileContainer}>
      <h3 className={styles.headerText}>
        Здравствуйте, {userInfo ? userInfo.name : ""}!
      </h3>
      <p className={styles.text}>
        Здесь вы можете изменить свои данные для входа.
      </p>
      <p className={styles.text}>
        Пожалуйста, введите текущий пароль и придумайте новый.
      </p>

      <form noValidate autoComplete="off" onSubmit={handleSubmit}>
        <div className={styles.loginPasswordContainer}>
          <TextField
            required
            id="userName"
            label="Ваше имя"
            variant="outlined"
            margin="normal"
            fullWidth
            value={userInfo ? userInfo.name : ""}
            disabled={true}
          />
          <TextField
            required
            id="email"
            label="Email"
            variant="outlined"
            margin="normal"
            fullWidth
            value={userInfo ? userInfo.email : ""}
            disabled={true}
          />
          <TextField
            required
            id="password"
            label="Текущий пароль"
            type="password"
            variant="outlined"
            margin="normal"
            fullWidth
            value={password}
            onChange={handleCurrentlyPassword}
          />
          <TextField
            required
            id="newPassword"
            label="Новый пароль"
            type="password"
            variant="outlined"
            margin="normal"
            fullWidth
            value={newPassword}
            onChange={handleNewPassword}
          />

          <button
            type="submit"
            className={styles.btnRegistration}
            disabled={!isFormValid}
          >
            Изменить пароль
          </button>
        </div>
      </form>
    </div>
  );
}

export default Profile;
