import React, { useState, useEffect, useRef } from "react";
import useDynamicHeight from "../../../hooks/useDynamicHeight";
import EndGameOptions from "../../end-game-options/end-game-options";
import { gsap } from "gsap";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import DraggableItem from "./DraggableItem";
import DropTarget from "./DropTarget";
import styles from "./studying-square.module.css";

import square from "./images/square.png";
import pyramide from "./images/pyramide.png";
import greenCube from "./images/greenCube.png";
import yellowCube from "./images/yellowCube.png";
import redCube from "./images/redCube.png";
import camera from "./images/camera.png";
import chess from "./images/chess.png";
import ladybird from "./images/ladybird.png";
import melon from "./images/melon.png";
import pie from "./images/pie.png";
import pillow from "./images/pillow.png";
import pictureOne from "./images/catOne.png";
import pictureTwo from "./images/catTwo.png";
import pictureThree from "./images/catThree.png";
import pictureFour from "./images/catFour.png";
import wallper from "./images/wallper.png";
import table from "./images/table.png";
import aquarium from "./images/aquarium.png";
import picture from "./images/picture.png";

import backgroundSound from "./sounds/backgroundSound.mp3";
import incorrectAnswerSound from "./sounds/no.mp3";
import correctAnswerSound from "./sounds/correctAnswer.mp3";

import taskZeroSound from "./sounds/taskZero.mp3";
import taskTwoSound from "./sounds/taskTwo.mp3";
import taskThreeSound from "./sounds/taskThree.mp3";
import taskFourSound from "./sounds/taskFour.mp3";

import cubeOneSound from "./sounds/cubeOne.mp3";
import cubeTwoSound from "./sounds/cubeTwo.mp3";
import puzzleSound from "./sounds/puzzle.mp3";
import praiseSound from "./sounds/praise.mp3";

const colors = [
  "#FF0000",
  "#FF7F00",
  "#FFFF00",
  "#00FF00",
  "#0000FF",
  "#4B0082",
  "#8B00FF",
];

const triggerFireworks = () => {
  const fireworksContainer = document.querySelector(
    `.${styles.fireworksContainer}`
  );
  if (fireworksContainer) {
    for (let i = 0; i < 10; i++) {
      setTimeout(() => createFirework(fireworksContainer), i * 500);
    }
  } else {
    console.error("Fireworks container not found");
  }
};

const createFirework = (container) => {
  const firework = document.createElement("div");
  firework.className = styles.firework;
  firework.style.left = `${Math.random() * 100}%`;
  firework.style.top = `${Math.random() * 100}%`;
  container.appendChild(firework);

  const particles = [];
  for (let i = 0; i < 30; i++) {
    const particle = document.createElement("div");
    particle.className = styles.particle;
    particle.style.background =
      colors[Math.floor(Math.random() * colors.length)];
    firework.appendChild(particle);
    particles.push(particle);
  }

  const tl = gsap.timeline({
    onComplete: () => {
      firework.remove();
    },
  });

  particles.forEach((particle) => {
    const angle = Math.random() * Math.PI * 2;
    const distance = Math.random() * 200 + 50;
    tl.fromTo(
      particle,
      { x: 0, y: 0, opacity: 1 },
      {
        x: Math.cos(angle) * distance,
        y: Math.sin(angle) * distance,
        opacity: 0,
        duration: 2,
        ease: "power2.out",
      },
      0
    );
  });
};

const StudyingSquareGame = () => {
  useDynamicHeight();
  const [isGameCompleted, setIsGameCompleted] = useState(false);
  const [currentTaskIndex, setCurrentTaskIndex] = useState(0);
  const [imageOpacity, setImageOpacity] = useState(1);
  const [buttonText, setButtonText] = useState("Послушать");
  const [isInteractionBlocked, setIsInteractionBlocked] = useState(true);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [selectedSquares, setSelectedSquares] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [foundSquaresCount, setFoundSquaresCount] = useState(0);
  const [selectedCubes, setSelectedCubes] = useState([]);
  const [currentCubeIndex, setCurrentCubeIndex] = useState(0);
  const [cubesVisibility, setCubesVisibility] = useState({
    yellowCube: true,
    greenCube: true,
    redCube: true,
  });
  const [isPyramidComplete, setIsPyramidComplete] = useState(false);

  const [pictures, setPictures] = useState([
    {
      id: "picture1",
      angle: 90,
      correctAngle: 0,
      fixed: false,
      src: pictureOne,
    },
    {
      id: "picture2",
      angle: 180,
      correctAngle: 0,
      fixed: false,
      src: pictureTwo,
    },
    {
      id: "picture3",
      angle: 90,
      correctAngle: 0,
      fixed: false,
      src: pictureThree,
    },
    {
      id: "picture4",
      angle: 180,
      correctAngle: 0,
      fixed: false,
      src: pictureFour,
    },
  ]);

  const audioRef = useRef(null);

  const backgroundAudioRef = useRef(new Audio(backgroundSound));
  const taskZeroSoundSoundRef = useRef(new Audio(taskZeroSound));
  const taskTwoSoundSoundRef = useRef(new Audio(taskTwoSound));
  const taskThreeSoundSoundRef = useRef(new Audio(taskThreeSound));
  const taskFourSoundSoundRef = useRef(new Audio(taskFourSound));
  const praiseSoundRef = useRef(new Audio(praiseSound));

  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.onended = () => {
        setButtonDisabled(false);
        setButtonText("Далее");
      };
    }
  }, [currentTaskIndex]);

  const tasks = [
    {
      question: "Это квадрат",
      images: [{ src: square, alt: "квадрат", class: styles.square }],
      audioRef: taskZeroSoundSoundRef,
    },
    {
      question: "Собери такую же башню из кубиков",
      images: [
        {
          src: yellowCube,
          alt: "кубик",
          id: "yellowCube",
          class: styles.yellowCube,
          type: "CUBE",
        },
        {
          src: greenCube,
          alt: "кубик",
          id: "greenCube",
          class: styles.greenCube,
          type: "CUBE",
        },
        {
          src: redCube,
          alt: "кубик",
          id: "redCube",
          class: styles.redCube,
          type: "CUBE",
        },
      ],
      audioRef: taskTwoSoundSoundRef,
    },
    {
      question: "Найди предметы похожие на квадрат",
      images: [
        {
          src: greenCube,
          alt: "кубик",
          class: styles.greenCubeThirdTask,
          isSquare: true,
        },
        {
          src: camera,
          alt: "фотоаппарат",
          class: styles.camera,
          isSquare: false,
        },
        {
          src: chess,
          alt: "шахматы",
          class: styles.chess,
          isSquare: true,
        },
        {
          src: ladybird,
          alt: "божья коровка",
          class: styles.ladybird,
          isSquare: false,
        },
        {
          src: melon,
          alt: "дыня",
          class: styles.melon,
          isSquare: false,
        },
        {
          src: pie,
          alt: "пирог",
          class: styles.pie,
          isSquare: false,
        },
        {
          src: pillow,
          alt: "подушка",
          class: styles.pillow,
          isSquare: true,
        },
      ],
      audioRef: taskThreeSoundSoundRef,
    },
    {
      question: "Поверни квадраты так, чтобы получилась кошка",
      images: [
        { src: pictureOne, alt: "кубик1", class: styles.pictureOne },
        { src: pictureTwo, alt: "кубик2", class: styles.pictureTwo },
        { src: pictureThree, alt: "кубик3", class: styles.pictureThree },
        { src: pictureFour, alt: "кубик4", class: styles.pictureFour },
      ],
      audioRef: taskFourSoundSoundRef,
    },
    {
      question: "Найди предметы похожие на квадрат",
      images: [
        {
          src: greenCube,
          alt: "кубик",
          class: styles.greenCubeLastTask,
          isSquare: true,
        },

        {
          src: chess,
          alt: "шахматы",
          class: styles.chessLastTask,
          isSquare: true,
        },

        {
          src: pillow,
          alt: "подушка",
          class: styles.pillowLastTask,
          isSquare: true,
        },
        {
          src: aquarium,
          alt: "аквариум",
          class: styles.aquariumLastTask,
          isSquare: true,
        },

        {
          src: table,
          alt: "стол",
          class: styles.tableLastTask,
          isSquare: true,
        },
        {
          src: picture,
          alt: "картина",
          class: styles.pictureLastTask,
          isSquare: true,
        },
      ],
      audioRef: taskThreeSoundSoundRef,
    },
  ];

  const currentTask = tasks[currentTaskIndex];

  useEffect(() => {
    // задача 1
    if (currentTaskIndex === 1) {
      const cubesPlacedCorrectly = currentCubeIndex >= 2;
      setButtonDisabled(!cubesPlacedCorrectly && buttonText === "Далее");
    }
  }, [currentCubeIndex, currentTaskIndex, buttonText]);

  useEffect(() => {
    // задача 2
    if (currentTaskIndex === 2) {
      const itemsFoundCorrectly = selectedItems.length >= 3;
      setButtonDisabled(!itemsFoundCorrectly && buttonText === "Далее");
    }
  }, [selectedItems, currentTaskIndex, buttonText]);

  useEffect(() => {
    // задача 3
    if (currentTaskIndex === 3) {
      const allPicturesCorrect = pictures.every((picture) => picture.fixed);
      setButtonDisabled(!allPicturesCorrect && buttonText === "Далее");
    }
  }, [pictures, currentTaskIndex, buttonText]);

  const playSound = () => {
    const taskAudioRef = currentTask.audioRef;

    if (taskAudioRef && taskAudioRef.current) {
      setIsInteractionBlocked(true);
      setButtonDisabled(true);

      taskAudioRef.current.play().catch((error) => {
        console.error("Playback failed:", error);
        setButtonDisabled(false);
        setIsInteractionBlocked(false);
      });

      taskAudioRef.current.onended = () => {
        setIsInteractionBlocked(false);
        setButtonDisabled(false);
        setButtonText("Далее");
      };
    }
  };
  const handleCircleClick = (index, isSquare) => {
    if (!selectedSquares.includes(index)) {
      if (isSquare) {
        const newSelectedSquares = [...selectedSquares, index];
        setSelectedSquares(newSelectedSquares);

        const correctSound = new Audio(correctAnswerSound);
        correctSound.play();

        if (currentTaskIndex === 4) {
          const newFoundSquaresCount = newSelectedSquares.length;
          setFoundSquaresCount(newFoundSquaresCount);

          if (newFoundSquaresCount === 6) {
            setButtonDisabled(false);
            setButtonText("Далее");

            triggerFireworks();
            setTimeout(() => {
              setIsGameCompleted(true);
            }, 5000);
            praiseSoundRef.current.play();
          }
        } else if (newSelectedSquares.length === 3) {
          setButtonDisabled(false);
          setButtonText("Далее");
        }
      } else {
        const incorrectSound = new Audio(incorrectAnswerSound);
        incorrectSound.play();
      }
    }
  };

  const cubeOrder = ["greenCube", "redCube"];

  const cubePositions = [
    { top: "30%", left: "52%" },
    { top: "3%", left: "52%" },
  ];

  const handleCubeDrop = (item) => {
    const targetIndex = currentCubeIndex;

    if (item.id === cubeOrder[targetIndex]) {
      const newCube = {
        ...item,
        top: cubePositions[targetIndex].top,
        left: cubePositions[targetIndex].left,
      };

      const correctSound =
        targetIndex === 0 ? new Audio(cubeOneSound) : new Audio(cubeTwoSound);
      correctSound.play();

      setSelectedCubes((prevCubes) => [...prevCubes, newCube]);
      setCurrentCubeIndex((prevIndex) => {
        const newIndex = prevIndex + 1;
        if (newIndex >= 2) {
          setButtonDisabled(false);
          setButtonText("Далее");
        }
        return newIndex;
      });
      setCubesVisibility((prevVisibility) => ({
        ...prevVisibility,
        [item.id]: false,
      }));

      if (targetIndex === cubeOrder.length - 1) {
        setTimeout(() => {
          setIsPyramidComplete(true);
        }, 500);
      }
    } else {
      const incorrectSound = new Audio(incorrectAnswerSound);
      incorrectSound.play();
    }
  };

  const handleRotate = (pictureId) => {
    setPictures((prevPictures) => {
      const updatedPictures = prevPictures.map((picture) => {
        if (picture.id === pictureId && !picture.fixed) {
          const newAngle = (picture.angle + 90) % 360;
          const isCorrect = newAngle === picture.correctAngle;
          if (isCorrect) {
            const sound = new Audio(puzzleSound);
            sound.play();
          }
          return { ...picture, angle: newAngle, fixed: isCorrect };
        }
        return picture;
      });
      const allCorrect = updatedPictures.every((picture) => picture.fixed);
      if (allCorrect) {
        setButtonDisabled(false);
        setButtonText("Далее");
      }

      return updatedPictures;
    });
  };

  const handleNextTask = () => {
    if (buttonText === "Послушать") {
      setIsInteractionBlocked(true);
      playSound();
    } else if (buttonText === "Далее" && !buttonDisabled) {
      setImageOpacity(0);
      setTimeout(() => {
        if (currentTaskIndex + 1 < tasks.length) {
          setCurrentTaskIndex((prevIndex) => {
            const nextIndex = prevIndex + 1;
            if (nextIndex === 4) {
              setSelectedSquares([]);
            }
            if (backgroundAudioRef.current) {
              setTimeout(() => {
                backgroundAudioRef.current.play();
              }, 10);
            }
            return nextIndex;
          });
          setButtonText("Послушать");
          setButtonDisabled(false);
          setIsInteractionBlocked(true);
        }
        setImageOpacity(1);
      }, 500);
    }
  };

  useEffect(() => {
    if (currentTaskIndex === 3) {
      document.body.style.backgroundColor = "#AAD5FC";
    } else {
      document.body.style.backgroundColor = "rgba(252, 229, 170, 1)";
    }

    return () => {
      if (taskZeroSoundSoundRef.current) {
        taskZeroSoundSoundRef.current.pause();
        taskZeroSoundSoundRef.current.currentTime = 0;
      }

      if (taskTwoSoundSoundRef.current) {
        taskTwoSoundSoundRef.current.pause();
        taskTwoSoundSoundRef.current.currentTime = 0;
      }

      if (taskThreeSoundSoundRef.current) {
        taskThreeSoundSoundRef.current.pause();
        taskThreeSoundSoundRef.current.currentTime = 0;
      }

      if (taskFourSoundSoundRef.current) {
        taskFourSoundSoundRef.current.pause();
        taskFourSoundSoundRef.current.currentTime = 0;
      }

      if (praiseSoundRef.current) {
        praiseSoundRef.current.pause();
        praiseSoundRef.current.currentTime = 0;
      }

      if (backgroundAudioRef.current) {
        backgroundAudioRef.current.pause();
        backgroundAudioRef.current.currentTime = 0;
      }

      document.body.style.backgroundColor = "";

      gsap.globalTimeline.clear();
    };
  }, [currentTaskIndex]);

  return (
    <DndProvider backend={HTML5Backend}>
      {isGameCompleted && <EndGameOptions />}
      <div className={`container ${styles.someOtherClass}`}>
        <div
          className={styles.backgroundContainer}
          style={{
            pointerEvents: isInteractionBlocked ? "none" : "auto",
          }}
        >
          <h3 className={styles.header}>{currentTask.question}</h3>
          <div
            style={{
              opacity: imageOpacity,
              transition: "opacity 0.5s ease-in-out",
            }}
            className={styles.taskContainer}
          >
            {currentTaskIndex === 0 &&
              currentTask.images.map((img, index) => (
                <img
                  key={index}
                  src={img.src}
                  alt={img.alt}
                  className={img.class}
                />
              ))}
            {currentTaskIndex === 1 && (
              <div className={styles.pyramideTaskContainer}>
                <img
                  src={pyramide}
                  alt="Пирамида"
                  className={styles.pyramide}
                />

                <DropTarget onDrop={handleCubeDrop} accept={["CUBE"]}>
                  <img
                    src={yellowCube}
                    alt="Желтый кубик"
                    className={styles.yellowCube}
                  />
                  {selectedCubes.map((cube, index) => (
                    <img
                      key={index}
                      src={cube.src}
                      alt={cube.alt}
                      className={`${styles.cube} ${styles[`cube${index + 1}`]}`}
                      style={{ top: cube.top, left: cube.left }}
                    />
                  ))}
                </DropTarget>

                <div className={styles.draggableCubesContainer}>
                  {currentTask.images
                    .filter((img) => img.id !== "yellowCube")
                    .map((img, index) =>
                      cubesVisibility[img.id] ? (
                        <DraggableItem key={index} item={img} type="CUBE" />
                      ) : null
                    )}
                </div>
              </div>
            )}
            {currentTaskIndex === 2 && (
              <div className={styles.figures}>
                {currentTask.images.map((image, index) => (
                  <img
                    key={index}
                    src={image.src}
                    alt={image.alt}
                    className={`${image.class} ${
                      selectedSquares.includes(index)
                        ? styles.selectedSquares
                        : ""
                    }`}
                    onClick={() => handleCircleClick(index, image.isSquare)}
                  />
                ))}
              </div>
            )}
            {currentTaskIndex === 3 && (
              <div className={styles.cubesContainerMain}>
                <div className={styles.cubesContainer}>
                  {pictures.map((picture) => (
                    <img
                      key={picture.id}
                      src={picture.src}
                      alt={picture.id}
                      className={styles[picture.id]}
                      style={{ transform: `rotate(${picture.angle}deg)` }}
                      onClick={() => handleRotate(picture.id)}
                    />
                  ))}
                </div>
              </div>
            )}
            {currentTaskIndex === 4 && (
              <div className={styles.wallperTaskContainer}>
                <h4 className={styles.subHeader}>
                  Найдено: {foundSquaresCount}/6
                </h4>
                <img src={wallper} alt="фон" className={styles.wallper} />
                <div className={styles.lastTask}>
                  {currentTask.images.map((image, index) => (
                    <img
                      key={index}
                      src={image.src}
                      alt={image.alt}
                      className={`${image.class} ${
                        selectedSquares.includes(index)
                          ? styles.selectedSquare
                          : ""
                      }`}
                      onClick={() => handleCircleClick(index, image.isSquare)}
                    />
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>
        {currentTaskIndex < tasks.length - 1 ? (
          <button
            className={styles.button}
            onClick={handleNextTask}
            disabled={buttonDisabled}
          >
            {buttonText}
          </button>
        ) : (
          buttonText === "Послушать" && (
            <button
              className={styles.button}
              onClick={() => {
                playSound();
                setButtonText("");
              }}
              disabled={buttonDisabled}
            >
              {buttonText}
            </button>
          )
        )}
        <div className={styles.fireworksContainer}></div>
      </div>
      <audio ref={audioRef} />
      <audio ref={backgroundAudioRef} src={backgroundSound} loop />
    </DndProvider>
  );
};
export default StudyingSquareGame;
