import React from "react";
import { useDrop } from "react-dnd";

const DropTarget = ({
  id,
  targetId,
  onDrop,
  left,
  top,
  expectedWidth,
  expectedHeight,
}) => {
  const [{ canDrop, isOver }, drop] = useDrop(() => ({
    accept: "ITEM",
    drop: (item, monitor) => {
      // Вызов функции onDrop с id перетаскиваемого элемента и targetId
      onDrop(item.id, targetId);
    },
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
      canDrop: !!monitor.canDrop(),
    }),
  }));

  // console.log(targetId);

  let backgroundColor = "rgba(0,0,0,0)"; // Прозрачный фон по умолчанию

  // Динамическое изменение размера целевой области
  const style = {
    left: `${left}%`,
    top: `${top}%`,
    backgroundColor,
    width: isOver && canDrop ? `${expectedWidth}px` : `100px`, // Пример размера по умолчанию
    height: isOver && canDrop ? `${expectedHeight}px` : `100px`, // Пример размера по умолчанию
    position: "absolute", // Убедитесь, что у родителя relative
  };

  return <div ref={drop} style={style} />;
};

export default DropTarget;
