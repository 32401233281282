import { Link } from "react-router-dom";
import styles from "./games-list.module.css";

export function GamesList({ gamesData, activeCategory }) {
  const handleClick = () => {
    // console.log("click");
  };

  const filteredGames = gamesData.filter((game) => {
    return (
      game.category === activeCategory.category &&
      (!activeCategory.subcategory ||
        game.subcategory === activeCategory.subcategory)
    );
  });

  const uniqueGames = filteredGames.reduce((acc, currentGame) => {
    const isDuplicate = acc.some((game) => game.title === currentGame.title);

    if (!isDuplicate) {
      acc.push(currentGame);
    }
    return acc;
  }, []);

  return (
    <div className={styles.centerContainer}>
      <div className={styles.container}>
        {uniqueGames.map((game) => (
          <div key={game.id} className={styles.gameContainer}>
            <div className={styles.imageContainer}>
              <img
                src={game.imageUrl}
                alt={game.title}
                className={styles.image}
              />
              <h4 className={styles.gameHeader}>{game.title}</h4>
              <p className={styles.gameDescription}>{game.description}</p>
              <Link to={game.path}>
                <button className={styles.btn} onClick={handleClick}>
                  Играть
                </button>
              </Link>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default GamesList;
